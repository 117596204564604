import { Component, OnInit } from '@angular/core';
import { DiagnosticService, UserService, TopBarService,ModalService } from '../../_services/index';
import { MixpanelService } from '../../_services/mixpanel.service';
import { Router } from '@angular/router';
import { routeAnimation } from '../../_animations/index';
import { GoBackHelper } from '../../_helpers/go-back-helper';

@Component({
  selector: 'app-diagnostic-list',
  templateUrl: './diagnostic-list.component.html',
  styleUrls: ['./diagnostic-list.component.scss'],
  animations:[routeAnimation],
  // host: { '[@fadeRoute]': '' }
})
export class DiagnosticListComponent implements OnInit {

  coupons:any;
  error = '';
  groups:any = {};
  archives:any = {};
  model: any = {};
  add_open = false;
  access_code_tip = '';

  constructor(private goBackHelper:GoBackHelper, private mixpanelService:MixpanelService, private modalService:ModalService,private router: Router, private diagnosticService: DiagnosticService, private userService: UserService, private topBarService:TopBarService) { }


  goBack()
  {
    this.goBackHelper.goBack()
  } 
  ngOnInit() {
  this.mixpanelService.trackView('Diagnostic List','view');
	 this.getDiagnostics();
   // this.topBarService.setShowHamburger(true);
     this.userService.getAccessCodeTip().subscribe(
        result => {
            this.access_code_tip=result;
          },
          error => {
              this.error = error ;
          })
  }

  openModal(id) {
   this.modalService.open(id)
  }

  openResult(diagnostic_id, diagnostic) {
    let name="View";
    if (diagnostic.ended)
      name="Result"
    this.mixpanelService.trackButton(['/diagnostics/item', diagnostic_id].join('/'),'Result Diagnostic Click','button', {'button_text':name,...diagnostic});
    this.router.navigate(['/diagnostics/item', diagnostic_id]);
   }
 

  addNew(id,diagnostic_id) {
    
  	this.diagnosticService.createDiagnostic(id,diagnostic_id).subscribe(
  		result => {
          this.mixpanelService.trackButton(['diagnostics','item',result].join('/'),'Start Diagnostic Click','button', {'diagnostic_id':diagnostic_id});
  			  this.router.navigate(['diagnostics','item',result]);
  		},
        error => {
            this.error = error ;
        	console.log("error",this.error)
        })
  }

  addAccessCode() {
     if (!this.add_open)
      {
        this.add_open=true;
        return true;
      }
      if (!this.model.access_code)
        {
          this.error= "Access Code empty";
          return true;
        }
      this.userService.addAccessCode(this.model.access_code).subscribe(
        result => {
            this.add_open=false;
            this.error= "";
            this.model.access_code="";
            this.getDiagnostics()
          },
          error => {
              this.error = error ;
          })
  }

  toggleCoupon(id) {
  	 this.groups[id]=this.groups[id]^1;
  }

  getDiagnostics() {
  	this.diagnosticService.getDiagnostics()
		.subscribe(result => { 
      this.archives=result.archives;
			this.coupons=result.diagnostics;
        let i = 0;
         for(let obj of this.coupons){ 

          this.groups[obj.id]=0;
          
          if (i==0)
            this.groups[obj.id]=1;
          i++;         
         }
		});
  }

  getArchieveLink(id) {
    this.diagnosticService.getArchiveLink(id)
    .subscribe(result => {
      this.mixpanelService.trackButton(result.url,'Archive Click','download', result);
      this.openInNewTab(result.url)
    });
  }

openInNewTab(url) {
  if (window['cordova']) {
            window['cordova'].InAppBrowser.open(url, '_system');
        } else {
            window.open(url, '_blank');
        }
  // var win = window.open(url);
  // win.focus();
}

}
