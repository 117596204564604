<section class="page icalm">
	<div class="main_container blurry">
		<div class="top_control">
			<div class="top_control__back_button" (click)="backButton()">
				<span class="material-symbols-rounded"> arrow_back_ios_new </span>
				<span i18n>Back</span>
			  </div>
			  <div class="top_control__options">
					<div class="top_control__option">
						<!-- <div class="top_control__selected_value">{{sound_options.obj[selected_option.sound]?.name}}</div>
						<select title='sound' class="top_control__select" (change)="selectOption('sound',$event.target.value)"
						[(ngModel)]="selected_option.sound">
							<option *ngFor="let sound of sound_options.arr" [value]="sound.id">{{sound.name}}</option>
						</select> -->
					</div>
			  </div>
		</div>
		<div class="breath" (click)="startCalmCss(3)" [style.--ring-size]="ringSize" [style.--ring-ex-size]="ringExSize">
			<div class="breath__main" [style.width]="'calc(var(--main-breath-size) * '+scale+')'" [style.height]="'calc(var(--main-breath-size) * '+scale+')'" [style.transition]="'all '+animationDuration/1000+'s ease'">
				<div class="breath__outer"></div>
			</div>
			<div class="breath__ring"></div>
			<div class="breath__ring_ex">
				<div class="breath__text">
					{{help_text}}
				</div>
			</div>
		</div>
		<!-- <div class="breath" (click)="startCalm(3)" [style.--ring-size]="ringSize" [style.--ring-ex-size]="ringExSize">
			<div class="breath__main" [style.--breath-scale]="scale">
				<div class="breath__outer"></div>
			</div>
			<div class="breath__ring"></div>
			<div class="breath__ring_ex">
				<div class="breath__text">
					{{help_text}}
				</div>
			</div>
		</div> -->
	</div>
	<audio #audioElement loop preload>
		<source [src]="audio_source" type="audio/mpeg">
	</audio> 
</section>