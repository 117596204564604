import { Component, OnInit } from "@angular/core";
import { homeAnimation, routeAnimation } from "../_animations/index";
import { ActivatedRoute, Router } from "@angular/router";
import { JourneyService } from "../_services/journey.service";

import {
  ModalService,
  DiagnosticService,
  PassDataService,
} from "../_services/index";

@Component({
  moduleId: module.id,
  templateUrl: "./journey.component.html",
  styleUrls: ["./journey.component.scss"],
  animations: [homeAnimation, routeAnimation],
  host: { "[@fadeRoute]": "" },
})
export class JourneyComponent implements OnInit {
  id: number;
  init: boolean = false;
  confirm_modal_show: boolean = false;
  journey: any = {};
  current_task: any = {};
  prev_task: any = {};

  constructor(
    private journeyService: JourneyService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private diagnosticService: DiagnosticService,
    private passDataService: PassDataService
  ) {}

  ngOnInit(): void {
    this.unsetJourneyPassData();
    this.route.params.subscribe((params) => {
      if (isNaN(params["id"])) this.id = 0;
      else this.id = +params["id"];

      this.journeyService.getJourney(this.id).subscribe(
        (journey) => {
          this.init = true;
          this.journey = journey;
        },
        (error) => (this.init = true)
      );
    });
  }
  
  modalCloseCallback= () => {
    this.closeModal()
  }

  buttonHandler(task) {
    this.setJourneyPassData();
    switch (task.item) {
      case "simple":
        // this.modalService.close("journey_item");
        this.closeModal()
        break;
      case "diagnostic":
        if (!task.connection_arr.id)
          this.addNewDiagnostic(this.journey.coupon_id, task.connection);
        else
          this.router.navigate([
            "diagnostics",
            "item",
            parseInt(task.connection_arr.id, 10),
          ]);
        // this.router.navigate(['diagnostics','item',parseInt(task.item_id,10)]);
        break;
      case "goalkeeper":
        
        this.router.navigate(["goalkeeper"]);
        break;
      case "meditation":
        
        this.router.navigate([
          "meditate",
          "episodes",
          parseInt(task.item_id, 10),
        ]);
        break;
      case "videos":
        
        this.router.navigate(["video", "watch", parseInt(task.item_id, 10)]);
        break;
      default:
        break;
    }
  }

  setJourneyPassData() {
    this.passDataService.set("journey", this.journey.id);
  }
  unsetJourneyPassData() {
    this.passDataService.set("journey", false);
  }

  markAsCompleted() {
    // this.current_task
    this.journeyService
      .markAsDone(this.journey.id, this.current_task.id, this.journey.coupon_id)
      .subscribe((result) => {
        this.current_task.done = true;
        this.current_task.doneDays = 0;
      });
    this.confirm_modal_show = false;
    this.modalService.close("journey_item");
  }

  markAsCompletedModal(index) {
    if (typeof index !== "undefined")
      this.current_task = this.journey.items[index];
    this.confirm_modal_show = true;
  }
  markAsCompletedModalClose() {
    this.confirm_modal_show = false;
  }

  openModal(index) {
    this.current_task = this.journey.items[index];
    if (this.journey.items[index - 1])
      this.prev_task = this.journey.items[index - 1];
    else this.prev_task = false;
    this.modalService.open("journey_item");
  }

  closeModal() {
    this.modalService.close("journey_item");
    this.current_task = {};
    this.current_task.video=null;
  }

  addNewDiagnostic(id, diagnostic_id) {
    this.diagnosticService.createDiagnostic(id, diagnostic_id).subscribe(
      (result) => {
        this.router.navigate(["diagnostics", "item", parseInt(result, 10)]);
      },
      (error) => {
        console.log("error", error);
      }
    );
  }
}
