import { Component, OnInit, ViewChildren,QueryList } from '@angular/core';
import { GoalkeeperService,ModalService } from '../../_services/index';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseChartDirective } from 'ng2-charts';
import {routeAnimation } from '../../_animations/index';


@Component({
  selector: 'app-goalkeeper-result',
  templateUrl: './goalkeeper-result.component.html',
  styleUrls: ['./goalkeeper-result.component.scss'],
  animations:[routeAnimation],
  host: { '[@fadeRouteNoAbs]': '' }
})
export class GoalkeeperResultComponent implements OnInit {
@ViewChildren(BaseChartDirective) baseCharts:QueryList<BaseChartDirective>;

chart_filters:any = {};
charts:any = [];
translate:any = [];
awards:any = [];
award:any = false;
colors:any = [];
stats:any = [];
success_percent=0;
today_percent=0;
done=0;
success_text = "" ;
error = "";
colors_all = ['#ff5722', '#2196f3', '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff5722', '#795548', '#9e9e9e', '#607d8b'];
colors_average = ['#f5ca11', '#607d8b'];
init=0;
chart_expand: any = {};

  constructor(private modalService:ModalService,private route:ActivatedRoute, private router:Router, private goalkeeperService: GoalkeeperService) { }

  openModal(id) {
   this.modalService.open(id)
  }

  closeModal(id) {
   this.modalService.close(id)
  }

  nextAward() {
   this.award = this.awards.shift();
   if (!this.award)
    this.closeModal('awards');
  }

  handleAwardNext(award) {
    this.nextAward();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['done'])
         this.done = 1;
    })
  this.goalkeeperService.getResultStats("all","week","all",0).subscribe(
      stats => {
          this.charts=stats.charts;
          this.translate=stats.translate;
             for (const chart of stats.charts) {
                          this.chart_filters[chart] = this.chart_filters[chart] || {}; 
                          this.chart_filters[chart].mode="main";
                          this.chart_filters[chart].range="week";
                          if (chart=='Average'){
                            this.chart_expand[chart]=1;
                            if (stats.data[chart].dataset[0].data.length<2)
                              this.setFilterRange(chart,'all')
                          }
                          else
                            this.chart_expand[chart]=0;
                          if (stats.data[chart].dataset)
                            this.colors[chart]=stats.data[chart].dataset[0].backgroundColor;
                        }

                        // console.log(this.chart_expand);
          this.stats=stats.data;
          this.success_percent = Math.round(stats.today.today_percent - stats.today.yesterday_percent);
          this.today_percent = stats.today.today_percent;
          if (this.success_percent < 0)
                    this.success_text = "lower";
                else if (this.success_percent > 0)
                    this.success_text = "higher";
                else
                    this.success_text = "";
          this.success_percent = Math.abs(this.success_percent);
          if (this.done)
            this.goalkeeperService.getResultsAwards().subscribe(
                awards => {
                    this.init = 1;
                    // this.awards=[{"name":"Achieve all goals","color":"#8cd6ff","icon_color":"#44d400","icon":"fa-plane","info":"You've achieved all your goals. Good job","title_text":"Congratulations!"},{"name":"Another reward at same track","color":"#ded959","icon_color":"#ed0e0e","icon":"fa-heart","info":"You've achieved all your goals. Good job","title_text":"Well Done!"}];
                    this.awards=awards;

                    if (this.awards.length>0){
                      setTimeout(()=>{ 
                        this.openModal('awards');
                      },800);
                      setTimeout(()=>{ 
                        this.nextAward()
                       },2200);
                    }
                },
              error => {
                this.init = 1;
                this.error = error ;
                console.log("error",this.error)
              })
          this.init = 1;
      },
    error => {
        this.init = 1;
        this.error = error ;
      console.log("error",this.error)
    })
  }

  public lineChartOptions:any = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
         enabled: false
    },
      fill: false,
       legend: {
        enabled: true,
        display: true,
        position:"bottom",
        labels: {
            fontColor: '#3C1F7A',
            boxWidth:15,
        }
    },
    scales: {
            yAxes: [{
                ticks: {
                  fontFamily: "'icomoon','Merriweather'",
                  // fontColor: "rgba(242,242,242,.5)",
                  min: 0,
                  max: 2,
                  stepSize: 1,
                  fontSize:20,
                  fontColor:['rgba(90, 220, 95, 0.9)','rgba(255, 235, 59, 0.9)','rgba(255, 17, 0, 0.9)'],
                  // fontColor:['rgba(90, 220, 95, 0.5)','rgba(255, 235, 59, 0.5)','rgba(247, 79, 67, 0.5)'],
                    callback: function(value, index, values) {
                      var text = "";
                        if (value == 2)
                            text = "\ue9e0";
                        if (value == 1)
                            text = "\ue9f8";
                        if (value == 0)
                            text = "\ue9e6";
                        return text;
                    }
                }
            }],
            xAxes: [{
              ticks: {
                fontFamily: "'icomoon','Merriweather'",
                fontColor: '#3C1F7A',
                fontSize:15,
                }
            }]
        }
  };

  public lineChartLegend:boolean = true;
  public lineChartType:string = 'line';
 
  // events
  public chartClicked(e:any):void {
    // console.log(this.baseCharts);
  }
 
  public chartHovered(e:any):void {
    console.log(e);
  }


  updateChart(name)
  {
    this.goalkeeperService.getResultStats(this.chart_filters[name].mode,this.chart_filters[name].range,0, name).subscribe(
      stats => {
        var dataset = stats.dataset;
        var label = stats.labels;

        if (this.chart_filters[name].mode=='main'){
            dataset[0].backgroundColor=this.colors[name];
            dataset[0].borderColor=this.colors[name];
            this.stats[name]['labels']=stats.labels;
            this.stats[name].dataset = dataset
            }
            else{
              var that = this;
              this.stats[name]['labels']=stats.labels;
              this.stats[name].dataset = stats.dataset;
            }

            this.baseCharts.forEach(function(item, i){
                if (item['element'].nativeElement.id==name)
                  {
                     item.chart.destroy();
                     item.chart = null;
                     // item['chart'] = 0;
                     item.datasets = dataset
                     item.labels = label
                     item.ngOnInit();
                     
                     //     setTimeout(() => {
                     //        item.refresh()
                     //        }, 0);
                  }
                  
              })
      },
    error => {
        this.error = error ;
      console.log("error",this.error)
    })
  }

  setFilterRange(name,range)
  {
    this.chart_filters[name] = this.chart_filters[name] || {}; 
    this.chart_filters[name].range=range;
    this.updateChart(name);
  }

  setFilterMode(name)
  {
    this.chart_filters[name] = this.chart_filters[name] || {}; 
    this.chart_filters[name].mode=this.chart_filters[name].mode=="main"?"sub":"main";
    this.updateChart(name);
  }


   goNext(){  
    this.router.navigate(['goalkeeper','dashboard']); 
   }

    
  toggleChart(name) {
     this.chart_expand[name]=this.chart_expand[name]^1;
     if (this.chart_expand[name]==1)
          setTimeout(() => this.updateChart(name),1000);
  }

}
