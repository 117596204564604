<section class="page diagnostics">
  <div class="main_container blurry">
	<div class="top_control">
		<div class="top_control__back_button" (click)="goBack()">
			<span class="material-symbols-rounded"> arrow_back_ios_new </span>
			<span>Back</span>
		  </div>
		  <div class="top_control__options">
		  </div>
	</div>
    <div class="greetings">
      <div class="greetings__phrase">Resilience Diagnostic</div>
      <div class="greetings__name" i18n>
		My Diagnostics
      </div>
    </div>
    <div class="grid">
		<div class="diagnostics-container">
			<div class="coupon_container open" *ngFor="let coupon of coupons">
			  <div class="title_container">
				<div class="">{{ coupon.name }}</div>
				<div class="sub_title" i18n>
				  {{ coupon.count }} {coupon.count, plural, =0 {No Diagnostics} =1
				  {Diagnostic} other {Diagnostics}}
				</div>
				<div class="diagnostics-controls"></div>
			  </div>
			  <div class="diagnostic_container">
				<div
				  class="diagnostics-item"
				  *ngFor="let diagnostic of coupon.diagnostics"
				>
				  <div class="diagnostics-item-content">
					<div class="item_title" *ngIf="diagnostic.diag_name">
					  {{ diagnostic.diag_name }}
					</div>
					<div class="item_title" *ngIf="!diagnostic.diag_name" i18n>
					  {{ diagnostic.type_name }}-Program
					</div>
					<div class="item_subtitle_container">
					  <div class="item_subtitle" *ngIf="diagnostic.diag_name" i18n>
						{{ diagnostic.type_name }}-Program
					  </div>
					  <div
						class="item_subtitle blue"
						*ngIf="diagnostic.available && !diagnostic.ended"
						i18n
					  >
						Available
					  </div>
					  <div
						class="item_subtitle"
						*ngIf="diagnostic.available && diagnostic.ended"
						i18n
					  >
						Completed
					  </div>
					  <div class="item_subtitle" *ngIf="!diagnostic.available" i18n>
						Closed
					  </div>
					</div>
	  
					<div class="item_button">
					  <button
						*ngIf="diagnostic.id"
						(click)="openResult(diagnostic.id, diagnostic)"
						class="lazy-button"
					  >
						<div *ngIf="!diagnostic.ended" i18n>View</div>
						<div *ngIf="diagnostic.ended" i18n>Result</div>
					  </button>
					  <button
						*ngIf="!diagnostic.id && diagnostic.available"
						(click)="addNew(coupon.id, diagnostic.coup_access_id)"
						class="lazy-button"
						i18n
					  >
						Start
					  </button>
					</div>
				  </div>
				</div>
			  </div>
			</div>
	  
			<div class="coupon_container open" *ngIf="archives.length">
			  <div (click)="toggleCoupon(coupon.id)" class="title_container">
				<div class="title">Archives</div>
				<div class="sub_title" i18n>
				  {{ archives.length }} {archives.length, plural, =0 {No Archives} =1
				  {Archive} other {Archives}}
				</div>
				<div class="diagnostics-controls"></div>
			  </div>
			  <div class="diagnostic_container">
				<div class="diagnostics-item" *ngFor="let archive of archives">
				  <div class="diagnostics-item-content">
					<div class="item_title">{{ archive.title }}</div>
					<div class="item_subtitle">Diagnostic</div>
					<div class="item_button">
					  <button
						(click)="getArchieveLink(archive.id)"
						class="lazy-button"
						i18n
					  >
						PDF
					  </button>
					</div>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
	</div>
	<div class="grid orgs">
		<app-organisations-footer></app-organisations-footer>
	</div>
  </div>
</section>