
import { Component, OnInit, HostListener, QueryList, ElementRef, ViewChildren } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PassDataService, ModalService, TopBarService, MeditationService } from '../../_services/index';
import { routeAnimation, videoAnimation } from '../../_animations/index';
import { MixpanelService } from '../../_services/mixpanel.service';

@Component({
  selector: 'app-meditate-episodes',
  templateUrl: './meditate-episodes.component.html',
  styleUrls: ['./meditate-episodes.component.scss'],
  animations: [routeAnimation, videoAnimation],
  // host: { '[@slideRoute]': '' }
})
export class MeditateEpisodesComponent implements OnInit {
  episodes: any = [];
  series: any = [];
  selected_episode: any = [];
  day = 0;
  id = 0;
  error = "";
  journey = 0;
  currentId = 0;
  reminder_settings: any = {};
  reminder_time: string = "";
  reminder_popup: boolean = false;
  init: boolean = false;
  reminder_times = ["7:30", "8:00"];
  old_tag_store: string;
  @ViewChildren("medRef") medRef: QueryList<ElementRef>;
  constructor(private meta:Meta, private mixpanelService: MixpanelService, private passDataService: PassDataService, private meditationService: MeditationService, private topBarService: TopBarService, private modalService: ModalService, private route: ActivatedRoute, private router: Router) { }

  scroll = (e) => this.reminderPopupGlobal(e)
  
  ngOnDestroy() {
    document.removeEventListener('scroll', this.scroll);
    this.meta.updateTag({ content: this.old_tag_store }, 'name=theme-color');
  }

  ngOnInit() {
    this.old_tag_store=this.meta.getTag('name=theme-color').content;
    this.meta.updateTag({ content: '#DFBCB1' }, 'name=theme-color');
    document.addEventListener('scroll', this.scroll, true);
    this.makeTimesArr();
    this.journey = this.passDataService.get('journey');
    this.route.params.subscribe(params => {
      this.id = +params['id']; // (+) c
      this.mixpanelService.trackView('Meditations Episodes', 'view', { 'meditation_id': this.id });
      this.meditationService.getSeries(this.id).subscribe(
        result => {
          this.episodes = result.episodes;
          this.series = result.series;
          this.day = result.user_day;
        },
        error => {
          this.error = error;
          console.log("error", this.error)
        })

      this.meditationService.getReminder(this.id).subscribe(
        result => {
          if (result.settings.length != 0)
            this.reminder_settings = result.settings;
            this.reminder_time=result.settings.notify_time
        },
        error => {
          this.error = error;
          console.log("error", this.error)
        },
        () => {
          this.init=true;
        })

    })
  }

  reminderPopupGlobal(e){
    const _l = ['bottom_footer','meditate-popup'];
    
    // console.log(e.target,e.target.parentNode.classList,_l.some(r=> [...e.target.parentNode.classList].includes(r)))
    if ((_l.some(r=> [...e.target?.classList].join().includes(r)))||(_l.some(r=> [...e.target.parentNode?.classList].join().includes(r))))
      return true;
    if (!this.reminder_popup)
      return true;
    
    this.reminder_popup=false;
    return true;
  }

  reminderPopup(event, show){
    if (event)
      event.stopPropagation();

    this.reminder_popup=show;
  }
  reminderPopupToggle(){
    this.reminder_popup=this.reminder_popup?false:true;
    
  }

  reminderSettingsChange(event){
    this.mixpanelService.trackButton('', 'Meditation Reminder settings change', 'button', { });
    this.saveOpt()
  }
  selectNotification(event, name){
    if (event)
      event.stopPropagation();
    this.reminder_settings[name]^=1;
    this.saveOpt()
  }

  saveOpt() {
    
    this.reminder_time=this.reminder_settings.notify_time
    this.meditationService.saveReminder(this.id, this.reminder_settings).subscribe(
      result => {
        //close popup
        // this.reminderPopup(false, false);
      },
      error => {
        this.error = error;
        console.log("error", this.error)
      })
  }

  makeTimesArr() {
    var x = 30; //minutes interval
    var times = []; // time array
    var tt = 0; // start time
    var ap = ['AM', 'PM']; // AM-PM
    //loop to increment the time and push results in array
    for (var i = 0; tt < 24 * 60; i++) {
      var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
      var mm = (tt % 60); // getting minutes of the hour in 0-55 format
      times[i] = ((hh === 12 || hh === 0) ? '12' : ("0" + (hh % 12)).slice(-2)) + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
      tt = tt + x;
    }
    this.reminder_times = times;
  }

  setCurrentId(id) {
    if (id == this.currentId)
      return false;
    this.currentId = id;
    this.totalStopAllButNotCurrent(id);
    this.mixpanelService.trackButton(['/meditate/watch', id].join('/'), 'Meditation Episode Click', 'button', { 'episode_id': id });
  }

  totalStopAllButNotCurrent(id) {
    this.medRef.toArray()
      .map(r => { if (r['item']['id'] !== id) r['totalStopPlay'](); })
  }

  formatTime(duration) {
    var minutes = Math.floor(duration / 60);
    var seconds: any = duration - minutes * 60;

    if (seconds == 0)
      seconds = "00";

    if (seconds.toString().length == 1)
      seconds = "0" + seconds;

    return minutes + ":" + seconds;
  }

  openMeditateEpisode(episode_id, episode) {
    this.mixpanelService.trackButton(['/meditate/watch', episode_id].join('/'), 'Meditation Episode Click', 'button', { 'episode_id': episode_id, ...episode });
    this.router.navigate(['/meditate/watch', episode_id]);
  }

}
