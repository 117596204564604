
<div 
            #volumeBar
            class="volumeBar"
            tabindex="0"
            role="slider"
            aria-label="volume level"
            aria-level="polite"
            [attr.aria-valuenow]="ariaValue"
            aria-valuemin="0"
            aria-valuemax="100"
            [attr.aria-valuetext]="ariaValue + '%'"
            (click)="onClick($event)"
            (mousedown)="onMouseDown($event)">
            <div class="volumeBackground" [ngClass]="{dragging: isDragging}">
                <div class="volumeValue" [style.width]="(getVolume() * (100-15)) + '%'"></div>
                <div class="volumeKnob" [style.left]="(getVolume() * (100-15)) + '%'"></div>
            </div>
            <div class="volume-icon">
				<i class="fa fa-volume-down"></i>
			</div>
</div>
