import {catchError, map} from 'rxjs/operators';
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { ApiService } from './index';

@Injectable()
export class JourneyService {
  constructor(
      private apiService: ApiService) {
  }

  getJourney(id): Observable<any> {
     return this.apiService.get("journeys/"+id).pipe(
       map((response: any) => response.results.journey),
     catchError((error:any) => {
          return observableThrowError(error.error.results.error)
      }),)
  }

  markAsDone(id, item_id, coupon_id): Observable<any> {
     return this.apiService.put("journeys/"+id+"/items/"+item_id+"/done",{coupon_id:coupon_id}).pipe(
       map((response: any) => response && response.results.result),
     catchError((error:any) => {
          return observableThrowError(error.error.results.error)
      }),)
  }

}

