import { Component, OnInit } from '@angular/core';
import { GoalkeeperService, PushNotificationService } from '../../_services/index';
import { ActivatedRoute, Router } from '@angular/router';
import {routeAnimation } from '../../_animations/index';


@Component({
  selector: 'app-goalkeeper-settings',
  templateUrl: './goalkeeper-settings.component.html',
  styleUrls: ['./goalkeeper-settings.component.scss'],
  animations:[routeAnimation],
  host: { '[@fadeRouteNoAbs]': '' }
})
export class GoalkeeperSettingsComponent implements OnInit {
  setup:any = {};
  error = "";
  timezones: any = [];


  constructor(private router: Router,private goalkeeperService:GoalkeeperService, private pushService:PushNotificationService) { }

  ngOnInit() {
    console.log('registration from service',this.pushService.native_token)
    this.pushService.subscribeToPush()
  	this.setup.time="week";
  	this.setup.weeks=6;
  	this.setup.daytime={morning:0,afternoon:0,evening:0};
  	this.goalkeeperService.getSettings().subscribe(
		settings => {
			  this.setup=settings.options
        this.setup=(this.setup.length==0)?{}:this.setup;
        this.setup.daytime = this.setup.daytime || {morning:0,afternoon:0,evening:0};
        this.setup.weeks = this.setup.weeks || 6;
        this.setup.time = this.setup.time || "week";
        this.setup.remind = this.setup.remind || "none";
        this.setup.timezone = this.setup.timezone || "+12:00";
			  this.timezones=settings.timezones;
		},
		error => {
		    this.error = error ;
			console.log("error",this.error)
		})
  }


  setupTime(time) {
  	this.setup.time=time
  }

  setupDayTime(day) {
  	this.setup.daytime[day]= (this.setup.daytime[day] == 1)?0:1;
  }

 goNext() {
    if (!Object.values(this.setup.daytime).reduce((item:number,acc:number)=>{return acc+item},0))
      {
        alert("Select DayTime")
        return ;
      }
    if (this.pushService.native_token.registrationId !==''){
        this.pushService.addSubscriber(this.pushService.native_token)
            .subscribe(
              res => {
                console.log('[App] Add subscriber request answer', res)
              },
              err => {
                console.log('[App] Add subscriber request failed', err)
              }
            )
        }

  	this.goalkeeperService.saveSettings(this.setup).subscribe(
		settings => {
			this.router.navigate(['goalkeeper','dashboard']); 
		},
		error => {
		    this.error = error ;
			console.log("error",this.error)
		})
  }

  notify() {

    }

}
