import { Component, OnInit } from '@angular/core';
import { AuthenticationService,PassDataService } from '../../_services/index';
import { ActivatedRoute,Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  model: any = {};
  error = '';
  success= false;

  constructor(private authenticationService: AuthenticationService,private passDataService: PassDataService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    // console.log('tut')
  }


  goBack() {
     this.passDataService.set("remind",'1')
     this.router.navigate(['/login']);
  }


  checkRemind() {
  	 this.authenticationService.remindEmail(this.model.username)
        .subscribe(result => {
            this.success=true;
            this.error = '' ;
        },
        error => {
            this.error = error ;
        });
  }


}
