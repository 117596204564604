
import {catchError, map} from 'rxjs/operators';
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Globals } from '../globals';

import { ApiService } from './index';

@Injectable()
export class MeditationService {
    constructor(
        private apiService: ApiService) {
    }

    getSeriesList(): Observable<any> {
       return this.apiService.get("meditations/series").pipe(
       map((response: any) => response && response.results),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error);
        }),)
    }

    getSeries(id): Observable<any> {
       return this.apiService.get("meditations/series/"+id).pipe(
       map((response: any) => response && response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error);
        }),)
    }

    getReminder(id): Observable<any> {
       return this.apiService.get("meditations/reminder/"+id).pipe(
       map((response: any) => response && response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error);
        }),)
    }

    getEpisode(id): Observable<any> {
       return this.apiService.get("meditations/episodes/"+id).pipe(
       map((response: any) => response && response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error);
        }),)
    }


    getLastStatForSeries(id): Observable<any> {
       return this.apiService.get("meditations/user_progress/last_by_series/"+id).pipe(
       map((response: any) => response && response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error);
        }),)
    }

    getStats(): Observable<any> {
       return this.apiService.get("meditations/user_progress").pipe(
       map((response: any) => response && response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error);
        }),)
    }

     saveStats(episode_id, seconds): Observable<any> {
       return this.apiService.post("meditations/user_progress/"+episode_id,{seconds:seconds}).pipe(
         map((response: any) => response && response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error)
        }),)
    }

    saveReminder(id,settings): Observable<any> {
       return this.apiService.post("meditations/reminder/"+id,{settings:settings}).pipe(
         map((response: any) => response && response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error)
        }),)
    }

    saveViews(episode_id): Observable<any> {
       return this.apiService.post("meditations/user_progress/views/"+episode_id,{}).pipe(
         map((response: any) => response && response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error)
        }),)
    }

    saveSession(episode_id, rate, seconds): Observable<any> {
       return this.apiService.post("meditations/user_progress/session/"+episode_id,{rate:rate, seconds:seconds}).pipe(
         map((response: any) => response && response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error)
        }),)
    }
    
    endQuiz(quiz_id,answers): Observable<any> {
       return this.apiService.post("meditations/user_progress/quiz/"+quiz_id,{answers:answers}).pipe(
         map((response: any) => response && response.results),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error)
        }),)
    }



}
