import { Injectable } from '@angular/core';

@Injectable()
export class PassDataService {
	data:any = {};
  constructor() { }


  set(name,value, storage=false)
  {
  	  this.data[name]=value;
      if (storage)
        localStorage.setItem(name, value);
  }

  get(name)
  {
  	if (this.data[name])
	  	return this.data[name]
	else{
    let _storage = localStorage.getItem(name);
    if (_storage)
      return _storage
	  return false;
  }
    
  }
}
