 <div [ngClass]="{'cutout':globals.cutout,'open': isOpen,'no-header':noHeader,'no-padding':noPadding,'hide-close':hideClose,'need-absolute':needAbsolute}">
        <div class="modal-overlay" (click)="close(true)"></div>

        <div class="app_modal main_container" [ngStyle]="{'z-index':zIndex}">
          <div *ngIf="!noHeader"  class="title" >
            <span *ngIf="modalTitle" class="title-text">{{ modalTitle }}</span>
            <span class="right-align close_button" (click)="close(true)"><i class="material-icons md-24">clear</i></span>
          </div>

          <div class="body">
            <ng-content></ng-content>
          </div>
        </div>
</div>