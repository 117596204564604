<div class="page">
            <div  class="access_page">
                  <div class="main_content">
                        <div class="title title_top" i18n="access code page title: access code page title@@access_code_main_title">
                             Access Codes
                        </div> 

                        <div class="error_text">
                        	{{error}}
                        </div>

                       <div class="main_container">
                        <br>
                        <div *ngIf="!list.length" class="access_code_validate">
                            <div class="form-group ">
                              <input type="text" [(ngModel)]="model.access_code" required class="form-control" placeholder="Access Code" name="access_code">
                              <i class="fa fa-key"></i>
                            </div>  
                            <br>
                            <button (click)="addAccessCode()" class="form-button" i18n="access code page button: access code page button@@access_code_main_button">Validate<span><i class="fa fa-caret-right"></i></span></button>
                          </div>
                         
                          <div *ngIf="list.length"> 
                            <div [routerLink]="['/'+item.url]" class="list" *ngFor="let item of list; let i = index">
                              <div  >
                                <div class="item_container">
                                  <div class="count">{{i+1}}.</div>
                                  <div class="text_resource">{{item.title}}</div>
                                </div>
                                <span class="go_to_resource"> <i class="fa fa-chevron-right" aria-hidden="true"></i></span>
                              </div>
                            </div>
                        </div>

                       </div>

                      
                  </div>        
        
            </div>
  </div>

  