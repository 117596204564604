 <div class="quiz_container" *ngIf="mode==0" @fadeChange>
        <div class="title_quiz">{{quiz.name}}</div>
        <div class="questions_select">
        	<div class="question_round" (click)="chooseQuestion(i)" *ngFor="let question of quiz.questions; let i = index;">	
            	<div [ngClass]="{'active':question.id==question_selected.id}" class='number_container'>
            		<div class="number">
            			{{i+1}}
            		</div>
            	</div>
            	
        	</div>

        </div>
        <div class="main_text_container">
          <div class="main_text">
          		<div class="question_title">{{question_selected.name}}</div>
          		<div class="options_container">
          			<div class="option" [ngClass]="{'active':option.id==quiz.result.answers[question_selected.id],'error':(highlights[question_selected.id]?highlights[question_selected.id][option.id]:-1)==0,'valid':(highlights[question_selected.id]?highlights[question_selected.id][option.id]:-1)==1}" (click)="chooseOption(question_selected.id, option.id)" *ngFor="let option of question_selected.options">
          				{{option.name}}
          			</div>
          		</div>
          		<br>
          		 <button *ngIf="quiz.questions[question_cursor+1]" (click)=chooseNextQuestion() class="form-button" i18n>Next Question<span><i class="fa fa-caret-right"></i></span></button>
          		 <button *ngIf="!quiz.questions[question_cursor+1]" (click)=endQuiz() class="form-button" i18n>End Quiz<span><i class="fa fa-caret-right"></i></span></button>
          </div>
          <br><br>
        </div>
</div> 

 <div class="quiz_container" *ngIf="mode==2" @fadeChange>
        <div class="main_text_container">
          <div class="middle_text">
              <div class="question_title" i18n>Sorry! You have some mistakes in the Quiz</div>
              <br>
               <button (click)=tryAgain() class="form-button" i18n>Try Again<span><i class="fa fa-caret-right"></i></span></button>
               <br>
               <button (click)=quitFromQuiz() class="form-button" i18n>Quit<span><i class="fa fa-caret-right"></i></span></button>
          </div>
          <br><br>
        </div>
</div> 

 <div class="quiz_container" *ngIf="mode==1" @fadeChange>
        <div class="main_text_container">
          <div class="middle_text success">
              <awards-container [award]='award' (awardNext)="handleAwardNext($event)"></awards-container>
              <br>
          </div>
          <br><br>
        </div>
</div> 