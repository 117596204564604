// import the required animation functions from the angular animations module
import { trigger, state, animate, transition, style, query, animateChild, group } from '@angular/animations';
 
export const loginMaterialAnimation =
    // trigger name for attaching this animation to an element using the [@triggerName] syntax
    [trigger('loginAnimation', [
      state('start', style("*")),
      state('triggered', style({display:"none"})),     
      transition('start => triggered', [
        style("*"),
        query("div", [
              animate("700ms cubic-bezier(0.35, 0, 0.25, 1)", style({ opacity: "0" })),
        ]),
        style({ overflow: "hidden" }),     
        animate("600ms cubic-bezier(0.35, 0, 0.25, 1)", style({ opacity: "0",height:"0","padding-top":"0" })),
        animate("100ms cubic-bezier(0.35, 0, 0.25, 1)", style({ border: "0" }))
      ]),
      transition('triggered => start', [
        
      ])
    ]),
    trigger('loginAnimationForm', [
      state('triggered', style("*")),
      state('start', style({display:"none"})),
      transition('start => triggered', [
        style("*"),
        group([
          query(".control_buttons", [
              style({opacity: "0"}),
              animate("1ms cubic-bezier(0.35, 0, 0.25, 1)", style({ opacity: "0" })),
          ]),
          query(".form_container", [
                style({opacity: "0"}),
                animate("1ms cubic-bezier(0.35, 0, 0.25, 1)", style({ opacity: "0" })),
          ]),
          query(".form_content", [
                style({opacity: "0"}),
                animate("1ms cubic-bezier(0.35, 0, 0.25, 1)", style({ opacity: "0" })),
          ]),
          ]),
        query('.form_container', [
              style({height: "auto","max-height":"0"}),
        ]),
        query('.control_buttons', [
              style({opacity: "0"}),
              animate("400ms cubic-bezier(0.35, 0, 0.25, 1)", style({ opacity: "1" })),
        ]),
        query('.form_container', [
              animate("400ms cubic-bezier(0.35, 0, 0.25, 1)", style({ opacity: "1" })),
        ]),
        query('.form_container', [
              animate("500ms cubic-bezier(0.35, 0, 0.25, 1)", style({ "max-height": "400px" })),
        ]),
        query('.form_content', [
              animate("500ms cubic-bezier(0.35, 0, 0.25, 1)", style({ opacity: "1" })),
        ]),
        
      ]),

    ]),
    trigger('loginAnimationTitle', [
      state('triggered', style("*")),
      state('start', style({display:"none"})),    
      transition('start => triggered', [
        style({opacity:0}),
        animate("500ms cubic-bezier(0.35, 0, 0.25, 1)", style({ opacity: "1" })),
      ]),
      transition('triggered => start', [
        
      ])
    ]),
    trigger('formTestAnimation', [
      transition('void => signup', [
              style({opacity:0}),
              animate("1000ms cubic-bezier(0.35, 0, 0.25, 1)", style({ opacity: "1" })),
       
      ]),
      transition('signup => void', [
          
      ]),
      transition('void => login', [
              style({opacity:0}),
              animate("1000ms cubic-bezier(0.35, 0, 0.25, 1)", style({ opacity: "1" })),
       
      ]),
      transition('login => void', [

      ])
    ]),
    trigger('fadeChange', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1000ms cubic-bezier(0.35, 0, 0.25, 1)', style("*"))
      ]),
      transition(':leave', [
        // style({ opacity: 1,position:'absolute',left:'50%',transform:'translateX(-50%)' }),
        // animate('1000ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 0 }))
      ]),
    ])
    ]
// 







