
import {catchError, map} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Globals } from '../globals';




import { ApiService } from './index';

@Injectable()
export class DiagnosticService {
    constructor(
        private apiService: ApiService) {
    }

    getDiagnostics(): Observable<any> {
       return this.apiService.get("diagnostics/").pipe(
       map((response: any) => response.results),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error);
        }),)
    }

    getArchiveLink(id): Observable<any> {
       return this.apiService.get("diagnostics/archive_link/"+id).pipe(
         map((response: any) => response['results']),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error)
        }),)
    }

    getDiagnostic(id): Observable<any> {
       return this.apiService.get("diagnostics/"+id).pipe(
         map((response: any) => response.results.diagnostic),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error)
        }),)
    }

    getDiagnosticStats(id): Observable<any> {
       return this.apiService.get("diagnostics/"+id+"/stats").pipe(
         map((response: any) => response.results.stats),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error)
        }),)
    }

    getDiagnosticAnswers(id): Observable<any> {
       return this.apiService.get("diagnostics/"+id+"/answers").pipe(
         map((response: any) => response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error)
        }),)
    }

    getDiagnosticPdf(id): Observable<any> {
       return this.apiService.download("diagnostics/"+id+"/pdf",'pdf').pipe(
         map((response: any) => response),
       catchError((error:any) => {
            // console.log(error)
            return observableThrowError(error.error.results.error)
        }),)
    }

    shareResult(id,emails): Observable<any> {
       return this.apiService.post("diagnostics/"+id+"/share",{emails:emails}).pipe(
         map((response: any) => response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error)
        }),)
    }

    createDiagnostic(coupon_id,diagnostic_id): Observable<any> {
       return this.apiService.post("diagnostics/new/"+coupon_id,{diagnostic_id:diagnostic_id}).pipe(
         map((response: any) => response && response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error)
        }),)
    }

    saveAnswer(result_id,question_id,answer): Observable<any> {
       return this.apiService.put("diagnostics/"+result_id+"/answer/"+question_id,{answer:answer}).pipe(
         map((response: any) => response && response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error)
        }),)
    }

    saveAddFields(result_id,options): Observable<any> {
       return this.apiService.post("diagnostics/"+result_id+"/additional_fields",{options:options}).pipe(
         map((response: any) => response && response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error)
        }),)
    }

    connectToGoalkeeper(keeper_id): Observable<any> {
       return this.apiService.put("diagnostics/connect/goalkeeper",{id:keeper_id}).pipe(
         map((response: any) => response && response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error)
        }),)
    }

    submitDiagnostic(result_id): Observable<any> {
       return this.apiService.put("diagnostics/"+result_id+"/submit",{}).pipe(
         map((response: any) => response && response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error)
        }),)
    }

}






