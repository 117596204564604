export class User {
	public username: string;
	public firstName: string;
	public lastName: string;
  public sex: string;
  public phone: string;

  constructor(public data:any){
  	this.username = data.login
  	this.firstName = data.first_name
  	this.lastName = data.last_name
    this.sex = data.sex
    this.phone = data.phone
  }

  get fullName(): string {
    return this.firstName + ' ' + this.lastName;
  }
}