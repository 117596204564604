import { Injectable } from '@angular/core';
import { ModalComponent } from '../_helpers/modal/modal.component';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class ModalService {
  private modals: Array<ModalComponent>;
  private stopScroll = new BehaviorSubject<boolean>(false); // true is your initial value
  stopScroll$ = this.stopScroll.asObservable();

  constructor() {
    this.modals = [];
  }

  setStopScroll(value: boolean) {
    this.stopScroll.next(value);
    // console.log('Show Hamburger', value);
  }

  registerModal(newModal: ModalComponent): void {
    const modal = this.findModal(newModal.modalId);

    // Delete existing to replace the modal
    if (modal) {
      this.modals.splice(this.modals.indexOf(modal));
    }
    this.modals.push(newModal);
  }

  get(modalId: string){
    return this.findModal(modalId);
  }

  open(modalId: string, payload:any = {}): void {
    const modal = this.findModal(modalId);
    if (modal) {
      modal.setPayload(payload);
      modal.isOpen = true;
    }
    this.check()
  }

  list(): void {
    for (const modal of this.modals) {
      console.log("was modal check", modal,modal.isOpen)
    }
  }

  check(): void {
    let open = false;
    for (const modal of this.modals) {
      if (modal.isOpen){
        open = true
        break;
      }
    }
    //console.log("was modal check", open)
    if (open)
      this.setStopScroll(true);
    else
      this.setStopScroll(false);

  }

  close(modalId: string, checkBlocking = false): void {
    const modal = this.findModal(modalId);
   
    if (modal) {
      if (checkBlocking && modal.blocking) {
        return;
      } 
      modal.isOpen = false;
    }
    this.check()
  }

  private findModal(modalId: string): ModalComponent {
    for (const modal of this.modals) {
      if (modal.modalId === modalId) {
        return modal;
      }
    }
    return null;
  }
}
