import {
    Component, ElementRef, Input, HostListener, OnInit, ViewEncapsulation, HostBinding,
    OnDestroy
} from '@angular/core';
import {VgApiService, VgStates} from '@videogular/ngx-videogular/core';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-video-watch-seeker',
  templateUrl: './video-watch-seeker.component.html',
  styleUrls: ['./video-watch-seeker.component.scss']
})
export class VideoWatchSeekerComponent implements OnInit,OnDestroy {
    @Input() vgFor: string;
    @Input() vgSlider: boolean = true;
    // current_percent:any;
    @Input() percent:any;
    @Input() buffer:any;


    API: VgApiService
    @Input('api')
    set api(itm: VgApiService) {
	   if (itm){
	   		this.API = itm; 
	   		this.target = this.API.getMediaById();   		
		 }
	  }	

    elem: HTMLElement;
    target: any;
    isSeeking: boolean = false;
    wasPlaying: boolean = false;

    subscriptions: Subscription[] = [];

    constructor(ref: ElementRef) {
        this.elem = ref.nativeElement;
    }

    ngOnInit() {
       
    }

    protected seekStart() {
        if (this.target.canPlay) {
            this.isSeeking = true;
            if (this.target.state === "playing") {
                this.wasPlaying = true;
            }
            this.target.pause();
        }
    }

    protected seekMove(offset: number) {
        if (this.isSeeking) {

            let percentage = Math.max(Math.min(offset * 100 / (<Element>this.elem.childNodes[0]).scrollWidth, 99.9), 0);
            this.target.time.current = percentage * this.target.time.total / 100;
            this.target.seekTime(percentage, true);
        }
    }

    protected seekEnd(offset: number) {
        this.isSeeking = false;
        if (this.target.canPlay) {
            let percentage = Math.max(Math.min(offset * 100 / (<Element>this.elem.childNodes[0]).scrollWidth, 99.9), 0);
            this.target.seekTime(percentage, true);
            if (this.wasPlaying) {
                this.wasPlaying = false;
                this.target.play();
            }
        }
    }

    protected touchEnd() {
        this.isSeeking = false;
        if (this.wasPlaying) {
            this.wasPlaying = false;
            this.target.play();
        }
    }

    protected getTouchOffset(event: any) {
        let offsetLeft: number = 0;
        let element: any = event.target;
        while (element) {
            offsetLeft += element.offsetLeft;
            element = element.offsetParent;
        }
        return event.touches[ 0 ].pageX - offsetLeft;
    }

    @HostListener('mousedown', [ '$event' ])
    onMouseDownScrubBar($event: any) {
        if (this.target) {
            if (!this.target.isLive) {
                if (!this.vgSlider) {

                    this.seekEnd($event.offsetX);
                }
                else {
                    this.seekStart();
                }
            }
        }
    }

    @HostListener('document:mousemove', [ '$event' ])
    onMouseMoveScrubBar($event: any) {
        if (this.target) {
            if (!this.target.isLive && this.vgSlider && this.isSeeking) {
            	// console.log($event.target.className,$event.target.parentNode.offsetX)
            	// $event.target.className=="handle"
                this.seekMove($event.offsetX);
            }
        }
    }

    @HostListener('document:mouseup', [ '$event' ])
    onMouseUpScrubBar($event: any) {
        if (this.target) {
            if (!this.target.isLive && this.vgSlider && this.isSeeking) {
                this.seekEnd($event.offsetX);
            }
        }
    }

    @HostListener('touchstart', [ '$event' ])
    onTouchStartScrubBar($event: any) {
        if (this.target) {
            if (!this.target.isLive) {
                if (!this.vgSlider) {
                    this.seekEnd(this.getTouchOffset($event));
                }
                else {
                    this.seekStart();
                }
            }
        }
    }

    @HostListener('document:touchmove', [ '$event' ])
    onTouchMoveScrubBar($event: any) {
        if (this.target) {
            if (!this.target.isLive && this.vgSlider && this.isSeeking) {
                this.seekMove(this.getTouchOffset($event));
            }
        }
    }

    @HostListener('document:touchcancel', [ '$event' ])
    onTouchCancelScrubBar($event: any) {
        if (this.target) {
            if (!this.target.isLive && this.vgSlider && this.isSeeking) {
                this.touchEnd();
            }
        }
    }

    @HostListener('document:touchend', [ '$event' ])
    onTouchEndScrubBar($event: any) {
        if (this.target) {
            if (!this.target.isLive && this.vgSlider && this.isSeeking) {
                this.touchEnd();
            }
        }
    }

    @HostListener('keydown', ['$event'])
    arrowAdjustVolume(event: KeyboardEvent) {
        if (this.target) {
            if (event.keyCode === 38 || event.keyCode === 39) {
                event.preventDefault();
                this.target.seekTime((this.target.time.current + 5000) / 1000, false);
            }
            else if (event.keyCode === 37 || event.keyCode === 40) {
                event.preventDefault();
                this.target.seekTime((this.target.time.current - 5000) / 1000, false);
            }
        }
    }

    getPercentage() {
        return this.target ? ((this.target.time.current * 100) / this.target.time.total) + '%' : '0%';
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}