import { Component, OnInit } from '@angular/core';
import { UserService,TopBarService } from '../_services/index';

@Component({
  selector: 'app-organisations-footer',
  templateUrl: './organisations-footer.component.html',
  styleUrls: ['./organisations-footer.component.scss']
})
export class OrganisationsFooterComponent implements OnInit {
   organisations=[];
   init = false;
  constructor(private userService: UserService) { }

  ngOnInit() {
  	    this.userService.getUserOrganisations()
        .subscribe(organisations => {
            this.organisations=organisations
            this.organisations = this.organisations.filter(item => item.logo!=="")
            this.init = true;
        },error => this.init = true);
 	
  }

}
