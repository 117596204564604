<section class="page weekly_tip">
  <div class="main_container blurry">
    <div class="top_control">
      <div class="top_control__back_button" (click)="goBack()">
        <span class="material-symbols-rounded"> arrow_back_ios_new </span>
        <span i18n>Back</span>
      </div>
      <div class="top_control__options">
        <div class="top_control__option">
        </div>
      </div>
    </div>
    <article>
      <h2>
        {{ weekly_tip.title }}
      </h2>
      <img [src]="weekly_tip.image" />
      <p [innerHTML]="weekly_tip.text"></p>
    </article>
  </div>
</section>
