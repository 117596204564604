  <div *ngFor="let item of activeItems" class="card_preview" @previewContentAnimation >
                  <div class="card_preview_content">
                    <div class="title_text_column">
                        <div class="title">
                        {{item.title}}
                        </div>
                        <div class="text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris et tortor nisi. Donec a pulvinar dui, sed tincidunt magna. Fusce non sollicitudin tellus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Ut consequat dui metus, vel iaculis ante dictum sed. Nam commodo neque quis lacus laoreet, vitae eleifend libero semper. Donec eu est a leo rutrum volutpat eget tincidunt sapien. Sed vel porttitor tellus. 
                        </div>
                    </div>
                    <div class="button_image_column">
                        <button mat-fab color="primary"><mat-icon>forward</mat-icon></button>
                    </div>
                  </div>
</div>