<header>
  <div class="logo"></div>
  <div class="my-account"></div>
</header>
<aside>
  <nav>
    <ul>
      <li><a href="#">Home</a></li>
      <li><a href="#">Meditations</a></li>
      <li><a href="#">Video</a></li>
      <li><a href="#">Goals</a></li>
      <li><a href="#">Resources</a></li>
    </ul>
  </nav>
</aside>
<div class="bg_image" >
	<img [src]="chooseBG(0)" [ngClass]="{'standalone':!isLoggedIn}">
</div>
<div class="bg_image_big" >
	<img [src]="chooseBG(1)" [ngClass]="{'standalone':!isLoggedIn}">
</div>
<main id="main" #main [ngClass]="{'no-scroll':stopScroll,'standalone':!isLoggedIn}">
  	<router-outlet></router-outlet>
</main>
<footer *ngIf="isLoggedIn">
  <nav>
    <img src="/assets/img/logo-new.png" alt="logo" srcset="">
    <ul class="bottom-navbar">
      <li [routerLink]="['/']" [ngClass]="navBarActive('/', true)">
        <span class="r-icon-homeFilled"></span>
        <span i18n>Home</span>
      </li>
      <li [routerLink]="['/meditate/episodes', meditation]" [ngClass]="navBarActive('/meditate/')">
        <span class="r-icon-meditationFilled"></span>
        <span i18n>Meditations</span>
      </li>
      <li [routerLink]="['/video/series']" [ngClass]="navBarActive('/video/')">
        <span class="r-icon-video2"></span>
        <span i18n>Videos</span>
      </li>
      <li [routerLink]="['/goalkeeper']" [ngClass]="navBarActive('/goalkeeper/')">
        <span class="r-icon-goal2"></span>
        <span i18n>Goals</span>
      </li>
      <li [routerLink]="['/resources']" [ngClass]="navBarActive('/resources/')">
        <span class="r-icon-resource2"></span>
        <span i18n>Resources</span>
      </li>
      <li [routerLink]="['/myaccount']" [ngClass]="navBarActive('/myaccount/')" class="nav-myaccount">
        <span class="material-symbols-rounded"> person </span>
        <span i18n>My Account</span>
      </li>
    </ul>
  </nav>
</footer>
<app-modal [blocking]='false' [modalId]="'push-notification'">
  <div class="push_noty_modal">
   <div class="title_modal" i18n>In-App Notification</div>
   <div class="main_text_container">
     <div class="main_text">
       {{notification.body}}
     </div>
     <div class="button">
       <button *ngIf='notification.url' i18n class="lazy-button" (click)="goTo(notification.url)" i18n>Go!</button>
     </div>
     <br><br>
   </div>
   </div> 
</app-modal>
<app-modal [blocking]='false' [modalId]="'greetings'">
<div class="greetings_modal">
<div class="main_text_container">
  <div class="main_text">
 <h1>{{greetings.title}}</h1>
 <div *ngIf="greetings.image" class='image_body'>
   <img [src]="greetings.image" />
 </div>
 <div *ngIf="greetings.video" class='video_body'>
   <small-video-player [preload]="'metadata'" [video]="greetings.video" ></small-video-player>
 </div>
 
   <div class='text_body' [innerHTML]="greetings.body"></div>
  </div>
  <button type="button" (click)="closeGreetings()"
            title="close_greetings"
            class="action action--simple-button close-greetings">
            {{greetings.button_text}}
  </button>
  <br><br>
</div>
</div> 
</app-modal>
<!-- <app-chatbot></app-chatbot> -->
