
import {catchError, map} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable,of, BehaviorSubject } from 'rxjs';
import { Injectable,LOCALE_ID,Inject } from '@angular/core';
import { Globals } from '../globals';




import { ApiService } from './index';
import { User } from '../_models/index';

@Injectable()
export class UserService {
  private user: User | null = null;
  private userData: any;
  private homeDataSubject = new BehaviorSubject<any>(null);
  homeData$ = this.homeDataSubject.asObservable();

    constructor(
        private apiService: ApiService,@Inject(LOCALE_ID) locale: string) {
      // console.log("Localka",locale)
    }

  


    getUser(): Observable<User> {

      if (this.user) 
        return of(this.user);

       return this.apiService.get("users/me").pipe(
         map((response: any) => {
          this.userData=response && response.results
          return response && response.results.user
        }),
         map((data) => {
          this.user = new User(data)
          return this.user
        }),
       catchError((error:any) => {
            console.log(error)
            return observableThrowError(error.error.results.error);
        }),)
    }

   

    getUserHomeData(): Observable<any> {
       return this.apiService.get("users/me/home").pipe(
         map((response: any) => response && response.results),
       catchError((error:any) => {
            console.log(error)
            return observableThrowError(error.error.results.error);
        }),)
    }

    refreshHomeDataCache() {
      this.apiService.get("users/me/home").pipe(
        map((response: any) => response && response.results),
        catchError((error:any) => {
          console.log(error)
          return observableThrowError(error.error.results.error);
        })
      ).subscribe(
        (data) => {
          // console.log("fresh data:", data);
          // update the value of the BehaviorSubject with the fresh data
          this.homeDataSubject.next(data);
        },
        (error) => {
          console.error(error);
        },
        () => {
          // console.log("completed");
        }
      );
    }

    getUserOrganisations(): Observable<any> {
       return this.apiService.get("users/me/organisations").pipe(
         map((response: any) => response && response.results),
       catchError((error:any) => {
            console.log(error)
            return observableThrowError(error.error.results.error);
        }),)
    }

    getUnseenNotifications(): Observable<any> {
       return this.apiService.get("users/notifications/unseen").pipe(
         map((response: any) => response && response.results.count),
       catchError((error:any) => {
            return observableThrowError(error);
        }),)
    }

    getNotifications(offset): Observable<any> {
      offset = (typeof offset !== "undefined")?offset:0;
       return this.apiService.get("users/notifications/list/"+offset).pipe(
         map((response: any) => response&& response.results.notifications),
       catchError((error:any) => {
            console.log(error)
            return observableThrowError(error.error.results.error);
        }),)
    }

    seenNotification(id): Observable<any> {
       return this.apiService.put("users/notifications/seen",{id:id}).pipe(
         map((response: any) => response ),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error);
        }),)
    }

    getUserData(): Observable<any> {
      if (this.userData)
        return of(this.userData);

       return this.apiService.get("users/me").pipe(
         map((response: any) => {
          this.userData=response && response.results
          return this.userData
        }),
       catchError((error:any) => {
            console.log(error)
            return observableThrowError(error.error.results.error);
        }),)
    }

    saveUserData(data): Observable<any> {
       return this.apiService.put("users/me",{data:data}).pipe(
         map((response: any) => response && response.results ),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error);
        }),)
    }

    addAccessCode(data): Observable<any> {
       return this.apiService.post("users/access_code/add",{access_code:data}).pipe(
         map((response: any) => response && response.results.result ),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error);
        }),)
    }

    getAccessCodeTip(): Observable<any> {
       return this.apiService.get("users/access_code/tip").pipe(
         map((response: any) => response && response.results.result),
       catchError((error:any) => {
            console.log(error)
            return observableThrowError(error.error.results.error);
        }),)
    }


    deleteAccount(reason): Observable<any> {
       return this.apiService.post("users/delete",{reason:reason}).pipe(
         map((response: any) => response && response.results.result ),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error);
        }),)
    }

    sendFeedbackAnswer(answer): Observable<any> {
       return this.apiService.post("users/feedbacks/answer",{answer:answer}).pipe(
         map((response: any) => response && response.results.result ),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error);
        }),)
    }

    answerDiagnosticTips(answer, data): Observable<any> {
       return this.apiService.post("users/diagnostic_tip",{answer:answer,data:data}).pipe(
         map((response: any) => response && response.results.result ),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error);
        }),)
    }

    systemFlowbotAnswer(answer, data, mode, session_id): Observable<any> {
       return this.apiService.post("users/flowbot/answer",{answer:answer,data:data,mode:mode, session_id:session_id}).pipe(
         map((response: any) => response && response.results.result ),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error);
        }),)
    }

    flowbotSession(): Observable<any> {
       return this.apiService.post("users/flowbot/session",{}).pipe(
         map((response: any) => response && response.results.result ),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error);
        }),)
    }

    getUserConfiguration(): Observable<any> {
    let offset = new Date().getTimezoneOffset()
    // offset = -360;
    let dirty_hours=Math.abs(offset/60)
    let offset_hr = Math.floor(dirty_hours);
    let offset_m = Math.floor((dirty_hours - offset_hr) * 60);

    let tz = (offset<0 ? "+" : "-") + (offset_hr.toString().length<2?"0"+offset_hr.toString():offset_hr.toString())+":"+(offset_m.toString().length<2?"0"+offset_m.toString():offset_m.toString());

       return this.apiService.get("users/me/configuration?tz="+ encodeURIComponent(tz)).pipe(
         map((response: any) => response && response.results),
       catchError((error:any) => {
            console.log(error)
            return observableThrowError(error.error.results.error);
        }),)
    }

      getUserResources(): Observable<any> {
       return this.apiService.get("users/resources").pipe(
         map((response: any) => response && response.results),
       catchError((error:any) => {
            console.log(error)
            return observableThrowError(error.error.results.error);
        }),)
    }

    getWearableUrl(type): Observable<any> {
       return this.apiService.get("users/wearable/"+type+"/url").pipe(
         map((response: any) => response && response.results),
       catchError((error:any) => {
            console.log(error)
            return observableThrowError(error.error.results.error);
        }),)
    }

    deleteWearable(type): Observable<any> {
       return this.apiService.delete("users/wearable/"+type+"/delete").pipe(
         map((response: any) => response && response.results),
       catchError((error:any) => {
            console.log(error)
            return observableThrowError(error.error.results.error);
        }),)
    }


    connectWearable(type, code): Observable<any> {
       return this.apiService.post("users/wearable/"+type+"/connect",{code:code}).pipe(
         map((response: any) => response && response.results ),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error);
        }),)
    }

    toggle2Fa(): Observable<any> {
      return this.apiService.post("users/2fa",{}).pipe(
        map((response: any) => response && response.results ),
      catchError((error:any) => {
           return observableThrowError(error.error.results.error);
       }),)
   }

    getWearableChartData(type,item,mode): Observable<any> {
       return this.apiService.get("users/wearable_chart/"+type+"/"+item+"/"+mode).pipe(
         map((response: any) => response && response.results.wearable),
       catchError((error:any) => {
            console.log(error)
            return observableThrowError(error.error.results.error);
        }),)
    }

    getWeeklyTip(): Observable<any> {
       return this.apiService.get("users/weekly_tip").pipe(
         map((response: any) => response && response.results.weekly_tip),
       catchError((error:any) => {
            console.log(error)
            return observableThrowError(error.error.results.error);
        }),)
    }

    getEmotionalCombat(): Observable<any> {
      return this.apiService.get("users/emotional_combat").pipe(
        map((response: any) => response && response.results),
      catchError((error:any) => {
           console.log(error)
           return observableThrowError(error.error.results.error);
       }),)
   }

   getInstantCalm(): Observable<any> {
    return this.apiService.get("users/instant_calm").pipe(
      map((response: any) => response && response.results),
    catchError((error:any) => {
         console.log(error)
         return observableThrowError(error.error.results.error);
     }),)
 }

 instantCalmProgress(count:number): Observable<any> {
  return this.apiService.post("users/instant_calm",{count:count}).pipe(
    map((response: any) => response && response.results),
  catchError((error:any) => {
       return observableThrowError(error.results.error)
   }),)
}

openSubscriptionWebinar(item_id:number,info:string): Observable<any> {
  return this.apiService.post("users/open_subscription_webinar",{item_id:item_id,info:info}).pipe(
    map((response: any) => response && response.results),
  catchError((error:any) => {
       return observableThrowError(error.results.error)
   }),)
}



   rateEmotionalCombat(factor_id,rate): Observable<any> {
    return this.apiService.post("users/emotional_combat/"+factor_id,{rate:rate}).pipe(
      map((response: any) => response && response.results),
    catchError((error:any) => {
         return observableThrowError(error.results.error)
     }),)
 }

}