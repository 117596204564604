<div class="keeper_container">
	<div class="title_small" i18n>
	What is something significant you would like to achieve by the end of tomorrow? This will appear on tomorrow’s tracking screen.
	</div>
	<div class="middle_container mygoal">
		<div class="mygoal_container" *ngFor="let mygoal of mygoals">
			<div class="mygoal_container_inner input_container">
				<input type="text" class="autofocus_need" myAutofocus [(ngModel)]="mygoal.name">
				<input type="hidden" [(ngModel)]="mygoal.id">
			</div>
			<div class="mygoal_container_inner delete_button_container">
				<i class="fa fa-minus-circle" (click)="removeRow(mygoal)"></i>
			</div>
		</div>				
		<div class="icon_button"><i (click)="addRow()" class="fa fa-plus-circle"></i></div>
	</div>

	<div class="bottom_container">
		<div class="next_button"><i class="fa fa-arrow-right" (click)="goNext()"  aria-hidden="true"></i></div>
	</div>
					<br><br>
<br><br>
<br><br>
<br><br>
<br><br>
</div>