<div class="main_mediate" [ngClass]="{'cutout':globals.cutout}" @fadeRoute *ngIf="init">
	<div class="control_panel">
		<div class="loader" *ngIf="loading">
			<svg version="1.1" id="L7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			  viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
			 <path fill="#fff" d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
			  c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">
			      <animateTransform 
			         attributeName="transform" 
			         attributeType="XML" 
			         type="rotate"
			         dur="2s" 
			         from="0 50 50"
			         to="360 50 50" 
			         repeatCount="indefinite" />
			  </path>
			 <path fill="#fff" d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
			  c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">
			      <animateTransform 
			         attributeName="transform" 
			         attributeType="XML" 
			         type="rotate"
			         dur="1s" 
			         from="0 50 50"
			         to="-360 50 50" 
			         repeatCount="indefinite" />
			  </path>
			 <path fill="#fff" d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
			  L82,35.7z">
			      <animateTransform 
			         attributeName="transform" 
			         attributeType="XML" 
			         type="rotate"
			         dur="2s" 
			         from="0 50 50"
			         to="360 50 50" 
			         repeatCount="indefinite" />
			  </path>
			</svg>
			<div class="loader_percent">
				{{loader_percent}}%
			</div>
		</div>	


		<div id="clock" *ngIf="!loading">
		    <p class="time">{{ timeFormatted }}</p>
		</div>
		<div class="help_text" *ngIf="!loading" >
		    <p @fadeChange *ngIf="help_text">{{help_text}}</p>
		</div>
		<div class="buttons" *ngIf="!loading">
			<div class="glow_button play" (click)="playMediate()">
				<i *ngIf="pause" class="fa fa-play"></i>
				<i *ngIf="!pause" class="fa fa-pause"></i>
			</div>
			<div class="glow_button end" (click)="finishMeditation()">
				<i class="fa fa-square"></i>
			</div>
		</div>
		
	</div>
	<canvas class='meditateCanvas' id='canvas'></canvas>
	<svg width='100%' height='100%' class='meditateSvg' (click)='clicked($event)'></svg>

	<audio #audioOption (canplaythrough)="myOnLoadedData()" preload>
	       <source [src]="episode.audio" type="audio/mpeg">
	   </audio>
</div>

 <app-modal [noHeader]="true" [zIndex]="1111" [noPadding]="true" [blocking]='false' [modalId]="'meditation_rate'">
  <div class="rate_modal" style="position:fixed;width:100%; height: 100%;overflow: auto;">
    <span class="right-align close_button" (click)="closeModal('meditation_rate')"><i class="material-icons md-24">clear</i></span>
    <div class="body_rate">
        <div class="main_content">
        	<div class="title_small" i18n>
			How do you feel?
			</div>
          <div class="rate_container">
          		<div class="rates_factor">
					<div class="rate_factor" [ngClass]="{'active':rate==2}" (click)="finishMeditationRate(2)" ><i class="icon-happy2"></i></div>
					<div class="rate_factor" [ngClass]="{'active':rate==1}" (click)="finishMeditationRate(1)" ><i class="icon-neutral2"></i></div>
					<div class="rate_factor" [ngClass]="{'active':rate==0}" (click)="finishMeditationRate(0)"><i class="icon-sad2"></i></div>
				</div>
          </div>
        </div>
    </div>
  </div>
</app-modal> 