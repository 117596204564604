import { Component, OnInit, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { routeAnimation } from "../_animations/index";
import { MixpanelService } from '../_services/mixpanel.service';
import { GoBackHelper } from '../_helpers/go-back-helper';
import {
  UserService,
  ModalService,
  AuthenticationService,
} from "../_services/index";
import { ChatbotService } from "../_services/chatbot.service";
import { Globals } from "../globals";

import { User } from "../_models/index";

@Component({
  moduleId: module.id,
  templateUrl: "myaccount.component.html",
  styleUrls: ["myaccount.component.scss"],
  animations: [routeAnimation],
  // host: { "[@fadeRoute]": "" },
})
export class MyAccountComponent implements OnInit {
  user_model: any = {};
  form_errors: any = {};
  languages: any = [];
  timezones: any = [];
  wearable: any = [];
  model: any = {};
  loading = false;
  error: string = "";
  ios = 0;
  add_open = false;
  add_open_success=false;
  access_code_tip = "";

  constructor(
    public goBackHelper:GoBackHelper,
    private mixpanelService:MixpanelService,
    private chat: ChatbotService,
    private zone: NgZone,
    private globals: Globals,
    private userService: UserService,
    private router: Router,
    private modalService: ModalService,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.mixpanelService.trackView('My Account Page','view',{});
    this.ios = this.globals.ios;
    // this.form_errors.new_password='asdasd as dsa sda '
    this.userService.getUserData().subscribe((data) => {
      this.user_model = data.user;
      this.languages = data.languages;
      this.timezones = data.timezones;
      this.wearable = data.wearable;
    });
  }
  logout(){
    this.authService.logout()
    this.router.navigate(["/"]);
  }

  deleteAccount() {
    this.userService.deleteAccount(this.model.reason).subscribe((result) => {
      this.router.navigate(["/"]);
    });
  }

  toggle2Fa() {
    this.loading = true;
    this.userService.toggle2Fa().subscribe((result) => {
      this.loading = false;
      this.user_model.tfa=result
    },
    (error) => {
      this.loading = false;
    });
  }

  openModal(id) {
    this.modalService.open(id);
  }

  saveUserInfo(formData) {
    this.mixpanelService.trackButton('', 'Save Account', 'button', {});
    this.loading = true;
    this.userService.saveUserData(this.user_model).subscribe(
      (result) => {
        this.loading = false;
        this.error = "";
        var addIndex = "";
        if (this.globals.ios) addIndex = "index.html";
        if (result.locale == "en-US") window.location.href = "/";
        else if (window.location.href.indexOf("/" + result.locale + "/") == -1)
          window.location.href = "/" + result.locale + "/" + addIndex;
      },
      (error) => {
        this.error = error;
        this.form_errors = JSON.parse(error);
        Object.getOwnPropertyNames(this.form_errors).map((key: string) => {
          // console.log(key, this.form_errors[key]);
          formData.form.controls[key].setErrors({ incorrect: true });
        });
        console.log(this.form_errors);
        this.loading = false;
      }
    );
  }

  deleteConnect(type) {
    this.loading = true;
    this.userService.deleteWearable(type).subscribe(
      (result) => {
        this.loading = false;
        this.wearable[type] = 0;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  connect(type) {
    if (this.wearable[type]) {
      this.deleteConnect(type);
      return false;
    }
    this.loading = true;
    this.userService.getWearableUrl(type).subscribe(
      (result) => {
        this.loading = false;
        this.openUrlWindow(result.url, type, result.redirect_url);
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  openUrlWindow(url, type, redirect_url) {
    if (typeof window["cordova"] !== "undefined") {
      // window['cordova'].InAppBrowser.open(url, '_self');
      var browser = window["cordova"].InAppBrowser.open(
        url,
        "_blank",
        "location=no"
      );
      // browser.addEventListener('loadstart', (evt) => {
      //     console.log('evt.url = ' + evt.url);
      //     if(evt.url.indexOf(redirect_url) == 0) {
      //         this.parseCode(e.data, type); // e.data hold the message
      //         browser.close();
      //     }
      // });
      // browser.addEventListener('loaderror', function(err) {
      //     console.log("error " + err);
      // });
      // browser.addEventListener('message', (e) => {
      //     console.log('info',e.data,type, e)
      //     if(redirect_url.indexOf(e.origin) == 0){
      //          this.parseCode(e.data, type); // e.data hold the message
      //          browser.close();
      //     }
      // });
      browser.addEventListener("loadstart", (e) => {
        // console.log("info2", e);
        if (e.url.indexOf(redirect_url) == 0) {
          let url = new URL(e.url);
          let urlParams = new URLSearchParams(url.search);
          let code = urlParams.get("code");
          // console.log("code", code, type);
          this.parseCode(code, type); // e.data hold the message
          browser.close();
        }
      });
    } else {
      var ref = window.open(url, "_blank", "location=no");
      window.addEventListener(
        "message",
        (e) => {
          if (redirect_url.indexOf(e.origin) == 0) {
            this.parseCode(e.data, type); // e.data hold the message
            ref.close();
          }
        },
        false
      );

      // window.setTimeout(function(){
      //   ref.addEventListener('loadstart', function(event) {
      //       console.log('in load ');
      //       alert('start: ' + event.url);
      //   });
      // }, 1000);

      // var waitForToken = setInterval(function () {
      //     console.log(ref.location)
      //     if (ref.closed) {
      //         clearInterval(waitForToken);
      //     }
      //     if (window.location.code) {

      //         ref.close();
      //     }
      // }, 2000)
    }
  }

  parseCode(code, type) {
    this.loading = true;
    this.userService.connectWearable(type, code).subscribe(
      (result) => {
        this.zone.run(() =>
          setTimeout(() => {
            this.loading = false;
            this.wearable[type] = 1;
            if (result.text) this.chat.botLocalMessage(result.text);
          }, 150)
        );
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  addAccessCode() {
    if (!this.add_open) {
      this.add_open = true;
      this.add_open_success=false;
      this.error = "";
      return true;
    }
    if (!this.model.access_code) {
      this.add_open_success=false;
      this.error = "Access Code empty";
      return true;
    }
    this.userService.addAccessCode(this.model.access_code).subscribe(
      (result) => {
        this.add_open = false;
        this.error = "Access Code Added";
        this.add_open_success=true;
        this.model.access_code = "";
        console.log('greetings1',result, result.greetings)
        if (result.greetings){
          this.modalService.open('greetings', result.greetings)
          console.log('greetings', result.greetings)
        }
      },
      (error) => {
        this.add_open_success=false;
        this.error = error;
      }
    );
  }
}
