<section class="page answers">
  <div class="main_container">
    <div class="top_control">
      <div class="top_control__back_button"  (click)="goPrev()">
        <span class="material-symbols-rounded" *ngIf="cursor != 0"> arrow_back_ios_new </span>
        <span *ngIf="cursor != 0" i18n>Back</span>
      </div>
      <div class="top_control__options">
        <div class="close_button" (click)="goBack()">
          <span class="material-symbols-rounded"> cancel </span>
        </div>
      </div>
    </div>

    <div class="diagnostic_answers_container">
      <div
        *ngIf="max_items"
        class="main_text"
        [ngClass]="{ hideit: cursor !== 0 && cursor !== max_items }"
      >
        <!-- <span
          class="start_text animate_text"
          *ngIf="cursor <= 0"
          [innerHTML]="start_text"
          [ngClass]="{ hideit: cursor !== 0 }"
        >
        </span> -->
      </div>
      <app-diagnostic-answer
        *ngIf="selectedAnswer"
        (answerUpdated)="handleAnswerUpdated($event)"
        [goNext]="goNext"
        [direction]="answer_direction"
        [item]="selectedAnswer"
        [showNext]="
          cursor != max_items-1 &&
          (answers[cursor].answer != -1 ||
            (answers[cursor + 1] && answers[cursor + 1].answer != -1))
        "
      ></app-diagnostic-answer>

    </div>
    <div class="progressbar">
      <div
        class="valuebar"
        [ngStyle]="{ width: (cursor / max_items) * 100 + '%' }"
      >
        <div class="value">
          {{ (cursor / max_items) * 100 | number : "1.0-0" }}%
        </div>
      </div>
    </div>


    <app-modal
  [blocking]="false"
  [modalId]="'add_fields'"
  [noPadding]="true"
  [noHeader]="true"
  [needAbsolute]="true"
>
  <div class="options_modal">
    <div
      class="title"
      i18n="
        diagnostic questions additional field title: diagnostic questions
        additional field title@@diagnostic_answers_additional_field_title"
    >
      About you
    </div>
    <div class="options_container">
      <div class="close_button" (click)="goBack()">
        <span class="material-symbols-rounded"> cancel </span>
      </div>
      <div class="simple_select" *ngFor="let field of add_fields.options">
        <div class="simple_select__option" >
          <div class="simple_select__selected_value" [ngClass]="{'default':!add_fields_values[field.id]}">
            <span>{{
              getOptionValue(
                add_fields_values[field.id],
                field.options,
                field.name
              )
            }}</span>
          </div>
          <select
            class="simple_select__select"
            [(ngModel)]="add_fields_values[field.id]"
          >
            <option
              value="{{ option.id }}"
              *ngFor="let option of field.options"
            >
              {{ option.value }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="save_button">
      <button i18n  (click)="saveAddFields()" class="action action--secondary action--purple action--simple-button">
        Next
      </button>
    </div>
  </div>
</app-modal>


  </div>
  
</section>

