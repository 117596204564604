
import {catchError, map, distinctUntilChanged} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable ,  BehaviorSubject } from 'rxjs';
import { Inject,Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { Globals } from '../globals';



import * as jwt_decode from 'jwt-decode';

export const TOKEN_NAME: string = 'jwt_token';

@Injectable()
export class AuthenticationService {
    public token: string;
    logged = new BehaviorSubject<boolean>(false); 
    logged$ = this.logged.asObservable();

    constructor(@Inject(LOCALE_ID) private locale: string,private http: HttpClient,private globals:Globals) {
        this.token = localStorage.getItem(TOKEN_NAME)
    }


      getToken(): string {
        return localStorage.getItem(TOKEN_NAME);
      }

      setToken(token: string): void {
        localStorage.setItem(TOKEN_NAME, token);
      }

      getTokenExpirationDate(token: string): Date {
        let decoded:any = {}
        decoded = jwt_decode(token);
        if (decoded ===+ {}) return null;
        if (decoded.exp === undefined) return null;

        const date = new Date(0); 
        date.setUTCSeconds(decoded.exp);
        return date;
      }

      isTokenExpired(token?: string): boolean {
        if(!token) token = this.getToken();
        if(!token) return true;

        const date = this.getTokenExpirationDate(token);
        if(date === undefined) return false;
        return !(date.valueOf() > new Date().valueOf());
      }


     isTokenExpiredObsChange(token?: string): boolean {
        let res = this.isTokenExpired(token);
        this.setLogged(!res);
        return res;
      }

    login(username: string, password: string, fa:string = "", fa_resend:number=0): Observable<string> {
        return this.http.post(this.globals.api_server+'auth/login', JSON.stringify({ username: username, password: password,fa:fa,fa_resend:fa_resend }),{headers: new HttpHeaders({ 'locale': this.locale })}).pipe(
            map((response: any) => {
                let token = response.results.token;
                if (token) {
                    this.token = token;
                    this.setToken(token)
                    return 'login';
                } else {
                    if (response.results.fa)
                        return '2fa';
                    else
                        return 'error';
                }
            }),catchError((error:any) => {
                
                return observableThrowError(error.error.results.error);
            }
            ),)
    }

    signup(username: string, password: string,first_name: string,last_name: string,phone: string,sex: string,year: string, coupon:string): Observable<any> {
        return this.http.post(this.globals.api_server+'auth/signup', JSON.stringify({ username: username, password: password,first_name:first_name,last_name:last_name,phone:phone,sex:sex,year:year,coupon:coupon }),{headers: new HttpHeaders({ 'locale': this.locale })}).pipe(
            map((response: any) => {
                return response.results
            }),catchError((error:any) => {  
                return observableThrowError(error.error.results.error);
            }
            ),)
    }

    remindEmail(email: string): Observable<boolean> {
        return this.http.post(this.globals.api_server+'auth/password/remind', JSON.stringify({ username: email }), {headers: new HttpHeaders({ 'locale': this.locale })}).pipe(
            map((response: any) => {
                return true
            }),catchError((error:any) => {  
                return observableThrowError(error.error.results.error);
            }
            ),)
    }


    getTerms(): Observable<any> {
        
        return this.http.get(this.globals.api_server+'auth/terms',{headers: new HttpHeaders({ 'locale': this.locale })}).pipe(
            map((response: any) => response && response.results),
            catchError((error:any) => {  
                return observableThrowError(error.error.results.error);
            }),)
    }

    getLanguages(): Observable<any> {     
        return this.http.get(this.globals.api_server+'auth/languages',{headers: new HttpHeaders({ 'locale': this.locale })}).pipe(
            map((response: any) => response && response.results),
            catchError((error:any) => {  
                return observableThrowError(error.error.results.error);
            }),)
    }

    checkCoupon(coupon): Observable<any> {
        return this.http.post(this.globals.api_server+'auth/coupon', JSON.stringify({ coupon:coupon }), {headers: new HttpHeaders({ 'locale': this.locale })}).pipe(
            map((response: any) => response && response.results),
            catchError((error:any) => {  
                return observableThrowError(error.error.results.error);
            }),)
    }

    resetPassword(token:string,password: string,again_password:string): Observable<boolean> {
        
        return this.http.post(this.globals.api_server+'auth/password/reset', JSON.stringify({ token:token, password: password,again_password:again_password }),{headers: new HttpHeaders({ 'locale': this.locale })}).pipe(
            map((response: any) => {
                return true
            }),catchError((error:any) => {  
                return observableThrowError(error.error.results.error);
            }
            ),)
    }

    logout(): void {
        // clear token remove user from local storage to log user out
        this.token = null;
        localStorage.removeItem(TOKEN_NAME);
        this.setLogged(false);
    }

    setLogged(value: boolean) {
        this.logged.next(value);
    }

}