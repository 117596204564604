<div class="page">
            <div  class="notifications_page">
                  <div class="main_content">
                        <div class="title title_top" i18n>
                             Messages
                       </div> 

                       <div class="main_container">
                              <div class="no_messages" *ngIf="!notifications.length" i18n> You have no messages</div>
                              <div *ngIf="notifications.length" class="list">
                              	<div (click)="openNoty(notification)" class="item" *ngFor="let notification of notifications" >
                              		<div class="header">
                              			<div class="icon">
                              				<i *ngIf="!notification.seen" class="fa fa-envelope-o"></i>
                              				<i *ngIf="notification.seen" class="fa fa-envelope-open-o"></i>
                              			</div>
                              			<div class="date">
                              				{{notification.date_formatted}}
                              			</div>
                              		</div>
                              		<div class="item_title">
                              			{{notification.subject}}
                              		</div>
                              		<div class="item_text">
                              			{{notification.short_body}}
                              		</div>
                              	</div>

                          	  <div *ngIf="!hide_more" class="more_button">
		                       	<button class="lazy-button" (click)="loadMore()" i18n>Load More</button>
		                      </div>

                              </div>
                       </div>

                     

				       <app-modal *ngIf="selected" [blocking]='false' [modalId]="'notification'">
				               <div class="noty_modal">
				                <div class="title_modal">{{selected.subject}}</div>
				                <div class="main_text_container">
				                  <div class="main_text" [innerHTML]="selected.body">
				                   
				                  </div>
				                  <div *ngIf="selected.video" class="video_flex">
				                    <iframe width="560" height="349" [src]="selected.video" frameborder="0" allowfullscreen></iframe>
				                  </div>
				                  <br><br>
				                </div>
				                </div> 
				     </app-modal>
                  </div>        
        
            </div>
  </div>

  