<section class="page resources bg_margin">
  <div class="main_container blurry">
    <div class="greetings">
      <div class="greetings__phrase" i18n>Resources</div>
      <div class="greetings__name" i18n>
        Practical techniques for a flexible mind
      </div>
    </div>
    <div class="grid">
      <div
        class="resource-item"
        *ngFor="let resource of resources; let i = index"
      >
        <a (click)="mixpanelService.trackButton(resource.url,'Resources Page Open Resource','link',{resource:resource});openInNewTab(resource.url)">
          <div class="resource-item__main_container">
            <div class="resource-item__icon">
              <span class="r-icon-resourcesItem"> </span>
            </div>
            <div class="resource-item__title_container">
              <div class="resource-item__title" >
				<p *ngFor="let t of prepareTitleArr(resource.name)">
					{{ t }}
				</p>
              </div>
            </div>
            <div class="resource-item__arrow">
              <span class="material-symbols-rounded"> navigate_next </span>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>