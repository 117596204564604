import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { routeAnimation } from '../../_animations/index';
import { MeditationService,UserService,ModalService } from '../../_services/index';
import { MixpanelService } from '../../_services/mixpanel.service';

@Component({
  selector: 'app-meditate-list',
  templateUrl: './meditate-list.component.html',
  styleUrls: ['./meditate-list.component.scss'],
  animations:[routeAnimation],
  // host: { '[@fadeRoute]': '' }
})
export class MeditateListComponent implements OnInit {
  series:any = [];
  resources:any = [];
  active = 0;
  error = "";
  model: any = {};
  add_open = false;
  access_code_tip = '';

  constructor( private router:Router, private mixpanelService:MixpanelService, private modalService:ModalService,private userService:UserService,private meditationService:MeditationService) { }

  ngOnInit() {

      this.getSeries()
      this.userService.getAccessCodeTip().subscribe(
        result => {
            
            this.access_code_tip=result;
          },
          error => {
              this.error = error ;
          })
  }

  openModal(id) {
   this.modalService.open(id)
  }

    getSeries() {
      this.meditationService.getSeriesList().subscribe(
      result => {
          this.resources = result.resources;
          this.series = result.series;
          this.mixpanelService.trackView('Meditations List','view',{});
          if (this.series.length > 0)
            this.active = this.series[0].id;
      },
        error => {
            this.error = error ;
          console.log("error",this.error)
        })
    
  }


   addAccessCode() {
     if (!this.add_open)
      {
        this.add_open=true;
        return true;
      }
      if (!this.model.access_code)
        {
          this.error= "Access Code empty";
          return true;
        }
      this.userService.addAccessCode(this.model.access_code).subscribe(
        result => {
            this.add_open=false;
            this.error= "";
            this.model.access_code="";
            this.getSeries()
          },
          error => {
              this.error = error ;
          })
  }

  trackRouteHelperClick(url:any, name:string,type:string, args:{}):void {
    if (typeof(url) === 'string'){
      this.router.navigate([url])
    }
    else{
      this.router.navigate(url)
      url=url.join('/')
    }
    
    this.mixpanelService.trackButton(url, name, type, args);
  }

}
