<section class="page remind">
  <div class="main_container blurry">
    <div class="top_control">
      <div class="top_control__back_button" (click)="goBack()">
        <span class="material-symbols-rounded"> arrow_back_ios_new </span>
        <span i18n>Back</span>
      </div>
      <div class="top_control__options">
        <div class="top_control__option"></div>
      </div>
    </div>
    <div class="reminder_container">
      <div class="account__form_error" *ngIf="error">
        {{ error }}
      </div>
      <div class="account__form">
        <fieldset>
          <div *ngIf="!success" class="account__form-group">
            <input
              type="text"
              [(ngModel)]="model.username"
              required
              placeholder="Email"
              name="username"
              i18n-placeholder
            />
          </div>
        </fieldset>
		<div *ngIf="!success" class="button">
			<div class="main-area__login-button">
				<button
				  title="Reset"
				  (click)="checkRemind()"
				  class="action action--secondary action--purple action--simple-button"
          i18n
				>
				Reset Password
				</button>
			  </div>
		  </div>
      </div>

      
      <div class="text" *ngIf="success">
        <p i18n>
          Please check your email and click the confirmation link. It can take
          up to 5 minutes to get an email
        </p>
      </div>
    </div>
  </div>
</section>

<div class="page fixed">
  <div class="welcome_page">
    <div class="email_container">
      <div class="back_button">
        <i class="fa fa-arrow-left" aria-hidden="true"></i>
      </div>
      <mat-error *ngIf="error">{{ error }}</mat-error>

      <div *ngIf="!success" class="form-group">
        <input
          type="text"
          [(ngModel)]="model.username"
          required
          class="form-control"
          placeholder="Email"
          name="username"
        />
        <i class="fa fa-user"></i>
      </div>

      <div *ngIf="!success" class="button">
        <button class="form-button" (click)="checkRemind()" i18n>
          Reset Password
        </button>
      </div>

      <div class="text" *ngIf="success">
        <p i18n>
          Please check your email and click the confirmation link. It can take
          up to 5 minutes to get an email
        </p>
      </div>
    </div>
  </div>
</div>
