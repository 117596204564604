<div class="video_container">
    <vg-player>
        <vg-overlay-play *ngIf="!controls"></vg-overlay-play>
        <video (play)='whenPlay()' (pause)='whenPause()' [controls]="controls ? 'controls' : null" [preload]="preload" [poster]="poster" [vgMedia]="media" style="object-fit: inherit;" #media id="singleVideo">
              <source [src]="video" type="video/mp4">    
        </video>
        <div
        [ngClass]="{'playpause':true,'active':!play}"
      ></div>
    </vg-player>
</div>