import { Component, OnInit, LOCALE_ID, Inject, ViewChild } from '@angular/core';
import { routeAnimation } from '../../_animations/index';
import { VideoService, UserService, ModalService } from '../../_services/index';
import { ActivatedRoute, Router } from '@angular/router';
import { MixpanelService } from '../../_services/mixpanel.service';
import { Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-video-list-series',
  templateUrl: './video-list-series.component.html',
  styleUrls: ['./video-list-series.component.scss'],
  animations: [routeAnimation],
  // host: { '[@fadeRoute]': '' }
})
export class VideoListSeriesComponent implements OnInit {
  @ViewChild('listDiv') listDiv;
  series: any = [];
  fseries: any = [];
  resources: any = [];
  webinars_recordings: any = [];
  active = 0;
  error = "";
  model: any = {};
  add_open = false;
  access_code_tip = '';
  featured_section_width: number = 0;
  old_tag_store: string;

  constructor(private meta:Meta, @Inject(LOCALE_ID) private locale: string, private router: Router, private mixpanelService: MixpanelService, private modalService: ModalService, private userService: UserService, private videoService: VideoService) { }

  ngOnDestroy() {
    this.meta.updateTag({ content: this.old_tag_store }, 'name=theme-color');
  }

  ngOnInit() {
    this.old_tag_store=this.meta.getTag('name=theme-color').content;
    this.meta.updateTag({ content: '#2F282F' }, 'name=theme-color');
    this.mixpanelService.trackView('Videos List', 'view', {});
    this.getSeries()
    this.userService.getAccessCodeTip().subscribe(
      result => {
        this.access_code_tip = result;
      },
      error => {
        this.error = error;
      })
    // this.series = [{name:"How To Bounce",author:"Sven Hansen",id:Math.floor(Math.random() * 9999) + 1  }, {name:"How To Bounce",author:"Sven Hansen",id:Math.floor(Math.random() * 9999) + 1  },{name:"How To Bounce",author:"Sven Hansen",id:Math.floor(Math.random() * 9999) + 1  },{name:"How To Bounce",author:"Sven Hansen",id:Math.floor(Math.random() * 9999) + 1  },{name:"How To Bounce",author:"Sven Hansen",id:Math.floor(Math.random() * 9999) + 1  },]
  }


  getFeatured(series) {
    if (series.length<=0)
      return [];
      
    return [...series].sort(() => 0.5 - Math.random()).slice(0, 3);
  }

  openModal(id) {
    this.mixpanelService.trackButton('', 'Videos Series Open Modal ' + id, 'button', {});
    this.modalService.open(id)
  }

  getSeries() {
    this.videoService.getSeriesList().subscribe(
      result => {
        this.resources = result.resources;
        if (result.webinars_recordings)
          this.webinars_recordings = result.webinars_recordings
        this.series = result.series;
        this.fseries=this.getFeatured(this.series);
        if (this.series.length > 0)
          this.active = this.series[0].id;
      },
      error => {
        this.error = error;
        console.log("error", this.error)
      })

  }


  addAccessCode() {
    if (!this.add_open) {
      this.add_open = true;
      return true;
    }
    if (!this.model.access_code) {
      this.error = "Access Code empty";
      return true;
    }
    this.userService.addAccessCode(this.model.access_code).subscribe(
      result => {
        this.add_open = false;
        this.error = "";
        this.model.access_code = "";
        this.getSeries()
      },
      error => {
        this.error = error;
      })
  }

  openVideoSeries(url: any, series = {},featured=0): void {
    this.router.navigate(url)
    this.mixpanelService.trackButton(url, 'Video Series Click', 'button', {...series,"featured":featured});
  }

  public prepareWebinarTimeDateFull(date) {
    let _date = new Date(Date.parse(date.replace(/-/g, "/") + ' UTC'))
    // console.log('dddd',date,_date)
    // let loc = "en-US"
    // let loc = undefined
    let loc = this.locale
    let options: any = { weekday: 'long', month: 'short', day: 'numeric' };
    let formatted = _date.toLocaleDateString(loc, options)
    // formatted = formatted.replace(/,/g, "")+'th'+" at "+ _date.getHours()+'h'
    // if (_date.getMinutes()!=0)
    //   formatted = formatted+" "+_date.getMinutes()+"m"
    return formatted
  }

}
