<div class="main_init" *ngIf="init">
    <div class="page">
        <div class="item_page">
            <app-modal [blocking]="false" [modalId]="'help'">
                <div class="help_guide">
                    <div class="title_guide" >
                        Help Guide
                    </div>
                    <div class="main_text_container">
                        <div class="help_video_container" *ngIf="help_video_url && help_video">
                            <small-video-player [preload]="'none'" [poster]="diagnostic.version.help_video_poster"
                                [video]="help_video_url"></small-video-player>
                        </div>
                        <div class="main_text" [innerHTML]="diagnostic.version.help_text"></div>
                        <br /><br />
                    </div>
                </div>
            </app-modal>
            <div class="main_content">
                <!-- <div class="top_tools_container">
          <div class="show_help" (click)="openModal('help')">
          <i class="fa fa-question-circle"></i>
        </div>
        <div class="show_download" *ngIf="!ios" (click)="downloadPdf()">
          <i class="fa fa-download"></i>
        </div>
      </div> -->

                <div class="report_header">
                    <div class="logo">
                        <img [src]="diagnostic.version.org.logo" />
                    </div>
                  
                    <div class="greetings">
                        <div class="greetings__name">Hello, Stranger<ng-container > this is </ng-container>
                        </div>
                        <div class="greetings__phrase" >Spiral explanation</div>
                    </div>
                    <div class="anon">
                        <span class="material-symbols-rounded">lock</span> <ng-container >100%
                            Private</ng-container>
                    </div>
                </div>

                <div class="horizontal_delimeter"></div>

                <div class="flex_parent">

                    <div class="bottom_container">
                        <div class="table spiral_card">
                            <div *ngIf="diagnostic.version.spiral_title" class="spiral_card__title">
                                {{ diagnostic.version.spiral_title }}
                            </div>
                            <div *ngIf="diagnostic.version.spiral_intro" class="spiral_card__text"
                               >Click on the spiral to discover quick ways to improve your resilience.</div>
                            <div class="spiral_container_hotfix">
                                <div class="spiral_container">
                                    <div class="spiral_row" (click)="openSpiralModal(category.name)"
                                        *ngFor="let category of stats.categories">
                                        <div class="spiral_cell" [id]="category.name">
                                            <img [src]="category.image" />
                                        </div>
                                        <div class="spiral_cell">
                                            <div class="spiral_stat" [ngStyle]="{ color: '#000;' }">
                                                <div class="arrow">
                                                    <span class="material-symbols-rounded">
                                                        navigate_next
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="spiral_overlay_hotfix">
                                    <div class="spiral_overlay" [ngClass]="{ active: spiral_open }">
                                        <div class="close_button" (click)="spiral_open = false">
                                            <i class="fa fa-times"></i>
                                        </div>
                                        <div class="info_text">
                                            {{ diagnostic.version.spiral_info }}
                                        </div>
                                        <div class="spiral_row" *ngFor="let category of stats.categories">
                                            <div>
                                                <div class="spiral_cell" [id]="category.name">
                                                    <img [src]="category.image" />
                                                </div>
                                                <div *ngIf="category.last_item" class="last_item">
                                                    <div class="label_item">{{ category.last_item }}</div>
                                                    <div *ngIf="category.inverse" class="info_item" >
                                                        Low score is better
                                                    </div>
                                                    <div *ngIf="!category.inverse" class="info_item" >
                                                        High score is better
                                                    </div>
                                                </div>
                                                <div *ngIf="category.first_item" class="first_item">
                                                    <div class="label_item">
                                                        {{ category.first_item }}
                                                    </div>
                                                    <div *ngIf="category.inverse" class="info_item" >
                                                        Low score is better
                                                    </div>
                                                    <div *ngIf="!category.inverse" class="info_item" >
                                                        High score is better
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="action action--center-text" (click)="openModal('next_steps')">
                                    <div class="icon">
                                    </div>
                                    <div class="text">
                                        <div class="title" >Next Step</div>
                                        <div class="sub_text"></div>
                                    </div>
                                    <div class="arrow">
                                        <span class="r-icon-chevronRight"></span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="bottom_container" style="width: 100%">
                    </div>
                    <div class="bottom_container" style="width: 100%;display: none;">
                    </div>
                </div>
            </div>
        </div>
    </div>
<app-modal [blocking]="false" [modalId]="'next_steps'">
    <div class="help_guide">
        <div class="title_guide" >Next Steps</div>
        <div class="main_text_container">
            <div class="main_text" [innerHTML]="diagnostic.version.next_steps"></div>
            <br /><br />
        </div>
    </div>
</app-modal>
<app-modal [blocking]="false" [modalId]="'share_report'">
    <div class="help_guide">
        <div class="title_guide" >Share Report</div>
        <div class="main_text_container">
            <div class="main_text share">
                <div class="share_container" *ngFor="let share_number of share_numbers">
                    <div class="share_container_inner input_container">
                        <input placeholder="Email" type="text" [(ngModel)]="share_number.number" />
                        <i class="fa fa-minus-circle" (click)="deleteShareRow(share_number)" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="add_button" (click)="addShareRow()">
                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                </div>

                <div class="send_button">
                    <button class="lazy-button" (click)="sendShare()" >Send</button>
                </div>
            </div>
            <br /><br />
        </div>
    </div>
</app-modal>

<app-modal [noHeader]="true" [zIndex]="1111" [noPadding]="true" [blocking]="false" [modalId]="'goalkeeper'">
    <div class="goalkeeper" style="position: fixed; width: 100%; height: 100%">
        <span class="right-align close_button" (click)="closeGoalkeeperModal()"><i
                class="material-icons md-24">clear</i></span>
        <div class="body_goalkeeper">
            <div class="main_content">
                <div class="title_top">Done!</div>
                <div class="title_sub" >
                    Simply click on Goals from home screen to track and manage your
                    settings.
                </div>
                <div class="button">
                    <button  class="lazy-button" (click)="closeGoalkeeperModal()">
                        Return to Diagnostic
                    </button>
                </div>
            </div>
        </div>
    </div>
</app-modal>

<app-modal [noHeader]="true" [zIndex]="20200" [noPadding]="true" [modalId]="'duty_of_care'">
    <div class="help_guide">
        <div class="title_guide">{{ diagnostic.version.duty_of_care.title }}</div>
        <div class="main_text_container">
            <div class="main_text share">
                <div [innerHTML]="diagnostic.version.duty_of_care.text"></div>

                <div class="duty_of_care_button_container">
                    <div class="duty_of_care_button">
                        <button class="lazy-button" (click)="closeModal('duty_of_care')">
                            {{ diagnostic.version.duty_of_care.button }}
                        </button>
                    </div>
                </div>
            </div>
            <br /><br />
        </div>
    </div>
</app-modal>

<ng-container *ngIf="stats?.categories">
    <div *ngFor="let category of stats.categories">
        <app-modal class="spiral_modal_modal" [blocking]="false" [modalId]="category.name" [noPadding]="true"
            [hideClose]="modals_spiral[category.name] !== 'main'">
            <div @slideLeft *ngIf="modals_spiral[category.name] == 'main'" class="spiral_modal top_container_modal">
                <div class="title_stat">
                    <span class="title">{{ category.name }}</span>
                </div>
                <div class="small_title">
                    {{ category.short_description }}
                </div>

                <div class="spiral-factors">
                    <div class="spiral-factor" (click)="openFactor(category.name, factor)"
                        *ngFor="let factor of category.factors">
                        <div class="factor_table">
                            <div class="factor_row">
                                <div class="factor_color factor_cell">
                                    
                                </div>
                                <div class="factor_title factor_cell">
                                    {{ factor.name }}
                                </div>
                            </div>
                            <div class="factor_row">
                                <div class="factor_cell"></div>
                                <div class="factor_cell">
                                    <div class="text">
                                        {{ factor.explanation }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="next_button">
                            <i class="fa fa-chevron-right" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div (@slideRight.start)="animationFactorStart($event)" (@slideRight.done)="animationFactorDone($event)"
                @slideRight *ngIf="modals_spiral[category.name] !== 'main'"
                class="spiral_modal top_container_modal factor">
                <div class="title_controls factor">
                    <div class="title_stat">
                        <span class="title">{{ modals_spiral[category.name]?.name }}</span>
                    </div>
                    <div (click)="openFactor(category.name, 'main')" class="back_button">
                        <i class="fa fa-chevron-left" aria-hidden="true"></i>
                    </div>
                </div>

                <div class="factor_container">
                    <div *ngIf="modals_spiral[category.name]?.video" class="video_flex">
                        <small-video-player *ngIf="factor_video_modal_show" [preload]="'none'"
                            [poster]="modals_spiral[category.name]?.video_poster"
                            [video]="modals_spiral[category.name]?.video"></small-video-player>
                    </div>
                    <div class="factor_tabs">
                        <div class="factor_tab" (click)="changeFactorActivityTab(category.name, 0)" [ngClass]="{
                active: modals_spiral[category.name]?.tab == '0'
              }">
                            <i class="fa fa-lightbulb-o" aria-hidden="true"></i>
                            <ng-container >Action</ng-container>
                        </div>
                        <div class="factor_tab" (click)="changeFactorActivityTab(category.name, 1)" [ngClass]="{
                active: modals_spiral[category.name]?.tab == '1'
              }">
                            <i class="fa fa-book" aria-hidden="true"></i>
                            <ng-container >Resources</ng-container>
                        </div>
                        <!-- <div class="factor_tab" (click)="modals_spiral[category.name].tab='2'" [ngClass]="{'active':modals_spiral[category.name]?.tab=='2'}">
                                                      <i class="fa fa-check-circle" aria-hidden="true"></i> <ng-container >Practice</ng-container>
                                                    </div> -->
                    </div>

                    <div class="factor_tab_contents">
                        <div @fadeChange2 class="factor_tab_content" *ngIf="modals_spiral[category.name]?.tab == '0'"
                            [innerHTML]="modals_spiral[category.name]?.action_tip"></div>
                        <div @fadeChange2 class="factor_tab_content resource_tab"
                            *ngIf="modals_spiral[category.name]?.tab == '1'">
                            <div class="resources">
                                <div class="resource_title">
                                    FURTHER READING
                                </div>
                                <div class="resource" *ngFor="
                    let resource of modals_spiral[category.name]?.resources;
                    let i = index
                  " (click)="openResource(resource.url, resource)">
                                    <a target="_blank">
                                        <div class="resource_container">
                                            <div class="count">{{ i + 1 }}.</div>
                                            <div class="text_resource">
                                                {{ resource.text }}
                                            </div>
                                        </div>
                                        <span class="go_to_resource">
                                            <i class="fa fa-chevron-right" aria-hidden="true"></i></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- <div *ngIf='0' @fadeChange2 class="factor_tab_content" *ngIf="modals_spiral[category.name]?.tab=='2'">
                                                    <div *ngIf="modals_spiral[category.name]?.keeper_category_id!==0" class="keeper_title" >Make {{modals_spiral[category.name]?.name}} part of your life</div>
                                                    <div *ngIf="modals_spiral[category.name]?.keeper_category_id==0" class="keeper_title" >Not available yet</div>
                                                    <div class="keeper_button">
                                                      <button *ngIf="modals_spiral[category.name]?.keeper_category_id!==0" [disabled]="modals_spiral[category.name]?.keeper_category_id<=0" (click)="openGoalkeeperModal(modals_spiral[category.name])" class="form-button">
                                                        <div style="display: inline;" *ngIf="modals_spiral[category.name]?.keeper_category_id>0"  >Add to Goal Keeper</div>
                                                         <div style="display: inline;"  *ngIf="modals_spiral[category.name]?.keeper_category_id==-1" >Added to Goal Keeper</div>
                                                        <span><i class="fa fa-caret-right"></i></span></button>
                                                    </div>
                                                   </div>  -->
                    </div>
                </div>
            </div>
        </app-modal>
    </div>
</ng-container>
<app-modal [blocking]="false" [modalId]="'ratio_help'">
    <div class="help_guide">
        <div class="title_guide" >Ratio Help</div>
        <div class="main_text_container">
            <div class="main_text" [innerHTML]="diagnostic.version.ratio_text"></div>
            <br /><br />
        </div>
    </div>
</app-modal>
</div>