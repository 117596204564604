<div class="main_container">
    <div class="top_control">
        <div class="top_control__back_button" (click)="backButton()">
            <span class="material-symbols-rounded"> arrow_back_ios_new </span>
            <span i18n>Back</span>
          </div>
          <div class="top_control__options">
                <div class="top_control__option">
                    <!-- <div class="top_control__selected_value">{{sound_options.obj[selected_option.sound]?.name}}</div>
                    <select title='sound' class="top_control__select" (change)="selectOption('sound',$event.target.value)"
                    [(ngModel)]="selected_option.sound">
                        <option *ngFor="let sound of sound_options.arr" [value]="sound.id">{{sound.name}}</option>
                    </select> -->
                </div>
          </div>
    </div>
    <div class="top_title" i18n>
        Emotional Combat
    </div> 
    
    <div>
        <div class="small_title" i18n>Click on the wheel to discover ways to build positivity</div>
    

        <div class="svgWrap" id="svgWrap" >
            <svg #svgWrap [attr.width]="svgSize" [attr.height]="svgSize">
                <ng-container  *ngFor="let transform of transforms; let i = index">
                    <defs>
                        <linearGradient [attr.id]="'path'+i+'grad'" x1="0%" y1="7%" x2="100%" y2="93%" >
                            <stop offset="0%" [style]="'stop-color:'+sections[i].color1+';stop-opacity:1'" />
                            <stop offset="100%" [style]="'stop-color:'+sections[i].color2+';stop-opacity:1'"/>
                        </linearGradient>
                    </defs>
                    <ng-container *ngIf="!openedSections.includes(i)">
                        <path @fadeChange 
                            [attr.id]="'path'+i"
                            (click)="pathClick(i)"
                            [attr.d]="d"
                            [attr.transform]="transform"
                            [attr.fill]="gardients?'url(#path'+i+'grad)':colors[i]"
                            stroke-width="2"
                            stroke="white">
                        </path>
                    </ng-container>
                    <ng-container *ngIf="openedSections.includes(i)">
                        <ng-container  *ngFor="let subTransform of subTransforms[i]; let j = index">
                            <path @fadeChange
                                [attr.id]="'subPath'+j+''+i"
                                (click)="subPathClick(i,j)"
                                [attr.d]="d"
                                [attr.transform]="subTransform"
                                [attr.fill]="gardients?'url(#path'+i+'grad)':colors[i]"
                                [class.active-slice]="selected_factor==sections[i].items[j]"
                                stroke-width="2"
                                stroke="white">
                            </path>
                        </ng-container>                
                        
                    </ng-container>
                </ng-container>
                
                <!-- <ng-container *ngIf="openedSections.includes(0)"> 
                    <ng-container *ngFor="let subTransform of subTransforms[0]; let j = index">
                        <use [attr.xlink:href]="'#subPath'+j+''+0"/>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!openedSections.includes(0)">
                    <use [attr.xlink:href]="'#path0'"/>
                </ng-container> -->
                <use @fadeChange *ngIf="!openedSections.includes(0)" [attr.xlink:href]="'#path0'" (click)="pathClick(0)"/>
                <!-- <use [ngClass]="{'safari_hide_fix':openedSections.includes(0)}" [attr.xlink:href]="'#path0'" (click)="pathClick(0)"/> -->
                <!-- <use [attr.xlink:href]="'#path0'"/> -->
                

                <ng-container *ngFor="let transform of textTransforms; let i = index">
                    <ng-container *ngIf="!openedSections.includes(i)">
                        <text @fadeChange
                            [attr.text-anchor]="textAnchors[i]"
                            (click)="pathClick(i)"
                            [attr.transform]="transform"
                            fill="white"
                            stroke="white" >
                            <tspan dx="59" >{{sections[i].name}}</tspan>
                        </text>
                    </ng-container>
                    
                    <ng-container *ngIf="openedSections.includes(i)">
                        <ng-container *ngFor="let subTextTransform of subTextTransforms[i]; let j = index">
                            <text @fadeChange
                                (click)="subPathClick(i,j)"
                                [attr.transform]="subTextTransform"
                                [attr.text-anchor]="textAnchors[i]"
                                fill="white"
                                stroke="white" >
                                <tspan dx="59" >{{sections[i].items[j].name}}</tspan>
                            </text>
                        </ng-container>
                    </ng-container>
                </ng-container>
                

             
            </svg>
          </div>

          <div class="help_text" id="help_text" @fadeChangeAbs *ngIf="selected_factor">
            <div class="help_text__title">{{selected_factor.name}}</div>
            <div class="help_text__text" [innerHTML]="selected_factor.description"></div>
            <div class="help_text__rate-content">
                <div class="help_text__rate-title">
                    <ng-container  *ngIf="current_rate===-1" i18n>Did this help?</ng-container>
                    <ng-container  *ngIf="current_rate!==-1" i18n>Thanks for rating this tool.</ng-container>
                    </div>
                  <div class="help_text__rate-container">
                          <div class="help_text__rate-rates">
                            <div class="help_text__rate-rate" [ngClass]="{'active':current_rate==2}" (click)="selectRate(selected_factor.id,2)" ><i class="icon-happy2"></i></div>
                            <div class="help_text__rate-rate" [ngClass]="{'active':current_rate==1}" (click)="selectRate(selected_factor.id,1)" ><i class="icon-neutral2"></i></div>
                            <div class="help_text__rate-rate" [ngClass]="{'active':current_rate==0}" (click)="selectRate(selected_factor.id,0)"><i class="icon-sad2"></i></div>
                        </div>
                        
                  </div>
            </div>
        </div>

    </div>
    <br>
    <div id="scroll_hack"></div>
    <br>
</div>