import { Component, OnInit } from '@angular/core';
import { GoalkeeperService } from '../../_services/index';
import { ActivatedRoute, Router } from '@angular/router';
import {routeAnimation } from '../../_animations/index';

@Component({
  selector: 'app-goalkeeper-mygoal',
  templateUrl: './goalkeeper-mygoal.component.html',
  styleUrls: ['./goalkeeper-mygoal.component.scss'],
  animations:[routeAnimation],
  host: { '[@fadeRouteNoAbs]': '' }
})
export class GoalkeeperMygoalComponent implements OnInit {
  mygoals: any = [];
  delete_mygoals: any = [];
  error = "";

  constructor(private router: Router, private goalkeeperService:GoalkeeperService) { }

  ngOnInit() {
  	this.goalkeeperService.getMyGoals().subscribe(
		mygoals => {
			  this.mygoals=mygoals;
		},
	error => {
	    this.error = error ;
		console.log("error",this.error)
	})
  }

  addRow(){
  	   this.mygoals.push({id:this.guidGenerator(),name:""});
  }

  removeRow(item){
  	this.delete_mygoals.push(item.id)
    var index = this.mygoals.indexOf(item);
    this.mygoals.splice(index, 1);
    // console.log(this.delete_mygoals)
  }


    

   guidGenerator() {
        var S4 = function() {
           return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
        };
        return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
   }

   goNext(){
   	  	this.goalkeeperService.saveMyGoals(this.mygoals,this.delete_mygoals).subscribe(
		result => {
			 this.router.navigate(['goalkeeper','dashboard']); 
		},
		error => {
		    this.error = error ;
			console.log("error",this.error)
		})
   }

}
