<div class="habit-tracker-list">
  <h1>
    Your habbits
    <button title="add habit" class="add-button" (click)="showModalAdd($event)">
      <i class="fa fa-plus"></i>
    </button>
  </h1>

  <div class="habit-tracker-list__habits">
    <div
      class="habit-tracker-list__habit"
      *ngFor="let habit of habits"
      (click)="navigateToHabit(habit)"
    >
      <div class="habit-tracker-list__habit__name">
        {{ habit.name }} ({{ habit.total_completed }} /
        {{ habit.total_days }} days)
      </div>
      <!-- reward text of habbit -->
      <div class="habit-tracker-list__habit__reward">
        Reward: {{ habit.reward }}
      </div>
      <div class="habit-tracker-list__habit__description">
        {{ habit.description }}
      </div>
      <div class="habit-tracker-list__habit__actions">
        <!-- edit and delete buttons with font awesome edit and delete icons or smth -->
        <button title="edit habit" (click)="showModal($event, habit);">
          <i class="fa fa-pencil"></i>
        </button>
        <button
          title="delete habit"
          class="delete-button"
          (click)="deleteHabit(habbit.id)"
        >
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </div>
    <!-- modal editAdd modal show only if showaAddEditModal-->
    
    <div class="habit-tracker-list__modal" [ngClass]='{
                    "hidden": !showAddEditModal
                }'>
      <div class="habit-tracker-list__modal__content">
        <div class="habit-tracker-list__modal__content__header">
          <h1>{{ showAddEditModalItem.id ? "Edit" : "Add" }} Habbit</h1>
          <button title="close" class="close-button" (click)="closeModal()">
            <i class="fa fa-times"></i>
          </button>
        </div>
        <div class="habit-tracker-list__modal__content__body">
          <div class="habit-tracker-list__modal__content__body__input">
            <label for="name">Name</label>
            <input
              type="text"
              [(ngModel)]="showAddEditModalItem.name"
              placeholder="Enter your goal here"
            />
          </div>
          <div class="habit-tracker-list__modal__content__body__input">
            <label for="reward">Reward</label>
            <input
              type="text"
              [(ngModel)]="showAddEditModalItem.reward"
              placeholder="Enter your Reward here"
            />
          </div>
          <div class="habit-tracker-list__modal__content__body__input">
            <label for="days">Days</label>
            <input
              type="number"
              [(ngModel)]="showAddEditModalItem.total_days"
              min="1"
              placeholder="Number of days"
            />
          </div>
         <!-- buttons save and delete and cancel -->
            <div class="habit-tracker-list__modal__content__body__actions">
                <button
                title="save"
                class=""
                (click)="saveHabit()"
                >
                <!-- <i class="fa fa-save"></i> -->
                Save
                </button>
                <button title="cancel" (click)="closeModal()">Cancel</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
