<section
  class="page goals"
  *ngIf="init"
  (scroll)="reminderPopupGlobal($event)"
  (click)="reminderPopupGlobal($event)"
  (touchmove)="reminderPopupGlobal($event)"
>
  <div class="main_container blurry">
    <div class="greetings">
      <div class="greetings__phrase" i18n>Goals & Tracking</div>
      <div class="greetings__name" i18n>Practical techniques made actionable</div>
    </div>

    <div class="animated_hide">
      <section class="tracking-section">
        <header class="tracking-section__header">
          <div class="tracking-section__header-left" i18n>Currently tracking</div>
          <div
            class="tracking-section__header-right"
            *ngIf="selected_factors.length"
            (click)="toggleEditMode()"
          >
            <ng-container *ngIf="!edit_mode" i18n>Edit</ng-container>
            <ng-container *ngIf="edit_mode" i18n>Done</ng-container>
          </div>
        </header>
        <div class="tracking-section__items">
          <div
            *ngIf="!selected_factors.length"
            class="tracking-section__item tracking-section__item--solo tracking-section__item--inactive"
          >
            <span i18n>Choose a goal from the list below</span>
          </div>
          <div
          *ngIf="!prepared_selected_factors.length&&prepared_checked_factors.length"
          class="tracking-section__item tracking-section__item--solo tracking-section__item--inactive"
        >
            <span i18n>Tomorrow your goals will appear again</span>
          </div>
          <ng-container *ngIf="selected_factors.length">
            <div
              class="tracking-section__item_edit_container"
              *ngFor="let item of prepared_selected_factors"
            >
              <div
                class="tracking-section__item_edit"
                (click)="selectFactor(item.id)"
                [ngClass]="{ active: edit_mode }"
              >
                <span class="material-symbols-rounded"> close </span>
              </div>
              <div
                class="tracking-section__item"
                [ngClass]="{ active: item.checked }"
              >
                <div class="tracking-section__item-icon">
                  <span
                    class=""
                    [ngClass]="generateIconClass(item.cat_name)"
                  ></span>
                </div>
                <div class="tracking-section__item-text">
                  <div class="tracking-section__item-title">
                    {{ item.name }}
                  </div>
                  <div class="tracking-section__item-subtitle">
                    {{ item.cat_name }}
                  </div>
                </div>
                <div class="tracking-section__item-checkbox">
                  <span
                    (click)="checkFactor(item.id, 0)"
                    [ngClass]="{
                      'r-icon-unsmileFaceFilled': item.checked == 0,
                      'r-icon-unsmileFace': item.checked != 0
                    }"
                  ></span>
                  <span
                    (click)="checkFactor(item.id, 3)"
                    [ngClass]="{
                      'r-icon-smileFaceFilled': item.checked == 3,
                      'r-icon-smileFace': item.checked != 3
                    }"
                  ></span>
                  <!-- <span *ngIf="item.checked" class="r-icon-checkbox_checked"></span>
              <span
                *ngIf="!item.checked"
                class="r-icon-checkbox_unchecked"
              ></span> -->
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </section>

      <div class="animated_hide" [ngClass]="{'hided':!prepared_checked_factors.length}">
        <section class="tracking-section done-section">
          <header class="tracking-section__header">
            <div class="tracking-section__header-left" i18n>Done</div>
            <div class="tracking-section__header-right">
            </div>
          </header>
          <div class="tracking-section__items">

            <div
              class="tracking-section__item_edit_container"
              *ngFor="let item of prepared_checked_factors"
            >
              <div
                class="tracking-section__item_edit"
                (click)="selectFactor(item.id)"
              >
                <span class="material-symbols-rounded"> close </span>
              </div>
              <div
                class="tracking-section__item tracking-section__item--inactive"
              >
                <div class="tracking-section__item-icon">
                  <span
                    class=""
                    [ngClass]="generateIconClass(item.cat_name)"
                  ></span>
                </div>
                <div class="tracking-section__item-text">
                  <div class="tracking-section__item-title">
                    {{ item.name }}
                  </div>
                  <div class="tracking-section__item-subtitle">
                    {{ item.cat_name }}
                  </div>
                </div>
                <div class="tracking-section__item-checkbox">
                  <span
                    (click)="checkFactor(item.id, 0)"
                    [ngClass]="{
                      'r-icon-unsmileFaceFilled': item.checked == 0,
                      'r-icon-unsmileFace': item.checked != 0
                    }"
                  ></span>
                  <span
                    (click)="checkFactor(item.id, 3)"
                    [ngClass]="{
                      'r-icon-smileFaceFilled': item.checked == 3,
                      'r-icon-smileFace': item.checked != 3
                    }"
                  ></span>
                </div>
              </div>
            </div>

          </div>
        </section>
      </div>

      <hr />
    </div>
    <h2 i18n>Choose from one of these categories</h2>

    <div class="select_tracks">
      <section
        class="tracking-section"
        [ngClass]="{ 'no-margin': !opened_categories.includes(category.id) }"
        *ngFor="let category of areas.categories"
      >
        <header
          class="tracking-section__header can_click"
          (click)="toggleCategory(category.id)"
        >
          <div class="tracking-section__header-left">{{ category.name }}</div>
          <div class="tracking-section__header-right">
            <span
              *ngIf="!opened_categories.includes(category.id)"
              class="material-symbols-rounded"
            >
              expand_more
            </span>
            <span
              *ngIf="opened_categories.includes(category.id)"
              class="material-symbols-rounded"
            >
              expand_less
            </span>
          </div>
        </header>
        <div
          class="tracking-section__items animated_hide"
          [ngClass]="{ hided: !opened_categories.includes(category.id) }"
        >
          <div
            class="tracking-section__item tracking-section__item--small tracking-section__item--bf"
            [ngClass]="{
              'tracking-section__item--inactive': selected_factors.includes(
                factor.id
              )
            }"
            *ngFor="let factor of category.factors"
            (click)="selectFactor(factor.id)"
          >
            <div class="tracking-section__item-icon">
              <span
                class=""
                [ngClass]="generateIconClass(category.name)"
              ></span>
            </div>
            <div class="tracking-section__item-text">
              <div class="tracking-section__item-title">
                {{ factor.name }}
              </div>
              <div class="tracking-section__item-subtitle"></div>
            </div>
            <div class="tracking-section__item-checkbox">
              <button
                class="small_box_button"
                [ngClass]="{
                  'small_box_button--invert': selected_factors.includes(
                    factor.id
                  )
                }"
              >
                <ng-container *ngIf="!selected_factors.includes(factor.id)" i18n
                  >Track</ng-container
                >
                <ng-container *ngIf="selected_factors.includes(factor.id)" i18n
                  >Stop</ng-container
                >
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

  <div
    class="bottom_footer"
    [ngClass]="{ hided: !selected_factors.length }"
    (click)="reminderPopupToggle()"
  >
    <div *ngIf="init" class="bottom_footer__text">
      <ng-container *ngIf="!reminder_settings.notify_time" i18n
        >Consistency is key, so let us
      </ng-container>
      <ng-container *ngIf="reminder_settings.notify_time" i18n
        >Everyday at</ng-container
      ><span
        class="meditate-popup__select_option"
        (click)="reminderPopup($event, true)"
      >
        <select
          title="s"
          class="meditate-popup__select"
          [(ngModel)]="reminder_settings.notify_time"
          (ngModelChange)="reminderSettingsChange($event)"
        >
          <option value="{{ option }}" *ngFor="let option of reminder_times">
            {{ option }}
          </option>
        </select>
        <ng-container *ngIf="!reminder_settings.notify_time" i18n
          >help remind you</ng-container
        >
        <ng-container *ngIf="reminder_settings.notify_time">{{
          reminder_settings.notify_time
        }}</ng-container>
      </span>
      <ng-container *ngIf="reminder_settings.notify_time" i18n> for </ng-container>
      <span
        class="meditate-popup__select_option"
        *ngIf="reminder_settings.notify_time"
        (click)="reminderPopup($event, true)"
      >
        <select
          title="weeks"
          class="meditate-popup__select"
          [(ngModel)]="reminder_settings.notify_weeks"
          (ngModelChange)="reminderSettingsChange($event)"
        >
          <option selected value="every" i18n>every</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
        </select>
        <ng-container>{{ reminder_settings.notify_weeks }}</ng-container>
      </span>
      <ng-container *ngIf="reminder_settings.notify_time" i18n>
        week(s)</ng-container
      >
      <div
        class="meditate-popup__notifications"
        [ngClass]="{ active: reminder_popup }"
      >
        <h2 i18n>Notifications</h2>
        <div class="meditate-popup__notifications_container">
          <div
            class="meditate-popup__notification meditate-popup__notification--button"
            (click)="selectNotification($event, 'notify_email')"
            [ngClass]="{
              active:
                reminder_settings.notify_email !== '0' &&
                reminder_settings.notify_email
            }"
            i18n
          >
            Email
          </div>
          <div
            class="meditate-popup__notification meditate-popup__notification--button"
            (click)="selectNotification($event, 'notify_push')"
            [ngClass]="{
              active:
                reminder_settings.notify_push !== '0' &&
                reminder_settings.notify_push
            }" i18n
          >
            Push
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="meditate-popup__overlay"
    [ngClass]="{ active: reminder_popup }"
  ></div>
</section>
