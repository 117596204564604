import { BrowserModule } from '@angular/platform-browser';
import { NgModule,Injectable } from '@angular/core';


import { FormsModule }    from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { LocationStrategy } from '@angular/common';

import { routing }        from './app.routing';
import { SafeUrlPipe } from './_pipes/safe-url.pipe';

import { AuthGuard } from './_guards/index';
import { AuthenticationService, ApiService, UserService, DiagnosticService, ModalService, TopBarService, PassDataService, GoalkeeperService, VideoService,MeditationService  } from './_services/index';
import { JourneyService } from './_services/journey.service';
import { LoginComponent } from './login/index';
import { HomeComponent, HomePreviewComponent } from './home/index';
import { DiagnosticComponent } from './diagnostic/index';
import { MyAccountComponent } from './myaccount/index';
import { Globals } from './globals';

import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';

import { FlexLayoutModule } from "@angular/flex-layout";

import { AppComponent } from './app.component';
import { DiagnosticListComponent } from './diagnostic/diagnostic-list/diagnostic-list.component';
import { DiagnosticItemComponent } from './diagnostic/diagnostic-item/diagnostic-item.component';
import { DiagnosticAnswersComponent } from './diagnostic/diagnostic-answers/diagnostic-answers.component';
import { LoginFormComponent } from './login/login-form/login-form.component';
import { DiagnosticAnswerComponent } from './diagnostic/diagnostic-answers/diagnostic-answer/diagnostic-answer.component';
import { ModalComponent } from './_helpers/modal/modal.component';
import { ChartsModule } from 'ng2-charts';

import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';
// import { VgCoreModule, VgControlsModule, VgOverlayPlayModule, VgBufferingModule } from 'ngx-videogular';
// import {VgApiService, VgStates} from '@videogular/ngx-videogular/core';

import {
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { SettingsComponent } from './settings/settings.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { StartLinkComponent } from './start-link/start-link.component';
import { LimitToDirective } from './_directives/limit-to.directive';
import { GoalkeeperComponent } from './goalkeeper/goalkeeper.component';
import { GoalkeeperDashboardComponent } from './goalkeeper/goalkeeper-dashboard/goalkeeper-dashboard.component';
import { GoalkeeperSettingsComponent } from './goalkeeper/goalkeeper-settings/goalkeeper-settings.component';
import { GoalkeeperAreasComponent } from './goalkeeper/goalkeeper-areas/goalkeeper-areas.component';
import { GoalkeeperMygoalComponent } from './goalkeeper/goalkeeper-mygoal/goalkeeper-mygoal.component';
import { GoalkeeperTrackComponent } from './goalkeeper/goalkeeper-track/goalkeeper-track.component';
import { GoalkeeperResultComponent } from './goalkeeper/goalkeeper-result/goalkeeper-result.component';
import { MyautofocusDirective } from './_directives/myautofocus.directive';
import { AwardsComponent } from './goalkeeper/goalkeeper-result/awards/awards.component';
import { VideoCoursesComponent } from './video-courses/video-courses.component';
import { VideoListSeriesComponent } from './video-courses/video-list-series/video-list-series.component';
import { VideoWatchComponent } from './video-courses/video-watch/video-watch.component';
import { VideoWatchSeekerComponent } from './video-courses/video-watch/video-watch-seeker/video-watch-seeker.component';
import { VgVolumeVerticalComponent } from './_helpers/vg-volume-vertical/vg-volume-vertical.component';
import { VgPlayNextPrevComponent } from './_helpers/vg-play-next-prev/vg-play-next-prev.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { AccessCodesComponent } from './access-codes/access-codes.component';
import { VideoQuizComponent } from './video-courses/video-quiz/video-quiz.component';
import { PasswordToggleDirective } from './_directives/password-toggle.directive';
import { StrengthPasswordBarComponent } from './login/login-form/strength-password-bar/strength-password-bar.component';
import { SmallVideoPlayerComponent } from './video-courses/small-video-player/small-video-player.component';
import { FooterComponent } from './footer/footer.component';
import { OrganisationsFooterComponent } from './organisations-footer/organisations-footer.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { ResourcesComponent } from './resources/resources.component';
import { MeditateComponent } from './meditate/meditate.component';
import { ListComponent } from './meditate/list/list.component';
import { MeditateListComponent } from './meditate/meditate-list/meditate-list.component';
import { MeditateWatchComponent } from './meditate/meditate-watch/meditate-watch.component';
import { MeditateEpisodesComponent } from './meditate/meditate-episodes/meditate-episodes.component';
import { MediateReminderComponent } from './meditate/mediate-reminder/mediate-reminder.component';
import { MeditateStatsComponent } from './meditate/meditate-stats/meditate-stats.component';
import { WeeklyTipsComponent } from './weekly-tips/weekly-tips.component';
import { WeeklyTipsItemComponent } from './weekly-tips/weekly-tips-item/weekly-tips-item.component';
import { JourneyComponent } from './journey/journey.component';
import { EmotionsComponent } from './emotions/emotions.component';
import { OnemcoachComponent } from './onemcoach/onemcoach.component';
import { TwofapinComponent } from './login/twofapin/twofapin.component';
import { InstantCalmComponent } from './instant-calm/instant-calm.component';
import { AudioPlayerComponent } from './meditate/audio-player/audio-player.component';
import { MeditatePlayerComponent } from './meditate/meditate-player/meditate-player.component';
import { CustomLocationStrategy } from './_helpers/custom-location-strategy';
import { VideoPlayerComponent } from './video-courses/video-player/video-player.component';
import { HabitTrackerComponent } from './src/app/habit-tracker/habit-tracker.component';
import { HabitTrackerListComponent } from './src/app/habit-tracker/habit-tracker-list/habit-tracker-list.component';
import { HabitTrackerItemComponent } from './src/app/habit-tracker/habit-tracker-item/habit-tracker-item.component';
import { DiagnosticSpiralSoloComponent } from './diagnostic/diagnostic-spiral-solo/diagnostic-spiral-solo.component';



declare var Hammer: any;

@Injectable()
export class MyHammerConfig extends HammerGestureConfig  {
  buildHammer(element: HTMLElement) {
    let mc = new Hammer(element, {
      touchAction: "auto",
      prevent_default:true,
      domEvents: true,
    });
    return mc;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    HomePreviewComponent,
    DiagnosticComponent,
    MyAccountComponent,
    DiagnosticListComponent,
    DiagnosticItemComponent,
    DiagnosticAnswersComponent,
    LoginFormComponent,
    DiagnosticAnswerComponent,
    ModalComponent,
    SettingsComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    StartLinkComponent,
    LimitToDirective,
    GoalkeeperComponent,
    GoalkeeperDashboardComponent,
    GoalkeeperSettingsComponent,
    GoalkeeperAreasComponent,
    GoalkeeperMygoalComponent,
    GoalkeeperTrackComponent,
    GoalkeeperResultComponent,
    MyautofocusDirective,
    AwardsComponent,
    VideoCoursesComponent,
    VideoListSeriesComponent,
    VideoWatchComponent,
    VideoWatchSeekerComponent,
    VgVolumeVerticalComponent,
    VgPlayNextPrevComponent,
    NotificationsComponent,
    AccessCodesComponent,
    VideoQuizComponent,
    PasswordToggleDirective,
    StrengthPasswordBarComponent,
    SmallVideoPlayerComponent,
    FooterComponent,
    OrganisationsFooterComponent,
    ChatbotComponent,
    ResourcesComponent,
    MeditateComponent,
    ListComponent,
    MeditateListComponent,
    MeditateWatchComponent,
    MeditateEpisodesComponent,
    MediateReminderComponent,
    MeditateStatsComponent,
    WeeklyTipsComponent,
    WeeklyTipsItemComponent,
    JourneyComponent,
    EmotionsComponent,
    OnemcoachComponent,
    TwofapinComponent,
    InstantCalmComponent,
    AudioPlayerComponent,
    MeditatePlayerComponent,
    VideoPlayerComponent,
    SafeUrlPipe,
    HabitTrackerComponent,
    HabitTrackerListComponent,
    HabitTrackerItemComponent,
    DiagnosticSpiralSoloComponent,
  ],
  imports: [
     BrowserModule,
        FormsModule,
        HttpClientModule,
        routing,
        BrowserAnimationsModule,
        FlexLayoutModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatRadioModule,
        MatSidenavModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatSelectModule,
        ChartsModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
  		AuthGuard,
        Globals,
        AuthenticationService,
        ApiService,
        UserService,
        DiagnosticService,
        GoalkeeperService,
        VideoService,
        MeditationService,
        TopBarService,
        PassDataService,
        JourneyService,
        ModalService,{
      // hammer instantion with custom config
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig ,
    },
    // {
    //   provide: LocationStrategy,
    //   useClass: CustomLocationStrategy
    // }
  ],
        bootstrap: [AppComponent],

})

export class AppModule { }
// registerLocaleData(localeEs, 'es');

