// import the required animation functions from the angular animations module
import { trigger, state, animate, transition, style, query, animateChild, group,stagger } from '@angular/animations';
 
export const homeAnimation =
    // trigger name for attaching this animation to an element using the [@triggerName] syntax
    [trigger('homeAnimation', [  
      transition('* => start', [
          group([
            query('.video', [
                  style({ opacity: "0", transform: "translateY(-100px)"}),
              ]),
            query('.rapt', [
                  style({ opacity: "0", transform: "translateY(-100px)"}),
              ]),
            query('.diagnostic', [
                  style({ opacity: "0", transform: "translateY(-100px)"}),
              ]),
            query('.goal', [
                  style({ opacity: "0", transform: "translateY(-100px)"}),
              ]),
          ]),
           group([
             query('.video', [
              query('.card_title', [
                 style({ opacity: "0",transform: "translateX(100px)",filter: "blur(30px)"}),
                 ]),
              animate("700ms 100ms cubic-bezier(0.35, 0, 0.25, 0.25)",  style({ opacity: "1",transform: "translateY(0)"})),
              query('.card_title', [
                 animate("700ms cubic-bezier(0.35, 0, 0.25, 1)",  style("*"))
              ]),
          ]),
          query('.goal', [
              query('.card_title', [
                 style({ opacity: "0",transform: "translateX(100px)",filter: "blur(30px)"}),
                 ]),
              animate("700ms 300ms cubic-bezier(0.35, 0, 0.25, 0.25)",  style({ opacity: "1",transform: "translateY(0)"})),
              query('.card_title', [
                 animate("700ms cubic-bezier(0.35, 0, 0.25, 1)",  style("*"))
              ]),
          ])
          ,
          query('.rapt', [
              query('.card_title', [
                 style({ opacity: "0",transform: "translateX(100px)",filter: "blur(30px)"}),
                 ]),
              animate("700ms 500ms cubic-bezier(0.35, 0, 0.25, 0.25)",  style({ opacity: "1",transform: "translateY(0)"})),
              query('.card_title', [
                 animate("700ms cubic-bezier(0.35, 0, 0.25, 1)",  style("*"))
              ]),
          ])
          ,
          query('.diagnostic', [
              query('.card_title', [
                 style({ opacity: "0",transform: "translateX(100px)",filter: "blur(30px)"}),
                 ]),
              animate("700ms 700ms cubic-bezier(0.35, 0, 0.25, 0.25)",  style({ opacity: "1",transform: "translateY(0)"})),
              query('.card_title', [
                 animate("700ms cubic-bezier(0.35, 0, 0.25, 1)",  style("*"))
              ]),
          ])
            ]),
       ]),
    ]),
    trigger('homePreviewAnimation', [
      state('start', style({ transform: "scale(0)",height:0,padding:0})), 
      state('preview', style("*")),
      transition('start => preview', [
          style({ transform: "scale(0)",height:0,padding:0}),
          animate("900ms cubic-bezier(0.35, 0, 0.25, 0.25)",  style("*")),
        ])
    ]),
    trigger('homePreviewContentAnimation', [
      state('start', style({opacity: 0 })),
      state('preview', style("*")),
      transition('start => preview', [
              style({opacity: 1 }),
              query('*', [
                style({ transform: 'translateX(200px)', opacity: 0 }),
                stagger(100, [
                  animate('1200ms cubic-bezier(0.35, 0, 0.25, 1)',  style("*"))
                ])
              ])
        ])
    ]),
      trigger('previewAnimation', [
                transition(':enter', [
                  
                   style({ transform: "scale(0)",height:0,padding:0}),
                   animate("900ms cubic-bezier(0.35, 0, 0.25, 0.25)",  style("*")),
                   query('.card_preview', [
                    animateChild()
                   ]),
                  ]),
                  transition(':leave', [
                    style({ overflow: 'hidden' }),
                    animate('0.5s cubic-bezier(0.35, 0, 0.25, 1)', style({ height: '0px'}))
                  ]),
               transition('* => *', [
                  query(':enter, :leave', style({ position: 'absolute', left: '0%' })),
                  query(':enter', style({ left: '100%' })),
                
                  group([
                    query(':leave', group([
                      animateChild(),
                      animate('1200ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity:0, left:'-100%' }))
                    ])),

                    query(':enter', group([
                      animate('1200ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity:1, left:'0%' })),
                      animateChild()
                    ]), { delay: 200 }),
                  ])
                ])
        ]),
  trigger('previewContentAnimation', [
            transition(':enter', [
            query('*', [
                style({ transform: 'translateX(200px)', opacity: 0 }),
                stagger(100, [
                  animate('1200ms cubic-bezier(0.35, 0, 0.25, 1)', style("*"))
                ])
              ])
            ]),
    ])
   ]









