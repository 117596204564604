import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute,Router } from '@angular/router';
import { VideoService,UserService,ModalService } from '../_services/index';
import { routeAnimation } from '../_animations/index';
import { onemcoachAnimation } from '../_animations/onemcoach.animation';
import { MixpanelService } from '../_services/mixpanel.service';
import {GoBackHelper} from '../_helpers/go-back-helper'

@Component({
  selector: 'app-onemcoach',
  templateUrl: './onemcoach.component.html',
  styleUrls: ['./onemcoach.component.scss'],
  animations:[routeAnimation,onemcoachAnimation],
  // host: { '[@fadeRoute]': '' }
})
export class OnemcoachComponent implements OnInit {
  episodes:any = [];
  episodes_buffer:any = [];
  tags:any = [];
  selected_tags:any = [];
  current_episode:any = {};
  current_rate=-1;
  error = "";
  featured_episodes=[];
  prepared_episodes=[]

  constructor(private goBackHelper:GoBackHelper, private viewportScroller: ViewportScroller,private route: ActivatedRoute, private mixpanelService:MixpanelService, private modalService:ModalService,private userService:UserService,private videoService:VideoService) { }

  ngOnInit(): void {
    
    this.mixpanelService.trackView('One Minute Coach Page','view');
    const tag = ('tag' in this.route.snapshot.queryParams)?this.route.snapshot.queryParams['tag']:false
    
    this.videoService.getOneMinuteCoachEpisodes().subscribe(
      result => {
          this.episodes = result.episodes;
          this.episodes_buffer = result.episodes;
          this.tags = this.extractTags()
          this.featured_episodes = this.getFeatured(result.episodes);
          this.prepared_episodes = this.prepareEpisodesByTags();
          if (tag)
            setTimeout(()=>this.selectTag(tag),100)

      },
        error => {
            this.error = error ;
          console.log("error",this.error)
        })
  }

  getFeatured(items) {
    if (items.length<=0)
      return [];
      
    return [...items].sort(() => 0.5 - Math.random()).slice(0, 3);
  }

  selectRate(rate) {
    this.current_rate=rate;
  }


  sendTrack(episode_id) {
    this.videoService.oneMinuteCoachTrack(episode_id).subscribe(
      result => {
      },
        error => {
            this.error = error ;
          console.log("error",this.error)
        })
  }
  goBack(){
    this.goBackHelper.goBack();
  }

  sendRate(episode_id,rate) {
    if (rate ===-1)
      return false;
    this.videoService.oneMinuteCoachRate(episode_id,rate).subscribe(
      result => {
      },
        error => {
            this.error = error ;
          console.log("error",this.error)
        })
  }

  openEpisode(episode) {
    this.current_rate=-1;
    this.current_episode = episode;
    this.modalService.open("onemcoach_modal");
    this.mixpanelService.trackButton('/','One Minute Coach Video','button',{episode_title:episode.title, episode_id:episode.id});
    this.sendTrack(episode.id);
  }
  closeModal = () => {
    this.sendRate(this.current_episode.id,this.current_rate);
    this.modalService.close("onemcoach_modal");
    this.current_episode = {};
    this.current_episode.video=null;
    this.current_rate=-1;
  }
  
  extractTags(){
      if (!(this.episodes))
        return []
      const tags = this.episodes.reduce((acc,episode)=>{
        let buff = acc.concat(episode.tags)
        return buff.filter((item,index)=>(buff.indexOf(item) == index))
      },[])
      return tags;
  }

  selectTag(tag){
    this.mixpanelService.trackButton('/','One Minute Coach Tag','button',{'tag':tag});
    // this.viewportScroller.scrollToPosition([0, 600])
    // document.getElementById(tag).scrollIntoView();
    document.getElementById("main").scrollTo({
      behavior: "smooth",
      top: document.getElementById(tag).offsetTop
    }); // strange scrollIntoView behaviour
    // console.log('trying', this.viewportScroller.scrollToAnchor(tag));
  }

  prepareEpisodes(){
    if (this.selected_tags.length===0)
      return this.episodes
    else
      return this.episodes.filter(item=>this.selected_tags.every(v => item.tags.includes(v)))
  }

  prepareEpisodesByTags(){
    let arr = []
    return this.tags.map(tag=>{return {'tag':tag,'episodes':this.episodes.filter(item=>item.tags.includes(tag))}})
  }

}
