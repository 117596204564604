import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable()
export class Globals {
  api_server: string = environment.apiUrl;
  // api_server: string = 'http://test.local/diagnostic-tool/api/v1.0/';
  // api_server: string = 'https://resilience.guide/cms/api/v1.0/';
  // api_server: string = 'https://resilience.direct/cms/api/v1.0/';
  web_push_public_key: string = 'BEnFPEnN2i3pQmUuncEVGEnyTadTtymCm9zFL7ZOJPtQ5qEUubSIsVnRzoiw-YUB8xsFHFa_0KPzSpMua6oB_qA';
  bot_key: string = 'ba169d1ad53f4ecf94f207e831f4e44c'; 
  ios = Number(environment.platform=="ios"||environment.platform=="android");
  platform = environment.platform;
  cutout = 0;
  features=[];
}