import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { routeAnimation } from '../_animations/route.animation';
import {
  UserService,
  ModalService,
} from "../_services/index";
import { fromEvent, interval } from 'rxjs';
import { map, switchMap, takeUntil, pairwise, filter, switchAll } from 'rxjs/operators';
import { GoBackHelper } from '../_helpers/go-back-helper';




@Component({
  selector: 'app-emotions',
  templateUrl: './emotions.component.html',
  styleUrls: ['./emotions.component.scss'],
  animations: [routeAnimation],

})
export class EmotionsComponent implements OnInit {
  @ViewChild('svgWrap', { static: true }) svgWrapRef: ElementRef;
  current_rate = -1;
  selected_factor = false;
  error = "";
  init = false;

  colors = ['#EF476F', '#06D6A0', '#456990', '#118AB2',
    '#FFD166', '#073B4C', '#028090', '#F45B69'];

  gradient_colors = [['#EF476F', '#f692aa'], ['#06D6A0', '#48facc'], ['#456990', '#a4bbd3'], ['#118AB2', '#6ed1f1'],
  ['#FFC53F', '#FFE29E'], ['#073B4C', '#6bd0f1'], ['#028090', '#5aeafd'], ['#F45B69', '#fbbdc3']];

  sections = [];
  // sections= [ 'Anger', 'Disgust', 'Sadness', 
  //           'Fear', 'Joy', 
  //           'Fun & Recreation',
  //           'Phyical Environ.','Career'];
  subcategories = []
  // subcategories=[['Infuriated','Hatred','sdaf'],['Abused','Reppeled','Loath','Revolted'],['Agony','Distress','Despair'],['Frightened','Threatened'],['Bliss','Gratitude','Ecstatic']]
  // subcategories=[['Infuriated','Furious','Outrage','Hatred'],['Abused','Reppeled','Loath','Revolted'],['Agony','Distress','Despair'],['Frightened','Threatened'],['Bliss','Gratitude','Ecstatic']]
  // subcategories=[['Infuriated','Furious','Outrage','Hatred'],['Abused','Reppeled','Loath','Revolted'],['Agony','Distress','Despair','Hurt'],['Frightened','Threatened','Frantic','Alarm'],['Bliss','Gratitude','Ecstatic','Inspiration']]

  openedSections = []

  svgSize = 410;//310;
  midSvgSize = this.svgSize / 2;
  diameter = this.svgSize - 10; // Should be even no
  r = this.diameter / 2
  offset = 20;
  textPadding = 120;//80;
  textPadding2 = 0;//60;
  divisions = 4; // Should be a factor of 360
  degree = 360 / this.divisions;
  gardients = 1;

  coords = this.coordinatesFromDegree(this.degree, this.r);
  d = `M 0,0 l ${this.r},0 A ${this.r},${this.r},0,0,0,${this.coords.x},${-this.coords.y} L 0,0`;
  transforms = [];
  subTransforms = {};
  textTransforms = [];
  textAnchors = [];
  subTextAnchors = [];
  subTextTransforms = {};
  center: any = {};

  bindScrollListener = this.onWindowScroll.bind(this)
  onWindowScroll(event: Event) {
    const svgWrapElement = this.svgWrapRef.nativeElement;
    const svgWrapRect = svgWrapElement.getBoundingClientRect();
    
    this.center = {
      x: svgWrapRect.left + svgWrapRect.width / 2,
      y: svgWrapRect.top + svgWrapRect.height / 2,
    };
    // console.log("center change", this.center)
  }

  constructor(private userService: UserService, private goBackHelper: GoBackHelper) { }

  ngOnDestroy(): void {
    
    const mainElement = document.querySelector('main');
    // console.log("Close compoennt", mainElement)
    if (mainElement) {
      mainElement.removeEventListener('scroll', this.bindScrollListener);
    }
  }

  

  ngOnInit(): void {
    const mainElement = document.querySelector('main');
    if (mainElement) {
      mainElement.addEventListener('scroll', this.bindScrollListener);
    }
    const svgWrapElement = this.svgWrapRef.nativeElement;
    const svgWrapRect = svgWrapElement.getBoundingClientRect();

    this.center = {
      x: svgWrapRect.left + svgWrapRect.width / 2,
      y: svgWrapRect.top + svgWrapRect.height / 2,
    };

    let previousRotation = 0;
    let currentRotation = 2 * Math.PI;
    let startAngle = 0;
    let inertiaInterval: any;
    let inertiaVelocity = 0; // Initial inertia velocity
    const inertiaDeceleration = 0.95; // Deceleration factor for inertia
    const inertiaStepFactor = 0.01;
    let totalRotation = 0; // Accumulated total rotation
    let previousTotalRotation = 0; // Previous accumulated total rotation
    let lastIterationForward = false;
    let lastDelta = 0;

    const mouseDown$ = fromEvent<MouseEvent>(svgWrapElement, 'mousedown');
    const mouseMove$ = fromEvent<MouseEvent>(document, 'mousemove');
    const mouseUp$ = fromEvent<MouseEvent>(document, 'mouseup');

    const touchStart$ = fromEvent<TouchEvent>(svgWrapElement, 'touchstart');
    const touchMove$ = fromEvent<TouchEvent>(document, 'touchmove');
    const touchEnd$ = fromEvent<TouchEvent>(document, 'touchend');
    let insideClick = false;

    const drag$ = mouseDown$.pipe(
      map((startEvent) => {
        const start = { x: startEvent.clientX, y: startEvent.clientY };
        const startAngle = Math.atan2(start.y - this.center.y, start.x - this.center.x);

        return mouseMove$.pipe(
          map((moveEvent) => {
            const current = {
              x: moveEvent.clientX - this.center.x,
              y: moveEvent.clientY - this.center.y,
            };
            const currentAngle = Math.atan2(current.y, current.x);
            let deltaAngle = currentAngle - startAngle;

            // Adjust deltaAngle to ensure continuous rotation
            if (deltaAngle > Math.PI) {
              deltaAngle -= 2 * Math.PI;
            } else if (deltaAngle < -Math.PI) {
              deltaAngle += 2 * Math.PI;
            }

            return deltaAngle;
          }),
          takeUntil(mouseUp$)
        );
      }),
      switchAll()
    );


    const touchDrag$ = touchStart$.pipe(
      map((startEvent) => {
        const start = {
          x: startEvent.touches[0].clientX - this.center.x,
          y: startEvent.touches[0].clientY - this.center.y,
        };
        
        const startAngle = Math.atan2(start.y, start.x);
        return touchMove$.pipe(
          map((moveEvent) => {
            const current = {
              x: moveEvent.touches[0].clientX - this.center.x,
              y: moveEvent.touches[0].clientY - this.center.y,
            };
            const currentAngle = Math.atan2(current.y, current.x);
            let deltaAngle = currentAngle - startAngle;
            if (deltaAngle > Math.PI) {
              deltaAngle -= 2 * Math.PI;
            } else if (deltaAngle < -Math.PI) {
              deltaAngle += 2 * Math.PI;
            }
            return deltaAngle;
          }),
          takeUntil(touchEnd$)
        );
      }),
      switchAll()
    );

    drag$.subscribe((deltaAngle) => {
      currentRotation = previousRotation + deltaAngle;

      if (lastDelta !== currentRotation)
        lastIterationForward = lastDelta < currentRotation

      lastDelta = currentRotation
      // console.log("check", lastIterationForward, lastDelta, currentRotation)
      if (Math.sign(currentRotation) !== Math.sign(previousRotation)) {

      }
      // console.log('rotation', previousRotation, deltaAngle)
      // Rotate the wheel based on the current and previous rotations
      const rotate = `rotate(${currentRotation}rad)`;
      svgWrapElement.style.transform = rotate;
    });

    touchDrag$.subscribe((deltaAngle) => {
      currentRotation = previousRotation + deltaAngle;
      // Rotate the wheel based on the current and previous rotations
      const rotate = `rotate(${currentRotation}rad)`;
      svgWrapElement.style.transform = rotate;
    });


    mouseDown$.subscribe((event) => {
      insideClick=true
      // Store the initial rotation angle and the current rotation
      startAngle = Math.atan2(event.clientY - this.center.y, event.clientX - this.center.x);
      previousRotation = currentRotation;
      previousTotalRotation = totalRotation;
      clearInterval(inertiaInterval);
    });

    touchStart$.subscribe((event) => {
      insideClick = true
      // Store the initial rotation angle and the current rotation
      startAngle = Math.atan2(
        event.touches[0].clientY - this.center.y,
        event.touches[0].clientX - this.center.x
      );
      previousRotation = currentRotation;
      clearInterval(inertiaInterval);
    });

    mouseUp$.subscribe(() => {
      // console.log("st",insideClick)
      if (!insideClick)
        return;
      insideClick=false
      // Calculate the inertia rotation and velocity
      const inertiaRotation = currentRotation - previousRotation;
      // console.log("inertion", previousTotalRotation, totalRotation);
      inertiaVelocity = inertiaRotation;

      // Start the inertia animation
      const inertiaTime = 500; // Duration of the inertia animation in milliseconds
      const framesPerSecond = 60; // Number of frames per second
      const totalFrames = inertiaTime / 1000 * framesPerSecond; // Total number of frames
      const frameDuration = 1000 / framesPerSecond; // Duration of each frame in milliseconds

      let currentFrame = 0;
      inertiaInterval = setInterval(() => {
        // Check if the inertia rotation goes backward
        if (Math.sign(inertiaRotation) !== Math.sign(inertiaVelocity)) {
          inertiaVelocity = -inertiaVelocity; // Reverse the inertia velocity
        }

        // Apply inertia velocity to the wheel
        currentRotation += inertiaVelocity * inertiaStepFactor;

        // Wrap the rotation within the valid range (0 to 2π)
        if (currentRotation < 0) {
          currentRotation += Math.PI * 2;
        } else if (currentRotation > Math.PI * 2) {
          currentRotation -= Math.PI * 2;
        }

        // Update the inertia velocity with deceleration
        inertiaVelocity *= inertiaDeceleration;

        // Rotate the wheel based on the current rotation
        const rotate = `rotate(${currentRotation}rad)`;
        svgWrapElement.style.transform = rotate;

        currentFrame++;

        // Stop the inertia animation when all frames have been rendered or the velocity becomes negligible
        if (currentFrame === totalFrames || Math.abs(inertiaVelocity) < 0.01) {
          clearInterval(inertiaInterval);
        }
      }, frameDuration);
    });

    touchEnd$.subscribe(() => {
      if (!insideClick)
      return;
      
      insideClick=false
      // Calculate the inertia rotation and velocity
      const inertiaRotation = currentRotation - previousRotation;
      inertiaVelocity = inertiaRotation;

      // Start the inertia animation
      const inertiaTime = 500; // Duration of the inertia animation in milliseconds
      const framesPerSecond = 60; // Number of frames per second
      const totalFrames = inertiaTime / 1000 * framesPerSecond; // Total number of frames
      const frameDuration = 1000 / framesPerSecond; // Duration of each frame in milliseconds

      let currentFrame = 0;
      inertiaInterval = setInterval(() => {
        // Check if the inertia rotation goes backward
        if (Math.sign(inertiaRotation) !== Math.sign(inertiaVelocity)) {
          inertiaVelocity = -inertiaVelocity; // Reverse the inertia velocity
        }

        // Apply inertia velocity to the wheel
        currentRotation += inertiaVelocity * inertiaStepFactor;

        // Update the inertia velocity with deceleration
        inertiaVelocity *= inertiaDeceleration;

        // Rotate the wheel based on the current rotation
        const rotate = `rotate(${currentRotation}rad)`;
        svgWrapElement.style.transform = rotate;

        currentFrame++;

        // Stop the inertia animation when all frames have been rendered or the velocity becomes negligible
        if (currentFrame === totalFrames || Math.abs(inertiaVelocity) < 0.01) {
          clearInterval(inertiaInterval);
        }
      }, frameDuration);
    });


    this.userService.getEmotionalCombat().subscribe(
      (result) => {
        this.init = true;
        this.sections = result;
        this.divisions = this.sections.length;
        this.reInit(this.sections.length, window.innerWidth < 420 ? 'small' : 'normal');
        this.error = '';
        this.draw()
        setTimeout(() => {
          const svgWrapRect = svgWrapElement.getBoundingClientRect();
          this.center = {
            x: svgWrapRect.left + svgWrapRect.width / 2,
            y: svgWrapRect.top + svgWrapRect.height / 2,
          };
        }, 100)
      },
      (error) => {
        this.error = error;
        this.init = true;
      }
    );


  }

  reInit(divisions, size = 'normal') {
    if (size == 'normal') {
      this.svgSize = 410;//310;
      this.midSvgSize = this.svgSize / 2;
      this.diameter = this.svgSize - 10; // Should be even no
      this.r = this.diameter / 2
      this.offset = 20;
      this.textPadding = 120;//80;
      this.textPadding2 = 0;//60;
      this.divisions = divisions; // Should be a factor of 360
      this.gardients = 1;
      this.degree = 360 / this.divisions;
      this.coords = this.coordinatesFromDegree(this.degree, this.r);
      this.d = `M 0,0 l ${this.r},0 A ${this.r},${this.r},0,0,0,${this.coords.x},${-this.coords.y} L 0,0`;

    }
    if (size == 'small') {
      this.svgSize = 310;
      this.midSvgSize = this.svgSize / 2;
      this.diameter = this.svgSize - 10; // Should be even no
      this.r = this.diameter / 2
      this.offset = 20;
      this.textPadding = 80;
      this.textPadding2 = 60;
      this.divisions = divisions; // Should be a factor of 360
      this.gardients = 1;
      this.degree = 360 / this.divisions;
      this.coords = this.coordinatesFromDegree(this.degree, this.r);
      this.d = `M 0,0 l ${this.r},0 A ${this.r},${this.r},0,0,0,${this.coords.x},${-this.coords.y} L 0,0`;
    }
    this.transforms = [];
    this.subTransforms = {};
    this.textTransforms = [];
    this.textAnchors = [];
    this.subTextAnchors = [];
    this.subTextTransforms = {};

  }

  pushTransformsPiece(i) {
    this.transforms.push(`rotate (${(i * this.degree) + this.degree / 2} 
        ${this.midSvgSize} ${this.midSvgSize}) translate (${this.midSvgSize}, ${this.midSvgSize})`);
    let calc = 0;
    let calcS = 0;
    if ((i * this.degree) > 120) {
      calc = (i * this.degree) - 180;
      calcS = 180 - this.midSvgSize;
    }
    else {
      calc = i * this.degree
      calcS = this.midSvgSize;
    }
    this.textTransforms.push(` rotate(${calc} ${this.midSvgSize} ${this.midSvgSize})
        translate(${calcS}, ${this.midSvgSize})`);

  }

  pushTransformsPieceNew(degree, index, sub, childSameVectorRes = 0) {
    let trArray: any = this.transforms
    let textArray: any = this.textTransforms
    let textAnchors = this.textAnchors

    if (sub) {
      if (!this.subTransforms[index])
        this.subTransforms[index] = []
      trArray = this.subTransforms[index];

      if (!this.subTextTransforms[index])
        this.subTextTransforms[index] = []
      textArray = this.subTextTransforms[index];

      textAnchors = this.subTextAnchors
    }

    trArray.push(`rotate (${(degree) + this.degree / 2 + this.offset} 
        ${this.midSvgSize} ${this.midSvgSize}) translate (${this.midSvgSize}, ${this.midSvgSize})`);
    let calc = 0;
    let calcS = 0;
    let rotate = 0;
    let childSameVector = 0;

    if ((childSameVectorRes == 1) || (((degree) > 130) && ((degree) < 280) && (childSameVectorRes != 2))) {
      // if (((degree)>130)&&((degree)<280)){
      calc = (degree) - 180 + this.offset;
      calcS = 180 - this.midSvgSize - this.textPadding2;
      textAnchors[index] = 'start';
      rotate = 3;
      if (sub && sub < this.degree)
        calc = calc - this.degree / 2 + sub / 3;

      childSameVector = 1;
    }
    else {
      calc = degree + this.offset
      calcS = this.midSvgSize + this.textPadding;
      textAnchors[index] = 'end';
      rotate = -6;
      // alert(sub)
      if (sub)
        calc = calc - this.degree / 2 + sub / (this.divisions > 4 ? 1.2 : 1.3);
      // calc=calc-this.degree/2+sub/3-sub/4;
      childSameVector = 2;
    }
    // console.log('sss', sub, degree);
    if (sub)
      textArray.push(` rotate(${calc} ${this.midSvgSize} ${this.midSvgSize})
              translate(${calcS}, ${this.midSvgSize - 10}) rotate(${rotate})`);
    else
      textArray.push(` rotate(${calc} ${this.midSvgSize} ${this.midSvgSize})
            translate(${calcS}, ${this.midSvgSize}) rotate(${rotate})`);

    return childSameVector;
  }

  draw() {
    this.transforms = [];
    this.textTransforms = [];
    this.subTransforms = {};
    this.subTextTransforms = {};

    for (let i = 0; i < this.divisions; i += 1) {
      if (this.openedSections.includes(i)) {
        let prevResult = 0;
        for (let j = 0; j < this.sections[i].items.length; j += 1) {
          if (!j) {
            prevResult = this.pushTransformsPieceNew(((i * this.degree) + (j * (this.degree) / this.sections[i].items.length)), i, ((this.degree) / this.sections[i].items.length), prevResult)
          }
          else {
            this.pushTransformsPieceNew(((i * this.degree) + (j * (this.degree) / this.sections[i].items.length)), i, ((this.degree) / this.sections[i].items.length), prevResult)
          }
        }
      }
      this.pushTransformsPieceNew(i * this.degree, i, false)

    }
    // console.log('!!!!',this.transforms,this.openedSections,this.subcategories)
  }



  coordinatesFromDegree(degree: any, radius: any) {
    // TODO: check if degree is valid
    const angleinRadians = degree * (Math.PI / 180);
    const y = Math.round(Math.sin(angleinRadians) * radius);
    const x = Math.round(Math.cos(angleinRadians) * radius);
    return { x, y };
  }

  pathClick(index) {
    this.openedSections = [];
    this.openedSections.push(index)
    this.draw()
  }

  subPathClick(index, subindex) {
    if (this.selected_factor === this.sections[index].items[subindex])
      return false;
    this.current_rate = -1;
    if (this.selected_factor) {
      this.selected_factor = false;
      setTimeout(() => this.selected_factor = this.sections[index].items[subindex], 600);

    } else {
      this.selected_factor = this.sections[index].items[subindex]
    }
    if (window.innerWidth < 780) {
      setTimeout(() => {
        const mainElement = document.querySelector("main");
        const helpTextElement = document.getElementById("help_text");
        const rect = helpTextElement.getBoundingClientRect();
        const helpTextPosition = rect.top + window.scrollY;
        mainElement.scrollTo({
          top: helpTextPosition,
          behavior: 'smooth'
        });
        // console.log('go', helpTextPosition, rect.top);
      }, 1100);
    }

  }


  backButton() {
    this.goBackHelper.goBack()
  }


  selectRate(factor_id, rate) {
    if (this.current_rate !== -1)
      return false;

    this.userService.rateEmotionalCombat(factor_id, rate).subscribe(
      (result) => {
        this.error = '';
        this.current_rate = rate;
      },
      (error) => {
        this.error = error;
      }
    );

  }


}
