import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {
  private version: string;
  private platform: string;
  /**
   * Initialize mixpanel.
   *
   * @param {string} userToken
   * @memberof MixpanelService
   */
  init(userToken: string, version: string, platform: string, production: boolean): void {
    this.version=version;
    this.platform=platform;
    mixpanel.init(userToken, {debug: !production});
    // mixpanel.register({
    //   "versiooon": version,
    // });
    
  }

   /**
   * add user identificator
   *
   * @param {string} id 
   * @memberof MixpanelService
   */
   registerUserIdentificator(id: string): void {

    mixpanel.register({
         "user": btoa(id)
       });
       mixpanel.identify(btoa(id));
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {
    action.version=this.version
    action.platform=this.platform
    mixpanel.track(id, action);
  }


  trackButton(buttonUrl: string, buttonName: string, action: string, add:{} = {}): void {
   this.track('Button Click',{'button_url':buttonUrl,'button_name':buttonName,'action':action,...add})
  }

  trackView(page: string, info: string = '', add:{} = {}): void {
    this.track('Page View',{'page':page,'info':info,...add})
  }

  /**
   * Push time event action to mixpanel.
   *
   * @param {string} name Name of the time event to track.
   * @memberof MixpanelService
   */
  timeEvent(name: string): void {
    mixpanel.time_event(name);
  }
}