import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-habit-tracker-item',
  templateUrl: './habit-tracker-item.component.html',
  styleUrls: ['./habit-tracker-item.component.scss']
})
export class HabitTrackerItemComponent implements OnInit {

  habbit: any;
  constructor(private sanitizer: DomSanitizer, private router: Router, private route: ActivatedRoute) {
    // this.router.getCurrentNavigation().extras.state.habitData)
    const navigation = this.router.getCurrentNavigation();
    console.log("navigation", navigation)
    if (navigation && navigation.extras && navigation.extras.state) {
      this.habbit = navigation.extras.state.habitData;
      const startDateObj = new Date(this.habbit.date_created);
      let checkedDays = this.habbit.total_completed;
      for (let i = 1; i <= this.habbit.total_days; i++) {
        const highlightDate = new Date(startDateObj);
        highlightDate.setDate(startDateObj.getDate() + i - 1);

        //rand of yes or no sentece to const checked yes or no and if yes then checkedDays--
        let checked = false
        if (checkedDays&&Math.random() > 0.5) {
          checked = true
          checkedDays--
        }

        this.daysArray.push({
          dayNum: i,
          date: highlightDate,
          checked: checked,
          crossSvg: checked?this.createCrossSvg():''
        });
      }
      this.updateGoalFor()
      console.log("this.habbit", this.habbit)
    } else {
      console.log("nothing", this.route.snapshot.params['id'])
      // Get habit id from route and fetch habit data if needed
      const habitId = this.route.snapshot.params['id'];
      // Fetch or handle the habit by its id if the full object wasn't provided
    }
  }


  ngOnInit(): void {

  }

  getSafeSVG(svg: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(svg);
  }

  goal = '';
  reward = '';
  startDate: any;
  days = 1;
  goalFor = '';
  daysArray: any[] = [];

  generateDays() {
    const daysNum = this.days;
    const startDateObj = new Date(this.startDate);

    this.daysArray = [];

    for (let i = 1; i <= daysNum; i++) {
      const highlightDate = new Date(startDateObj);
      highlightDate.setDate(startDateObj.getDate() + i - 1);

      this.daysArray.push({
        dayNum: i,
        date: highlightDate,
        checked: false,
        crossSvg: ''
      });
    }
    this.updateGoalFor();
  }

  updateGoalFor() {
    const amountOfChecked = this.daysArray.filter(d => d.checked).length;
    this.goalFor = `for ${amountOfChecked} of ${this.habbit.total_days}`;
    console.log(this.habbit)
  }

  isHighlightDay(date: Date): boolean {
    const currentDate = new Date();
    return currentDate.getFullYear() === date.getFullYear() &&
      currentDate.getMonth() === date.getMonth() &&
      currentDate.getDate() === date.getDate();
  }

  isMissedDay(date: Date): boolean {
    const currentDate = new Date();
    return date < currentDate;
  }

  markCompleted(day: any) {
    day.checked = !day.checked;
    if (day.checked) {
      day.crossSvg = this.createCrossSvg();
      if (this.isHighlightDay(day.date)) {
        day.animate = true;
      }
    }else{
      day.animate = false;
    }
   
    this.updateGoalFor();
  }

  createCrossSvg(): string {
    const svgNS = "http://www.w3.org/2000/svg";
    const svg = document.createElementNS(svgNS, "svg");
    svg.setAttributeNS(null, "viewBox", "0 0 35 42");
    svg.setAttributeNS(null, "width", "100%");
    svg.setAttributeNS(null, "height", "100%");
    const rotation = Math.random() * 15 - 5;
    svg.setAttributeNS(null, "transform", `rotate(${rotation} 17.5 21)`);

    const path1 = document.createElementNS(svgNS, "path");
    path1.setAttributeNS(null, "d", this.generateRandomPath());
    path1.setAttributeNS(null, "stroke", "#605F6C");
    path1.setAttributeNS(null, "stroke-width", "3");
    path1.setAttributeNS(null, "fill", "none");

    const path2 = document.createElementNS(svgNS, "path");
    path2.setAttributeNS(null, "d", this.generateMirroredPath());
    path2.setAttributeNS(null, "stroke", "#605F6C");
    path2.setAttributeNS(null, "stroke-width", "3");
    path2.setAttributeNS(null, "fill", "none");

    svg.appendChild(path1);
    svg.appendChild(path2);
    // console.log("svg here?", svg.outerHTML, svg)
    return svg.outerHTML;
  }

  generateRandomPath(): string {
    const p1 = this.getRandomPoint(4.7, 4.1, 0.1, 0.4);
    const p2 = this.getRandomPoint(3.3, 8.2, 2.1, 4.9);
    const p3 = this.getRandomPoint(5.2, 19.7, 9.6, 25.2);
    const p4 = this.getRandomPoint(31.2, 33.2, 30.8, 33.8);
    const p5 = this.getRandomPoint(30.8, 33.8, 31.3, 31.6);
    const p6 = this.getRandomPoint(27.7, 29.7, 25.7, 27.9);
    const cp1 = p5
    const cp2 = p6

    return `M${p1.x} ${p1.y} C ${p2.x} ${p2.y} ${p3.x} ${p3.y} ${p4.x} ${p4.y}`;
    // return `M${p1.x} ${p1.y} C ${p2.x} ${p2.y} ${p3.x} ${p3.y} ${p4.x} ${p4.y} M${p5.x} ${p5.y} C ${cp1.x} ${cp1.y} ${cp2.x} ${cp2.y} ${p6.x} ${p6.y}`;
    // return `M${p1.x} ${p1.y} C ${p2.x} ${p2.y} ${p3.x} ${p3.y} ${p4.x} ${p4.y} L${p5.x} ${p5.y}`; //simple
    // return `M${p1.x} ${p1.y} C ${p2.x} ${p2.y} ${p3.x} ${p3.y} ${p4.x} ${p4.y} M${p5.x} ${p5.y} C ${p6.x} ${p6.y}`;//buggy
  }

  generateMirroredPath(): string {
    const p1 = this.getRandomPoint(1.7, 4.1, 37.6, 37.9);
    const p2 = this.getRandomPoint(3.3, 8.2, 33.7, 35.9);
    const p3 = this.getRandomPoint(5.2, 19.7, 16.8, 32.4);
    const p4 = this.getRandomPoint(31.2, 33.2, 1.2, 1.8);
    const p5 = this.getRandomPoint(30.8, 33.8, 2.2, 0.4);
    const p6 = this.getRandomPoint(27.7, 29.7, 7.2, 9.4);
    const cp1 = p6
    const cp2 = p6
    // console.log("p1", p1, p2, p3, p4, p5, p6)
    return `M${p1.x} ${p1.y} C ${p2.x} ${p2.y} ${p3.x} ${p3.y} ${p4.x} ${p4.y}`;
    // return `M${p1.x} ${p1.y} C ${p2.x} ${p2.y} ${p3.x} ${p3.y} ${p4.x} ${p4.y} M${p5.x} ${p5.y} C ${cp1.x} ${cp1.y} ${cp2.x} ${cp2.y} ${p6.x} ${p6.y}`;
    // return `M${p1.x} ${p1.y} C ${p2.x} ${p2.y} ${p3.x} ${p3.y} ${p4.x} ${p4.y} L${p5.x} ${p5.y}`;
    // return `M${p1.x} ${p1.y} C ${p2.x} ${p2.y} ${p3.x} ${p3.y} ${p4.x} ${p4.y} M${p5.x} ${p5.y} C ${p6.x} ${p6.y}`;
  }

  getRandomPoint(minX: number, maxX: number, minY: number, maxY: number): { x: number, y: number } {
    const x = Math.random() * (maxX - minX) + minX;
    const y = Math.random() * (maxY - minY) + minY;
    return { x, y };
  }

}
