// import the required animation functions from the angular animations module
import { trigger, state, animate, transition, style } from '@angular/animations';
 
export const routeAnimation =[
        trigger('fadeRoute', [
        // route 'enter' transition
        transition(':enter', [

            style({ position: 'absolute',width: '100%',opacity: 0.1 }),

            // animation and styles at end of transition
            animate('1s', style({ opacity: 1 }))
        ]),
    ]),
    trigger('fadeRouteNoAbs', [
        // route 'enter' transition
        transition(':enter', [

            style({ 'min-height':'100vh',position: 'absolute',height:'100%',width: '100%',opacity: 0 }),

            // animation and styles at end of transition
            animate('1s', style({ opacity: 1 }))
        ]),
    ]),
    trigger('slideRoute', [
        transition(':enter', [
            style({
                position: 'absolute',
                width: '100%',
                right: '-400%',
                'box-shadow': '10px 10px 5px 0px rgba(0,0,0,0.75)',
            }),

            // animation and styles at end of transition
            animate('1.2s', style({
                right: 0,
            }))
        ]),
        transition(':leave', [
            style({
                position: 'absolute',
                width: '100%',
                right: '0',
                'box-shadow': '10px 10px 5px 0px rgba(0,0,0,0.75)',
            }),

            // animation and styles at end of transition
            animate('1s', style({
                right: "-400%",
            }))
        ]),
    ]),
    trigger('fadeChange', [
      transition(':enter', [
        style({ opacity: 0}),
        animate('1000ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('1000ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 0 }))
      ]),
    ]),
    trigger('fadeChangeAbs', [
        transition(':enter', [
          style({ opacity: 0}),
          animate('1000ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1 }))
        ]),
        transition(':leave', [
          style({ opacity: 1 }),
          animate('1000ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 0,top:'-100%',position:'absolute'}))
        ]),
      ]),
      trigger('fadeChangeAbs2', [
        transition(':enter', [
          style({ opacity: 0}),
          animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1 }))
        ]),
        transition(':leave', [
          style({ opacity: 1 }),
          animate('200ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 0})),
          animate('300ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 0,transform:'translateY(-1000px)',position:'absolute'}))
        ]),
      ]),
    ]