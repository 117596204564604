// import the required animation functions from the angular animations module
import { trigger, state, animate, transition, style, query, animateChild, group } from '@angular/animations';
 
export const videoAnimation =
  [ trigger('slideRight', [
      transition(':enter', [
        style({ float:'left',transform:'translateX(150%)' }),
        animate('2000ms 1000ms cubic-bezier(0.35, 0, 0.25, 1)', style({position:'relative',transform:'translateX(0)' }))
      ])
    ]),
    ]
   







