import { Component, OnInit, HostListener,ViewChild,ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { PassDataService, DiagnosticService,ModalService, TopBarService,VideoService } from '../../_services/index';
import { routeAnimation,videoAnimation } from '../../_animations/index';
import { MixpanelService } from '../../_services/mixpanel.service';
// import {VgAPI,VgFullscreenAPI} from 'ngx-videogular';
import {VgApiService, VgFullscreenApiService} from '@videogular/ngx-videogular/core';

@Component({
  selector: 'app-video-watch',
  templateUrl: './video-watch.component.html',
  styleUrls: ['./video-watch.component.scss'],
  animations:[routeAnimation,videoAnimation],
  host: { '[@slideRoute]': '' }
})
export class VideoWatchComponent implements OnInit {
  id = 0;
  episodes:any = [];
  quiz:any = false;
  series:any = {};
  last:any={};
  selected_episode:any = [];
  last_percent_stat = 0;
  error = "";
  duration ="00:00";
  current = "00:00";
  percent = 0;
  buffered= 0;
  fullscreen= false;
  api:VgApiService;
  fsAPI: VgFullscreenApiService;
  nativeFs: boolean = true;
  pause=0;
  journey=0;
  old_tag_store: string;
  @ViewChild('videoTarget') videoPlayerDivRef: ElementRef;

	openModal(name)
	{
		this.modalService.open(name);
	}

	openQuiz(quiz)
	{
		this.modalService.open('quiz');
		this.mixpanelService.trackButton('', 'Video Episode Quiz Open', 'button', {'selected_episode':this.selected_episode[0].id});
	}

	handleQuizEnded(value){
	  	this.modalService.close('quiz');
	  	// this.quiz.result.finished = 1;
	 }

	onPlayerReady(api:VgApiService) {
	    this.api = api;
	    this.fsAPI = this.api.fsAPI;
        this.nativeFs = this.fsAPI.nativeFullscreen;

        this.fsAPI.onChangeFullscreen.subscribe(
	        event => {
	           this.fullscreen = event;
	        }
	    );

	    this.api.getDefaultMedia().subscriptions.canPlay.subscribe(
	        () => {
	        	this.api.play();
	            this.duration = this.transformTime(this.api.getDefaultMedia().time.total,"mm:ss")
	            if ((this.last)&&(this.last.init==0)){
	            	this.last.init=1;
				    this.api.seekTime(this.last.percent,true)	  
				} 
	        }
	    );

        	         

	    this.api.getDefaultMedia().subscriptions.timeUpdate.subscribe(
	        () => {
	           this.current = this.transformTime(this.api.getDefaultMedia().time.current,"mm:ss");
	           this.percent = this.getPercentage(this.api.getDefaultMedia().time.current,this.api.getDefaultMedia().time.total);
	           
	           if ((this.percent>0)&&((Math.round(this.percent) % 2)==0))
	           		if (this.last_percent_stat !== Math.round(this.percent))	
	           			this.videoService.saveStats(this.selected_episode[0].id,this.percent).subscribe(
					      result => {
					          this.last_percent_stat=Math.round(this.percent)
					      },
					        error => {
					            this.error = error ;
					          console.log("error",this.error)
					        })

	        }
	    );


	    this.api.getDefaultMedia().subscriptions.progress.subscribe(
	        () => {
	            this.buffered = this.getPercentage(this.api.getDefaultMedia().buffer.end ,this.api.getDefaultMedia().time.total);
	        }
	    );

	    this.api.getDefaultMedia().subscriptions.pause.subscribe(
	        () => {
	            this.pause = 1;
	            console.log("pause");
	        }
	    );

	    this.api.getDefaultMedia().subscriptions.play.subscribe(
	        () => {
	             this.pause = 0;
	             console.log("play");
	        }
	    );

	   
	    
	}

  constructor( private meta:Meta,private mixpanelService:MixpanelService, private passDataService:PassDataService,private videoService: VideoService, private topBarService: TopBarService, private modalService:ModalService, private route: ActivatedRoute,private diagnosticService: DiagnosticService, private router: Router) { }

    
  ngOnDestroy() {
    this.meta.updateTag({ content: this.old_tag_store }, 'name=theme-color');
  }

  ngOnInit() {
	this.old_tag_store=this.meta.getTag('name=theme-color').content;
    this.meta.updateTag({ content: '#2F292D' }, 'name=theme-color');
	this.mixpanelService.trackView('Videos Series List','view',{});
	this.journey=this.passDataService.get('journey');
  	this.topBarService.setShowHamburger(false);
  	this.route.params.subscribe(params => {
       this.id = +params['id']; // (+) c
       		 this.videoService.getSeries(this.id).subscribe(
			      result => {
			          this.episodes = result.episodes;
			          this.quiz = result.quiz;
			          this.series = result.series;
			          if (result.last)
			          	{
			          		
			          		this.last=result.last;
			          		this.last.init=0;
	            			let key = this.findEpisode(this.last.episode_id)
	            			console.log(this.episodes,this.last.episode_id,this.findEpisode(this.last.episode_id));
	            			if (key!==false)
			        		 this.selected_episode = [this.episodes[key]];
			        		 
			          	}else{
			          		if (this.episodes.length > 0)
				           	 this.selected_episode = [this.episodes[0]];	
			          	}
			          	this.videoService.saveViews(this.selected_episode[0].id).subscribe()
			          	
				         
				        


			      },
			        error => {
			            this.error = error ;
			          console.log("error",this.error)
			        })

       })
  }


  goBack(){
	  if (!this.journey)
		   this.router.navigate(['video','series']); 
		else
		   this.router.navigate(['journey', this.journey]); 
  }

  chooseEpisode(i){
  	if (this.episodes[i].id==this.selected_episode[0].id){
  		if (this.pause)
  			this.api.play();
  		else
  			this.api.pause();
  	}
  	else{
  		this.selected_episode = [this.episodes[i]];
  		this.videoService.saveViews(this.selected_episode[0].id).subscribe()
  	}
	this.videoPlayerDivRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
  	this.mixpanelService.trackButton('', 'Video Episode Click', 'button', {'selected_episode':this.selected_episode[0].id,'play':this.pause});
  }


transformTime(value: number, format: string): string {
    let date = new Date(value);
    let result = format;
    let ss: string|number = date.getUTCSeconds();
    let mm: string|number = date.getUTCMinutes();
    let hh: string|number = date.getUTCHours();

    if (ss < 10) {
        ss = '0' + ss;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    if (hh < 10) {
        hh = '0' + hh;
    }

    result = result.replace(/ss/g, <string>ss);
    result = result.replace(/mm/g, <string>mm);
    result = result.replace(/hh/g, <string>hh);

    return result;
}
  
  getPercentage(current,total) {
        return ((current * 100) / total);
    }

 doubleClickFunction(){
  	this.fsAPI.toggleFullscreen();
  }


 findEpisode(id){

		for (const {item, index} of this.episodes.map((item, index) => ({ item, index }))) {
		  if (item.id === id) {
				        return index;
				  }
		}
		return false;
  }


}
