<div
  *ngIf="state_form == 'login'"
  [@formTestAnimation]="state_form"
  class="form_content"
>
  <form
    class="account__form"
    name="form"
    (ngSubmit)="login()"
    #f="ngForm"
    novalidate
  >
    <fieldset>
      <h2 class="account__center_title" i18n>Login</h2>
      <div class="account__form_error" *ngIf="error">{{ error }}</div>
      <div class="account__form_group">
        <input
          type="email"
          [(ngModel)]="model.username"
          required
          placeholder="Email"
          #loginUsername
          name="username"
        />
      </div>

      <div class="account__form_group">
        <input
          [passwordToggle]="'right'"
          [width]="36"
          type="password"
          placeholder="Password"
          #loginPassword
          name="password"
          id="password"
          [(ngModel)]="model.password"
          i18n-placeholder
        />
      </div>
    </fieldset>
    <div class="main-area__login-button">
      <button
        type="submit"
        title="login"
        class="action action--secondary action--purple action--simple-button"
      >
        Enter
      </button>
      <div class="remind_button">
        <span [routerLink]="['/remind']" i18n>Forgot Password</span>
      </div>
      <!-- <div *ngIf="loading" style="margin: 0 auto">
        <img
          src="./assets/img/loader.svg"
          onerror="this.onerror=null; this.src='./assets/img/loader.svg'"
        />
      </div> -->
    </div>
  </form>

  <div class="twofapin-container" @fadeChange *ngIf="twofa">
    <div class="twofapin-container__text" i18n>
      We have sent verification code to your email address. Write it below.
    </div>
    <twofapin
      #codeInput
      [isCodeHidden]="false"
      [codeLength]="6"
      (codeChanged)="onCodeChanged($event)"
      (codeCompleted)="onCodeCompleted($event)"
    >
    </twofapin>
    <div class="twofapin-container__resend_button" (click)="resend2Fa()">
      <ng-container i18n>Resend code</ng-container>
      <ng-container *ngIf="twofa_seconds" i18n
        >({{ twofa_seconds }} s)</ng-container
      >
    </div>
  </div>

</div>

<div
  *ngIf="state_form == 'signup'"
  [@formTestAnimation]="state_form"
  class="form_content"
>
  <div class="show_coupon" *ngIf="!coupon" @fadeChange>
    <form
      class="account__form"
      name="form"
      (ngSubmit)="sf.form.valid && checkCoupon(sf)"
      #sf="ngForm"
      novalidate
    >
      <fieldset>
        <h2 class="account__center_title" i18n>Access code</h2>
        <div class="account__form_error" *ngIf="accept_error">
          {{ accept_error }}
        </div>
        <div
          class="account__form_error"
          *ngIf="!(coupon.valid || (coupon.pristine && !sf.submitted))"
        >
          {{ coupon.error }}
        </div>
        <div class="account__form_group">
          <input
            type="text"
            #coupon="ngModel"
            [ngClass]="{ error: !coupon.valid }"
            [(ngModel)]="signup_model.coupon"
            placeholder="Access Code"
            name="coupon"
            i18n-placeholder
          />
        </div>
      </fieldset>
      <div class="main-area__login-button">
        <button
          type="submit"
          title="login"
          class="action action--secondary action--purple action--simple-button"
          i18n
        >
          Enter
        </button>
      </div>
    </form>
  </div>
  <div class="hide_coupon" @fadeChange *ngIf="coupon">
    <form
      name="form"
      class="account__form"
      (ngSubmit)="sf.form.valid && signup(sf)"
      #sf="ngForm"
    >
    
      <fieldset>
        <h2 class="account__center_title" i18n>Register</h2>
        <div class="account__form_error " *ngIf="accept_error">
          {{ accept_error }}
        </div>
        <div class="account__form_group">
          <div
            class="account__form_error account__form_error--abs"
            [hidden]="
              first_name.valid || (first_name.pristine && !sf.submitted)
            "
          >
            {{ form_errors.first_name }}
          </div>

          <input
            type="text"
            #first_name="ngModel"
            [ngClass]="{ error: !first_name.valid }"
            [(ngModel)]="signup_model.first_name"
            placeholder="First Name"
            name="first_name"
            i18n-placeholder
          />
        </div>

        <div class="account__form_group">
          <div
            class="account__form_error account__form_error--abs"
            [hidden]="last_name.valid || (last_name.pristine && !sf.submitted)"
          >
            {{ form_errors.last_name }}
          </div>
          <input
            type="text"
            #last_name="ngModel"
            [ngClass]="{ error: !last_name.valid }"
            [(ngModel)]="signup_model.last_name"
            placeholder="Last Name"
            name="last_name"
            i18n-placeholder
          />
        </div>

        <div class="account__form_group">
          <div
            class="account__form_error account__form_error--abs"
            [hidden]="username.valid || (username.pristine && !sf.submitted)"
          >
            {{ form_errors.username }}
          </div>
          <input
            type="email"
            [(ngModel)]="signup_model.username"
            #username="ngModel"
            [ngClass]="{ error: !username.valid }"
            placeholder="Email "
            name="username"
            i18n-placeholder
          />
        </div>
        <div class="account__form_group">
          <div
            class="account__form_error account__form_error--abs"
            [hidden]="password.valid || (password.pristine && !sf.submitted)"
          >
            {{ form_errors.password }}
          </div>
          <input
            [passwordToggle]="'right'"
            [width]="36"
            type="password"
            #password="ngModel"
            [ngClass]="{ error: !password.valid }"
            [(ngModel)]="signup_model.password"
            placeholder="Password "
            name="password"
            i18n-placeholder
          />
          <password-strength-bar
            [passwordToCheck]="signup_model.password"
            [barLabel]=""
          ></password-strength-bar>
        </div>

        <div class="account__form_group" *ngIf="!hide_age">
          <div
            class="account__form_error account__form_error--abs"
            [hidden]="year.valid || (year.pristine && !sf.submitted)"
          >
            {{ form_errors.year }}
          </div>
          <select
            #year="ngModel"
            [ngClass]="{ error: !year.valid }"
            [(ngModel)]="signup_model.year"
            placeholder="Birth Year"
            name="year"
            i18n-placeholder
          >
            <option selected disabled hidden value="0" i18n>Birth Year</option>
            <option *ngFor="let age of getYearsArr()" [value]="age">
              {{ age }}
            </option>
          </select>
        </div>

        <div class="account__form_group sex_group">
          <div
            class="account__form_error account__form_error--abs"
            [hidden]="sex.valid || (sex.pristine && !sf.submitted)"
          >
            {{ form_errors.sex }}
          </div>
          <select
            #sex="ngModel"
            [ngClass]="{ error: !sex.valid }"
            [(ngModel)]="signup_model.sex"
            placeholder="Gender"
            name="sex"
            i18n-placeholder
          >
            <option selected disabled hidden value="-1" i18n>Gender</option>
            <option value="1" i18n>Male</option>
            <option value="0" i18n>Female</option>
            <option value="2" i18n>Other</option>
          </select>
        </div>
        <div class="terms">
          <div class="terms__checkbox">
            <label class="agreement_label" for="agreement">
              <input
                [(ngModel)]="signup_model.accept"
                name="accept"
                id="agreement"
                type="checkbox"
              />
              <div class="terms__checkbox-icon"></div>
            </label>
              <span
                ><ng-container i18n
                  >By checking this box, I acknowledge that I have read,
                  understand, and agree to Resilience Institute </ng-container
                ><span (click)="openModal('terms')" class="link" i18n
                  >Terms and Conditions</span
                >
                <ng-container i18n> and </ng-container
                ><span (click)="openModal('privacy')" class="link" i18n
                  >Privacy Policy</span
                ></span
              >
           
          </div>
        
        </div>
      </fieldset>
      <div class="main-area__login-button">
        <button
          type="submit"
          title="register"
          class="action action--secondary action--purple action--simple-button"
          i18n
        >
          Register
        </button>
      </div>
    </form>
  </div>

  <app-modal *ngIf="init" [blocking]="false" [modalId]="'terms'">
    <div class="help_guide">
      <div class="title_guide" i18n>Terms and Conditions</div>
      <div class="main_text_container">
        <div class="main_text" [innerHTML]="terms"></div>
        <br /><br />
      </div>
    </div>
  </app-modal>
  <app-modal *ngIf="init" [blocking]="false" [modalId]="'privacy'">
    <div class="help_guide">
      <div class="title_guide" i18n>Privacy Policy</div>
      <div class="main_text_container">
        <div class="main_text" [innerHTML]="privacy"></div>
        <br /><br />
      </div>
    </div>
  </app-modal>
</div>
