	<div class="question_group" *ngFor="let item of activeItems" [@answerChange2]="answer_direction" (@answerChange2.done)=doneAnimation($event)>
		<div *ngIf="!item.submit" class="question_group_container">
	  			<div class="question_text" [innerHTML]="item.text">
	      		</div>
	      		<div class="answers" >
					<button type="button"
						class="action--secondary action--simple-button" (click)="changeAnswer(1)" [ngClass]="{'selected':item.answer==1}" i18n>
						Strongly disagree
					</button>
					<button type="button"
						class="action--secondary action--simple-button" (click)="changeAnswer(2)" [ngClass]="{'selected':item.answer==2}" i18n>
						Disagree
					</button>
					<button type="button"
						class="action--secondary action--simple-button" (click)="changeAnswer(3)" [ngClass]="{'selected':item.answer==3}" i18n>
						Neither
					</button>
					<button type="button"
						class="action--secondary action--simple-button" (click)="changeAnswer(4)" [ngClass]="{'selected':item.answer==4}" i18n>
						Agree
					</button>
					<button type="button"
						class="action--secondary action--simple-button" (click)="changeAnswer(5)" [ngClass]="{'selected':item.answer==5}" i18n>
						Strongly agree
					</button>
					<button i18n *ngIf="showNext" (click)="goNext()" class="next action--secondary action--purple action--simple-button" >
						Next
					</button>
		</div>
	</div>	

	<div *ngIf="item.submit" class="question_group_container">
		   <!-- <mat-error *ngIf="error">{{error}}</mat-error> -->
	  				<!-- <div class="button">
		      			<button class="form-button" (click)="submitDiagnostic()" i18n="diagnostic question submit question button: diagnostic question submit question button@@diagnostic_answer_submit_button">Submit Answers<span><i class="fa fa-caret-right"></i></span></button>
		      		</div> -->
	</div>