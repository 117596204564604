<div class="page" @fadeRoute *ngIf="init">
  <div class="item_page">
    <div class="main_container">
      <div class="title" i18n>
        Journey
      </div>



      <ol class="timeline">

        <li [ngClass]="{'completed': item.done}" class='task' *ngFor="let item of journey.items; let i = index" >
          <div class="task__title">{{i+1}}. {{item.name}}</div>
          <div class="task__description">
            <ng-container *ngIf="item.done"><i class="fa fa-check-circle"></i></ng-container>
            <ng-container *ngIf="!item.done">{{item.description}}</ng-container>       
          </div>
          <div class="task__controls">
            <button *ngIf="(!journey.items[i-1]||journey.items[i-1].done)||item.done" class="box_button" (click)="openModal(i)">
              <ng-container *ngIf="!item.done" i18n>Start</ng-container>
              <ng-container *ngIf="item.done" i18n>View</ng-container>
            </button>
            <button [disabled]='(item.connection_arr&&!item.connection_arr.ended)||(journey.items[i-1]&&journey.items[i-1].done&&(journey.items[i-1].doneDays<item.delay))' *ngIf="(!journey.items[i-1]||journey.items[i-1].done)&&!item.done" (click)="markAsCompletedModal(i)"  class="box_button complete_button">
              <ng-container *ngIf='journey.items[i-1]&&journey.items[i-1].done&&(journey.items[i-1].doneDays<item.delay); else enabledButton' >{{item.delay-journey.items[i-1].doneDays}} days left</ng-container>
              <ng-template #enabledButton>
                <ng-container *ngIf="!item.connection_arr||item.connection_arr.ended" i18n>Complete task</ng-container>
                <ng-container *ngIf="item.connection_arr&&!item.connection_arr.ended" i18n>Complete task</ng-container>
              </ng-template>
            </button>
            <div  *ngIf="(journey.items[i-1]&&!journey.items[i-1].done)" class="task__help_text" i18n >Please complete the previous task</div>
          </div>
        </li>
        
      </ol>
    </div>
  </div>

  <div class="confirm_modal" @fadeChange *ngIf="confirm_modal_show">
    <div class="confirm_modal__overlay" (click)="markAsCompletedModalClose()"></div>
    <div class="confirm_modal__body">
      <div class="confirm_modal__title" i18n>
        Are you sure you want to complete this task?
      </div>
      <div class="confirm_modal__buttons">
        <div class="box_button"  (click)="markAsCompleted()" i18n>Complete</div>
        <div class="box_button" (click)="markAsCompletedModalClose()" i18n>Cancel</div>
      </div>
    </div>
  </div>

  <app-modal [blocking]='false' [modalId]="'journey_item'" [closeCallbackFunction]="modalCloseCallback">
    <div class="journey_modal">
     <div class="journey_modal__title" >{{current_task.name}}</div>
     <div class="journey_modal__body_container">
       <div class="journey_modal__body" [innerHTML]="current_task.text">
        
       </div>
       <div *ngIf="current_task.video">
          <small-video-player [show_playpause]='true' [controls]="'controls'" [preload]="'metadata'" [video]="current_task.video" ></small-video-player>
       </div>
       <br><br>
       <div class="journey_modal__buttons_container">
        <div class="box_button" (click)="buttonHandler(current_task)">
          <ng-container *ngIf='current_task.done||(current_task.connection_arr&&current_task.connection_arr.ended); else viewButtonCustomText' ><ng-container i18n *ngIf="current_task.item!=='simple'">View</ng-container><ng-container i18n *ngIf="current_task.item==='simple'">Close</ng-container></ng-container>
          <ng-template #viewButtonCustomText>{{current_task.button_text}}</ng-template>
        </div>
        <button [disabled]='(current_task.connection_arr&&!current_task.connection_arr.ended)||(prev_task&&prev_task.done&&(prev_task.doneDays<current_task.delay))' *ngIf="(!prev_task||prev_task.done)&&!current_task.done" (click)="markAsCompletedModal()"  class="box_button complete_button">
          <ng-container *ngIf='prev_task&&prev_task.done&&(prev_task.doneDays<current_task.delay); else enabledButton' i18n>{{current_task.delay-prev_task.doneDays}} days left</ng-container>
          <ng-template #enabledButton>
            <ng-container *ngIf="!current_task.connection_arr||current_task.connection_arr.ended" i18n>Complete task</ng-container>
            <ng-container *ngIf="current_task.connection_arr&&!current_task.connection_arr.ended" i18n>Complete task</ng-container>
          </ng-template>
        </button>
       </div>
       
       <br><br>
     </div>
        
    </div> 
</app-modal>

</div>
