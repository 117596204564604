import { Component, OnInit } from '@angular/core';
import { GoalkeeperService } from '../../_services/index';
import { ActivatedRoute, Router } from '@angular/router';
import {routeAnimation } from '../../_animations/index';



@Component({
  selector: 'app-goalkeeper-track',
  templateUrl: './goalkeeper-track.component.html',
  styleUrls: ['./goalkeeper-track.component.scss'],
  animations:[routeAnimation],
  host: { '[@fadeRouteNoAbs]': '' }
})
export class GoalkeeperTrackComponent implements OnInit {
  categories:any = [];
  track:any = {};
  mygoals:any = [];
  mygoals_track:any = {};
  tooltip_opened = 0;
  error = "";

  constructor(private router:Router,private goalkeeperService:GoalkeeperService) { }


  ngOnInit() {
  	  	this.goalkeeperService.getTrack().subscribe(
		track => {

			  this.categories=track.user_categories;

			  if (track.track && track.track.length !== 0)
			  	this.track=track.track;

			  this.mygoals=track.mygoals;

			  if (track.mygoal_track && track.mygoal_track.length !== 0)
			  	this.mygoals_track=track.mygoal_track;

			 
		},
		error => {
		    this.error = error ;
			console.log("error",this.error)
		})
  }

  toggleTooltip(event,id) {
	   this.tooltip_opened = (id !== this.tooltip_opened) ? id: 0
  }

  goNext() { 
  	  	this.goalkeeperService.saveTrack(this.track).subscribe(
		settings => {
		    this.goalkeeperService.saveMyGoalsTrack(this.mygoals_track).subscribe(
				settings => {
					this.router.navigate(['goalkeeper','result','done']); 
				},
				error => {
				    this.error = error ;
					console.log("error",this.error)
			})
			
		},
		error => {
		    this.error = error ;
			console.log("error",this.error)
		})
  }



}
