import { Component, OnInit } from '@angular/core';
import { GoalkeeperService } from '../../_services/index';
import { ActivatedRoute, Router } from '@angular/router';
import {routeAnimation } from '../../_animations/index';

@Component({
  selector: 'app-goalkeeper-areas',
  templateUrl: './goalkeeper-areas.component.html',
  styleUrls: ['./goalkeeper-areas.component.scss'],
  animations:[routeAnimation],
  host: { '[@fadeRouteNoAbs]': '' }
})
export class GoalkeeperAreasComponent implements OnInit {
  categories:any = {};
  areas:any = {};
  error = "";

  constructor(private router:Router, private goalkeeperService: GoalkeeperService) { }

  ngOnInit() {
  	this.categories = [];
  	this.goalkeeperService.getAreas().subscribe(
		areas => {
			  this.categories=areas.categories;
			  this.areas=areas.selected_categories;
		},
	error => {
	    this.error = error ;
		console.log("error",this.error)
	})
  }

  toggleArea(id) {
            var index = this.areas.indexOf(id);
            if (index !== -1) {
                this.areas.splice(index, 1);
            } else
                this.areas.push(id);
   }


  goNext() {
    this.goalkeeperService.saveAreas(this.areas).subscribe(
		result => {
			this.router.navigate(['goalkeeper','settings']); 
		},
	error => {
	    this.error = error ;
		console.log("error",this.error)
	})  
   }

}
