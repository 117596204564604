import { Component, ElementRef,EventEmitter, HostListener, OnInit, Input,Output, ViewEncapsulation, OnDestroy } from '@angular/core';
import {VgApiService, VgStates} from '@videogular/ngx-videogular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'vg-play-next-prev',
  templateUrl: './vg-play-next-prev.component.html',
  styleUrls: ['./vg-play-next-prev.component.scss']
})

export class VgPlayNextPrevComponent implements OnInit, OnDestroy {
    @Input() vgFor: string;

    elem: HTMLElement;
    target: any;

    subscriptions: Subscription[] = [];

    ariaValue = VgStates.VG_PAUSED;

    constructor(ref: ElementRef, public API: VgApiService) {
        this.elem = ref.nativeElement;
    }

    ngOnInit() {
        if (this.API.isPlayerReady) {
            this.onPlayerReady();
        }
        else {
            this.subscriptions.push(this.API.playerReadyEvent.subscribe(() => this.onPlayerReady()));
        }
    }

    onPlayerReady() {
        this.target = this.API.getMediaById(this.vgFor);
    }

    // @HostListener('click')
    // onClick() {
    //     this.playPause();
    // }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        // On press Enter (13) or Space (32)
        if (event.keyCode === 13 || event.keyCode === 32) {
            event.preventDefault();
            this.playPause();
        }
    }

    playPause() {
        let state = this.getState();

        switch (state) {
            case VgStates.VG_PLAYING:
                this.target.pause();
                break;

            case VgStates.VG_PAUSED:
            case VgStates.VG_ENDED:
                this.target.play();
                break;
        }
    }

    getState() {
        this.ariaValue = this.target ? this.target.state : VgStates.VG_PAUSED;
        return this.ariaValue;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    rightArrow(){
        this.target.seekTime((this.target.time.current + 5000) / 1000, false);
        
    }


    leftArrow(){
        this.target.seekTime((this.target.time.current - 5000) / 1000, false);
    }

    @HostListener('keydown', ['$event'])
    arrowAdjustVolume(event: KeyboardEvent) {
        if (this.target) {
            if (event.keyCode === 38 || event.keyCode === 39) {
                event.preventDefault();
                this.target.seekTime((this.target.time.current + 5000) / 1000, false);
            }
            else if (event.keyCode === 37 || event.keyCode === 40) {
                event.preventDefault();
                this.target.seekTime((this.target.time.current - 5000) / 1000, false);
            }
        }
    }

}