
import {catchError, map} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Globals } from '../globals';




import { ApiService } from './index';

@Injectable()
export class GoalkeeperService {
    constructor(
        private apiService: ApiService) {
    }

    getWelcome(): Observable<any> {
       return this.apiService.get("goalkeeper/welcome").pipe(
       map((response: any) => response && response.results.welcome),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error);
        }),)
    }

    getResultsAwards(): Observable<any> {
       return this.apiService.get("goalkeeper/results/awards").pipe(
       map((response: any) => response && response.results.awards),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error);
        }),)
    }

    getAreas(): Observable<any> {
       return this.apiService.get("goalkeeper/areas").pipe(
       map((response: any) => response && response.results.areas),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error);
        }),)
    }


    saveAreas(data): Observable<any> {
       return this.apiService.put("goalkeeper/areas",{data:data}).pipe(
         map((response: any) => response && response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error)
        }),)
    }

    saveFactor(factor_id:number, onoff:boolean): Observable<any> {
        return this.apiService.put("goalkeeper/areas/factor",{factor_id:factor_id,onoff:onoff}).pipe(
          map((response: any) => response && response.results.result),
        catchError((error:any) => {
             return observableThrowError(error.error.results.error)
         }),)
     }

    getSettings(): Observable<any> {
       return this.apiService.get("goalkeeper/settings").pipe(
       map((response: any) => response && response.results.settings),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error);
        }),)
    }

    saveSettings(data): Observable<any> {
       return this.apiService.put("goalkeeper/settings",{data:data}).pipe(
         map((response: any) => response && response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error)
        }),)
    }

    getTrack(): Observable<any> {
       return this.apiService.get("goalkeeper/track").pipe(
       map((response: any) => response && response.results.track),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error);
        }),)
    }

    saveTrack(data): Observable<any> {
       return this.apiService.post("goalkeeper/track",{data:data}).pipe(
         map((response: any) => response && response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error)
        }),)
    }

    saveFactorTrack(factor_id, onoff, value): Observable<any> {
        return this.apiService.post("goalkeeper/track/factor",{factor_id:factor_id,onoff:onoff,value:value}).pipe(
          map((response: any) => response && response.results.result),
        catchError((error:any) => {
             return observableThrowError(error.error.results.error)
         }),)
     }

    saveMyGoalsTrack(data): Observable<any> {
       return this.apiService.post("goalkeeper/mygoals/track",{data:data}).pipe(
         map((response: any) => response && response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error)
        }),)
    }

    getMyGoals(): Observable<any> {
       return this.apiService.get("goalkeeper/mygoals").pipe(
       map((response: any) => response && response.results.mygoals),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error);
        }),)
    }

    saveMyGoals(data,deleted): Observable<any> {
       return this.apiService.put("goalkeeper/mygoals",{mygoals:data,deleted:deleted}).pipe(
         map((response: any) => response && response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error)
        }),)
    }

    getResultStats(name,mode,average,category): Observable<any> {
       return this.apiService.get("goalkeeper/results/"+name+"/"+mode+"/"+average+"/"+category).pipe(
         map((response: any) => response && response.results.stats),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error)
        }),)
    }

}






