import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService, TopBarService,MeditationService } from '../../_services/index';
import { routeAnimation,videoAnimation } from '../../_animations/index';

@Component({
  selector: 'app-mediate-reminder',
  templateUrl: './mediate-reminder.component.html',
  styleUrls: ['./mediate-reminder.component.scss'],
  animations:[routeAnimation,videoAnimation],
  host: { '[@slideRoute]': '' }
})
export class MediateReminderComponent implements OnInit {
	id = 0;
	settings:any = {};
	error ="";
	times = ["7:30","8:00"];
	title ="";
  constructor(private meditationService: MeditationService, private topBarService: TopBarService, private modalService:ModalService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
  	this.makeTimesArr();
  	  this.route.params.subscribe(params => {
       this.id = +params['id']; //
       		 this.meditationService.getReminder(this.id).subscribe(
			      result => {
			      	  if (result.settings.length!=0)
			          	this.settings = result.settings;

			          this.title = result.title
			      },
			        error => {
			            this.error = error ;
			          console.log("error",this.error)
			        })

       })
  }

  saveOpt(){
  	 this.meditationService.saveReminder(this.id, this.settings).subscribe(
			      result => {
			          this.router.navigate(['meditate','episodes',this.id]);
			      },
			        error => {
			          this.error = error ;
			          console.log("error",this.error)
			        })
  }

  makeTimesArr(){
  	var x = 30; //minutes interval
	var times = []; // time array
	var tt = 0; // start time
	var ap = ['AM', 'PM']; // AM-PM
	//loop to increment the time and push results in array
	for (var i=0;tt<24*60; i++) {
	  var hh = Math.floor(tt/60); // getting hours of day in 0-24 format
	  var mm = (tt%60); // getting minutes of the hour in 0-55 format
	  times[i] = ((hh===12||hh===0)?'12':("0" + (hh % 12)).slice(-2)) + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh/12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
	  tt = tt + x;
	}
	this.times = times;
  }
}
