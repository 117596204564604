import { Component, OnInit } from '@angular/core';
import { UserService} from '../../_services/index';
import { Router } from "@angular/router";
import {Location} from '@angular/common';
import { MixpanelService } from '../../_services/mixpanel.service';

@Component({
  selector: 'app-weekly-tips-item',
  templateUrl: './weekly-tips-item.component.html',
  styleUrls: ['./weekly-tips-item.component.scss']
})
export class WeeklyTipsItemComponent implements OnInit {

  weekly_tip:any = {};
  init = false;

  constructor(private mixpanelService:MixpanelService, private router:Router, private userService:UserService,private _location: Location) { }

  ngOnInit() {
  	 this.userService.getWeeklyTip()
        .subscribe(weekly_tip => {
            this.weekly_tip = weekly_tip;
            this.mixpanelService.trackView('Weekly Tip','view',weekly_tip);
        },error => this.init = true);
  }

  goBack() {
    this.mixpanelService.trackButton("/",'Weekly Tip Back Button','button',this.weekly_tip);
    this.router.navigate(["/"]);
    // this._location.back();
  }

}
