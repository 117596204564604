import { Component, OnInit,ViewChildren,ViewChild,QueryList,LOCALE_ID,Inject } from '@angular/core';
import { DomSanitizer,Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../_models/index';
import { Subject } from 'rxjs'
import { PassDataService,UserService,TopBarService, PushNotificationService,DiagnosticService } from '../_services/index';
import { homeAnimation,routeAnimation } from '../_animations/index';
import { ChatbotService } from '../_services/chatbot.service';
import { MixpanelService } from '../_services/mixpanel.service';
import { BaseChartDirective } from 'ng2-charts';
import { Globals } from '../globals'

enum DayTime {
  Morning,
  Lunch,
  Afternoon,
  Evening,
  Night
}

@Component({
    moduleId: module.id,
    templateUrl: 'home.component.html',
    styleUrls:  ['home.component.scss'],
    animations:[homeAnimation,routeAnimation],
    // host: { '[@fadeRoute]': '' }
})

export class HomeComponent implements OnInit {
  @ViewChildren(BaseChartDirective) baseCharts:QueryList<BaseChartDirective>;
  @ViewChild('coachDiv') coachDiv;
  playMeditation: Subject<string> = new Subject<string>();


    user: User;
    user_name: string = "";
    animation_var = 'start';
    animation_var_content = 'start';
    selectedMenu = null;
    day_time: DayTime = DayTime.Morning;
    dayTime = DayTime;
    colors:any = [];
    stats:any = {};
    home:any = {};
    coach_sections:any = []
    coach_sections_width: number = 0;
    podcast:any = {};
    init=false;
    recommendations: any = {data:[],current:0};
    videos: any = {data:[],current:0,rows:2};
    wearable_charts: any = {};
    wearable_connections: any = {};

    constructor(public sanitizer: DomSanitizer,private diagnosticService:DiagnosticService,private meta:Meta, private mixpanelService:MixpanelService, @Inject(LOCALE_ID) private locale: string,private passDataService:PassDataService,public globals:Globals,private chat:ChatbotService, private router:Router, private userService: UserService, private topBarService:TopBarService, private pushService:PushNotificationService) { }

    playMeditationEvent() {
      this.playMeditation.next('play');
    }
    
    onResize(event) {
      // this.coach_sections_width=event.target.innerWidth
      this.coach_sections_width=this.coachDiv.nativeElement.offsetWidth;
    }

    returnUserDayTime() {
      const now = new Date();
      const hour = now.getHours();

      switch(true) {
        case hour >= 5 && hour < 12:
          return DayTime.Morning;
        case hour >= 12 && hour < 14:
          return DayTime.Lunch;
        case hour >= 14 && hour < 17:
          return DayTime.Afternoon;
        case hour >= 17 && hour < 23:
          return DayTime.Evening;
        default:
          return DayTime.Night;
      }
    }

    ngOnInit() {
        this.meta.updateTag({ content: '#b5d5d4' }, 'name=theme-color');
        this.day_time=this.returnUserDayTime();//setup current datetime
        this.mixpanelService.trackView('Home','view');
        this.pushService.subscribeToPush()
        if (this.pushService.native_token.registrationId !==''){
          this.pushService.addSubscriber(this.pushService.native_token)
              .subscribe(
                res => {
                  console.log('[App] Add subscriber request answer', res)
                },
                err => {
                  console.log('[App] Add subscriber request failed', err)
                }
              )
        }

        this.topBarService.setBgStyle("main");
    
        this.stats.colors=["#9e9e9e","#9e9e9e"];

        // this.userService.getUserConfiguration()
        // .subscribe(configuration => {
        //   this.chat.setShow(configuration.chatbot)
        // },
        // error => {

        //   });
        const chunkSize = 4;
        this.userService.homeData$.subscribe((home) => {//change later getUserHomeData only on this one
          this.home = home;
          this.user_name=home.user_name
          // this.coach_sections=[this.home.coach_tags]
          if (this.home.coach_tags){
            for (let i = 0; i < this.home.coach_tags.length; i += chunkSize) {
                const chunk = this.home.coach_tags.slice(i, i + chunkSize);
                this.coach_sections.push(chunk)
            }
            this.coach_sections=[this.coach_sections[0]]//current fix for one
            // this.coach_sections=[this.home.coach_tags]//current fix for one
          }
            
        });
        this.userService.refreshHomeDataCache();
        this.init = true;
        this.userService.getUserHomeData()
        .subscribe(home => {

            this.user_name=home.user_name
            if (home.journey){ 
              this.passDataService.set('journey',parseInt(home.journey, 10))
              this.router.navigate(['journey',parseInt(home.journey, 10)]);
              return ;
            }
            
            this.passDataService.set('journey', false)
            // this.router.navigate(['goalkeeper/track']); 
            this.recommendations.data = home.recommendations;
            
            //make video chunks
            if (home.video_series){
             let chunkCount = Math.ceil(home.video_series.series.length / this.videos.rows);
             let chunks = new Array(chunkCount);
              for(let i = 0, j = 0, k = this.videos.rows; i < chunkCount; ++i) {
                  chunks[i] = home.video_series.series.slice(j, k);
                  j = k;
                  k += this.videos.rows;
              }
              this.videos.data = chunks;
            }

            this.home = home;
            //make coach_tags in chunks
            
            if (this.home.coach_tags){
              for (let i = 0; i < this.home.coach_tags.length; i += chunkSize) {
                  const chunk = this.home.coach_tags.slice(i, i + chunkSize);
                  this.coach_sections.push(chunk)
              }
              this.coach_sections=[this.coach_sections[0]]//current fix for one
              // this.coach_sections=[this.home.coach_tags]//current fix for one
            }
              // setTimeout(()=>{
              //   this.coach_sections_width=this.coachDiv.nativeElement.offsetWidth;
              // },200)
              // setTimeout(()=>{
              //   this.coach_sections_width=this.coachDiv.nativeElement.offsetWidth;
              // },600)//safari
           

            if (home.keeper.data.length){
             this.stats = home.keeper;
            }else{
              this.stats.labels=['January', 'February', 'March', 'April', 'May', 'June', 'July'];
              this.stats.dataset=[{data: [1, 2, 0, 1, 2, 1, 2], label: '',fill: false,
                  borderColor: "#ffffff",
                  backgroundColor: "#ffffff",
              }];
            }
            this.wearable_charts=home.wearables;
            this.wearable_connections=home.wearable_connections;
            if (home.podcast)
              this.podcast = home.podcast
            else
              this.podcast = false
            // console.log(this.stats)
            this.init = true;
        },error => this.init = true);
        
        const redirect = this.passDataService.get('redirect')
        
        if (redirect){
          this.passDataService.set('redirect','', true)
          this.router.navigate([redirect]);
        }
    }



         public lineChartOptions:any = {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                 enabled: false
            },

              fill: false,
               legend: {
                display: false,
                position:"bottom",
                labels: {
                    fontColor: 'rgb(255,255,255)',
                    boxWidth:15,
                }
            },
            scales: {
                    yAxes: [{
                          gridLines: {
                          display: false ,
                          color: "#FFFFFF"
                        },
                        ticks: {
                          fontFamily: "'icomoon','Merriweather'",
                          // fontColor: "rgba(242,242,242,.5)",
                          min: 0,
                          max: 2,
                          stepSize: 1,
                          fontSize:20,
                          fontColor:['rgba(90, 220, 95, 0.9)','rgba(255, 235, 59, 0.9)','rgba(255, 17, 0, 0.9)'],
                            callback: function(value, index, values) {
                              var text = "";
                                if (value == 2)
                                    text = "\ue9e0";
                                if (value == 1)
                                    text = "\ue9f8";
                                if (value == 0)
                                    text = "\ue9e6";
                                return text;
                            }
                        }
                    }],
                    xAxes: [{
                        gridLines: {
                          display: false ,
                          color: "#FFFFFF"
                        },
                      ticks: {
                        fontFamily: "'icomoon','Merriweather'",
                        fontColor: "rgba(242,242,242,.5)",
                        fontSize:15,
                        }
                    }]
                }
          };

          public lineChartWearablesOptions:any = {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                 enabled: true,
                 mode: "index",
                  callbacks: {
                    label: function(tooltipItems, data) { 
                      // console.log(tooltipItems,data)
                      var date = new Date(tooltipItems.yLabel*1000);
                      if (date.getFullYear()<2010)
                          return tooltipItems.yLabel+" "+data['datasets'][tooltipItems.datasetIndex]['label'];
                      else
                        return date.getUTCHours()+":"+(date.getUTCMinutes()<10?'0':'') + date.getUTCMinutes();
                    }
                }
            },

              fill: false,
               legend: {
                display: false,
                position:"bottom",
                labels: {
                     color: "#FFFFFF",
                    boxWidth:15,
                }
            },
            scales: {
                    yAxes: [{
                          gridLines: {
                          display: false ,
                          color: "#FFFFFF",
                        },
                        ticks: {
                        fontFamily: "Poppins",
                        fontColor: "#FFFFFF",
                        fontSize:15,
                            callback: function(value, index, values) {
                              var date = new Date(value*1000);
                              if (date.getFullYear()<2010)
                                  return value;
                              else
                                return date.getUTCHours()+":"+(date.getUTCMinutes()<10?'0':'') + date.getUTCMinutes();
                            }
                        }
                    }],
                    xAxes: [{
                        gridLines: {
                          display: false ,
                          color: "#FFFFFF",
                        },
                      ticks: {
                        fontFamily: "Poppins",
                        fontColor: "#FFFFFF",
                        fontSize:15,
                        }
                    }]
                }
          };

          public lineChartLegend:boolean = true;
          public lineChartType:string = 'line';

  preview(name: string) {
    this.animation_var = "preview"
    this.selectedMenu = (this.selectedMenu && (name===this.selectedMenu.title)) ? null : {title:name};
  }

  endAnimOpen(state) {
        // console.log(state);
        
     if (state=="preview")
        this.animation_var_content = "preview"
    else
        this.animation_var_content = "start"
    }

  recSliderIndex(i,event,mode) {
    switch (mode) {
      case "recommendations":
        this.recommendations.current = i;
        this.mixpanelService.trackButton('/','Recommendations Slider','slider',{'position':i,'rec':this.recommendations});
        break;
      case "videos":
        this.videos.current = i;
        this.mixpanelService.trackButton('/','Videos Slider','slider',{'position':i,'vid':this.videos});
        break;
     
    }
   
  }
  
  recSliderRight(event, mode) {

    switch (mode) {
      case "recommendations":
        var count = this.recommendations.data.length-1;
        if (this.recommendations.current-1 < 0)
          this.recommendations.current=count;
        else
          this.recommendations.current--;

        break;
      case "videos":
        var count = this.videos.data.length-1;
        if (this.videos.current-1 < 0)
          this.videos.current=count;
        else
          this.videos.current--;
        break;
     
    }


    
    return false;
  }

    recSliderLeft(event, mode) {

      switch (mode) {
      case "recommendations":
        var count = this.recommendations.data.length-1;
        if (this.recommendations.current+1 > count)
          this.recommendations.current=0;
        else
          this.recommendations.current++;


        break;
      case "videos":
        var count = this.videos.data.length-1;
        if (this.videos.current+1 > count)
          this.videos.current=0;
        else
          this.videos.current++;
        break;
   
    }
    
    return false;
  }  

  showWearableChart(type,item){
            this.userService.getWearableChartData(type,item,'week')
            .subscribe(wearable => {
              this.wearable_charts[type]=wearable;
              this.wearable_charts[type]['show']='none';
              setTimeout(() => {this.wearable_charts[type]['show']=item},0)
              },
              error => {});
  }

    // events
  public chartClicked(e:any):void {
    // console.log(this.baseCharts);
  }
 
  public chartHovered(e:any):void {
    console.log(e);
  }

  public prepageWeeklyTipsText(text) {
    const max = 230;
    return text && text.length > max ? text.slice(0,max).split(' ').slice(0, -1).join(' ')+"..." : text
  }
  public prepareWebinarsRegistered(webinars){
    const webinar = webinars.find((webinar)=>{
      const _date = new Date(Date.parse(webinar.date_start.replace(/-/g, "/")+' UTC'))
      const _date_e = new Date(Date.parse(webinar.date_end.replace(/-/g, "/")+' UTC'))
      const cur_date = new Date()
      webinar['live']=_date<=cur_date
      return _date_e>=cur_date
    })
    
    if (!webinar)
      return []
    else
      return [webinar]
  }

  public getCurrentWebinarTime(webinars){
    const _web = this.prepareWebinarsRegistered(webinars)
    if (_web.length>0)
      return _web[0]
    
    return false;
  }

  public prepareWebinarTimeDate(date) {
    let _date = new Date(Date.parse(date.replace(/-/g, "/")+' UTC'))
    // console.log('dddd',date,_date)
    // let loc = "en-US"
    // let loc = undefined
    let loc = this.locale
    let options:any = { month: 'long', day: 'numeric' };
    let formatted = _date.toLocaleDateString(loc, options)
    return formatted
  }

  public prepareWebinarTimeDateFull(date) {
    let _date = new Date(Date.parse(date.replace(/-/g, "/")+' UTC'))
    // console.log('dddd',date,_date)
    // let loc = "en-US"
    // let loc = undefined
    // this.locale = "fr"
    let loc = this.locale
    let options:any = { weekday:'long',month: 'short', day: 'numeric' };
    let formatted = _date.toLocaleDateString(loc, options)
    if (this.locale=="en-US"){
      formatted = formatted.replace(/,/g, "")+'th'+" at "+ _date.getHours()+'h'
    if (_date.getMinutes()!=0)
      formatted = formatted+" "+_date.getMinutes()+"m"
    }else{
      options = { month: 'long', day: 'numeric', hour:'numeric' };
      formatted = _date.toLocaleDateString(loc, options)
    }
    return formatted
  }
  
  webinarLinkClick(link, item_id, info):void {
    if (this.globals.platform=="ios")
      window['cordova'].InAppBrowser.open(link, '_blank', 'location=no');
    else
      window.open(link, "_blank");
    this.mixpanelService.trackButton(link,'Webinar Link',info,{'webinar_id':item_id});
    // reg activity, create new api call for webinar click
    this.userService.openSubscriptionWebinar(item_id,info).subscribe(configuration => {
      },
      error => {

        });;
  }

  podcastClick(link):void {
    if (this.globals.platform=="ios")
      window['cordova'].InAppBrowser.open(link, '_blank', 'location=no');
    else
      window.open(link, "_blank");
      this.mixpanelService.trackButton(link,'Home Podcast Button Default','button',{})
    }

  weeklyTipClick():void {
    this.router.navigate(['/weekly_tips/item'])
    this.mixpanelService.trackButton('/weekly_tips/item','Weekly Tip Link','button');
  }


  tipOfTheDayClick(video:{}):void {
    this.mixpanelService.trackButton('','Tip of the day Video','video',video);
  }

  getAvailableDiagnostic(){
    this.mixpanelService.trackButton('','Home Available Diagnostic','button',this.home.diagnostic_available);
    if (this.home.diagnostic_available?.id){
      this.router.navigate(['/diagnostics/item/',this.home.diagnostic_available?.id])
      return;
    }
    // console.log(this.home.diagnostic_available)
    this.diagnosticService.createDiagnostic(this.home.diagnostic_available?.c,this.home.diagnostic_available?.dc).subscribe(
  		result => {
          this.mixpanelService.trackButton(['diagnostics','item',result].join('/'),'Home Page Started new Available Diagnostic','button', {'diagnostic_id':result});
  			  this.router.navigate(['diagnostics','item',result]);
  		},
        error => {
        	console.log("error", error)
        })
  }

  trackRouteHelperClick(url:any, name:string,type:string, args:{},queryParams:{}={}):void {
    if (typeof(url) === 'string'){
      this.router.navigate([url],{ queryParams: queryParams})
    }
    else{
      this.router.navigate(url,{ queryParams: queryParams})
      url=url.join('/')
    }
    
    this.mixpanelService.trackButton(url, name, type, args);
  }

 

}