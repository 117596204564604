<section class="page videos">
  <div class="main_container blurry">
    <div class="greetings">
      <div class="greetings__phrase" i18n>Videos</div>
      <div class="greetings__name" i18n>
        Practical techniques for a flexible mind
      </div>
	  <div *ngIf="error" class="rerror">{{error}}</div>
    </div>

    <div class="grid" *ngIf="series.length">
      <section class="box featured">
        <h2 i18n>Featured</h2>
        <div class="scroll_list__viewport">
          <div class="scroll_list scroll_list--awesome">
            <div class="scroll_list__item" (click)="openVideoSeries(['/video/watch',sery.id],sery,1)" *ngFor="let sery of fseries">
              <div class="scroll_list__item-inner">
                <div class="scroll_list__bg">
                  <img [src]="sery.image" />
                </div>
                <div class="scroll_list__play">
                  <span class="material-symbols-rounded"> play_arrow </span>
                </div>
                <div class="scroll_list__titles">
                  <div class="scroll_list__title">{{ sery.title }}</div>
                  <div class="scroll_list__subtitle">
                    {{ sery.count }} <ng-container i18n>videos</ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="grid" *ngIf="series.length">
      <section class="box all-series">
        <h2 i18n>All series</h2>
			<div class="standard_list">
				<div class="scroll_list__item" *ngFor="let sery of series" (click)="openVideoSeries(['/video/watch',sery.id],sery,0)">
					<div class="scroll_list__item-inner">
					  <div class="scroll_list__bg">
						<img [src]="sery.image" />
					  </div>
					  <div class="scroll_list__play">
						<span class="material-symbols-rounded"> play_arrow </span>
					  </div>
					  <div class="scroll_list__titles">
						<div class="scroll_list__title">{{ sery.title }}</div>
						<div class="scroll_list__subtitle">
						  {{ sery.count }} <ng-container i18n>videos</ng-container>
						</div>
					  </div>
					</div>
				  </div>
			</div>
      </section>
    </div>
  </div>
</section>
<!-- 
<div class="open_resources" (click)="openModal('resources')"></div>
<div
      class="webinars_recordings"
      *ngIf="webinars_recordings.length"
      (click)="openModal('recordings')"
    ></div>

	<app-modal [blocking]="false" [modalId]="'resources'">
		<div class="standard_modal">
		  <div class="title_standard_modal" i18n>Resources</div>
		  <div class="">
			<div class="resources">
			  <div *ngFor="let resource of resources; let i = index">
				<a target="_blank" [href]="resource.url">
				  <div class="resource">
					<div class="resource_container">
					  <div class="count">{{ i + 1 }}.</div>
					  <div class="text_resource">{{ resource.name }}</div>
					</div>
					<span class="go_to_resource">
					  <i class="fa fa-chevron-right" aria-hidden="true"></i
					></span>
				  </div>
				</a>
			  </div>
			</div>
			<br /><br />
		  </div>
		</div>
	  </app-modal>
	
	  <app-modal [blocking]="false" [modalId]="'recordings'">
		<div class="standard_modal">
		  <div class="title_standard_modal" i18n>Recordings</div>
		  <div class="">
			<div class="resources">
			  <div
				(click)="
				  mixpanelService.trackButton(
					record.recording_url,
					'Video Webinar Recordings Webinar Click',
					'button',
					{}
				  )
				"
				*ngFor="let record of webinars_recordings; let i = index"
			  >
				<a target="_blank" [href]="record.recording_url">
				  <div class="resource">
					<div class="resource_container">
					  <div class="count"></div>
					  <div class="text_resource">
						{{ record.title }} [{{
						  prepareWebinarTimeDateFull(record.date_start)
						}}]
					  </div>
					</div>
					<span class="go_to_resource">
					  <i class="fa fa-chevron-right" aria-hidden="true"></i
					></span>
				  </div>
				</a>
			  </div>
			</div>
			<br /><br />
		  </div>
		</div>
	  </app-modal> -->
