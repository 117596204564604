<div class="icon prev"  (click)="leftArrow()" >
	<i class="fa fa-backward"></i>
</div>
<div class="icon play" (click)="playPause()">
	<div class="play_container">
	<i [class.fa-pause]="getState() === 'playing'"
             [class.fa-play]="getState() === 'paused' || getState() === 'ended'" class="fa"></i>
         </div>
</div>
<div (click)="rightArrow()" class="icon next">
	<i class="fa fa-forward"></i>
</div>