// import the required animation functions from the angular animations module
import { trigger, state, animate, transition, style, query, animateChild, group } from '@angular/animations';
 
export const onemcoachAnimation =
    // trigger name for attaching this animation to an element using the [@triggerName] syntax
    [
      trigger('fadeChangeMaxHeight', [
          transition(':enter', [
            style({ opacity: 0,'max-width':0,'max-height':0,'overflow':'hidden'}),
            animate('2000ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1,'max-width':'3000px','max-height':'3000px' }))
          ]),
          transition(':leave', [
            style({ opacity: 1,'max-width':'3000px','max-height':'3000px' }),
            animate('2000ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 0,'max-width':0,'max-height':0,'overflow':'hidden'  }))
          ]),
        ]),
      ]







