<div class="container">
	<div class="title">
		<span i18n>Progress</span>
		<br>
		<br>
		<div @fadeChange *ngIf='finished' class="title_small" i18n>
			Congratulations, you finished.
		</div>
		<div @fadeChange *ngIf='!finished' class="title_small" i18n>
			Sorry you didn't finish today.
		</div>
		<div class="title_small" i18n>
			You have now meditated for:
		</div>
		
	</div>
	
	<div class="middle_container">

		<br>
		<div class="minutes_circle">
			<div @fadeChange class="minutes_circle_value">
				{{minutes}}
				<div class="small_text" i18n>minutes</div>
			</div>
			<div class="minutes_add">
				<div class='wave -one'></div>
	  			<div class='wave -two'></div>
	  			<div class='wave -three'></div>
	  		</div>
		</div>
		<br>
		<br>
		<div class="total_stats_container" @fadeChange>
			<div class="rows" >
				<div class="row_stats">
					<div class="icon">
						<i class="fa fa-fire"></i>
					</div>
					<div class="stats">
						<div class="stats_title" i18n>Current streak</div>
						<div class="amount">{{streak.streak_days}}</div>
						<div class="small_description" i18n>Days in a row, started on {{streak.date_formatted}}</div>
					</div>
				</div>
				<div class="row_stats">
					<div class="icon">
						<i class="fa fa-bar-chart"></i>
					</div>
					<div class="stats">
						<div class="stats_title" i18n>Total meditations</div>
						<div class="amount">{{sessions.total}}</div>
						<div class="small_description" i18n>You started meditating on {{sessions.date_formatted}}</div>
					</div>
				</div>
			</div>
		</div>
        <br>
		<br>
		<br>
                        
	</div>

	<div class="bottom_container" @fadeChange >
		 <button class="blocks_button" (click)="goBack()" i18n>Done</button>
	</div>
	<br><br>
</div>