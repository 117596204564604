// import the required animation functions from the angular animations module
import { trigger, state, animate, transition, style } from '@angular/animations';
 
export const testAnimation =
    // trigger name for attaching this animation to an element using the [@triggerName] syntax
    trigger('someCoolAnimation', [
    state('fadeIn', style({ opacity: 1 })),
    state('fadeOut', style({ opacity: 0 })),
      transition('fadeOut => fadeIn', [
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 }))
      ]),
      transition('fadeIn => fadeOut', [
        animate(1000, style({ opacity: 0 })),
      ])
    ])