<div class="container">
	<div class="title" i18n>
		Reminder
		<div class="title_small" >
			({{title}})
	</div>
	</div>
	
	<div class="middle_container">
		<br>
		<br>
		<br>
		<div class="title_small" i18n>
			Send me notifications by
		</div>
		<br>
		<div class="checkbox_opt" style="margin-right: 20px;">
			<input class="rcheckbox" [(ngModel)]="settings.notify_email" name="accept" id="notify_email" type="checkbox">
			<label class="" for="notify_email">
			</label>
			<span>Email</span>
		</div>
		<div class="checkbox_opt">
			<input class="rcheckbox" [(ngModel)]="settings.notify_push" name="accept" id="notify_push" type="checkbox">
			<label class="" for="notify_push">
			</label>
			<span>Push</span>
		</div>
		<br>
		<br>
		<br>
		<div class="title_small" i18n>
			Every day at
		</div>
            <div class="select_option">
                  <select style="font-size: 16px;" [(ngModel)]="settings.notify_time">
                        <option value="{{option}}" *ngFor="let option of times" >
                             {{option}}
                        </option> 
                  </select>
                  <div class="value_option" *ngIf="settings.notify_time">{{settings.notify_time}}</div>
                  <div class="select_arrow">
                        <i class="fa fa-chevron-down"></i>
                  </div>
            </div>
            <br>
			<br>
			<br>
                        
	</div>

	<div class="bottom_container">
		 <button class="blocks_button" (click)="saveOpt()" i18n>Save</button>
	</div>
	<br><br>

</div>