import { Component, OnInit } from '@angular/core';

import {routeAnimation } from '../_animations/index';
import { DiagnosticService,TopBarService } from '../_services/index';

@Component({
    moduleId: module.id,
    templateUrl: 'diagnostic.component.html',
    styleUrls:  ['diagnostic.component.scss'],
    animations:[routeAnimation],
    // host: { '[@fadeRoute]': '' }
})

export class DiagnosticComponent implements OnInit {

    

    constructor( private topBarService:TopBarService, private diagnosticService: DiagnosticService) { }

    ngOnInit() {
		this.topBarService.setBgStyle("main");
    }


}