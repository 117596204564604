import { Component, OnInit, Input,HostBinding } from '@angular/core';

import { homeAnimation } from '../_animations/index';


@Component({
    selector: "home-preview",
    templateUrl: 'preview.component.html',
    styleUrls:  ['preview.component.scss'],
    animations:[homeAnimation],
})

export class HomePreviewComponent implements OnInit {
  
    @HostBinding('@previewAnimation')
    public count: number = 0;

    public activeItems: any[] = [];
    
    @Input('item')
    set item(itm: any) {
    this.activeItems = [];
    if (itm && this.activeItems[0] != itm) {
      this.activeItems.push(itm);
      this.count++;
    }
  }

    constructor() { }

    ngOnInit() {
    
    }

}