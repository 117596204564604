
import {catchError} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient,HttpHeaders,HttpResponse  } from '@angular/common/http';
import { Router } from '@angular/router';
import { Globals } from '../globals';




import { AuthenticationService } from './index';
import { User } from '../_models/index';

@Injectable()
export class ApiService {
    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private http: HttpClient,
        private authenticationService: AuthenticationService,
        private router: Router,
        private globals:Globals) {
    }

    download(name: string,type): Observable<any> {
        // add authorization header with jwt token
        

        // get users from api
        return this.http.get(this.globals.api_server+name,
            { 
             headers: new HttpHeaders({ 
                                 'Authorization': 'Bearer ' + this.authenticationService.token , 
                                  'Content-Type': 'application/'+type,'locale': this.locale}) ,
             responseType: 'blob' }
            ).pipe(catchError((error:any) => {

            if (error.status==401)
                {
                    this.authenticationService.logout()
                    this.router.navigate(['/login']);
                    return observableThrowError("Unauthorized");
                }
            return observableThrowError(error);
        }))
    }

    get(name: string): Observable<any> {
        return this.http.get(this.globals.api_server+name,
            {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authenticationService.token,'locale': this.locale,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
             })}
            ).pipe(catchError((error:any) => {

            if (error.status==401)
                {
                    this.authenticationService.logout()
                    this.router.navigate(['/login']);
                    return observableThrowError("Unauthorized");
                }
            return observableThrowError(error);
        }))
    }

    delete(name: string): Observable<any> {

        return this.http.delete(this.globals.api_server+name,
            {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authenticationService.token,'locale': this.locale })}
            ).pipe(catchError((error:any) => {
            if (error.status==401)
                {
                    this.authenticationService.logout()
                    this.router.navigate(['/login']);
                    return observableThrowError("Unauthorized");
                }
            return observableThrowError(error);    
        }))
    }

    post(name: string, data: any): Observable<any>  {
        return this.http.post(this.globals.api_server+name,JSON.stringify(data),
            {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authenticationService.token,'locale': this.locale })}
            ).pipe(catchError((error:any) => {
            if (error.status==401)
                {
                    this.authenticationService.logout()
                    this.router.navigate(['/login']);
                    return observableThrowError("Unauthorized");
                }
            return observableThrowError(error);    
        }))
    }

    put(name: string, data: any): Observable<any>  {
        return this.http.put(this.globals.api_server+name,JSON.stringify(data),
            {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authenticationService.token,'locale': this.locale })}
            ).pipe(catchError((error:any) => {
            if (error.status==401)
                {
                    this.authenticationService.logout()
                    this.router.navigate(['/login']);
                    return observableThrowError("Unauthorized");
                }
            return observableThrowError(error);    
        }))
    }
}