<section class="page account">
  <div class="main_container blurry">
    <div class="top_control">
      <div class="top_control__back_button" (click)="goBackHelper.goBack()">
        <span class="material-symbols-rounded"> arrow_back_ios_new </span>
        <span i18n>Back</span>
      </div>
      <div class="top_control__options">
        <div class="top_control__option"></div>
      </div>
    </div>
    <form
      class="account__form"
      name="form"
      (ngSubmit)="f.form.valid && saveUserInfo(f)"
      #f="ngForm"
    >
      <fieldset>
        <legend class='main_title' i18n>Profile Information</legend>
        <div class="account__form_group">
          <label for="email" i18n>Email</label>
          <input
            disabled
            placeholder="Email"
            name="email"
            id="email"
            type="email"
            [(ngModel)]="user_model.login"
            i18n-placeholder
          />
        </div>

        <div class="account__form_group">
          <label for="new_password" 
            ><ng-container i18n>Password</ng-container>
            <span
              class="account__form_error"
              *ngIf="form_errors.new_password"
              >{{ form_errors.new_password }}</span
            ></label
          >
          <input
            [passwordToggle]="'right'"
            [width]="36"
            type="password"
            placeholder="New Password"
            name="new_password"
            id="new_password"
            [(ngModel)]="user_model.password"
            #sign_up_password="ngModel"
            i18n-placeholder
          />
        </div>
        <hr/>
        <div class="account__form_group">
          <label for="first_name" 
            ><ng-container i18n>First Name</ng-container>
            <span class="account__form_error" *ngIf="form_errors.first_name">{{
              form_errors.first_name
            }}</span></label
          >
          <input
            placeholder="First Name"
            name="first_name"
            id="first_name"
            type="text"
            [(ngModel)]="user_model.first_name"
            #sign_up_first_name="ngModel"
            i18n-placeholder
          />
        </div>

        <div class="account__form_group">
          <label for="last_name"
            ><ng-container i18n>Last Name</ng-container>
            <span class="account__form_error" *ngIf="form_errors.last_name">{{
              form_errors.last_name
            }}</span></label
          >
          <input
            placeholder="Last Name"
            name="last_name"
            id="last_name"
            type="text"
            [(ngModel)]="user_model.last_name"
            #sign_up_last_name="ngModel"
            i18n-placeholder
          />
        </div>

        <div class="account__form_group">
          <label for="sex" 
            ><ng-container i18n>Gender</ng-container><span class="account__form_error" *ngIf="form_errors.sex">{{
              form_errors.sex
            }}</span></label
          >
          <select
            title="gender"
            placeholder="Gender"
            name="sex"
            id="sex"
            [(ngModel)]="user_model.sex"
            i18n-placeholder
          >
            <option value="1" i18n>Male</option>
            <option value="0" i18n>Female</option>
            <option value="2" i18n>Other</option>
          </select>
        </div>

        <hr/>

        <div class="account__form_group">
          <label for="language" 
            ><ng-container i18n>Language</ng-container><span
              class="account__form_error"
              *ngIf="form_errors.language"
              >{{ form_errors.language }}</span
            ></label
          >
          <select
            title="language"
            placeholder="Language"
            name="language"
            id="language"
            [(ngModel)]="user_model.language"
            i18n-placeholder
          >
            <option *ngFor="let language of languages" [value]="language.id">
              {{ language.name }}
            </option>
          </select>
        </div>

        <div class="account__form_group">
          <label for="timezone"
            >Timezone<span
              class="account__form_error"
              *ngIf="form_errors.timezone"
              >{{ form_errors.timezone }}</span
            ></label
          >
          <select
            title="timezone"
            placeholder="Timezone"
            name="timezone"
            [(ngModel)]="user_model.timezone"
            i18n-placeholder
          >
            <option *ngFor="let timezone of timezones" [value]="timezone.id">
              GMT{{ timezone.id }}
            </option>
          </select>
        </div>
      </fieldset>

      <div class="account__button">
        <button [disabled]="loading" type="submit"
          title="save"
          class="action action--secondary action--simple-button"
          i18n>
          Save
        </button>
      </div>

      <hr class="full_width">

      <fieldset>
        <legend i18n>2-Factor authentication</legend>
        <div class="account__button">
          <button [disabled]="loading" (click)="toggle2Fa()" type="button"
            title="save"
            class="action action--secondary action--simple-button"
            [ngClass]="{
              connect_button: !this.user_model.tfa,
              delete_button: this.user_model.tfa
            }">
            <ng-container *ngIf="this.user_model.tfa" i18n>Disable</ng-container>
            <ng-container *ngIf="!this.user_model.tfa" i18n>Enable</ng-container>
          </button>
        </div>
      </fieldset>
      <hr class="full_width">
      <fieldset>
        <legend i18n>Add new access code</legend>
        <div class="account__form_group">
          <input
            type="text"
            [(ngModel)]="model.access_code"
            placeholder="Access Code"
            name="access_code"
            i18n-placeholder
          />
        </div>
        <div class="account__button">
          <button [disabled]="loading" type="button" (click)="addAccessCode()"
            title="save"
            class="action action--secondary action--simple-button" i18n>
            Add
          </button>
        </div>
      </fieldset>
      <hr class="full_width">
      <fieldset>
        <legend i18n>User Logout</legend>
        <div class="account__button">
          <button [disabled]="loading" type="button" (click)="logout()"
            title="delete account"
            class="action action--secondary action--simple-button logout_account" i18n>
            Logout
          </button>
        </div>
      </fieldset>
      <hr class="full_width">
      <fieldset>
        <div class="account__button">
          <button [disabled]="loading" type="button" (click)="openModal('delete')"
            title="delete account"
            class="action action--secondary action--simple-button delete_account" i18n>
            Delete my account
          </button>
        </div>
      </fieldset>
    </form>
    
  </div>
</section>

<app-modal [blocking]="false" [modalId]="'delete'" class="myacc_modal">
  <div class="modal_standard ">
    <div class="modal_standard_title" i18n>Delete Account</div>
    <div class="main_text_container">
      <div class="main_text">
        <div class="small-warning-title" i18n>
          Please note that this action will permanently delete your Resilience
          App user account and data
        </div>
        <div class="account__form">
          <fieldset>
            <div class="account__form_group">
              <input
              type="text"
              [(ngModel)]="model.reason"
              placeholder="what's the reason?"
              name="delete_account_matter"
              i18n-placeholder
            />
            </div>
          </fieldset>
          <br />
          <div class="account__button">
            <button [disabled]="loading" type="button" (click)="deleteAccount()"
              title="delete account"
              class="action action--secondary action--simple-button delete_account" i18n>
              Delete
            </button>
          </div>
        </div>
      </div>
      <br /><br />
    </div>
  </div>
</app-modal>

