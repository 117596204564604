<div class="habit-tracker-item">
    <div class="">
        <h1>Challenge</h1>
        <hr />
        <!-- <div class="input-group">
            <input type="text" [(ngModel)]="goal" placeholder="Enter your goal here" />
            <input type="text" [(ngModel)]="reward" placeholder="Enter your Reward here" />
            <br />
            <input type="date" [(ngModel)]="startDate" placeholder="date start" />
            <input type="number" [(ngModel)]="days" min="1" placeholder="Number of days" (input)="generateDays()" />
        </div> -->
        <br /><br />
        <div class="my-goal">My Goal: <span>{{habbit.name}} {{ goalFor }}</span></div>
        <div class="day-container">
            <div *ngFor="let day of daysArray" [ngClass]="{'animate': day.animate, 'day': true,'checked': day.checked, 'highlight': isHighlightDay(day.date), 'missed': isMissedDay(day.date)&&!isHighlightDay(day.date) }" (click)="markCompleted(day)">
                {{ day.dayNum }}
                <svg *ngIf="day.checked" [innerHTML]="getSafeSVG(day.crossSvg)"></svg>
            </div>
        </div>
        <div class="my-goal">My Reward: <span>{{ habbit.reward }}</span></div>
    </div>
    
</div>