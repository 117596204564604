<div
        class="video_animation_pool"
        style="white-space: nowrap; overflow: hidden; width: 100%"
      >
        <div #videoTarget class="video">
          <div class="video_container">
            <vg-player
              (dblclick)="doubleClickFunction()"
              (onPlayerReady)="onPlayerReady($event)"
            >
              <vg-overlay-play></vg-overlay-play>
              <vg-buffering></vg-buffering>

              <vg-scrub-bar *ngIf="fullscreen">
                <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
              </vg-scrub-bar>

              <vg-controls
                [vgAutohide]="true"
                [ngClass]="{ 'vg-controls': !fullscreen }"
              >
                <!--  <vg-time-display *ngIf="fullscreen" vgProperty="current" vgFormat="mm:ss"></vg-time-display> -->
                <vg-volume-vertical
                  style="pointer-events: initial"
                ></vg-volume-vertical>

                <div
                  *ngIf="fullscreen">
                  <vg-play-next-prev></vg-play-next-prev>
                </div>
              </vg-controls>

              <video
                [vgMedia]="media"
                [poster]="poster"
                style="object-fit: inherit"
                #media
                id="singleVideo"
                preload="auto"
              >
                <source [src]="url" type="video/mp4" />
                <!-- <source [src]="'http://static.videogular.com/assets/videos/videogular.mp4'" type="video/mp4">   -->
              </video>
            </vg-player>
          </div>

          <div class="control_bar">
            <app-video-watch-seeker
              [buffer]="buffered"
              [percent]="percent"
              [api]="api"
            ></app-video-watch-seeker>
            <!-- 	<div class="seek" role="slider">
                        <div class="buffer" style="width: 85%;"></div>
                        <div class="watched" style="width: 75%;"><i class="handle"></i></div>
                    </div> -->
            <div class="timestamps">
              <div class="current_time">{{ current }}</div>
              <div class="full_time">{{ duration }}</div>
            </div>
          </div>
        </div>
      </div>