import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-meditate',
  templateUrl: './meditate.component.html',
  styleUrls: ['./meditate.component.scss']
})
export class MeditateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
