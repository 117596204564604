import { Component, OnInit } from '@angular/core';
import { PassDataService } from '../_services/index';
import { ActivatedRoute,Router } from '@angular/router';

@Component({
  selector: 'app-start-link',
  templateUrl: './start-link.component.html',
  styleUrls: ['./start-link.component.scss']
})
export class StartLinkComponent implements OnInit {
  slug: string;

  constructor(private passDataService: PassDataService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
  	this.route.params.subscribe(params => {
       this.passDataService.set("coupon",params['slug'])
       this.router.navigate(['/login']);
    });

  }

}
