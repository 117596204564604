<div class="keeper_container">
	<div class="title_small" i18n>
		Which areas would you like to focus on?
	</div>
	<div class="middle_container">
			<div class="areas_setup">
				<div class="area" *ngFor="let category of categories"  [ngClass]="{'active':areas.indexOf(category.id) > -1}" (click)="toggleArea(category.id)"><i  class="r-icon-smileFace"></i> <span>{{category.name}}</span></div>

					<div class="bottom_container">
						<div class="next_button"><i class="fa fa-arrow-right" (click)="goNext()"  aria-hidden="true"></i></div>
					</div>
					<br><br>
			</div>
	</div>

</div>