import { Component, OnInit, ViewChild, ElementRef,Input, Output,EventEmitter } from '@angular/core';
import { VgApiService, VgFullscreenApiService } from '@videogular/ngx-videogular/core';
import { Observable, Subscription } from 'rxjs'

@Component({
  selector: 'video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {

  duration = "00:00";
  current = "00:00";
  percent = 0;
  buffered = 0;
  fullscreen = false;
  api: VgApiService;
  fsAPI: VgFullscreenApiService;
  nativeFs: boolean = true;
  pause = 0;
  journey = 0;
  old_tag_store: string;
  @ViewChild('videoTarget') videoPlayerDivRef: ElementRef;
  private _playEvent: Subscription;
  @Input() playEvent: Observable<number>;
  @Input('noeq') noeq: boolean = false;
  @Input('url') url: string;
  @Input('poster') poster: string;
  @Output('progress') progressEvent = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
    if (!this.poster)
      this.poster='.\\assets\\img\\video_placeholder.jpg';
    // this._playEvent = this.playEvent.subscribe((play) => this.clickPlayPause(play));
  }

  addProgress(value: number) {
    this.progressEvent.emit(value);
  }

  ngOnDestroy() {
    // this._playEvent.unsubscribe();
  }

  onPlayerReady(api: VgApiService) {
    this.api = api;
    this.fsAPI = this.api.fsAPI;
    this.nativeFs = this.fsAPI.nativeFullscreen;

    this.fsAPI.onChangeFullscreen.subscribe(
      event => {
        this.fullscreen = event;
      }
    );

    this.api.getDefaultMedia().subscriptions.canPlay.subscribe(
      () => {
        this.api.play();
        this.duration = this.transformTime(this.api.getDefaultMedia().time.total, "mm:ss")
      }
    );



    this.api.getDefaultMedia().subscriptions.timeUpdate.subscribe(
      () => {
        this.current = this.transformTime(this.api.getDefaultMedia().time.current, "mm:ss");
        this.percent = this.getPercentage(this.api.getDefaultMedia().time.current, this.api.getDefaultMedia().time.total);

      }
    );


    this.api.getDefaultMedia().subscriptions.progress.subscribe(
      () => {
        this.addProgress(this.getPercentage(this.api.getDefaultMedia().buffer.end, this.api.getDefaultMedia().time.total));
        this.buffered = this.getPercentage(this.api.getDefaultMedia().buffer.end, this.api.getDefaultMedia().time.total);
      }
    );

    this.api.getDefaultMedia().subscriptions.pause.subscribe(
      () => {
        this.pause = 1;
        console.log("pause");
      }
    );

    this.api.getDefaultMedia().subscriptions.play.subscribe(
      () => {
        this.pause = 0;
        console.log("play");
      }
    );



  }

  transformTime(value: number, format: string): string {
    let date = new Date(value);
    let result = format;
    let ss: string | number = date.getUTCSeconds();
    let mm: string | number = date.getUTCMinutes();
    let hh: string | number = date.getUTCHours();

    if (ss < 10) {
      ss = '0' + ss;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    if (hh < 10) {
      hh = '0' + hh;
    }

    result = result.replace(/ss/g, <string>ss);
    result = result.replace(/mm/g, <string>mm);
    result = result.replace(/hh/g, <string>hh);

    return result;
  }

  getPercentage(current, total) {
    return ((current * 100) / total);
  }

  doubleClickFunction() {
    this.fsAPI.toggleFullscreen();
  }

}
