import { Component, OnInit } from '@angular/core';
import { TopBarService } from '../_services/index';

import {routeAnimation } from '../_animations/index';

@Component({
  moduleId: module.id,
  selector: 'app-goalkeeper',
  templateUrl: './goalkeeper.component.html',
  styleUrls: ['./goalkeeper.component.scss'],
  animations:[routeAnimation],
  // host: { '[@fadeRoute]': '' }
})
export class GoalkeeperComponent implements OnInit {

  constructor(private topBarService:TopBarService) { }

  ngOnInit() {
  	// this.topBarService.setBgStyle("goalkeeper");
  }

}
