import { Component, OnInit, NgZone } from "@angular/core";
import {
  PassDataService,
  GoalkeeperService,
  UserService,
} from "../../_services/index";
import { ChatbotService } from "../../_services/chatbot.service";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { routeAnimation } from "../../_animations/index";
import { Globals } from '../../globals'
import { MixpanelService } from '../../_services/mixpanel.service';

@Component({
  selector: "app-goalkeeper-dashboard",
  templateUrl: "./goalkeeper-dashboard.component.html",
  styleUrls: ["./goalkeeper-dashboard.component.scss"],
  animations: [routeAnimation],
  // host: { "[@fadeRouteNoAbs]": "" },
})
export class GoalkeeperDashboardComponent implements OnInit {
  welcome = 0;
  init = 0;
  video: any;
  video_poster: any;
  error = "";
  loading = false;
  reminder_popup = false;
  reminder_settings: any = {'notify_weeks':'every'};
  edit_mode = 0;
  wearable: any = [];
  wearable_charts: any = {};
  areas: any = {};
  selected_factors = [];
  opened_categories = [];
  reminder_times = [];
  prepared_selected_factors: any = {};
  prepared_checked_factors: any = {};
  checked_factors = {};

  journey = 0;
  icon_map = {
    'sleep':'sleep',
    'exercise':'fitnes',
    'contemplation':'contemplation',
    'connection':'connection',
    'positivity':'positivity',
    'nutrition':'nutrition',
    'flow':'flow',
  }

  constructor(
    private mixpanelService:MixpanelService,
    private passDataService: PassDataService,
    private chat: ChatbotService,
    private userService: UserService,
    private zone: NgZone,
    private sanitizer: DomSanitizer,
    private goalkeeperService: GoalkeeperService,
    private route: ActivatedRoute,
    private router: Router,
    public globals:Globals
  ) {}

  youTubeGetID(url) {
    var ID = "";
    url = url
      .replace(/(>|<)/gi, "")
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      ID = url[2].split(/[^0-9a-z_\-]/i);
      ID = ID[0];
    } else {
      ID = url;
    }
    return ID;
  }

  makeTimesArr() {
    var x = 30; //minutes interval
    var times = []; // time array
    var tt = 0; // start time
    var ap = ['AM', 'PM']; // AM-PM
    //loop to increment the time and push results in array
   
    for (var i = 0; tt < 22 * 60; i++) {
      var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
      var mm = (tt % 60); // getting minutes of the hour in 0-55 format
      if (i>14)
        times.push(((hh === 12 || hh === 0) ? '12' : ("0" + (hh % 12)).slice(-2)) + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh / 12)]);
      tt = tt + x;
    }
    this.reminder_times = times;
  }

  getVideoUrl(code) {
    if (!code) return false;
    return code;
    // code = this.youTubeGetID(code);
    // return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + code+"?rel=0");
  }

  reminderSettingsChange(event){
    this.mixpanelService.trackButton('','Goalkeeper Dashboard Reminder Settings Change','button',{});
    this.goalkeeperService.saveSettings(this.reminder_settings).subscribe(
      settings => {
        
      },
      error => {
          this.error = error ;
      })
  }

  selectNotification(event, name){
    if (event)
      event.stopPropagation();
    this.reminder_settings[name]^=1;
    this.reminderSettingsChange(false);
  }

  toggleEditMode(edit=0) {
    this.edit_mode^=1;
  }

  reminderPopupToggle(){
    this.reminder_popup=this.reminder_popup?false:true;
  }

  reminderPopupGlobal(e){
    // console.log('scroll', e)
    const _l = ['bottom_footer','meditate-popup'];
    
    // console.log(e.target,e.target.parentNode.classList,_l.some(r=> [...e.target.parentNode.classList].includes(r)))
    if ((_l.some(r=> [...e.target?.classList].join().includes(r)))||(_l.some(r=> [...e.target.parentNode?.classList].join().includes(r))))
      return true;
    if (!this.reminder_popup)
      return true;
    
    this.reminder_popup=false;
    return true;
  }

  reminderPopup(event, show){
    if (event)
      event.stopPropagation();

    this.reminder_popup=show;
  }

  generateIconClass(name){
    if (this.icon_map[name.toLowerCase()])
      return `r-icon-${this.icon_map[name.toLowerCase()]}`;
    else
      return `r-icon-positivity`;
  }

  prepareSelectedFactors(){
    
    this.prepared_selected_factors = this.areas.categories.reduce((acc,cat) => acc.concat(cat.factors.filter(fac=>this.selected_factors.includes(fac.id)&&(!(fac.id in this.checked_factors))).map(fac=>{fac['cat_name']=cat.name;fac['checked']=this.checked_factors[fac['id']]; return fac})), [])
    this.prepared_checked_factors = this.areas.categories.reduce((acc,cat) => acc.concat(cat.factors.filter(fac=>fac.id in this.checked_factors&&this.selected_factors.includes(fac.id)).map(fac=>{fac['cat_name']=cat.name;fac['checked']=this.checked_factors[fac['id']]; return fac})), [])
  }

  getRandomStat(){
    return [`${Math.round(Math.random() * 30)+1} day streak`,`${Math.round(Math.random() * 20)+1} times in / ${Math.round(Math.random() * 20)+1} weeks (better than ${Math.round(Math.random() * 80)+1}% of users)`,`${Math.round(Math.random() * 20)+1} times in / ${Math.round(Math.random() * 20)+1} weeks`, `${Math.round(Math.random() * 30)+1} times`, `${Math.round(Math.random() * 10)+1} weeks in a row (better than ${Math.round(Math.random() * 80)+1}% of users)`,`${Math.round(Math.random() * 20)+1} times`].sort(() => 0.5 - Math.random())[0];
  }

  toggleCategory(cat_id:number){
    this.mixpanelService.trackButton('','Goalkeeper Dashboard Toggle Category','toggle',{'cat_id':cat_id});
    if (this.opened_categories.includes(cat_id))
      this.opened_categories=this.opened_categories.filter((c)=> c!==cat_id)
    else
    this.opened_categories.push(cat_id);
  }

  selectFactor(factor_id:number){
    let onoff=true;
    if (this.selected_factors.includes(factor_id)){
      onoff=false
      this.selected_factors=this.selected_factors.filter((f)=> f!==factor_id)
    }else{
      this.selected_factors.push(factor_id);
    }
    this.prepareSelectedFactors();
    if (!this.selected_factors.length)
      this.edit_mode=0;
    this.mixpanelService.trackButton('','Goalkeeper Dashboard Select Factor','toggle',{'fac_id':factor_id,toggle:onoff});
    this.goalkeeperService.saveFactor(factor_id, onoff).subscribe(
      result => {
       
      },
    error => {
        this.error = error ;
      console.log("error",this.error)
    }) 
  }

  checkFactor(factor_id:number, value){
    let onoff=true;
    if (this.checked_factors[factor_id]==value){
        delete this.checked_factors[factor_id];
        onoff=false;
    }else{
      this.checked_factors[factor_id]=value;
    }
    this.prepareSelectedFactors();
    this.mixpanelService.trackButton('','Goalkeeper Dashboard Check Factor','toggle',{'fac_id':factor_id,toggle:onoff,value:value});
    this.goalkeeperService.saveFactorTrack(factor_id, onoff, value).subscribe(
      result => { 
      },
    error => {
        this.error = error ;
      console.log("error",this.error)
    }) 
    
  }

  scroll = (e) => this.reminderPopupGlobal(e)
  
  ngOnDestroy() {
    document.removeEventListener('scroll', this.scroll);
  }

  ngOnInit() {
    document.addEventListener('scroll', this.scroll, true);
    this.mixpanelService.trackView('Goalkeeper Dashboard','view',{});
    this.makeTimesArr();
    console.log(this.reminder_times);
    this.journey = this.passDataService.get("journey");
    this.goalkeeperService.getAreas().subscribe(
      (areas) => {
        this.init = 1;
        this.areas=areas;
        this.selected_factors=areas.selected_factors;
        if (areas.checked_factors && typeof areas.checked_factors === 'object' && !Array.isArray(areas.checked_factors))
          this.checked_factors=areas.checked_factors;
        this.areas.categories.map(cat=>{cat.factors.map(fac=>{fac['stats']=this.getRandomStat();return fac;})});
        this.prepareSelectedFactors();
      },
      (error) => {
        this.error = error;
        console.log("error", this.error);
      }
    );

    this.goalkeeperService.getSettings().subscribe(
      settings => {
          
          if (!settings.options.notify_weeks)
            this.reminder_settings.notify_weeks='every';
          else
            this.reminder_settings=settings.options
          
      },
      error => {
          this.error = error ;
        console.log("error",this.error)
      })

    this.userService.getUserData().subscribe((data) => {
      this.wearable = data.wearable;
      this.wearable_charts = data.wearables;
    });
  }

  showWearableChart(type, item) {
    this.userService.getWearableChartData(type, item, "week").subscribe(
      (wearable) => {
        this.wearable_charts[type] = wearable;
        this.wearable_charts[type]["show"] = "none";
        setTimeout(() => {
          this.wearable_charts[type]["show"] = item;
        }, 0);
      },
      (error) => {}
    );
  }
  deleteConnect(type) {
    this.loading = true;
    this.userService.deleteWearable(type).subscribe(
      (result) => {
        this.loading = false;
        this.wearable[type] = 0;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  connect(type) {
    this.mixpanelService.trackButton('', 'Connect/Delete '+type, 'button', {});
    if (this.wearable[type]) {
      this.deleteConnect(type);
      return false;
    }
    this.loading = true;
    this.userService.getWearableUrl(type).subscribe(
      (result) => {
        this.loading = false;
        this.openUrlWindow(result.url, type, result.redirect_url);
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  openUrlWindow(url, type, redirect_url) {
    if (typeof window["cordova"] !== "undefined") {
      var browser = window["cordova"].InAppBrowser.open(
        url,
        "_blank",
        "location=no"
      );
      browser.addEventListener("loadstart", (e) => {
        if (e.url.indexOf(redirect_url) == 0) {
          let url = new URL(e.url);
          let urlParams = new URLSearchParams(url.search);
          let code = urlParams.get("code");
          this.parseCode(code, type); // e.data hold the message
          browser.close();
        }
      });
    } else {
      var ref = window.open(url, "_blank", "location=no");
      window.addEventListener(
        "message",
        (e) => {
          if (redirect_url.indexOf(e.origin) == 0) {
            this.parseCode(e.data, type); // e.data hold the message
            ref.close();
          }
        },
        false
      );
    }
  }

  parseCode(code, type) {
    this.loading = true;
    this.userService.connectWearable(type, code).subscribe(
      (result) => {
        this.zone.run(() =>
          setTimeout(() => {
            this.loading = false;
            this.wearable[type] = 1;
            if (result.text) this.chat.botLocalMessage(result.text);
          }, 150)
        );
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  goBack() {
    if (!this.journey) this.router.navigate(["dashboard"]);
    else this.router.navigate(["journey", this.journey]);
  }

  public lineChartWearablesOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      enabled: true,
      mode: "index",
      callbacks: {
        label: function (tooltipItems, data) {
          // console.log(tooltipItems,data)
          var date = new Date(tooltipItems.yLabel * 1000);
          if (date.getFullYear() < 2010)
            return (
              tooltipItems.yLabel +
              " " +
              data["datasets"][tooltipItems.datasetIndex]["label"]
            );
          else
            return (
              date.getUTCHours() +
              ":" +
              (date.getUTCMinutes() < 10 ? "0" : "") +
              date.getUTCMinutes()
            );
        },
      },
    },

    fill: false,
    legend: {
      display: false,
      position: "bottom",
      labels: {
        color: "#FFFFFF",
        boxWidth: 15,
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
            color: "#FFFFFF",
          },
          ticks: {
            fontFamily: "'icomoon','Merriweather'",
            fontColor: "#FFFFFF",
            fontSize: 15,
            callback: function (value, index, values) {
              var date = new Date(value * 1000);
              if (date.getFullYear() < 2010) return value;
              else
                return (
                  date.getUTCHours() +
                  ":" +
                  (date.getUTCMinutes() < 10 ? "0" : "") +
                  date.getUTCMinutes()
                );
            },
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            color: "#FFFFFF",
          },
          ticks: {
            fontFamily: "'icomoon','Merriweather'",
            fontColor: "#FFFFFF",
            fontSize: 15,
          },
        },
      ],
    },
  };

  public lineChartLegend: boolean = true;
  public lineChartType: string = "line";
}
