<div class="award" *ngFor="let item of activeItems" [@awardChange]="count">
	  <div class="title_top">
            {{item.title_text}}
      </div>
	
	  <div class="badge yellow" [ngStyle]="{background:'linear-gradient(to bottom right, '+item.color+' 0%, '+ colorLuminance(item.color,0.4) +' 100%)'}">
	    <div class="circle"> <i [ngStyle]="{color:item.icon_color}" class="fa {{item.icon}}"></i></div>
	  </div>

	<div class="ribbon one">

		  <div class="bk l">
		    <div class="arrow top"></div> 
		    <div class="arrow bottom"></div>
		  </div>

		  <div class="skew l"></div>

		  <div class="main">
		    <div>
		    	<div class="text">{{item.name}}</div>   
		    </div>   
		  </div>

		  <div class="skew r"></div>
		  
		  <div class="bk r">
		    <div class="arrow top"></div> 
		    <div class="arrow bottom"></div>
		  </div>

	</div>

	<div class="button">
            <button class="lazy-button" (click)="changeAward()" i18n>Done</button>
    </div>
</div>	