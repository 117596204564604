<section class="page onemcoach">
  <div class="main_container blurry">
    <div class="top_nav">
        <div class="back_button" (click)="goBack()">
          <span class="material-symbols-rounded"> arrow_back_ios_new </span>
          <span i18n>Back</span>
        </div>
      </div>
    <div class="greetings">
      <div class="greetings__phrase" i18n>One minute coach</div>
      <div class="greetings__name" i18n>
        Success is 60 seconds away. Discover tips from our global team.
      </div>
    </div>
    <div class="grid">
      <section class="box tags">
        <h2 i18n>Select one of these categories</h2>
        <div class="scroll_list__viewport" #list>
          <div class="scroll_list" #list>
            <button
              class="scroll_list__button"
              (click)="selectTag(tag)"
              *ngFor="let tag of tags"
            >
              <span>{{ tag|titlecase }}</span>
            </button>
          </div>
        </div>
      </section>

      <section class="box featured">
        <h2 i18n>Featured</h2>

        <div class="standard_list">
          <div class="videos-player" (click)="openEpisode(episode)" *ngFor="let episode of featured_episodes">
            <div class="videos-player__main_container">
              <div class="videos-player__icon">
                <span [ngStyle]="{ 'font-size': '1.5rem' }" class="r-icon-play">
                </span>
              </div>
              <div class="videos-player__title_container">
                <div class="videos-player__title">
                  {{ episode.title }}
                </div>
                <div class="videos-player__duration">{{ episode.tags[0]|titlecase }}</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="box all-series">
        <!-- <h2>All series</h2> -->
        <div class="standard_list">
        <div class="all-series-item" [id]="item.tag" *ngFor="let item of prepared_episodes">
        <h2>{{item.tag|titlecase}}</h2>
          <div class="videos-player" (click)="openEpisode(episode)" *ngFor="let episode of item.episodes">
            <div class="videos-player__main_container">
              <div class="videos-player__icon">
                <span [ngStyle]="{ 'font-size': '1.5rem' }" class="r-icon-play">
                </span>
              </div>
              <div class="videos-player__title_container">
                <div class="videos-player__title">
                  {{ episode.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
    </div>
  </div>
</section>

<app-modal
    [blocking]="false"
    [hideClose]="true"
    [modalId]="'onemcoach_modal'"
    [closeCallbackFunction]="closeModal"
  >
    <div class="onemcoach_modal">
        <div class="onemcoach_modal__back_button" (click)="closeModal()">
            <span class="material-symbols-rounded"> arrow_back_ios_new </span>
            <span>Back</span>
        </div>
      <div class="onemcoach_modal__title" *ngIf="current_episode.tags">{{ current_episode.tags[0]|titlecase }}</div>
      <div class="onemcoach_modal__body_container">
        <div *ngIf="current_episode.video">
            <video-player [url]="current_episode.video">
            </video-player>
            <div class="onemcoach_modal__title onemcoach_modal__title--big onemcoach_modal__title--center">{{ current_episode.title }}</div>
        </div>
        <br /><br />
      </div>
    </div>
  </app-modal>