import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs'

@Component({
  selector: 'meditate-player',
  templateUrl: './meditate-player.component.html',
  styleUrls: ['./meditate-player.component.scss']
})


export class MeditatePlayerComponent implements OnInit {
  constructor() { }

  @Input('item') item:any;
  playMeditation: Subject<number> = new Subject<number>();
  play:number = 0;
  started:number = 0.0;
  show_rate:boolean = false;
  rate_sent:boolean = false;
  current_progress:number = 0.0;

  ngOnInit(): void {
  }

  playMeditationEvent() {
    if (!this.started)
      this.started=0.001;
    this.rate_sent=false;
    this.show_rate=false;
    this.play=this.play^1;
    this.playMeditation.next(this.play);
  }

  totalStopPlay() {
    this.started=0;
    this.play=0;
    this.show_rate=false;
    this.playMeditation.next(-1);
  }

  stopMeditation(e){
    if (e)
      e.stopPropagation()
    this.play=0;
    this.started=0;
    this.playMeditation.next(-1);
    this.show_rate=true;
  }

  sendRate(e, id, rate){
    if (e)
      e.stopPropagation()
    this.rate_sent=true;
    this.totalStopPlay()
  }


  getCurrentProgress(progress:number){
    this.current_progress=progress;
    if (progress>=100)
      this.stopMeditation(false)
  }
  formatTime(duration){
    var minutes = Math.floor(duration/60);
    var seconds:any = duration - minutes * 60;

    if (seconds==0)
      seconds="00";

    if (seconds.toString().length==1)
      seconds="0"+seconds;

    return minutes;
  }

}
