<div class="keeper_container" *ngIf="init">
  <div *ngIf="done" class="title_small" i18n>
    Today you achieved
  </div>
  <div *ngIf="done" class="title_score">
    {{today_percent}}%
  </div>
  <div *ngIf="done" class="title_small" style="padding-top:0" i18n>
    of your goals. This is {{success_percent}}% {{success_text}} than last time you tracked
  </div>
  <div class="middle_container">
    <div *ngFor="let chart of charts ;let i = index">
    <div class="results_chart {{chart}}" *ngIf="stats[chart]?.dataset" [attr.data-chart]="chart">

      <div class="chart_title" >
        {{translate[chart]?translate[chart]:chart}}
        <i (click)="toggleChart(chart)" class="fa" [ngClass]="{'fa-plus':chart_expand[chart]==0,'fa-minus':chart_expand[chart]==1}"></i>
      </div>
      <div class="expanded_area" [ngClass]="{'open':chart_expand[chart]==1}">
      	<div class="chart_filter_group">
      		<div class="chart_filter" (click)="setFilterRange(chart,'week')" [ngClass]="{active:chart_filters[chart]?.range=='week'}" i18n>Week</div>
      		<div class="chart_filter" (click)="setFilterRange(chart,'month')" [ngClass]="{active:chart_filters[chart]?.range=='month'}" i18n>Month</div>
      		<div class="chart_filter" (click)="setFilterRange(chart,'all')" [ngClass]="{active:chart_filters[chart]?.range=='all'}" i18n>All</div>
      		<div class="chart_filter always" (click)="setFilterMode(chart)" *ngIf="i!==0 && i !== charts.length-1">
      			<span *ngIf="chart_filters[chart]?.mode=='main'" i18n>Details</span>
      			<span *ngIf="chart_filters[chart]?.mode!=='main'" i18n>Main</span>
      		</div>
        </div>
        <canvas  id="{{chart}}" class="chart chart-line-alt" baseChart width="280" height="300"
                [datasets]="stats[chart]['dataset']"
                [labels]="stats[chart]['labels']"
                [options]="lineChartOptions"
                [colors]="stats[chart]['colors']"
                [legend]="lineChartLegend"
                [chartType]="lineChartType"
                (chartHover)="chartHovered($event)"
                (chartClick)="chartClicked($event)"></canvas>
          </div>
          </div>       
    </div>

  <div class="bottom_container">
    <div class="next_button"><i class="r-icon-chevronRight" (click)="goNext()"  aria-hidden="true"></i></div>
  </div>
  <br><br>

</div>

 <app-modal [noHeader]="true" [zIndex]="1111" [noPadding]="true" [blocking]='false' [modalId]="'awards'">
  <div class="goalkeeper_modal" style="position:fixed;width:100%; height: 100%;overflow: auto;">
    <span class="right-align close_button" (click)="closeModal('awards')"><i class="material-icons md-24">clear</i></span>
    <div class="body_goalkeeper">
        <div class="main_content">
          <div class="award_container">
            <awards-container [award]='award' (awardNext)="handleAwardNext($event)"></awards-container>
          </div>
        </div>
    </div>
  </div>
</app-modal>  