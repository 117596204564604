import { Component, OnInit, Input, HostBinding,AfterViewChecked,ChangeDetectorRef, Output, EventEmitter  } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { diagnosticAnimation } from '../../../_animations/index';
import { DiagnosticService, TopBarService } from '../../../_services/index';

@Component({
  selector: 'app-diagnostic-answer',
  templateUrl: './diagnostic-answer.component.html',
  styleUrls: ['./diagnostic-answer.component.scss'],
  animations:[diagnosticAnimation],
})
export class DiagnosticAnswerComponent implements OnInit,AfterViewChecked  {

    @Output() answerUpdated = new EventEmitter();
    // @HostBinding('@answerChange')
    public count: number = 0;
    public id: number;

    private changeDetectorRef: ChangeDetectorRef;

    @Input('direction')
    public answer_direction;

    public answer_direction_b;

    public activeItems: any[] = [];
    error = '';
    @Input() goNext: () => void;
    @Input('showNext')
    public showNext;
    @Input('item')
    set item(itm: any) {
    this.changeDetectorRef.detectChanges();
    this.activeItems = [];
    if (itm && this.activeItems[0] != itm) {
      this.activeItems.push(itm);
      this.count++;
    }
    if (itm.submit){
      this.submitDiagnostic()
    }
    // this.answer_direction = (this.answer_direction=="up")?"down":"up";
  }	

  doneAnimation($e) { 
    
  }

  changeAnswer(value){
    this.answerUpdated.emit(value);
  }

  constructor(private router: Router,private route: ActivatedRoute,changeDetectorRef: ChangeDetectorRef,private diagnosticService: DiagnosticService,private topBarService:TopBarService) {
   this.topBarService.setShowHamburger(false);
   this.changeDetectorRef = changeDetectorRef;
  }
 
  ngOnInit() {
    
     this.route.params.subscribe(params => {
       this.id = +params['id']; // (+) converts string 'id' to a number
    });

  }

  ngAfterViewChecked() {

  }

  submitDiagnostic() {
            this.diagnosticService.submitDiagnostic(this.id).subscribe(
              result => {
                this.router.navigate(['diagnostics','item',this.id], { replaceUrl: true, queryParams: { 'new': '' } });
            },
            error => {
              if (this.error=='')
                setTimeout(()=>{this.submitDiagnostic()},500)

              this.error = error;
              console.log("error",this.error)
        })
  }

}
