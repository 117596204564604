<div class="container_main">

    <div class="container_items">

                        <div class="logo_title" i18n>
                             Meditations
                       </div>
      
      <div class="videos-container" >
      	<div class="video-perspective" *ngFor="let sery of series">
		      <div class="video-item" (click)="active=sery.id" [ngClass]="{'active':(active==sery.id)}" >
		      	<img class="image" [src]="sery.image" alt="">
		      	<div class="text_container">
		      		<div class="title_text">
		      			{{sery.title}}
		      		</div>
		      		<div class="author">
		      			{{sery.author}}
		      		</div>
		      	</div>
		      	<div class="controls_container">
		      		<div (click)="trackRouteHelperClick(['/meditate/episodes',sery.id],'Meditation Sessions Click','button',{'series_id':sery.id})" class="button play" i18n>
		      			<i class="fa fa-play"></i> Sessions
		      		</div>
		      		<div class="button reminder" (click)="trackRouteHelperClick(['/meditate/reminder',sery.id],'Meditation Reminders Click','button',{'series_id':sery.id})">
		      			<i class="fa fa-clock-o"></i> Reminders
		      		</div>
		      	</div>
		      </div>
	       </div>
    </div>

    <div class="add_access_container">
				<mat-error *ngIf="error">{{error}}</mat-error>
		        <div class="form-group " *ngIf="add_open">
			         <input type="text"  [(ngModel)]="model.access_code" required class="form-control" placeholder="Access Code" name="username">
			         <div class="tooltip_help_icon" (click)="openModal('access_help')">
	                    <i class="fa fa-question-circle"></i>
	                 </div>
		        </div>
		        
	    	</div>
	    	<br><br>
	    	<div >
	    		<app-organisations-footer></app-organisations-footer>
	    	</div>
</div>

             <app-modal [blocking]='false' [modalId]="'access_help'">
                   <div class="standard_modal">
                    <div class="title_standard_modal" i18n>Access Code</div>
                    <div class="main_text_container">
			        	<div class="main_text" [innerHTML]="access_code_tip">
		                </div>
			        </div>
			        <br><br>
                    </div> 
                </app-modal>

             <app-modal [blocking]='false' [modalId]="'resources'">
                   <div class="standard_modal">
                    <div class="title_standard_modal" i18n>Resources</div>
                    <div class="">
                      <div class="resources">
                      	<div *ngFor="let resource of resources; let i = index">
                      		<a target="_blank" [href]="resource.url">
							<div class="resource" >
							  <div class="resource_container">
							    <div class="count">{{i+1}}.</div>
							    <div class="text_resource">{{resource.name}}</div>
							  </div>
							  <span class="go_to_resource"> <i class="fa fa-chevron-right" aria-hidden="true"></i></span>
							</div>
							</a>
						</div>
						</div>
                      <br><br>
                    </div>
                    </div> 
                </app-modal>
