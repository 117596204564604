// import the required animation functions from the angular animations module
import { trigger, state, animate, transition, style, query, animateChild, group } from '@angular/animations';
 
export const chatbotAnimation =
    // trigger name for attaching this animation to an element using the [@triggerName] syntax
    [trigger('answerChange', [
      transition('void => up', [
        style({ opacity: 0,transform: "translate(-50%, -200%)" }),
        animate('1000ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: "translate(-50%, -50%)" }))
      ]),
      transition('up => void', [
        style({ opacity: 1,transform: "translate(-50%, -50%)" }),
        animate('1000ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 0, transform: "translate(-50%, 200%)" }))
      ]),

      transition('void => down', [
        style({ opacity: 0,transform: "translate(-50%, 200%)" }),
        animate('1000ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: "translate(-50%, -50%)" }))
      ]),
      transition('down => void', [
        style({ opacity: 1,transform: "translate(-50%, -50%)" }),
        animate('1000ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 0, transform: "translate(-50%, -200%)" }))
      ]),

    ]),
    trigger('fadeChange', [
      transition(':enter', [
        style({ opacity: 0,position:'absolute' }),
        animate('1000ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1,position:'absolute' }),
        animate('1000ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 0 }))
      ]),
    ]),
    trigger('fadeChange2', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1000ms cubic-bezier(0.35, 0, 0.25, 1)', style("*"))
      ]),
      transition(':leave', [
        // style({ opacity: 1,position:'absolute',left:'50%',transform:'translateX(-50%)' }),
        // animate('1000ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 0 }))
      ]),
    ])
    ,
    trigger('slideRight', [
      transition(':enter', [
        style({ transform:'translateX(120%)' }),
        animate('300ms cubic-bezier(0.35, 0, 0.25, 1)', style({position:'relative',transform:'translateX(0)' }))
      ]),
      transition(':leave', [
        style({position:'absolute',transform:'translateX(0)' }),
        animate('300ms cubic-bezier(0.35, 0, 0.25, 1)', style({ transform:'translateX(150%)' }))
      ]),
    ]),
    trigger('slideLeft', [
      transition(':enter', [
        style({ transform:'translateX(-120%)' }),
        animate('1000ms cubic-bezier(0.35, 0, 0.25, 1)', style({position:'relative',transform:'translateX(0)',float:'left' }))
      ]),
      transition(':leave', [
        style({transform:'translateX(0)' }),
        animate('1000ms cubic-bezier(0.35, 0, 0.25, 1)', style({ transform:'translateX(-150%)' }))
      ]),
    ]),
    trigger('fadeAnim', [
      transition(':enter', [
        style({ opacity:0 }),
        animate('300ms cubic-bezier(0.35, 0, 0.25, 1)', style({opacity:1}))
      ]),
      transition(':leave', [
       style({ opacity:1 }),
        animate('300ms cubic-bezier(0.35, 0, 0.25, 1)', style({opacity:0}))
      ]),
    ])
    ]
// 







