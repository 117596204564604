<div class="keeper_container">
	<div class="title_small" i18n>
		For how long would you like to track yourself?
	</div>
	<div class="middle_container">
			<div class="setup_container">
				<div class="setup">
					<div class="setup_time">
						<div class="column choose_week" [ngClass]="{'active':setup.time=='week'}" (click)="setupTime('week')">
							<div class="column_container">
									<div class="select_week">
										<select [(ngModel)]="setup.weeks">
											<option>1</option>
											<option>2</option>
											<option>3</option>
											<option>4</option>
											<option>5</option>
											<option>6</option>
										</select>
										<div class="value_week">{{setup.weeks}}</div>
										<div class="select_arrow">
											<i class="fa fa-chevron-down"></i>
										</div>
									</div>
							</div>
							<div class="column_title" i18n>
								weeks
							</div>
						</div>
						<div class="column_line">
							
						</div>
						<div class="column choose_ongoing" [ngClass]="{'active':setup.time=='ongoing'}" (click)="setupTime('ongoing')">
							<div class="column_container infinity">
							<i>&#8734;</i>
							</div>
							<div class="column_title" i18n>
								ongoing
							</div>
							
						</div>
					</div>
				</div>
			</div>

		<div class="title_small" i18n>
			When would you like to be reminded to track progress?
		</div>

		<div class="setup_container">
				<div class="setup">
						<div class="setup_daytime">
							<div class="column choose_morning" [ngClass]="{'active':setup.daytime.morning==1}" (click)="setupDayTime('morning')">
							<div class="column_container">
								<span class="icon-weather-07"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span></span>
							</div>
							<div class="column_title" i18n>morning</div>
							</div>
							<div class="column choose_afternoon" [ngClass]="{'active':setup.daytime.afternoon==1}" (click)="setupDayTime('afternoon')" >
							<div class="column_container">
								<span class="icon-weather-01"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span></span>
							</div>
							<div class="column_title" i18n>afternoon</div>
							</div>
							<div class="column choose_evening" [ngClass]="{'active':setup.daytime.evening==1}" (click)="setupDayTime('evening')">
							<div class="column_container">
								<span class="icon-weather-10"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
							</div>
							<div class="column_title" i18n>evening</div>
							</div>
							<div class="select_timezone">
								<select [(ngModel)]="setup.timezone">
									<option *ngFor="let timezone of timezones" [value]=timezone.id>{{timezone.name}}</option>
								</select>
								<div class="value_timezone" *ngIf="setup.timezone">GMT ({{setup.timezone}})</div>
								<div class="select_arrow">
									<i class="fa fa-chevron-down"></i>
								</div>
							</div>
						</div>
				</div>
		</div>

		<div class="title_small" i18n>
			Remind me by
		</div>
		<div class="setup_container">
				<div class="setup">
						<div class="setup_remind">

						  <ul>
						  <!-- <li>
						    <input type="radio" id="f-option" [(ngModel)]="setup.remind" value="email" name="selector">
						    <label for="f-option" i18n>email</label>
						    <div class="check"></div>
						  </li> -->
						  
						  <li ng-if="pushData.id!=''">
						    <input type="radio" id="t-option" [(ngModel)]="setup.remind" value="noty" name="selector">
						    <label for="t-option" i18n>push notification</label>
						    <div class="check"><div class="inside"></div></div>
						  </li>

						  <li>
						    <input type="radio" id="n-option" [(ngModel)]="setup.remind" value="none" name="selector">
						    <label for="n-option" i18n>no reminders</label>
						    <div class="check"><div class="inside"></div></div>
						  </li>

						</ul>
						
						</div>
				</div>
		</div>				

	</div>

	<div class="bottom_container">
						<div class="next_button"><i class="fa fa-arrow-right" (click)="goNext()"  aria-hidden="true"></i></div>
					</div>
					<br><br>

</div>