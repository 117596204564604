import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService, TopBarService,MeditationService } from '../../_services/index';
import { routeAnimation,videoAnimation } from '../../_animations/index';
import { Globals } from '../../globals';
import * as d3 from 'd3';

export class Color{
	r:any;
	g:any;
	b:any;
	rS:any;
	gS:any;
	bS:any;

	constructor(r,g,b) {
	  this.r = r;
	  this.g = g;
	  this.b = b;
  	  this.rS = this.gS = this.bS = 1;
	}
  
  change() {
    if (this.r == 0 || this.r == 255) this.rS *= -1;
    if (this.g == 0 || this.g == 255) this.gS *= -1;
    if (this.b == 0 || this.b == 255) this.bS *= -1;
    this.r += this.rS;
    this.g += this.gS;
    this.b += this.bS;
  }
}

export class Shape{
	maxGeneration:any = 5;
	mainsvg:any= false;
	end:any={}
	start:any={}
	rotation:any;
	generation:any;
	size:any;
	sizeVar:any;
	ctx:any;
	color:Color;
	degToRad(deg) {
  return Math.PI / 180 * deg;
}

	constructor(gen , x, y , size, rotation, sizeVariation, ctx, color) {
		
	  this.generation = gen;
	  this.size = size;
	  this.ctx = ctx;
	  this.sizeVar = sizeVariation;
	  this.rotation = -rotation;
	  this.color = color;

	  this.start = {
	    x: x,
	    y: y
	  };
	  this.end = {
	    x_1: this.start.x + Math.cos(this.degToRad(this.rotation)) * this.size,
	    y_1: this.start.y + Math.sin(this.degToRad(this.rotation)) * this.size,
	    x_2: this.start.x + Math.cos(this.degToRad(this.rotation + 360 / 3)) * this.size,
	    y_2: this.start.y + Math.sin(this.degToRad(this.rotation + 360 / 3)) * this.size,
	    x_3:
	      this.start.x +
	      Math.cos(this.degToRad(this.rotation + 360 / 3 * 2)) * this.size,
	    y_3:
	      this.start.y + Math.sin(this.degToRad(this.rotation + 360 / 3 * 2)) * this.size
	  };

	  this.init();
  }
  init(){
	  if (this.generation < this.maxGeneration) {
	    var gen = this.generation + 1,
	      newSize = this.size * this.sizeVar,
	      newRotation = this.rotation;

	      new Shape(gen, this.end.x_1, this.end.y_1, newSize, newRotation,this.sizeVar, this.ctx, this.color)
	      new Shape(gen, this.end.x_2, this.end.y_2, newSize, newRotation,this.sizeVar, this.ctx, this.color)
	      new Shape(gen, this.end.x_3, this.end.y_3, newSize, newRotation,this.sizeVar, this.ctx, this.color)
	  }
	  this.draw();
	};

	draw(){
		
		 this.ctx.beginPath();
		 this.ctx.lineWidth = 2;
		  this.ctx.moveTo(this.start.x, this.start.y);
		  this.ctx.lineTo(this.end.x_1, this.end.y_1);
		  this.ctx.moveTo(this.start.x, this.start.y);
		  this.ctx.lineTo(this.end.x_2, this.end.y_2);
		  this.ctx.moveTo(this.start.x, this.start.y);
		  this.ctx.lineTo(this.end.x_3, this.end.y_3);
		  // this.ctx.strokeStyle = "#FF0000";
		  this.ctx.strokeStyle =
		    "rgba(" + this.color.r + "," + this.color.g + "," + this.color.b + "," + 1 / this.generation / 2 + ")";
		  this.ctx.stroke();
	}
}

@Component({
  selector: 'app-meditate-watch',
  templateUrl: './meditate-watch.component.html',
  styleUrls: ['./meditate-watch.component.scss'],
  animations:[routeAnimation,videoAnimation],
  host: { '[@fadeRoute]': '' }
})
export class MeditateWatchComponent implements OnInit {

  @ViewChild('audioOption') audioPlayerRef: ElementRef;
  radius = 10;
  mainsvg:any= false;
  i = 0;
  PHI = (1 + Math.sqrt(5)) / 2;
  frameDuration = 1000 / 60;
  duration = 3000;
  rotationSpeed = 0.1;
  totalIterations = Math.floor(this.duration / this.frameDuration);
  maxBaseSize = 100;
  baseSizeSpeed = 0.000001;
  shapes:Shape[] = [];
  sizeVariation = 1;
  iteration = 0;
  animationDirection = 1;
  sizeVariationRange = .15;
  baseRotation = 0;
  baseSize = 70;
  color = new Color(43, 205, 255);
  ctx:any;
  timeLeft = 120;
  episodeDuration = 120;
  timeFormatted = "";
  pause:any = true;
  interval:any;

  id = 0;
  episode:any;
  error = "";
  init = false;
  rate = -1;
  loading = true;
  loader_percent:any = 0;
  loader_interval:any;
  help_text_i:any = -1;
  help_text_interval:any = false;
  help_text:any = "";
  help_texts = [];


  constructor(private meditationService: MeditationService,public globals:Globals, private topBarService: TopBarService, private modalService:ModalService, private route: ActivatedRoute, private router: Router) { 

  }

  ngOnInit() {
	   this.route.params.subscribe(params => {
	   this.id = +params['id']; // (+) c
	   		 this.meditationService.getEpisode(this.id).subscribe(
			      result => {
						this.episode = result.episode;
						this.help_texts=result.help_texts;
			         	this.timeLeft = this.episode.duration;
			         	this.episodeDuration = this.episode.duration;
			         	this.prepareTimer()
			         	setTimeout(() => {
			         		this.init = true;
			         		},700)
			         	setTimeout(() => {
		                  	this.mainsvg = d3.select('.meditateSvg');
						  	var canvas = document.getElementById("canvas") as HTMLCanvasElement;
						  	this.ctx = canvas.getContext("2d")
						  	this.ctx.strokeStyle = "rgba(66,134,240,.3)";
						  	this.ctx.globalCompositeOperation = "lighter";
						  	canvas.width = window.innerWidth;
						    canvas.height = window.innerHeight;
						  	this.animate()
						  	this.audioPlayerRef.nativeElement.play();
						  	this.audioPlayerRef.nativeElement.pause();
						  	this.loaderUpdate();
							// if ((typeof this.mainsvg != 'boolean') && this.mainsvg != null)
						 //  		this.mainsvg.on("ontouchstart" in document ? "touchmove" : "mousemove", this.particle);
	                  	},800)
			         	this.meditationService.saveViews(this.id).subscribe();

			      },
			        error => {
			            this.error = error ;
			            this.init = true;
			          console.log("error",this.error)
			        })

	   })
	
  	


  }

 loaderUpdate() {
 	  	this.loader_interval = setInterval(() => {
  			 var buffered = this.audioPlayerRef.nativeElement.buffered;
			  var duration = this.audioPlayerRef.nativeElement.duration;

			  if (buffered.length) {
			    this.loader_percent = (100 * buffered.end(0) / duration).toFixed(0);
			  }
			  if (this.loader_percent >= 100){
			  		console.log('stop_loader_interval')
			  		clearInterval(this.loader_interval)
			  }
	    },50)
	}

  prepareHelpTexts(){
  		 	setTimeout(() => {
			this.help_text_interval = setInterval(() => {
				this.help_text_i=this.help_text_i+1;
				// console.log(this.help_text_i, this.help_text_i%2,this.help_texts[this.help_text_i])
				if (this.help_text_i%2){
					this.help_text=false;
					return ;
				}else{
					this.help_text=this.help_texts[this.help_text_i/2];
					if (typeof this.help_text === 'undefined')
					{
						clearInterval(this.help_text_interval)
						console.log('stop interval')
					}
				}
				// console.log(this.help_text)
			},3000)
		},100)
  }

  prepareTimer(){
  	this.formatTime()
  	this.interval = setInterval(() => {
  		if (this.pause)
  			return false

	      if(this.timeLeft > 0) {
	        this.timeLeft--;
	      } else {
	        this.timeLeft = 120;
	      }
	      this.formatTime()
	      this.checkTime()
	    },1000)
  }

  formatTime(){
  	var minutes = Math.floor(this.timeLeft/60);
  	var seconds:any = this.timeLeft - minutes * 60;

  	if (seconds==0)
  		seconds="00";

  	if (seconds.toString().length==1)
  		seconds="0"+seconds;

  	this.timeFormatted=minutes+":"+seconds;
  }

  animate(){
  requestAnimationFrame(this.animate.bind(this));
  if (this.pause)
  	return false;
  this.ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
  this.ctx.globalCompositeOperation = "lighter";
  this.color.change()
  new Shape(0, window.innerWidth / 2, window.innerHeight / 2, this.baseSize, this.baseRotation, this.sizeVariation, this.ctx,this.color)
  	this.iteration++;
	  if (this.baseSize < this.maxBaseSize) this.baseSize += this.baseSizeSpeed;
	  this.baseRotation += this.rotationSpeed;
	  this.sizeVariation = this.easeInOutSine(
	    this.iteration,
	    1 - this.sizeVariationRange * this.animationDirection,
	    this.sizeVariationRange * 2 * this.animationDirection,
	    this.totalIterations
	  );
	  if (this.iteration >= this.totalIterations) {
	    this.iteration = 0;
	    this.animationDirection *= -1;
	  }

  	
  }

  easeInOutSine(currentIteration,startValue,changeInValue,totalIterations) {
  return (
    changeInValue /
      2 *
      (1 - Math.cos(Math.PI * currentIteration / totalIterations)) +
    startValue
  );
}

ngAfterContentInit() {
	// if ((typeof this.mainsvg != 'boolean') && this.mainsvg != null)
	// 	this.makePlayButton();
 }

 ngAfterViewInit(){

 }

 makePlayButton(){

 }

 clicked(event){
 	// this.generateShape(250, 250, this.baseSize, 45)
 }

degToRad(deg) {
  return Math.PI / 180 * deg;
}

 generateShape(x, y, size, rotation) {
  
  	var x_1 = x + Math.cos(this.degToRad(rotation)) * size;
  	var y_1 = y + Math.sin(this.degToRad(rotation)) * size;
  	var x_2= x + Math.cos(this.degToRad(rotation + 360 / 3)) * size;
    var y_2 = y + Math.sin(this.degToRad(rotation + 360 / 3)) * size;
    var x_3 = x + Math.cos(this.degToRad(rotation + 360 / 3 * 2)) * size;
    var y_3 = y + Math.sin(this.degToRad(rotation + 360 / 3 * 2)) * size;

   this.mainsvg.append("line",'line')
   							.attr("x1", x)
   							.attr("y1", y)
   							.attr("x2", x_1)
   							.attr("y2", y_1)
   							.attr("stroke-width", 2)
   							.attr("stroke", "red")
   							.attr('class','line');

  this.mainsvg.append("line",'line')
							.attr("x1", x)
							.attr("y1", y)
							.attr("x2", x_2)
							.attr("y2", y_2)
							.attr("stroke-width", 2)
							.attr("stroke", "red")
							.attr('class','line');

	  this.mainsvg.append("line",'line')
							.attr("x1", x)
							.attr("y1", y)
							.attr("x2", x_3)
							.attr("y2", y_3)
							.attr("stroke-width", 2)
							.attr("stroke", "red")
							.attr('class','line');


}

playMediate(){
	if (this.pause){
		this.audioPlayerRef.nativeElement.play();
	  	this.pause=false;
	  	if (typeof window['plugins']['insomnia'] !== 'undefined') {
		        window['plugins']['insomnia'].keepAwake()
		}
	}else{
		this.audioPlayerRef.nativeElement.pause();
	  	this.pause=true;
	  	if (typeof window['plugins']['insomnia'] !== 'undefined') {
		        window['plugins']['insomnia'].allowSleepAgain()
		}
	}
	  
}

finishMeditation(){
	 this.openModal('meditation_rate')
	 this.audioPlayerRef.nativeElement.pause();
	 this.pause=true;
	 if (typeof window['plugins']['insomnia'] !== 'undefined') {
		        window['plugins']['insomnia'].allowSleepAgain()
	 }
}

finishMeditationRate(rate){
	if (this.rate !==-1)
		return ;
	 var seconds = this.episodeDuration-this.timeLeft;
	 // this.meditationService.saveStats(this.id, seconds).subscribe();
	 this.meditationService.saveSession(this.id, rate, seconds).subscribe(result => {this.router.navigate(['meditate','stats']);}, error =>{});
	 
}



checkTime() {
    if (this.timeLeft<=0){
    	this.audioPlayerRef.nativeElement.pause();
	  	this.pause=true;
	  	this.finishMeditation();
    }	
};

  openModal(id) {
   this.modalService.open(id)
  }

  closeModal(id) {
   this.modalService.close(id)
  }

  myOnLoadedData(){
  	this.loading = false;
  	this.prepareHelpTexts();
  }


 particle = () => {
 	if (this.pause)
 		return false;
	var m = d3.event;
	this.mainsvg.insert("circle", "rect")
	  .attr("cx", m.pageX)
	  .attr("cy", m.pageY)
	  .attr("r", 1e-6)
	  .style("stroke", d3.hsl((this.i = (this.i + 1) % 360), 1, .5))
	  .style("stroke-opacity", .5)
	.transition()
	  .duration(2000)
	  .ease(Math.sqrt)
	  .attr("r", 100)
	  .style("stroke-opacity", 1e-6)
	  .remove();

	d3.event.preventDefault();
}



}
