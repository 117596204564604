import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/index';
import { HomeComponent } from './home/index';
import { DiagnosticComponent } from './diagnostic/index';
import { MyAccountComponent } from './myaccount/index';
import { AuthGuard } from './_guards/index';
import { DiagnosticListComponent } from './diagnostic/diagnostic-list/diagnostic-list.component';
import { DiagnosticItemComponent } from './diagnostic/diagnostic-item/diagnostic-item.component';
import { DiagnosticAnswersComponent } from './diagnostic/diagnostic-answers/diagnostic-answers.component';
import { SettingsComponent } from './settings/settings.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { StartLinkComponent } from './start-link/start-link.component';
import { GoalkeeperComponent } from './goalkeeper/goalkeeper.component';
import { GoalkeeperDashboardComponent } from './goalkeeper/goalkeeper-dashboard/goalkeeper-dashboard.component';
import { GoalkeeperSettingsComponent } from './goalkeeper/goalkeeper-settings/goalkeeper-settings.component';
import { GoalkeeperAreasComponent } from './goalkeeper/goalkeeper-areas/goalkeeper-areas.component';
import { GoalkeeperMygoalComponent } from './goalkeeper/goalkeeper-mygoal/goalkeeper-mygoal.component';
import { GoalkeeperTrackComponent } from './goalkeeper/goalkeeper-track/goalkeeper-track.component';
import { GoalkeeperResultComponent } from './goalkeeper/goalkeeper-result/goalkeeper-result.component';
import { VideoCoursesComponent } from './video-courses/video-courses.component';
import { VideoListSeriesComponent } from './video-courses/video-list-series/video-list-series.component';
import { VideoWatchComponent } from './video-courses/video-watch/video-watch.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { AccessCodesComponent } from './access-codes/access-codes.component';
import { ResourcesComponent } from './resources/resources.component';
import { MeditateComponent } from './meditate/meditate.component';
import { MeditateListComponent } from './meditate/meditate-list/meditate-list.component';
import { MeditateWatchComponent } from './meditate/meditate-watch/meditate-watch.component';
import { MeditateEpisodesComponent } from './meditate/meditate-episodes/meditate-episodes.component';
import { MediateReminderComponent } from './meditate/mediate-reminder/mediate-reminder.component';
import { MeditateStatsComponent } from './meditate/meditate-stats/meditate-stats.component';
import { WeeklyTipsComponent } from './weekly-tips/weekly-tips.component';
import { WeeklyTipsItemComponent } from './weekly-tips/weekly-tips-item/weekly-tips-item.component';
import { JourneyComponent } from './journey/journey.component';
import { EmotionsComponent } from './emotions/emotions.component';
import { OnemcoachComponent } from './onemcoach/onemcoach.component';
import { InstantCalmComponent } from './instant-calm/instant-calm.component';
import { HabitTrackerComponent } from './src/app/habit-tracker/habit-tracker.component';
import { HabitTrackerListComponent } from './src/app/habit-tracker/habit-tracker-list/habit-tracker-list.component';
import { HabitTrackerItemComponent } from './src/app/habit-tracker/habit-tracker-item/habit-tracker-item.component';
import { DiagnosticSpiralSoloComponent } from './diagnostic/diagnostic-spiral-solo/diagnostic-spiral-solo.component';

const appRoutes: Routes = [
    { path: 'spiral', component: DiagnosticSpiralSoloComponent },
    { path: 'login', component: LoginComponent },
    { path: 'login/:mode', component: LoginComponent },
    { path: 'start/:slug', component: StartLinkComponent },
    { path: 'remind', component: ForgotPasswordComponent },
    { path: 'reset_password/:token', component: ResetPasswordComponent },
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'myaccount', component: MyAccountComponent, canActivate: [AuthGuard] },
    { path: 'emotions', component: EmotionsComponent, canActivate: [AuthGuard] },
    { path: 'onemcoach', component: OnemcoachComponent, canActivate: [AuthGuard] },
    { path: 'instant_calm', component: InstantCalmComponent, canActivate: [AuthGuard] },
    { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
    { path: 'resources', component: ResourcesComponent, canActivate: [AuthGuard] },
    { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard] },
    { path: 'access_codes', component: AccessCodesComponent, canActivate: [AuthGuard] },
    { path: 'journey/:id', component: JourneyComponent, canActivate: [AuthGuard] },
    { path: 'journey', component: JourneyComponent, canActivate: [AuthGuard] },
    { path: 'habbit', component: HabitTrackerComponent,
        children: [
            { path: '', redirectTo: 'list', pathMatch: 'full' },
            { path: 'list', component: HabitTrackerListComponent },
            { path: 'item/:id', component: HabitTrackerItemComponent },
        ],
        canActivate: [AuthGuard] },
    { path: 'diagnostics', component: DiagnosticComponent,
		children: [
		    { path: '', redirectTo: 'list', pathMatch: 'full' },
            { path: 'list', component: DiagnosticListComponent },
            { path: 'item/:id', component: DiagnosticItemComponent },
            { path: 'answers/:id', component: DiagnosticAnswersComponent },
        ],
     	canActivate: [AuthGuard] },
    { path: 'goalkeeper', component: GoalkeeperComponent,
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'dashboard', component: GoalkeeperDashboardComponent },
            { path: 'settings', component: GoalkeeperSettingsComponent },
            { path: 'areas', component: GoalkeeperAreasComponent },
            { path: 'mygoals', component: GoalkeeperMygoalComponent },
            { path: 'track', component: GoalkeeperTrackComponent },
            { path: 'result/:done', component: GoalkeeperResultComponent },
            { path: 'result', component: GoalkeeperResultComponent },
        ],
        canActivate: [AuthGuard] },
    { path: 'video', component: VideoCoursesComponent,
        children: [
            { path: '', redirectTo: 'series', pathMatch: 'full' },
            { path: 'series', component: VideoListSeriesComponent },
            { path: 'watch/:id', component: VideoWatchComponent },
        ],
        canActivate: [AuthGuard] }, 
    { path: 'meditate', component: MeditateComponent,
        children: [
            { path: '', redirectTo: 'list', pathMatch: 'full' },
            { path: 'list', component: MeditateListComponent },
            { path: 'episodes/:id', component: MeditateEpisodesComponent },
            { path: 'watch/:id', component: MeditateWatchComponent },
            { path: 'reminder/:id', component: MediateReminderComponent },
            { path: 'stats', component: MeditateStatsComponent },
        ],
        canActivate: [AuthGuard] },
          { path: 'weekly_tips', component: WeeklyTipsComponent,
        children: [
            { path: '', redirectTo: 'item', pathMatch: 'full' },
            { path: 'item', component: WeeklyTipsItemComponent },
            //{ path: 'item/:id', component: WeeklyTipsItemComponent },
        ],
         canActivate: [AuthGuard] },
    

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy',scrollPositionRestoration: 'enabled' });