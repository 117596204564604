import { Component, OnInit, Input, HostBinding,AfterViewChecked,ChangeDetectorRef, Output, EventEmitter,ViewEncapsulation  } from '@angular/core';
import { goalkeeperAnimation } from '../../../_animations/index';

@Component({
  selector: 'awards-container',
  // encapsulation: ViewEncapsulation.None,
  templateUrl: './awards.component.html',
  styleUrls: ['./awards.component.scss'],
  animations:[goalkeeperAnimation],
})
export class AwardsComponent implements OnInit {
  @Output() awardNext = new EventEmitter();
  private changeDetectorRef: ChangeDetectorRef;

  
  public count: number = 0;

  @Input('award')
  set item(itm: any) {
	this.changeDetectorRef.detectChanges();
	this.activeItems = [];
	if (itm && this.activeItems[0] != itm) {
	  this.activeItems.push(itm);
	  this.count++;
  }
}

  public activeItems: any[] = [];	

  constructor(changeDetectorRef: ChangeDetectorRef) {
  	this.changeDetectorRef = changeDetectorRef;
  }

  ngOnInit() {
  }

  changeAward(){
    this.awardNext.emit('1');
  }
  
  colorLuminance(hex, lum) {

    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = "#", c, i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i*2,2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += ("00"+c).substr(c.length);
    }

    return rgb;
  }

}
