import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  NgZone,
  Inject,
  LOCALE_ID
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AuthenticationService,
  TopBarService,
  PassDataService,
} from "../_services/index";
import { loginMaterialAnimation } from "../_animations/index";
import { PasswordToggleDirective } from "../_directives/password-toggle.directive";
import { ChatbotService } from "../_services/chatbot.service";
import { Globals } from '../globals';
import { TimeInterval } from "rxjs";

@Component({
  moduleId: module.id,
  templateUrl: "login.component.html",
  styleUrls: ["login.component.scss"],
  animations: [loginMaterialAnimation],
})
export class LoginComponent implements OnInit {
  model: any = {};
  languages: any = [];
  language = "";
  signup_model: any = {};
  loading = false;
  error = "";
  form_errors: any = {};
  state = "";
  animation_var = "start";
  animation_var_child = "start";
  signup_var_done = false;
  signup_var_done_class = "none";
  mode = "";

  constructor(
    private zone: NgZone,
    private chat: ChatbotService,
    private router: Router,
    private route: ActivatedRoute,
    private topBarService: TopBarService,
    private passDataService: PassDataService,
    private authenticationService: AuthenticationService,
    @Inject(LOCALE_ID) private locale: string
    ,private globals:Globals
  ) {
    this.signup_model.sex = "1";
  }

  onForceLoginForm(result: boolean){
    setTimeout(()=>this.zone.run(() => {
      this.animation_var = "triggered";
      this.state = "login";
     }),100);
  }

  goBack(){
    this.animation_var = "triggered";
    this.animation_var_child = "triggered";
    this.state = "";
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (params["mode"]) this.mode = params["mode"];
      if (this.mode == "logout") {
        this.animation_var = "triggered";
        this.animation_var_child = "triggered";
        this.state = "login";
      }
    });
    // reset login status

    this.authenticationService.getLanguages().subscribe((result) => {
      this.languages = result.languages;
      this.language = this.languages.find(language=>language.code===this.locale).code
      if (this.locale=="en-US")
        this.language = "en";
    });

    if (this.passDataService.get("coupon")) {
      // if after start then show signup instant
      this.animation_var = "triggered";
      this.animation_var_child = "triggered";
      this.state = "signup";
    }
    if (this.passDataService.get("remind")) {
      this.animation_var = "triggered";
      this.animation_var_child = "triggered";
      this.state = "login";
    }
    this.topBarService.setBgStyle("main");
    this.topBarService.setShowHamburger(false);
    this.authenticationService.logout();
    this.chat.setShow(false);
 
  }

  ngAfterViewInit() {
  }

  welcome_button(state) {
    this.animation_var = "triggered";
    this.state = state;
  }

  sg_hide() {
    this.animation_var = "login";
    this.error = "";
  }

  endAnimOpen(state) {
    // console.log(state);
    // alert()
    if (state == "triggered") this.animation_var_child = "triggered";
    else {
      this.animation_var_child = "start";
    }
  }

  selectLanguage(code){
    this.language = this.languages.find(language=>language.code==code).code
    var addIndex ="";
    if (this.globals.ios)
                   addIndex="index.html";
               if (code=="en-US")
                   window.location.href="/";
               else
                    if (window.location.href.indexOf('/'+code+'/')==-1)
                            window.location.href = '/'+code+'/'+addIndex;
  }



}
