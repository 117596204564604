import {
    Component, Input, ElementRef, HostListener, OnInit, ViewEncapsulation, ViewChild,
    OnDestroy
} from '@angular/core';
import {VgApiService} from '@videogular/ngx-videogular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'vg-volume-vertical',
  templateUrl: './vg-volume-vertical.component.html',
  styleUrls: ['./vg-volume-vertical.component.scss']
})
export class VgVolumeVerticalComponent implements OnInit, OnDestroy {
    @Input() vgFor: string;
    @ViewChild('volumeBar') volumeBarRef: ElementRef;

    elem: HTMLElement;
    target: any;
    isDragging: boolean;
    mouseDownPosY: number;
    ariaValue: number;

    subscriptions: Subscription[] = [];

    constructor(ref: ElementRef, public API: VgApiService) {
        this.elem = ref.nativeElement;
        this.isDragging = false;
    }

    ngOnInit() {
        if (this.API.isPlayerReady) {
            this.onPlayerReady();
        }
        else {
            this.subscriptions.push(this.API.playerReadyEvent.subscribe(() => this.onPlayerReady()));
        }
    }

    onPlayerReady() {
    	console.log("ready");
        this.target = this.API.getMediaById(this.vgFor);
        this.ariaValue = this.getVolume() * 100;
    }

    onClick(event: { clientY: number }) {
        this.setVolume(this.calculateVolume(event.clientY));
    }

    onMouseDown(event: { clientY: number }) {
        this.mouseDownPosY = event.clientY;
        this.isDragging = true;
    }

    @HostListener('document:mousemove', [ '$event' ])
    onDrag(event: { clientY: number }) {
        if (this.isDragging) {
            this.setVolume(this.calculateVolume(event.clientY));
        }
    }

    @HostListener('document:mouseup', [ '$event' ])
    onStopDrag(event: { clientY: number }) {
        if (this.isDragging) {
            this.isDragging = false;
            if (this.mouseDownPosY === event.clientY) {
                this.setVolume(this.calculateVolume(event.clientY));
            }
        }
    }

    @HostListener('keydown', ['$event'])
    arrowAdjustVolume(event: KeyboardEvent) {
        if (event.keyCode === 38 || event.keyCode === 39) {
            event.preventDefault();
            this.setVolume(Math.max(0, Math.min(100,(this.getVolume() * 100) + 10)));
        }
        else if (event.keyCode === 37 || event.keyCode === 40) {
            event.preventDefault();
            this.setVolume(Math.max(0, Math.min(100,(this.getVolume() * 100) - 10)));
        }
    }

    calculateVolume(mousePosY: number) {
        const recObj = this.volumeBarRef.nativeElement.getBoundingClientRect();
        const volumeBarOffsetLeft: number = recObj.bottom;
        const volumeBarWidth: number = recObj.height;
        console.log(mousePosY,volumeBarOffsetLeft,volumeBarWidth,(mousePosY - volumeBarOffsetLeft) / volumeBarWidth * 100)
        return ((volumeBarOffsetLeft - mousePosY) / volumeBarWidth * 100);
    }

    setVolume(vol: number) {
        this.target.volume = Math.max(0, Math.min(1, vol / 100));
        this.ariaValue = this.target.volume * 100;
    }

    getVolume(): number {
        return this.target ? this.target.volume : 0;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
