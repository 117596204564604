<div class="keeper_container">
	<div class="title_small" i18n>
		Let's track today
	</div>
	<div class="middle_container">
		<div class="tracks_container">
			<div class="track_container" *ngFor="let category of categories">
				<div class="track_category">{{category.name}}</div>
				<div class="track_factors">
					<div class="track_factor" *ngFor="let factor of category.factors">
						<div class="title_factor" [ngClass]="{'opened':tooltip_opened==factor.id}" (click)="toggleTooltip($event,factor.id)">{{factor.name}}
							<div class="tooltip" *ngIf="factor.tooltip && (tooltip_opened==factor.id)">
							{{factor.tooltip}}
							    <div style="padding: 0px;display: block;width: 100%;">
							      <!-- <div class="media">
 									 <div class="media__video">
                                     	<iframe [src]="factor?.video" frameborder="0" allowfullscreen></iframe>
                                     </div>
                                 </div> -->
							    </div>
								<div class="close"><i class="fa fa-times" aria-hidden="true"></i></div>
							</div>
						</div>
						
						<div class="rates_factor">
							<div class="rate_factor" [ngClass]="{'active':track[factor.id]=='3'}" (click)="track[factor.id]=3" ><i class="r-icon-smileFace"></i></div>
							<div class="rate_factor" [ngClass]="{'active':track[factor.id]=='1'}" (click)="track[factor.id]=1" ><i class="r-icon-unsmileFace"></i></div>
							<div class="rate_factor" [ngClass]="{'active':track[factor.id]=='0'}" (click)="track[factor.id]=0 "><i class="r-icon-neutralFace"></i></div>
						</div>
					</div>
				</div>
			</div>

			<div class="track_container" *ngIf="mygoals.length">
				<div class="track_category" i18n>My Goals</div>
				<div class="track_factors">
					<div class="track_factor" *ngFor="let mygoal of mygoals">
						<div class="title_factor" ng-click="toggleClass($event)">{{mygoal.name}}
						</div>
						<div class="rates_factor">
							<div class="rate_factor" [ngClass]="{'active':mygoals_track[mygoal.id]=='3'}" (click)="mygoals_track[mygoal.id]=3" ><i class="icon-happy2"></i></div>
							<div class="rate_factor" [ngClass]="{'active':mygoals_track[mygoal.id]=='1'}" (click)="mygoals_track[mygoal.id]=1"><i class="icon-neutral2"></i></div>
							<div class="rate_factor" [ngClass]="{'active':mygoals_track[mygoal.id]=='0'}" (click)="mygoals_track[mygoal.id]=0" ><i class="icon-sad2"></i></div>
						</div>
					</div>
				</div>
			</div>

		</div>		
	</div>
	<div class="bottom_container">
		<div class="next_button"><i class="r-icon-chevronRight" (click)="goNext()"  aria-hidden="true"></i></div>
	</div>
	<br>
	<br>
	<br>
</div>