import { Component, OnInit, Inject } from '@angular/core';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { PassDataService, DiagnosticService, ModalService, TopBarService, UserService } from '../../_services/index';
import { MixpanelService } from '../../_services/mixpanel.service';
import { ChatbotService } from '../../_services/chatbot.service';
import { diagnosticAnimation, routeAnimation } from '../../_animations/index';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import * as FileSaver from 'file-saver';
import { User } from '../../_models/index';
import { Globals } from '../../globals';
import { GoBackHelper } from '../../_helpers/go-back-helper';

@Component({
  selector: 'app-diagnostic-spiral-solo',
  templateUrl: './diagnostic-spiral-solo.component.html',
  styleUrls: ['./diagnostic-spiral-solo.component.scss'],
  animations: [diagnosticAnimation, routeAnimation],
})
export class DiagnosticSpiralSoloComponent implements OnInit {
  init = 0;
  report_build = 0;
  modals_help: any = {};
  modals_spiral: any = {};
  share_numbers = [{ number: "" }];

  id: number;
  first_run = false
  ratio_open = false;
  spiral_open = false;
  factor_video_modal_show = false;
  welcome_video = true;
  welcome_video_url: any = "";
  help_video = false;
  help_video_url: any = "";
  diagnostic: any = {};
  recommendations: any = { data: [], current: 0 };
  user: User;
  stats: any;
  error = '';
  ios = 0;
  hide_result = 0;
  journey = 0;
  private readonly canGoBack: boolean;

  constructor(private goBackHelper: GoBackHelper, private location: Location, private mixpanelService: MixpanelService, private passDataService: PassDataService, private chat: ChatbotService, private globals: Globals, private userService: UserService, @Inject(DOCUMENT) private document: Document, private sanitizer: DomSanitizer, private router: Router, private route: ActivatedRoute, private diagnosticService: DiagnosticService, private modalService: ModalService, private topBarService: TopBarService) {
  }


  getVideoUrl(code) {
    return code;//rework for custom player
    // if (!code)
    //   return false;
    // return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + code+"?rel=0");
  }

  getTypeFormUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }


  openModal(id) {
    this.mixpanelService.trackButton('', 'Diagnostic result Modal', 'button', { 'modal': id });
    this.modals_help[id] = "main";
    if (id == 'help')
      this.help_video = true;
    this.modalService.open(id)
  }

  closeModal(id) {
    this.modalService.close(id);
  }

  openDutyOfCareModal() {
    this.modalService.open('duty_of_care');
  }

  openGoalkeeperModal(factor) {
    this.diagnosticService.connectToGoalkeeper(factor.keeper_category_id).subscribe(
      data => {
        factor.keeper_category_id = -1;
      },
      error => {
      })
    this.modalService.open('goalkeeper')
  }

  closeGoalkeeperModal() {
    this.modalService.close('goalkeeper')
  }

  openSpiralModal(id) {
    this.mixpanelService.trackButton('', 'Spiral Category Click', 'image', this.stats.categories.find((cat) => cat.name == id));
    this.modalService.open(id)
  }

  closeSpiralModal(id) {
    this.modalService.close(id)
  }

  openFactor(id, factor) {
    this.mixpanelService.trackButton('', 'Spiral Factor Click', 'image', { 'factor_id': id, ...factor });
    this.modals_spiral[id] = factor;
  }

  changeFactorActivityTab(name, index) {
    let _name = 'Spiral Factor Modal Change on Action Click';
    if (index == 1)
      _name = 'Spiral Factor Modal Change on Resources Click';
    this.mixpanelService.trackButton('', _name, 'button', {});
    this.modals_spiral[name].tab = index
  }

  openResource(url, resource) {
    this.mixpanelService.trackButton(url, "Spiral Factor Modal Resource Open", 'button', resource);
    // var ref = window.open(url, "_blank", "location=no");
    if (window['cordova']) {
      if (this.globals.platform == "android")
        window['cordova'].InAppBrowser.open(url, '_system');
      else
        window['cordova'].InAppBrowser.open(url, '_blank', 'location=no');
    } else {
      window.open(url, '_blank');
    }
    // this.modals_spiral[name].tab=index
  }

  trackRouteHelperClick(url: any, name: string, type: string, args: {}, queryParams: {} = {}): void {

    if (typeof (url) === 'string') {
      this.router.navigate([url], { queryParams: queryParams })
    }
    else {
      this.router.navigate(url, { queryParams: queryParams })
      url = url.join('/')
    }

    this.mixpanelService.trackButton(url, name, type, args);
  }


  toggleWelcomeVideo() {
    this.welcome_video = (this.welcome_video) ? false : true;
    //  console.log(this.welcome_video,this.welcome_video_url)
  }

  openHint(id, state) {
    if (this.modals_help[id] == state)
      this.modals_help[id] = "main";
    else
      this.modals_help[id] = state;
  }

  moveToFactor(stats, factor_name) {
    const _factors = stats.categories.reduce((acc, cat) => acc.concat(cat.factors), [])
    const fact = _factors.find(fac => fac.name.trim() == factor_name.trim())
    // console.log('aaa',_factors, fact)
    if (fact) {
      this.openSpiralModal(fact.cat_name)
      this.openFactor(fact.cat_name, fact)
    }
  }
  filterResources(resources) {
    // return resources
    return resources.filter(item => !item.url.includes("resiliencei"))
  }
  ngOnInit() {
    //load stats from stats.json
    fetch('./assets/stats.json').then(res => res.json()).then(json => {
      this.stats = json.results.stats;
      console.log('STTTTAAATTS', this.stats.ratio)
      var ratio_deg = this.stats.ratio.degree;
      this.stats.ratio.degree = -88;
      for (const group of this.stats.groups) {
        this.modals_help[group.name] = "main";
        this.recommendations.data = this.recommendations.data.concat(group.action_tips);
      }
      for (const category of this.stats.categories) {
        this.modals_spiral[category.name] = "main";
        for (const factor of category.factors) {
          factor.video = this.getVideoUrl(factor.video)
        }

      }
      fetch('./assets/diagnostic.json').then(res => res.json()).then(json => {
        
        this.diagnostic = json.results.diagnostic;
        this.welcome_video_url = this.getVideoUrl( this.diagnostic.version.video);
        this.help_video_url = this.getVideoUrl( this.diagnostic.version.help_video);
        this.init = 1;
        console.log('DIAGNOSTIC', this.diagnostic)
      }  );
      // let group of stats.groups
      setTimeout(() => this.stats.ratio.degree = ratio_deg, 1000)
    });
    
  

  }

  animationFactorStart($event) {
    var el = $event.element;
    while ((el = el.parentElement) && !el.classList.contains("app_modal"));
    if (el)
      el.scroll({
        top: 0, // could be negative value
        left: 0,
        behavior: 'smooth'
      });

    this.factor_video_modal_show = false;
  }

  animationFactorDone($event) {
    this.factor_video_modal_show = true;
  }

  addShareRow() {
    this.share_numbers.push({ number: "" });
  }

  deleteShareRow(item) {
    var index = this.share_numbers.indexOf(item);
    this.share_numbers.splice(index, 1);
  }

  recSliderIndex(i, event) {
    this.recommendations.current = i;
    this.mixpanelService.trackButton('', 'Diagnostic Result Recommendation Select', 'pagination', { 'page': i });
  }

  recSliderRight(event) {
    var count = this.recommendations.data.length - 1;

    if (this.recommendations.current - 1 < 0)
      this.recommendations.current = count;
    else
      this.recommendations.current--;


    return false;
  }

  recSliderLeft(event) {

    var count = this.recommendations.data.length - 1;

    if (this.recommendations.current + 1 > count)
      this.recommendations.current = 0;
    else
      this.recommendations.current++;


    return false;
  }

  sendShare() {
    this.diagnosticService.shareResult(this.id, this.share_numbers).subscribe(
      data => {
        this.share_numbers = [{ number: "" }];
      },
      error => {
        console.log("error", error)
        this.error = error;
      })

    this.modalService.close("share_report")
  }

  downloadPdf() {
    this.diagnosticService.getDiagnosticPdf(this.id).subscribe(
      data => {
        // console.log(data)
        this.downloadFile(data)
      },
      error => {
        console.log("error", error)
        this.error = error;
      })
  }

  downloadFile(data: any) {
    var blob = new Blob([data], { type: 'application/pdf' });
    var utc = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
    FileSaver.saveAs(blob, "Diagnostic_" + this.user.lastName + "_" + utc + ".pdf");
    // var url= window.URL.createObjectURL(blob);
    // console.log("pdf",url);
    // window.open(url);
  }

  spiralOpenHelp() {
    let element = document.getElementById('Spirit in Action');

    var childOffset = {
      top: element.offsetTop,
      left: element.offsetLeft,
      height: element.clientHeight,
      width: element.clientWidth,
    }
  }


  goBack() {
    if (!this.journey)
      this.router.navigate(['home']);
    else
      this.router.navigate(['journey', this.journey]);
  }

}
