import { Component, OnInit, Inject, ViewChild, HostBinding, NgZone } from '@angular/core';
import { AuthenticationService, TopBarService, PassDataService, UserService, PushNotificationService, ModalService } from './_services/index';
import { routeAnimation } from './_animations/index';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ChatbotService } from './_services/chatbot.service';
import { MixpanelService } from './_services/mixpanel.service';
import { Globals } from './globals';
import { environment } from '../environments/environment';
import { GoBackHelper } from './_helpers/go-back-helper';



import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Observable, interval,Subscription } from 'rxjs';
import 'rxjs/add/observable/interval';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { catchError, map, distinctUntilChanged } from 'rxjs/operators';
import { UAParser } from 'ua-parser-js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  moduleId: module.id,
  animations: [routeAnimation],
})

export class AppComponent implements OnInit {
  title = 'app';
  unseen = 0;
  error = "";
  isLoggedIn = true;
  sidenav_status = 'closed';
  showHamburger: boolean;
  stopScroll: boolean = false;
  bgStyle: string;
  @ViewChild('main') main;
  @ViewChild('sidenav') sidenav;
  notification: any = {};
  greetings: any = {};
  flowbot_config = [];
  meditation: number = 0;
  routeChange: Subscription;
  // @HostBinding('class.noFooter') noFooter: boolean = false;

  constructor(private goBackHelper:GoBackHelper,private mixpanelService: MixpanelService, private passDataService: PassDataService, public zone: NgZone, public globals: Globals, public chat: ChatbotService, @Inject(DOCUMENT) private document: Document, private pushService: PushNotificationService, private authService: AuthenticationService, private userService: UserService, private topBarService: TopBarService, private router: Router, private modalService: ModalService) {
  //   this.routeChange = router.events.subscribe((event) => { //todo: not for now
  //     if (event instanceof NavigationEnd) {
  //       console.log("smth changed check", event)
  //       // setTimeout(()=>modalService.list(),100)
  //       // setTimeout(()=>modalService.list(),5000)
  //     }
  // });
  }

  swipe(event) {
    // console.log(event.target.className,event.srcEvent.target.className)
    if (event.srcEvent.target.className.indexOf("slide") !== -1)
      return false;
    this.sidenav.open();
  }

  languageReDirector(language_code) {
    var oldURL = document.referrer;
    var userLang = 'en-US'
    var addIndex = "";
    if (this.globals.ios)
      addIndex = "index.html";

    if (language_code) {
      userLang = language_code;
    } else {
      userLang = navigator.language;
    }

    var languages = ['es', 'fr', 'de', 'nl', 'zh', 'pl']

    if (new RegExp(languages.join("|")).test(oldURL)) {//prevent redirection loop
      if (!new RegExp(languages.join("|")).test(window.location.href)) {
        return false;
      }
    }
    if (window.location.href == oldURL)
      return false;

    for (var i in languages) {
      var language = languages[i]
      if (window.location.href.indexOf('/' + language + '/') == -1)
        if (userLang.indexOf(language) !== -1) {
          let pathname = window.location.pathname;
          pathname = pathname.replace(/(\/.{2})\//, '/');

          if (pathname && pathname !== '/') {
            this.passDataService.set('redirect', pathname, true)
          }
          window.location.href = '/' + language + '/' + addIndex;
        }
    }
  }

  goTo(url) {
    this.modalService.close('push-notification')
    this.router.navigate([url]);
  }

  initFlowbotConfig(config) {
    this.flowbot_config = config;
    this.nextFlowbotEvent();
  }

  nextFlowbotEvent() {
    if (this.flowbot_config.length <= 0)
      return false;

    let current = this.flowbot_config.shift();
    switch (current.type) {
      case "diagnostic_tips":
        this.chat.setMode(this.chat.DIAGNOSTIC_TIPS_MODE);
        // this.chat.botLocalMessage(current.coupon_name+":");
        this.chat.botLocalMessage(current.tip);
        if (current.replies)
          this.chat.botLocalMessage('', 2, { replies: current.replies });
        this.chat.setData(this.chat.mode.getValue(), { 'factor_id': current.factor_id, 'more': 1 })

        break;
      case "flowbot_scheduled":
        this.chat.setMode(this.chat.FLOWBOT_SCHEDULED);
        this.chat.botLocalMessage(current.text);
        if (current.payload !== "undefined")
          for (let payload of current.payload) {
            this.chat.botLocalMessage("", payload.type, payload);
          }
        this.chat.setMode(this.chat.NORMAL_MODE);
        break;
      default:
        break;
    }
  }

  onResume = () => {
    setTimeout(() => {
      if (this.chat.mode.getValue() == this.chat.NORMAL_MODE)
        this.userConfigLoad();
    }, 1500)
  }

  onBlur = () => {

  }

  navBarActive = (name, strict = false) => {
    
    if (strict) {
      // console.log('aaaa',window.location.origin + name,window.location.href,window.location.host + name == window.location.href)
      if (window.location.origin + name == window.location.href)
        return 'active'
      else
        return false;
    } else {
      if (window.location.href.includes(name))
        return 'active'
      else
        return '';
    }

  }

  userConfigLoad() {
    this.userService.getUserConfiguration()
      .subscribe(configuration => {
        
        setTimeout(() => {
          this.chat.setShow(configuration.chatbot)
          if (configuration.feedback) {
            this.chat.setMode(this.chat.FEEDBACK_MODE)
            setTimeout(() => {
              this.chat.feedbackBotMessage(configuration.feedback)
            }, 1500)

          } else
            setTimeout(() => {
              this.initFlowbotConfig(configuration.flowbot_arr)
            }, 1500)

          if (configuration.journey_only) {
            this.globals.features['journey'] = configuration.last_journey;
            this.globals.features['weekly_tips'] = configuration.weekly_tips;
          } else {
            this.globals.features['resources'] = configuration.show_resources;
            this.globals.features['goalkeeper'] = configuration.goalkeeper;
            this.globals.features['meditations'] = configuration.meditations;
            this.meditation = configuration.meditations;
            this.globals.features['diagnostic'] = configuration.diagnostic;
            this.globals.features['videos'] = configuration.videos;
            this.globals.features['journey'] = configuration.last_journey;
            this.globals.features['weekly_tips'] = configuration.weekly_tips;
            this.globals.features['ecombat'] = configuration.ecombat;
            this.globals.features['icalm'] = configuration.icalm;
            this.globals.features['coach'] = configuration.coach;
            this.globals.features['hidefitbit'] = configuration.hidefitbit;
          }

        }, 300)
        this.languageReDirector(configuration.language_code)
      },
        error => {

        });
  }

  closeGreetings() {
    this.modalService.close('greetings')
  }

  ngOnInit() {
    const parser = new UAParser();
    const result = parser.getResult();
    
    this.mixpanelService.init(environment.mixpanel_token, environment.version, environment.platform, environment.production)
    // setTimeout(()=>{
    //   this.modalService.open('greetings',this.greetings)
    // },5000)
    
    
    // this.mixpanelService.track('test ver 1',{'button_name':'asd','url':'ccc'})
    // this.mixpanelService.timeEvent('start_view')

    if (['Explorer', 'IE'].some(browser => result.browser.name.includes(browser)))
      alert("Your browser is not currently supported. Please use the latest version of Chrome, Opera, Firefox, Safari or Edge");

    // setTimeout(() =>  console.log('tut cordova before 20 sec',(typeof window['cordova'] !== 'undefined'),(typeof window['cutout'] !== 'undefined')), 20000);
    // console.log('tut cordova first',(typeof window['cordova'] !== 'undefined'),(typeof window['cutout'] !== 'undefined'))
    setTimeout(() => {
      if (typeof window['cordova'] !== 'undefined') {
        // console.log('tut cordova before');
        if (typeof window['cutout'] !== 'undefined') {
          // console.log('tut cutout');
          window['cutout'].has().then((result) => {
            this.zone.run(() => setTimeout(() => this.globals.cutout = result, 150))
            // console.log('asdfasfsd',this.globals.cutout,result);
          });
        }
        document.addEventListener("resume", this.onResume, false);
        window.addEventListener("blur", this.onBlur);
        // document.addEventListener("backbutton", (e)=>{
        //   e.preventDefault()
        //   e.stopPropagation()
          
        //   this.goBackHelper.goBackBuilds()
        //   alert(1)
        // }, false);

        if (typeof window['Keyboard'] !== 'undefined')
          window['Keyboard'].hideFormAccessoryBar(false);
      }
      this.pushService.subscribeToNativePush()
    }, 2000)




    // 

    setTimeout(() => {
      this.modalService.get('push-notification').payload$
        .subscribe(payload => this.notification = payload)
    }, 500)
    setTimeout(() => {
      this.modalService.get('greetings').payload$
        .subscribe(payload => this.greetings = payload)
    }, 100)
    // this.pushService.subscribeToNativePush()
    // this.pushService.subscribeToPush()

    this.authService.logged$.pipe(distinctUntilChanged())
      .subscribe((logged) => {
        // console.log('LOGGED',logged)
        if (logged) {
          this.isLoggedIn = true; // {2}
          this.userConfigLoad();
          this.userService.getUser().subscribe((data) => {
            this.mixpanelService.registerUserIdentificator(data.username)
          });

          //app time tracking
          // this.userService.getUnseenNotifications()
          //   .subscribe(
          //     count => {
          //       this.unseen = count;
          //     },
          //     error => {
          //       this.error = error;
          //     });

          // interval(20 * 1000).subscribe(x => {
          //   this.userService.getUnseenNotifications()
          //     .subscribe(
          //       count => {
          //         this.unseen = count;
          //       },
          //       error => {
          //         this.error = error;
          //       });
          // })

        } else {
          this.isLoggedIn = false;
        }
      });


    // this.authService.logged$.distinctUntilChanged()
    // .subscribe((logged) => {
    //   console.log('LOGGED',logged)
    //   if (logged){
    //         this.isLoggedIn = true; // {2}
    //         this.userConfigLoad();
    //   }else{
    //     this.isLoggedIn = false;
    //   }
    //   });



    this.chat.mode.asObservable().pipe(distinctUntilChanged())
      .subscribe((mode) => {
        if (mode == this.chat.NORMAL_MODE) {
          this.nextFlowbotEvent()
        }
      });

    // this.chat.mode.asObservable().distinctUntilChanged()
    // .subscribe((mode) => {
    //   if (mode==this.chat.NORMAL_MODE){
    //         this.nextFlowbotEvent()
    //   }
    //   });







    // if (!this.authService.isTokenExpired()) {
    //        this.isLoggedIn = true; // {2}
    //          this.userService.getUserConfiguration()
    //          .subscribe(configuration => {
    //            this.chat.setShow(configuration.chatbot)
    //            if (configuration.feedback){
    //               this.chat.setFeedback(true)
    //               setTimeout(() => {
    //                  this.chat.feedbackBotMessage(configuration.feedback) 
    //                }, 1500)

    //            }

    //            this.show_resources=configuration.show_resources;
    //            // this.languageReDirector(configuration.language_code)
    //          },
    //          error => {

    //            });
    //      }
    //      else{
    //       this.isLoggedIn = false; // {2}
    //      }
    console.log(this.isLoggedIn)

    // this.topBarService.setShowHamburger(true);
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // this.topBarService.setShowHamburger(true);
          // this.main.nativeElement.scrollTop = 0
          this.main.nativeElement.scrollTo({top:0, behavior: 'instant'});
        }
      });

    this.topBarService.bgStyle$
      .subscribe(bgStyle => setTimeout(() => this.bgStyle = bgStyle, 0))

    this.modalService.stopScroll$
       .subscribe(stopScroll =>  setTimeout(() => this.stopScroll = stopScroll, 0))

    // this.topBarService.showHamburger$
    //   .subscribe(showHamburger =>  setTimeout(() => this.showHamburger = showHamburger, 0))

    //         Observable
    //   .interval(20*1000)
    //   .timeInterval()
    //   .flatMap(() =>  this.userService.getUnseenNotifications())
    //   .subscribe(
    //         count => {
    //           this.unseen = count;
    //       },
    //       error => {
    //           this.error = error ;
    //       });
    // }
  }

  chooseBG(big){
    const map = {
      'all':['/assets/img/natman.jpg','/assets/img/big-natman-test.png'],
      // 'all':'/assets/img/big-natman-test.png',
      'meditate':'/assets/img/mbg.jpg',
      'video':'/assets/img/videos.jpeg',
      'resources':'/assets/img/resources.jpg',
    }
    
    for (const _m in map){
      const bg = map[_m];
      const explode = _m.split(',')
      for (const _address of explode){
        if (window.location.href.includes(_address))
        if (!Array.isArray(bg)){
          return bg
        }else{
          if (big)
            return bg[1]
          return bg[0]
        }
        
      }
    }
    
    if (!Array.isArray(map['all'])){
      return map['all']
    }else{
      if (big)
        return map['all'][1]
      return map['all'][0]
    }
  }
}

