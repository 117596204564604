import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { AuthenticationService } from '../../_services/index';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  model: any = {};
  token: string;
    error = '';

  constructor(private route: ActivatedRoute,private router: Router, private authenticationService: AuthenticationService) { }

  ngOnInit() {
  	this.route.params.subscribe(params => {
       this.token = params['token']; // (+) converts string 'id' to a number
    });
  }

  resetPassword() {
       this.authenticationService.resetPassword(this.token,this.model.password,this.model.again_password)
        .subscribe(result => {
            this.error = '' ;
            this.router.navigate(['/']);
        },
        error => {
            this.error = error ;
        });		
  }
}
