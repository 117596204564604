import { Component, Input, OnInit, HostListener,OnDestroy } from '@angular/core';
import { ModalService } from '../../_services/index';
import {BehaviorSubject} from 'rxjs';
import { Globals } from '../../globals';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit,OnDestroy {
	  @Input() modalId: string;
	  @Input() modalTitle: string;
	  @Input() blocking = false;
	  @Input() noHeader = false;
	  @Input() noPadding = false;
	  @Input() hideClose = false;
	  @Input() zIndex = false;
	  @Input() needAbsolute = false;
	  @Input() closeCallbackFunction: () => void;
	  isOpen = false;
	  private payload = new BehaviorSubject<any>({}); // true is your initial value
	  payload$ = this.payload.asObservable();

	  setPayload(value) {
	    this.payload.next(value);
	  }

	  @HostListener('keyup') onMouseEnter(event) {
	    this.keyup(event);
	  }

	  constructor(public globals:Globals, private modalService: ModalService) {
	  }

	  ngOnInit() {
	    this.modalService.registerModal(this);
	    console.log(this.modalId)
	  }
	  ngOnDestroy(): void {
		//   console.log("Modal destroyed", this.modalId)
		  this.modalService.close(this.modalId); // close modal after destroy to avoid problem with global modals service and stuck scroll 
	  }

	  close(checkBlocking = false): void {
		if (this.closeCallbackFunction)
			this.closeCallbackFunction();
	    this.modalService.close(this.modalId, checkBlocking);
	  }

	  private keyup(event: KeyboardEvent): void {
	    if (event && event.keyCode === 27) {
	      this.modalService.close(this.modalId, true);
	    }
	  }
}