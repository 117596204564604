import { Component, OnInit,Input, Output, EventEmitter  } from '@angular/core';
import { DiagnosticService,ModalService, TopBarService,VideoService } from '../../_services/index';
import { diagnosticAnimation } from '../../_animations/index';

@Component({
  selector: 'app-video-quiz',
  templateUrl: './video-quiz.component.html',
  styleUrls: ['./video-quiz.component.scss'],
  animations:[diagnosticAnimation],
})
export class VideoQuizComponent implements OnInit {
 
 	error = "";
	@Output() quizEnded = new EventEmitter();

    @Input('quiz')
    public quiz:any = {};

    question_selected:any={};
    question_cursor = 0;
    highlights:any = {};
    mode = 0;
    award:any=false;

	@Input('quiz')
	set item(itm: any) {
		this.quiz=itm;
		this.question_selected=itm.questions[0];
		if (this.quiz.result.answers.length==0)
			this.quiz.result.answers={};
	}



  constructor(private videoService:VideoService) { }

  ngOnInit() {
    
  }

  chooseOption(q_id,o_id) {
   	this.quiz.result.answers[q_id]=o_id;
  }

  chooseQuestion(cursor) {
   	 this.question_selected=	this.quiz.questions[cursor];
   	 this.question_cursor=cursor;
  }


  endQuiz(cursor) {
   	 this.videoService.endQuiz(this.quiz.id,this.quiz.result.answers).subscribe(
	      result => {
          if (result.success){
            this.quiz.result.finished=1;
            this.mode=1;
            setTimeout(()=>{ 
              this.award={"name":"Quiz completed perfectly!","color":"#8cd6ff","icon_color":"#fbe953","icon":"fa-star","info":"Quiz completed perfectly","title_text":"Congratulations!"};
            },1000);
            
          }else
          {
            this.mode=2;
            this.highlights=result.results;
          }
             console.log(result)
          },
	        error => {
	            this.error = error ;
	          console.log("error",this.error)
	  })
  }

    tryAgain() {
      this.chooseQuestion(0);
      this.mode=0; 
    }

    quitFromQuiz() {
      this.quizEnded.emit(1);
      this.chooseQuestion(0);
      this.mode=0; 
    }
    
    finishQuiz() {
      
    }

    handleAwardNext(award) {
      this.quizEnded.emit(1);
      this.mode=0; 
    }

  chooseNextQuestion() {
   	 for (var i = 0; i < this.quiz.questions.length; ++i) {
   	 	if (this.quiz.questions[i].id==this.question_selected.id)
   	 	{
   	 		 this.question_selected=this.quiz.questions[i+1];
   	 		 this.question_cursor=i+1;
   	 		 break;
   	 	}
   	 		
   	 }
  }
}
