<section class="page videos_watch">
  <div class="main_container blurry">
    <div class="grid">
      <div class="top_nav">
        <div class="back_button" (click)="goBack()">
          <span class="material-symbols-rounded"> arrow_back_ios_new </span>
          <span i18n>Back</span>
        </div>
      </div>
      <h2 class="title">
        {{ series.title }}
      </h2>
      <div
        class="video_animation_pool"
        style="white-space: nowrap; overflow: hidden; width: 100%"
      >
        <div #videoTarget class="video" *ngFor="let episode of selected_episode">
          <div class="video_container">
            <vg-player
              (dblclick)="doubleClickFunction()"
              (onPlayerReady)="onPlayerReady($event)"
            >
              <vg-overlay-play></vg-overlay-play>
              <vg-buffering></vg-buffering>

              <vg-scrub-bar *ngIf="fullscreen">
                <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
              </vg-scrub-bar>

              <vg-controls
                [vgAutohide]="true"
                [ngClass]="{ 'vg-controls': !fullscreen }"
              >
                <!--  <vg-time-display *ngIf="fullscreen" vgProperty="current" vgFormat="mm:ss"></vg-time-display> -->
                <vg-volume-vertical
                  style="pointer-events: initial"
                ></vg-volume-vertical>

                <div
                  *ngIf="fullscreen"
                  style="
                    width: 100%;
                    background-color: rgba(134, 132, 132, 0.5);
                  "
                >
                  <vg-play-next-prev></vg-play-next-prev>
                </div>
              </vg-controls>

              <video
                [vgMedia]="media"
                [poster]="'.\\assets\\img\\video_placeholder.jpg'"
                style="object-fit: inherit"
                #media
                id="singleVideo"
                preload="auto"
              >
                <source [src]="episode.video" type="video/mp4" />
                <!-- <source [src]="'http://static.videogular.com/assets/videos/videogular.mp4'" type="video/mp4">   -->
              </video>
            </vg-player>
          </div>

          <div class="control_bar">
            <app-video-watch-seeker
              [buffer]="buffered"
              [percent]="percent"
              *ngIf="api !== 'undefined'"
              [api]="api"
            ></app-video-watch-seeker>
            <!-- 	<div class="seek" role="slider">
                        <div class="buffer" style="width: 85%;"></div>
                        <div class="watched" style="width: 75%;"><i class="handle"></i></div>
                    </div> -->
            <div class="timestamps">
              <div class="current_time">{{ current }}</div>
              <div class="full_time">{{ duration }}</div>
            </div>
            <div class="video_title">
              <div class="video_title__name">
                {{ episode.title }}
              </div>
              <div class="video_title__author">{{ episode.author }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="episodes_table">
        <div
          class="videos-player"
          (click)="chooseEpisode(i)"
          [ngClass]="{ active: episode.id == selected_episode[0].id }"
          *ngFor="let episode of episodes; let i = index"
        >
          <div class="videos-player__main_container">
            <div
              class="videos-player__icon"
              [ngClass]="{ 'icon-padding': started }"
            >
            <span
                [ngStyle]="{ 'font-size': '1.5rem' }"
                *ngIf="episode.id !== selected_episode[0].id&&!episode.viewed"
                class="r-icon-play"
              >
              </span>
              <span
                [ngStyle]="{ 'font-size': '1.5rem'}"
                *ngIf="episode.id == selected_episode[0].id && pause"
                class="r-icon-play"
              >
              </span>
              <span
                *ngIf="episode.id == selected_episode[0].id && !pause"
                class="material-symbols-rounded"
              >
                pause
              </span>
              <span class="material-symbols-rounded video_viewed" *ngIf="episode.id !== selected_episode[0].id&&episode.viewed">
                check_circle
                </span>
            </div>
            <div class="videos-player__title_container">
              <div class="videos-player__title">
                {{ episode.title }}
              </div>
              <div class="videos-player__duration"></div>
            </div>
          </div>
        </div>
        <!-- *ngIf="episode.id !== selected_episode[0].id && episode.viewed" -->
      </div>
    </div>
  </div>
</section>

<!-- <div *ngIf="quiz" class="row">
    <div class="number">{{ episodes.length + 1 }}.</div>
    <div class="title">{{ quiz.name }}</div>
    <div class="status">
      <div class="item_button">
        <button
          *ngIf="!quiz.result.finished"
          [disabled]="quiz.result.finished"
          (click)="openQuiz()"
          class="lazy-button"
        >
          Start
        </button>
        <button
          *ngIf="quiz.result.finished"
          [disabled]="quiz.result.finished"
          (click)="openQuiz()"
          class="lazy-button"
          i18n
        >
          Done
        </button>
      </div>
    </div>
    <div class="line"></div>
  </div> -->

<app-modal *ngIf="quiz" [blocking]="false" [modalId]="'quiz'">
  <app-video-quiz
    (quizEnded)="handleQuizEnded($event)"
    [quiz]="quiz"
  ></app-video-quiz>
</app-modal>

<ng-container *ngFor="let episode of episodes">
  <app-modal *ngIf="episode.title" [blocking]="false" [modalId]="episode.title">
    <div class="help_guide">
      <div class="title_guide">{{ episode.title }}</div>
      <div class="main_text_container">
        <div class="main_text" [innerHTML]="episode.help_text"></div>
        <br /><br />
      </div>
    </div>
  </app-modal>
</ng-container>
