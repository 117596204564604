import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'hammerjs';

if (environment.production) {
  enableProdMode();
}


const bootstrap = () => {
  platformBrowserDynamic().bootstrapModule(AppModule);
};

if (typeof window['cordova'] !== 'undefined') {
  document.addEventListener('deviceready', () => {
    bootstrap();
    // window.open = window['cordova'].InAppBrowser.open;
  }, false);
} else {
  bootstrap();
}

// // applications
// console.log('wiiindoow',window)
//  if (typeof window['cordova'] !== 'undefined') {
// 	document.addEventListener("deviceready", () => 
// 	  platformBrowserDynamic().bootstrapModule(AppModule)
// 	  .catch(err => console.log(err)), false);
// }else{
// 	platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.log(err));
// }

