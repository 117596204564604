import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/index';
import { routeAnimation } from '../_animations/index';
import { MixpanelService } from '../_services/mixpanel.service';
import { Globals } from '../globals';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
  animations: [routeAnimation],
  // host: { '[@fadeRoute]': '' }
})
export class ResourcesComponent implements OnInit {


  resources: any = [];

  constructor(private globals: Globals, private mixpanelService: MixpanelService, private userService: UserService,) { }

  ngOnInit() {
    this.mixpanelService.trackView('Resources Page', 'view', {});
    this.userService.getUserResources()
      .subscribe(data => {
        this.resources = data.resources;
      });
  }

  prepareTitleArr(text) {
    return text.split('-').map(item => item.trim())
  }

  openInNewTab(url) {
    if (window['cordova']) {
      if (this.globals.platform == "android")
        window['cordova'].InAppBrowser.open(url, '_system');
      else
        window['cordova'].InAppBrowser.open(url, '_blank', 'location=no');
    } else {
      window.open(url, '_blank');
    }
  }

}
