import {catchError, map} from 'rxjs/operators';
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable,LOCALE_ID,Inject } from '@angular/core';
import { Router} from '@angular/router';

import { Globals } from '../globals';
import { SwPush } from '@angular/service-worker';
import { ApiService, ModalService } from './index';


@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
    native_token={registrationId:""};

    constructor(private modalService:ModalService, private globals: Globals, private swPush: SwPush, private apiService: ApiService,@Inject(LOCALE_ID) locale: string, private router:Router) {
    }

    notificationHandler(data) {
      if (data['additionalData']['foreground'])
      { 
        if (data['additionalData']['data']['url']!=='undefined')
          this.modalService.open('push-notification',{body:data['message'], url:data['additionalData']['data']['url']})
        else
          this.modalService.open('push-notification',{body:data['message'], url:null})
      }else{
        if (data['additionalData']['data']['url']!=='undefined')
          this.router.navigate([data['additionalData']['data']['url']]);
      }

    }

    subscribeToNativePush() {
      
        if (typeof window['cordova'] !== 'undefined') {
        let push = PushNotification.init({
          android: {
            
            },
          ios: {
              alert: "true",
              badge: true,
              sound: "true",
              "clearBadge": true
          },
          browser: {
           
        },
        windows: {}
        });
         
        push.on('registration', (data) => {
          this.native_token = data
          console.log('registration', data);
        });

        push.on('notification', data => {
          this.notificationHandler(data)
          // console.log('notification', data);
        });

        push.on('error', (e) => {
          // console.log('error notifictaion', e.message);
        });
        // console.log("NATIVE PUSH SYSTEM:",push);
      }
    }

    subscribeToPush() {

    // Requesting messaging service to subscribe current client (browser)
    this.swPush.requestSubscription({
      serverPublicKey: this.globals.web_push_public_key
    })
      .then(pushSubscription => {
        // Passing subscription object to our backend
        this.addSubscriber(pushSubscription)
          .subscribe(
          res => {
            // console.log('[App] Add subscriber request answer', res)
          },
          err => {
            // console.log('[App] Add subscriber request failed', err)
          }

          )
      })
      .catch(err => {
        console.error(err);
      })

  }

  addSubscriber(pushSubscription): Observable<any> {
  	// console.log('push',pushSubscription)
       return this.apiService.post("push/add",{pushSubscription:pushSubscription}).pipe(
         map((response: any) => response.json() ),
       catchError((error:any) => {
            return observableThrowError(error.error.json().results.error);
        }),)
    }

}