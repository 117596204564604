import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { ModalService, TopBarService, UserService } from '../_services/index';
import { MixpanelService } from '../_services/mixpanel.service';
import { routeAnimation, videoAnimation } from '../_animations/index';
import { Globals } from '../globals';
import { animate } from '@angular/animations';
import { GoBackHelper } from '../_helpers/go-back-helper';

enum CALM_MODE {
  Init = 0,
  Prepare = 1,
  Prepare2 = 11,
  In = 2,
  Out = 3,
  DelayIn = 4,
  DelayOut = 5,
  Ended = 6
}

@Component({
  selector: 'app-instant-calm',
  templateUrl: './instant-calm.component.html',
  styleUrls: ['./instant-calm.component.scss'],
  animations: [routeAnimation, videoAnimation],
  // host: { '[@fadeRoute]': '' }
})

export class InstantCalmComponent implements OnInit, AfterViewInit {


  // @ViewChild('audioOption') audioPlayerRef: ElementRef;

  scale = 1;
  maxScale = 5;
  ringSize = 4.3;
  ringExSize = 5;
  initScale=0;
  animationDuration = 5000; // 5 seconds
  easing:any = [0, .25, .45, 1.0]; // cubic-bezier(0.25, 0.1, 0.25, 1.0)
  startTime: number;
  lastTime: number;
  steps: number;
  timePrepare = 4000;
  // timeIn = 3600;
  // timeOut = 5600;
  timeIn = 4300;
  timeOut = 6300;
  delayIn = 200;
  delayOut = 200;
  currentCount=0;
  currentTime=0;
  currentDelay=0;

  help_text = "";
  mode = CALM_MODE.Init;
  init = 0;
  count = 0;
  options: any;
  calm_process: any;
  calm_process_interval: any;
  calm_process_animation: any;
  selected_option = { 'sound': '', 'color': 'animate' };
  sound_options = { arr: [], obj: {} };
  audio_source: string = "";
  @ViewChild('audioElement', { static: false }) public _audioRef: ElementRef;
  private audio: HTMLMediaElement;
  private readonly canGoBack: boolean;


  selectOption(option, value) {
    this.selected_option[option] = value
    switch (option) {
      case 'sound':
        this.audio.pause()
        this.audio_source = this.sound_options.obj[value]['url'];
        this.audio.load()
        setTimeout(() => {
          this.audio.play()
        }, 500)
        break;
      default:
        break;
    }
  }
  between(min, max) {
    return Math.random() * (max - min) + min;
  }
  //
  changeMode(mode) {
    this.mode = mode;
    console.log('AAaaOPTIONS!!!', this.options)
    switch (mode) {
      case CALM_MODE.Init:
        this.changeHelpText(this.options['instant_calm_init_text']['value'])
        break;
      case CALM_MODE.Prepare:
        this.changeHelpText(this.options['instant_calm_prepare_text']['value'])
        break;
      case CALM_MODE.Prepare2:
        this.changeHelpText(this.options['instant_calm_prepare2_text']['value'])
        break;
      case CALM_MODE.In:
        this.changeHelpText(this.options['instant_calm_breath_in_text']['value'])
        break;
      case CALM_MODE.Out:
        this.changeHelpText(this.options['instant_calm_breath_out_text']['value'])
        break;
      case CALM_MODE.Ended:
        this.changeHelpText(this.options['instant_calm_ended_text']['value'])
        break;

      default:
        break;
    }
  }
  changeHelpText(text) {
    this.help_text = "";
    setTimeout(() => {
      this.help_text = text
    }, 300)
  }

  startCalm(count) {
    console.log('startCalm', count, this.mode)
    if ((this.mode !== CALM_MODE.Init) && (this.mode !== CALM_MODE.Ended))
      return false
    this.changeMode(CALM_MODE.Prepare)
    this.calm_process = setTimeout(() => {
      clearTimeout(this.calm_process)
      this.calmProccess(this.count)
    }, this.timePrepare)
  }

  startCalmCss(count) {
    console.log('startCalm', count, this.mode)
    this.mixpanelService.trackButton('','Instant Calm Start','button',{count:count});
    if ((this.mode !== CALM_MODE.Init) && (this.mode !== CALM_MODE.Ended))
      return false
    this.changeMode(CALM_MODE.Prepare)
    this.calm_process = setTimeout(() => {
      clearTimeout(this.calm_process)
      this.calmProccessCss(this.count)
    }, this.timePrepare)
  }

  constructor(public mixpanelService:MixpanelService, private goBackHelper:GoBackHelper,private location:Location, private router: Router, private route: ActivatedRoute,public globals: Globals, private userService: UserService) {
    this.canGoBack = !!(this.router.getCurrentNavigation()?.previousNavigation);
  }
  ngOnDestroy(): void {
    clearInterval(this.calm_process_interval)
    clearTimeout(this.calm_process)
    cancelAnimationFrame(this.calm_process_animation);
  }

  startAn(){
    if (this.scale>=this.maxScale){
      this.scale=this.maxScale;
      this.maxScale=this.initScale;
    }
    this.startTime = performance.now();
    this.initScale=this.scale;
    const updateInterval = 1000 / 60; // 60 frames per second
    const framesPerUpdate = 1000 * updateInterval / this.animationDuration;
    this.steps = (this.maxScale - this.scale) / framesPerUpdate;
    this.animate = this.animate.bind(this);
    requestAnimationFrame(this.animate);
  }

  ngAfterViewInit() {
    this.audio = this._audioRef.nativeElement;
  }

  ngOnInit(): void {
    this.mixpanelService.trackView('Instant Calm Page','view');
    this.userService.getInstantCalm().subscribe(
      (result) => {
        this.init = 1;
        this.options = result.options;

        this.sound_options['arr'] = result.sounds
        let i = 0;
        for (let sound of result.sounds) {
          if (i == 0)
            this.selected_option['sound'] = sound.id;
          console.log('sound', sound.name, sound.url)
          this.sound_options['obj'][sound.id] = { 'name': sound.name, 'url': sound.url };
          i++;
        }

        console.log('sounds', this.sound_options)

        this.count = parseFloat(this.options['instant_calm_count']['value'])
        // console.log('count',this.count,this.options['instant_calm_count']['value'])
        this.help_text = this.options['instant_calm_init_text']['value']
        //init all particles stuff
       
      },
      (error) => {
        this.init = 1;
      }
    );

  }

  animate() {
    if (!this.startTime){
      this.startTime=performance.now();
    }
    const frameDiff = performance.now() - this.lastTime;
    const timeElapsed = performance.now() - this.startTime;
    let progressRatio = Math.min(timeElapsed / this.animationDuration, 1);
    // const easeRatio = this.cubicBezier(this.easing, progressRatio);
    const easeRatio = this.easeInOut(progressRatio);
    const remainingScale = this.maxScale - this.scale;
    const remainingTime = this.animationDuration - timeElapsed;
    this.lastTime = performance.now();
    // this.scale = easeRatio * (this.maxScale - this.initScale)+this.initScale;
    // console.log(timeElapsed,progressRatio,easeRatio,this.scale)


    switch (this.mode) {
      case CALM_MODE.In:
        this.animationDuration=this.timeIn;
        if (this.scale < this.maxScale) {
          this.scale = easeRatio * (this.maxScale - this.initScale)+this.initScale;
        } else {
          this.changeMode(CALM_MODE.DelayIn)
        }
        break;
      case CALM_MODE.DelayIn:
        if (this.currentDelay < this.delayIn) {
          this.currentDelay += frameDiff;
        } else {
          this.currentDelay = 0
          this.startTime = 0;
          this.animationDuration=this.timeOut;
          this.changeMode(CALM_MODE.Out)
        }
        break;
      case CALM_MODE.Out:
        if (this.scale > this.initScale) {
          this.scale = easeRatio * (this.initScale -this.maxScale)+this.maxScale;
        } else {
          this.changeMode(CALM_MODE.DelayOut)
        }
        break;
      case CALM_MODE.DelayOut:
        if (this.currentDelay < this.delayOut) {
          this.currentDelay += frameDiff;
        } else {
          this.currentDelay = 0;
          this.startTime = 0;
          this.currentCount++;
          if (this.currentCount >= this.count) {
            this.changeMode(CALM_MODE.Ended)
            //store user progress
            this.userService.instantCalmProgress(this.count).subscribe(
              (result) => {
              },
              (error) => {

              }
            );
          }
          else{
            this.animationDuration=this.timeIn;
            this.changeMode(CALM_MODE.In)
          }
        }
        break;
      case CALM_MODE.Ended:
        if (this.scale > this.initScale) {
          this.scale = easeRatio * (this.initScale - this.maxScale)+this.maxScale;
        } else {
          clearInterval(this.calm_process_interval)
          clearTimeout(this.calm_process)
          cancelAnimationFrame(this.calm_process_animation);
        }

        break;
      default:
        break;
    }

    this.calm_process_animation=requestAnimationFrame(this.animate);
    if (progressRatio >= 1) {
      this.startTime=0;
    }
    // if (progressRatio < 1) {
    //   this.calm_process_animation=requestAnimationFrame(this.animate);
    // } else {
    //   this.startTime=performance.now();
    // }
  }

  calmProccess(count) {
    // console.log('calmProcess', count, this.mode)
    this.currentCount = 0;
    this.currentDelay = 0;

    this.changeMode(CALM_MODE.In)
    this.startTime = performance.now();
    this.initScale=this.scale;
    this.count=count;
    this.animationDuration=this.timeIn;
    this.animate = this.animate.bind(this);
    requestAnimationFrame(this.animate);
  }


  calmProccessCss(count) {
    // console.log('calmProcess', count, this.mode)
    this.currentCount = 0;
    this.currentDelay = 0;
    this.currentTime=0;

    this.changeMode(CALM_MODE.In)
    this.initScale=this.scale;
    this.count=count;
    this.calm_process_interval = setInterval(() => {
      this.calmController()
    }, 100)
  }

  calmController(){
    console.log('calmProcess', this.currentCount, this.mode)
    switch (this.mode) {
      case CALM_MODE.In:
        this.animationDuration=this.timeIn;
        this.scale=this.maxScale;
        if (this.currentTime>=this.animationDuration){
          this.currentTime=0;
          this.changeMode(CALM_MODE.DelayIn)
        }
        break;
      case CALM_MODE.DelayIn:
        this.animationDuration=this.delayIn;
        this.scale=this.maxScale;
        if (this.currentTime>=this.animationDuration){
          this.currentTime=0;
          this.changeMode(CALM_MODE.Out)
        }
        break;
      case CALM_MODE.Out:
        this.animationDuration=this.timeOut;
        this.scale=this.initScale;
        if (this.currentTime>=this.animationDuration){
          this.currentTime=0;
          this.changeMode(CALM_MODE.DelayOut)
        }
        break;
      case CALM_MODE.DelayOut:
        this.animationDuration=this.delayOut;
        this.scale=this.initScale;
        if (this.currentTime>=this.animationDuration){
          this.currentTime=0;
          this.currentCount++;
          console.log('konec?',this.currentCount,this.count)
          if (this.currentCount >= this.count) {
            this.changeMode(CALM_MODE.Ended)
            //store user progress
            this.userService.instantCalmProgress(this.count).subscribe(
              (result) => {


              },
              (error) => {

              }
            );
          }
          else{
            this.currentTime=0;
            this.changeMode(CALM_MODE.In)
          }
        }
        break;
      case CALM_MODE.Ended:
        this.animationDuration=this.timeOut;
        this.scale=this.initScale;
        clearInterval(this.calm_process_interval)
        clearTimeout(this.calm_process)
        break;
      default:
        break;
    }
    this.currentTime+=100;
  }

  cubicBezier([p0, p1, p2, p3], t) {
    const res = (1 - t) ** 3 * p0 + 3 * (1 - t) ** 2 * t * p1 + 3 * (1 - t) * t ** 2 * p2 + t ** 3 * p3;
    if (res < 0) {
      return 0;
    }
    return res;
  }

  easeInOut(t) {
    return t < 0.5 ? 4 * t ** 3 : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  }

  ease(t) {
    return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1;
  }

  linearEase(progress) {
    return progress;
  }

  backButton()
  {
    this.goBackHelper.goBack()
  }

}
