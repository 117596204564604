import { Component, OnInit, Input,ViewChildren, ViewChild,ElementRef, EventEmitter,Output } from '@angular/core';
import { Router } from '@angular/router';
import { loginMaterialAnimation } from '../../_animations/index';
import { AuthenticationService,PassDataService,ModalService, UserService } from '../../_services/index';
import { PasswordToggleDirective } from '../../_directives/password-toggle.directive';
import { ChatbotService } from '../../_services/chatbot.service';
import { keyframes } from '@angular/animations';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  animations:[loginMaterialAnimation],
})
export class LoginFormComponent implements OnInit {

    init = 0
	state_form = "";
	error = "";
	form_errors:any = {};
	signup_model: any = {};
	model: any = {};
	loading = false;
    coupon = "";
    terms = "";
    privacy = "";
    accept_error = "";
    tooltips = {};
    tooltips_opens = {};
    redirect = {}
    greetings = {}
    hide_age = 0;
    @ViewChildren(PasswordToggleDirective) dirs;
    @ViewChild('loginUsername') loginUsername: ElementRef;
    @ViewChild('loginPassword') loginPassword: ElementRef;
    twofa = false;
    twofa_seconds=0;
    twfofa_timer:any;
    @ViewChild('codeInput') codeInput:any;
    @Output() readonly forceLoginForm = new EventEmitter<boolean>();

    // @HostBinding('@previewAnimation')
    public count: number = 0;

    public activeItems: any[] = [];
    
    @Input('state')
    set state(itm: any) {
    this.error="";
    this.form_errors={};
    this.state_form = itm
    this.count++;

    setTimeout(() => {
        this.dirs.forEach(function(item){
            item.ngOnInit()
        })
        }, 5000)
        
  }


  constructor( private chat:ChatbotService,private userService:UserService, private modalService:ModalService,private router: Router,private authenticationService: AuthenticationService, private passDataService: PassDataService) {
            this.signup_model.sex=-1;
            this.signup_model.year=0;
         }

  ngOnInit() {
    this.coupon = this.passDataService.get("coupon");
    if (!this.coupon)
        this.coupon = "";

    this.authenticationService.getTerms()
            .subscribe(result => {
                this.terms = result.terms
                this.privacy = result.privacy
                this.tooltips = result.tooltips
                this.init =1 
            },
            error => {
                this.init =1 
                });
            
    //check touchid feature
    setTimeout(() => this.checkKeychainAndStartFingerPrint(), 2000);
  }


  checkKeychainAndStartFingerPrint() {
    // console.log('tut',window['cordova'])
    if (typeof window["cordova"] !== "undefined")
      if (
        typeof window["cordova"]["plugins"]["SecureKeyStore"] !== "undefined"
      ) {
        window["cordova"]["plugins"]["SecureKeyStore"].get(
          (res) => {
            let data = JSON.parse(res);
            // console.log(data)
            if (data["password"] !== "undefined"){
              this.initiateFingerPrint(data);
            }
              
          },
          (error) => {
            console.log(error);
          },
          "resilience-auth"
        );
      }
  }

  initiateFingerPrint(data) {
    if (typeof window["Fingerprint"] !== "undefined") {
      window["Fingerprint"].isAvailable(
        (res) => {
          window["Fingerprint"].show(
            {
              clientId: "resilience-app",
              clientSecret: "resilience-app",
            },
            () => {
              this.forceLoginForm.emit(true)
              this.login("",0,data["login"], data["password"])
            },
            (err) => {
              console.log(err);
            }
          );
        },
        (message) => {
          console.error(message);
        }
      );
    }
  }


  toggleTooltip(name) {
   this.tooltips_opens[name]=this.tooltips_opens[name]^1
  }

  hideTooltip(name) {
   this.tooltips_opens[name]=0
  }

  openModal(id) {
   this.modalService.open(id)
  }

  focusOutFunction(){
    alert()
  }

 checkCoupon(formData) {
      this.loading = true;
        this.authenticationService.checkCoupon(this.signup_model.coupon)
            .subscribe(result => {
                this.loading = false;
                if (result.result==true){
                    this.coupon = this.signup_model.coupon;
                    this.accept_error = '';
                    this.hide_age=result.hide_age;
                }else{
                    this.accept_error = 'Wrong Coupon' ;
                }
                
            },
            error => {
                this.accept_error = error ;
                this.loading = false;
                });
 }

   signup(formData) {
    this.accept_error=""
        if (!this.signup_model.accept)
            {
                this.accept_error='You should accept Terms and Policy';
                return true;
            }
        this.loading = true;
        this.authenticationService.signup(this.signup_model.username, this.signup_model.password, this.signup_model.first_name , this.signup_model.last_name, this.signup_model.phone, this.signup_model.sex,this.signup_model.year, this.coupon)
            .subscribe(result => {
                this.loading = false;
                this.error = '';
                if (result.redirect)
                    this.redirect = result.redirect
                if (result.greetings)
                    this.greetings = result.greetings
                    
                this.login('',0);
            },
            error => {
                this.error = error ;
                this.form_errors = JSON.parse(error);
                Object.getOwnPropertyNames(this.form_errors)
                .map((key: string) => {
                    // console.log(key,this.form_errors[key])
                    if (key!=="coupon")
                        formData.form.controls[key].setErrors({'incorrect': true});
                    else
                        this.accept_error=this.form_errors["coupon"];
                    })
                this.loading = false;
                });
    }

    login(fa:string = "", fa_resend:number=0,alogin="",apassword="") {
        let username = this.loginUsername && this.loginUsername.nativeElement.value;
        let password =  this.loginPassword && this.loginPassword.nativeElement.value;
        if (!this.model.username)
            this.model.username = username
        if (!this.model.password)
            this.model.password = password
        
        if (!this.model.username)
            this.model.username = this.signup_model.username
        if (!this.model.password)
            this.model.password = this.signup_model.password
        
        if (!this.model.username)
            this.model.username = alogin
        if (!this.model.password)
            this.model.password = apassword
        
        this.loading = true;
        this.authenticationService.login(this.model.username, this.model.password,fa, fa_resend)
            .subscribe(result => {
                if (result==='login'){
                    if (typeof window['cordova']!=="undefined" && typeof window['cordova']['plugins']['SecureKeyStore'] !== 'undefined'){
                        let data = {'login':this.model.username,'password':this.model.password}
                        let json = JSON.stringify(data)
                        window['cordova']['plugins']['SecureKeyStore'].set(function (res) {
                        //   console.log('stored',res); // res - string securely stored
                        }, function (error) {
                        console.log(error);
                        }, "resilience-auth", json);
                    }
                    this.loading = false;
                    this.error = '' ;
                    if (this.signup_model.username&&this.signup_model.password){
                        if (Object.keys(this.redirect).length === 0){
                            if (this.coupon!=="")
                                this.router.navigate(['/home']);
                            else
                                this.router.navigate(['/access_codes']);
                        }else{  
                            if (this.redirect['diagnostic']){
                                this.router.navigate(['diagnostics','item',parseInt(this.redirect['diagnostic'],10)]);
                            }
                            else
                                this.router.navigate(['/home']);
                        }
                        
                        if (Object.keys(this.greetings).length !== 0){
                          this.modalService.open('greetings',this.greetings)
                        }
                        
                       
                    }else
                        this.router.navigate(['/home']);
                }else if (result==='2fa'){
                    this.twofa=true;
                    this.start2FaTimer();
                    this.loading = false;
                    this.error = '' ;
                }else{
                    this.loading = false;
                    this.error = 'Can\'t login. contact to admin' ;
                }
              // this.userService.getUserConfiguration()
              //   .subscribe(configuration => {
              //     this.chat.setShow(configuration.chatbot)
              //   },
              //   error => {

              //     });
            },
            error => {
                if (this.codeInput)
                    this.codeInput.reset();
                this.error = error ;
                this.loading = false;
                });
    }


    getYearsArr() {
      const min=1910;
      const max=new Date().getFullYear()-5
      return Array.from({ length: max - min + 1 }, (_, i) => min + i);
    }

    onCodeChanged(code: string) {
        // alert(code)
        
      }
    
      // this called only if user entered full code
      onCodeCompleted(code: string) {
          this.login(code)
        // alert('completed: '+code)
        // console.log('codeinput', this.codeInput)
        // this.codeInput.reset();
      }
    
      resend2Fa(){
        if (this.twofa_seconds>0)
          return false; 
        this.login('',1);
        this.start2FaTimer();
    
      }
      start2FaTimer(){
        clearInterval(this.twfofa_timer)
        this.twofa_seconds=60;
        this.twfofa_timer = setInterval(() => {
          
            if (--this.twofa_seconds < 0) {
              this.twofa_seconds = 0;
              clearInterval(this.twfofa_timer)
            }
        }, 1000);
      }


}
