// import the required animation functions from the angular animations module
import { trigger, state, animate, transition, style, query, animateChild, group } from '@angular/animations';
 
export const goalkeeperAnimation =
    // trigger name for attaching this animation to an element using the [@triggerName] syntax
    [trigger('awardChange', [
      transition(':enter', [
        query('.button', [
                style({opacity: "0"}),
          ]),
        group([
          query('.title_top', [
                style({top:'-40px',opacity: "0"}),
                animate("1000ms 1s cubic-bezier(0.35, 0, 0.25, 1)", style("*")),
          ]),
          query('.badge', [
                style({top:'-40px',opacity: "0"}),
                animate("1000ms .9s cubic-bezier(0.35, 0, 0.25, 1)", style("*")),
          ]),
          query('.ribbon', [
                style({bottom:'-150px',opacity: "0"}),
                animate("1000ms 1.1s cubic-bezier(0.35, 0, 0.25, 1)", style("*")),
          ])
        ]),
        query('.button', [
                style({opacity: "0"}),
                animate("1000ms .2s cubic-bezier(0.35, 0, 0.25, 1)", style({ opacity: 1 })),
          ])
      ]),
    ]),]







