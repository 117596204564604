import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthenticationService,PassDataService } from '../_services/index';


@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private passDataService:PassDataService, private router: Router, private authService: AuthenticationService) { }

    canActivate() {
         if (!this.authService.isTokenExpiredObsChange()) {
              return true;
            }

        // not logged in so redirect to login page
        
        let pathname = window.location.pathname;
        pathname = pathname.replace(/(\/.{2})\//,'/');
        
        if (pathname && pathname!=='/'){
            this.passDataService.set('redirect',pathname)
        }
        
        this.router.navigate(['/login']);
        return false;
    }
}