import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

interface Habit {
  name: string;
  total_days: number;
  total_completed: number;
  reward: string;
  date_created: string;
  date_completed: string;
  date_updated: string;
  id: number;
  is_completed: boolean;
  is_deleted: boolean;
  user_id: number;
}

@Component({
  selector: 'app-habit-tracker-list',
  templateUrl: './habit-tracker-list.component.html',
  styleUrls: ['./habit-tracker-list.component.scss']
})
export class HabitTrackerListComponent implements OnInit {

  
  //example habbits array habit.name, habit.date_created, habit.date_completed, habit.date_updated, habit.id, habit.is_completed, habit.is_deleted, habit.user_id
  habits: Habit[] = [
    { name: "not drink alco", "total_days": 26, "total_completed": 10, reward: "bottle of wine", date_created: "2023-08-16", date_completed: "2020-01-01", date_updated: "2020-01-01", id: 1, is_completed: true, is_deleted: false, user_id: 1 },
    { name: "Habbit 2", "total_days": 11, "total_completed": 5, reward: "pack of icecream", date_created: "2023-08-01", date_completed: "2020-01-01", date_updated: "2020-01-01", id: 2, is_completed: true, is_deleted: false, user_id: 1 },
    { name: "Habbit 3", "total_days": 30, "total_completed": 3, reward: "smth", date_created: "2023-08-01", date_completed: "2020-01-01", date_updated: "2020-01-01", id: 3, is_completed: true, is_deleted: false, user_id: 1 },
    { name: "Habbit 4", "total_days": 12, "total_completed": 11, reward: "smth", date_created: "2023-08-01", date_completed: "2020-01-01", date_updated: "2020-01-01", id: 4, is_completed: true, is_deleted: false, user_id: 1 },
  ]

  // create var and logic for showAddModal
  showAddEditModal = false;
  //habbit var for ngModel
  showAddEditModalItem: Habit = {
    name: '',
    total_days: 1,
    total_completed:0,
    reward: '',
    date_created: '',
    date_completed: '',
    date_updated: '',
    id: 0,
    is_completed: false,
    is_deleted: false,
    user_id: 0
  }
  



  //function for showAddModal
  showModal(e, item) {
    e.stopPropagation();
    e.preventDefault();
    this.showAddEditModal = true;
    this.showAddEditModalItem = item;
  }

  showModalAdd(e) {
    e.stopPropagation();
    e.preventDefault();
    this.showAddEditModal = true;
    this.showAddEditModalItem = {
      name: '',
      total_days: 1,
      total_completed:0,
      reward: '',
      date_created: '',
      date_completed: '',
      date_updated: '',
      id: 0,
      is_completed: false,
      is_deleted: false,
      user_id: 0
    };

  }

  closeModal() {
    this.showAddEditModal = false;
    // this.showAddEditModalItem = null;
  }
  deleteHabit(e, item) {
    e.stopPropagation();
    e.preventDefault();
    console.log("deleteHabit", item)
  }
  saveHabit() {
    //if no id exists add new habit to habbits and generate id, if id exists just fix old habbit
    if (this.showAddEditModalItem.id) {
      console.log("saveHabit", this.showAddEditModalItem)
    }
    else {
      const newItem ={...this.showAddEditModalItem}
      newItem.id = this.habits.length + 99;
      newItem.date_created = new Date().toISOString().slice(0, 10);
      setTimeout(()=>{this.habits.push(newItem)}, 100)
      console.log("saveHabit", newItem,this.habits)
    }
    this.closeModal()
  }

  constructor(private router: Router) { }


  ngOnInit(): void {
  }

  navigateToHabit(habit: any): void {
    this.router.navigate(['/habbit/item', habit.id], {
      state: { habitData: habit }
    });
  }

}
