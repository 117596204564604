import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "small-video-player",
  templateUrl: "./small-video-player.component.html",
  styleUrls: ["./small-video-player.component.scss"],
})
export class SmallVideoPlayerComponent implements OnInit {
  @Input("video") video: string;
  @Input("poster") poster: string;
  @Input("preload") preload: string = "auto";
  @Input("controls") controls: string = "";
  @Input("show_playpause") show_playpause: boolean = false;
  @ViewChild("media") singleVideo: ElementRef;

  play: boolean = false;

  constructor() {}

  ngOnInit() {
    if (!this.show_playpause) this.play = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (typeof this.singleVideo !== "undefined") {
      if (changes.video) {
        this.singleVideo.nativeElement.src = changes.video.currentValue;
        this.singleVideo.nativeElement.load();
        this.singleVideo.nativeElement.play();
      }
    }
  }

  whenPlay() {
    if (this.show_playpause) this.play = true;
  }

  whenPause() {
    if (this.show_playpause) this.play = false;
  }
}
