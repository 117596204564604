<section class="page remind">
	<div class="main_container blurry">
		<div class="reminder_container">
			<div class="account__form_error" *ngIf="error">
			  {{ error }}
			</div>
			<div class="account__form">
				<fieldset>
				  <div class="account__form-group">
					<input
					  type="text"
					  [(ngModel)]="model.password"
					  required
					  placeholder="New Password"
					  name="password"
					  i18n-placeholder
					/>
				  </div>
				  <div class="account__form-group">
					<input
					  type="text"
					  [(ngModel)]="model.again_password"
					  required
					  placeholder="Confirm New Password"
					  name="again_password"
					  i18n-placeholder
					/>
				  </div>
				</fieldset>
				<div class="main-area__login-button">
					<button
					  title="Change password"
					  (click)="resetPassword()"
					  class="action action--secondary action--purple action--simple-button"
					  i18n
					>
					Change Password
					</button>
				  </div>
			</div>
		</div>
	</div>
</section>