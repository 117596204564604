<section class="page meditations bg_margin" (scroll)="reminderPopupGlobal($event)" (click)="reminderPopupGlobal($event)" (touchmove)="reminderPopupGlobal($event)">
  <div class="main_container blurry">
    <div class="greetings">
      <div class="greetings__phrase">{{ series.title }}</div>
      <div class="greetings__phrase" i18n>Meditations</div>
      <div class="greetings__name" i18n>
        Practical techniques for a flexible mind
      </div>
    </div>
    <div *ngIf="error" class="rerror">{{error}}</div>
    <div class="grid">
      <meditate-player
        (click)="setCurrentId(episode.id)"
        #medRef
        *ngFor="let episode of episodes; let i = index"
        [item]="episode"
      ></meditate-player>
    </div>
  </div>
  <div class="bottom_footer"  (click)="reminderPopupToggle()">
    <div  *ngIf="init" class="bottom_footer__text">
       
      <ng-container *ngIf="!reminder_settings.notify_time" i18n>Consistency is key, so let us </ng-container> 
      <ng-container  *ngIf="reminder_settings.notify_time" i18n>Remind me everyday at</ng-container><span class="meditate-popup__select_option" (click)="reminderPopup($event, true)">
        <select title='s' class="meditate-popup__select" [(ngModel)]="reminder_settings.notify_time" (ngModelChange)="reminderSettingsChange($event)">
          <option value="{{ option }}" *ngFor="let option of reminder_times">
            {{ option }}
          </option>
        </select>
        <ng-container *ngIf="!reminder_settings.notify_time" i18n>help remind you</ng-container>
        <ng-container *ngIf="reminder_settings.notify_time">{{reminder_settings.notify_time}}</ng-container>
        </span>
      <div class="meditate-popup__notifications" [ngClass]="{'active':reminder_popup}">
        <h2 i18n>Notifications</h2>
        <div class="meditate-popup__notifications_container">
          <div i18n class="meditate-popup__notification meditate-popup__notification--button" (click)="selectNotification($event, 'notify_email')" [ngClass]="{'active':reminder_settings.notify_email!=='0'&&reminder_settings.notify_email}">
            Email
          </div>
          <div i18n class="meditate-popup__notification meditate-popup__notification--button"  (click)="selectNotification($event, 'notify_push')" [ngClass]="{'active':reminder_settings.notify_push!=='0'&&reminder_settings.notify_push}">
            Push
          </div>
      </div>
      </div>
    </div>
  </div>
  <div class="meditate-popup__overlay" [ngClass]="{'active':reminder_popup}"></div>
</section>
