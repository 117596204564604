import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class TopBarService {

  private showHamburger = new BehaviorSubject<boolean>(false); // true is your initial value
  private bgStyle = new BehaviorSubject<string>("main"); // true is your initial value
  showHamburger$ = this.showHamburger.asObservable();
  bgStyle$ = this.bgStyle.asObservable();
  constructor() { }

  setShowHamburger(value: boolean) {
    this.showHamburger.next(value);
    // console.log('Show Hamburger', value);
  }

  setBgStyle(value: string) {
    this.bgStyle.next(value);
    // console.log('Change bgStyle', value);
  }

  private get getShowHamburger():boolean {
    return this.showHamburger.getValue()
  }

}
