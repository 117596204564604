import { Injectable } from '@angular/core';
import { NavigationEnd,NavigationStart, Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common'
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class GoBackHelper {
  canGoBack: boolean;
  prevURL = []
  goingBack: boolean;

  constructor(private globals:Globals, private location: Location, private router: Router, private route: ActivatedRoute) {
    this.router.events.subscribe(event => {
      // if (event instanceof NavigationStart) {
      //   console.log('PREV URL',this.router.getCurrentNavigation()?.previousNavigation)
      // }

      if (event instanceof NavigationEnd) {
        // console.log('wtf1',event)
        this.canGoBack = !!(this.router.getCurrentNavigation()?.previousNavigation);
        let pu = event.urlAfterRedirects
        // let pu = this.router.getCurrentNavigation()?.previousNavigation.finalUrl.toString()
        // // /diagnostics/item/
        // // console.log('wtf',event,pu)
      
        if (!this.goingBack){
          this.prevURL.push(pu)
        }else{
          this.goingBack=false;
        }
        // console.log('PREV URL',this.router.getCurrentNavigation()?.previousNavigation.finalUrl.toString(), this.goingBack)
      }
    });
  }

  goBack(_default: string = '/'): void {
    // 
    console.log("prev",this.globals.ios)
    if (this.globals.ios){
      this.goBackBuilds(_default)
      return;
    }

    if (this.canGoBack) {
      this.location.back();
    } else {
      this.router.navigate([_default], { relativeTo: this.route });
    }
  }

  goBackBuilds(_default: string = '/'): void {
    this.prevURL.pop()
    console.log("prev",this.prevURL)
    
    if (this.prevURL[this.prevURL.length-1]&&this.prevURL[this.prevURL.length-2]&&this.prevURL[this.prevURL.length-1].includes("/diagnostics/answers/")&&this.prevURL[this.prevURL.length-2].includes("/diagnostics/item/")){
      this.prevURL=[]
      // this.prevURL.pop()
      this.router.navigate([_default], { relativeTo: this.route });
      return
    }
    if (this.prevURL[this.prevURL.length-1]){
      
      const url = this.prevURL[this.prevURL.length-1];
      // this.prevURL.pop()
      this.router.navigate([url], { relativeTo: this.route });
      this.goingBack=true;
    } else {
      this.router.navigate([_default], { relativeTo: this.route });
    }
  }
}
