<section class="page login">
  <div class="main_container blurry">
    <div class="top_control">
			<div class="top_control__back_button" (click)="goBack()">
				<span class="material-symbols-rounded" *ngIf="state!==''" > arrow_back_ios_new </span>
				<span *ngIf="state!==''" i18n>Back</span>
			  </div>
			  <div class="top_control__options">
					<div class="top_control__option">
						<div class="top_control__selected_value language">
              <span class="material-symbols-rounded">
                language
                </span>
              <span>{{language|uppercase}}</span>
            </div>
						<select title='language' class="top_control__select" (change)="selectLanguage($event.target.value)">
							<option *ngFor="let _language of languages" [selected]="(_language.code == language) || (_language.code=='en-US'&& language=='en')" [value]="_language.code">{{_language.name}}</option>
						</select>
					</div>
			  </div>
		</div>
    <div class="main-area">
      <div class="main-area__top-logo" [ngClass]="{ 'main-area__top-logo--stick-bottom': state!='' }">
        <img src="/assets/img/logo-new.png" alt="logo" srcset="">
        <div class="main-area__login-title">
          <h2 i18n>Resilience Toolbox</h2>
          <h3 i18n>
            Assess, learn and practice your resilience skills.
          </h3>
        </div>
      </div>
      <div class="main-area__login-container">
        <app-login-form *ngIf="state!==''" (forceLoginForm)="onForceLoginForm($event)" [state]="state"></app-login-form>
        <div class="main-area__login-buttons">
          <div class="main-area__login-button">
            <button *ngIf="state==''" (click)="welcome_button('login')" type="button"
              title="login"
              class="action action--secondary action--purple action--simple-button" i18n>
              Login
            </button>
          </div>
          <div class="main-area__login-button">
            <button *ngIf="state=='signup'" (click)="welcome_button('login')" type="button"
              title="login"
              class="action action--clear action--simple-button" i18n>
              Login
            </button>
          </div>
          <div class="main-area__login-button" *ngIf="state!=='signup'">
            <button (click)="welcome_button('signup')" type="button"
              title="register"
              class="action action--clear action--simple-button" i18n>
              Register
            </button>
          </div>
        </div>
       
      </div>
    </div>
  </div>
</section>