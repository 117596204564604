<div class='container' *ngIf='init&&organisations.length>0'>
	<div class="title" i18n>
		Brought to you by
	</div>
	<div class="organisations">
		<div class='organisation' *ngFor="let organisation of organisations;">
			<div class="logo">
				<img [title]='organisation.name' [alt]='organisation.name' [src]='organisation.logo' />
			</div>
			
		</div>
 	</div>
</div>
