import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DiagnosticService,ModalService } from '../../_services/index';
import {GoBackHelper} from '../../_helpers/go-back-helper'

@Component({
  selector: 'app-diagnostic-answers',
  templateUrl: './diagnostic-answers.component.html',
  styleUrls: ['./diagnostic-answers.component.scss']
})
export class DiagnosticAnswersComponent implements OnInit {
  
  selectedAnswer = null;
  id: number;
  cursor: number;
  max_items: number;
  answer_direction: string = "up";
  answers: any = {};	
  error = '';
  start_text = '';
  add_fields: any = {}; 
  add_fields_values : any = {}; 

  constructor(public goBackHelper:GoBackHelper, private modalService:ModalService, private route: ActivatedRoute,private diagnosticService: DiagnosticService, private router: Router) { 
	
  }

  openModal(id) {
   this.modalService.open(id)
  }

  closeModal(id) {
   this.modalService.close(id)
  }

  getOptionValue(id,fields, def=""){
    if (!id)
      return def
    return fields.find(item=>item.id==id).value
  }

  goBack(){
    this.router.navigate(['/']);
  }

  saveAddFields(){

    // console.log(this.add_fields_values,this.add_fields,Object.keys(this.add_fields_values).length,this.add_fields.options.length,(Object.keys(this.add_fields_values).length==this.add_fields.options.length))
    // return ;
    if (Object.keys(this.add_fields_values).length>=this.add_fields.options.length)
      this.diagnosticService.saveAddFields(this.id,this.add_fields_values).subscribe(
        result => {
          this.modalService.close('add_fields');
        },
          error => {
            this.error = error ;
            console.log("error",this.error)
      })
    
  }

  ngOnInit() {
  	this.route.params.subscribe(params => {
       this.id = +params['id']; // (+) converts string 'id' to a number
        this.diagnosticService.getDiagnosticAnswers(this.id).subscribe(
          result => {
             this.answers= result.answers;
             
             this.max_items=this.answers.length;
             this.answers[this.max_items]={'submit':'yes'}
             this.cursor=this.answers.findIndex(item=>item.answer==-1||item.submit);
             this.selectedAnswer=this.answers[this.cursor];
             this.start_text = result.start_text;
             this.add_fields = result.add_fields;
             if (this.add_fields.answers.length !== 0)
                this.add_fields_values = this.add_fields.answers;
             if (this.add_fields.show)
               this.openModal('add_fields');
          },
            error => {
                if (error=="ended")
                  this.router.navigate(['diagnostics','item',this.id]);
              this.error = error ;
              console.log("error",this.error)
        })
    });
  }

  handleAnswerUpdated(answer) {
    // console.log("lol",answer)
    this.selectedAnswer.answer=answer
    this.goNext();
  }

  goPrev() {
    
    if (typeof this.selectedAnswer.submit=="undefined")
      this.saveAnswer();
    this.answer_direction = "up"
  	this.cursor--;
  	this.selectedAnswer=this.answers[this.cursor];
  }

  
  goNext = ():void =>{
    this.saveAnswer();
    this.answer_direction = "down"
  	this.cursor++;
  	this.selectedAnswer=this.answers[this.cursor];
  }

  saveAnswer() {

    this.diagnosticService.saveAnswer(this.id,this.selectedAnswer.id,this.selectedAnswer.answer).subscribe(
          result => {
             console.log(result)
          },
            error => {
                this.error = error ;
              console.log("error",this.error)
        })
  }

          

}
