import { Component, OnInit } from '@angular/core';
import { UserService, ModalService } from '../_services/index';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import {routeAnimation } from '../_animations/index';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  animations:[routeAnimation],
  host: { '[@fadeRoute]': '' }
})
export class NotificationsComponent implements OnInit {

  notifications:any = [];
  selected:any = {};
  hide_more=0;

  constructor(private sanitizer: DomSanitizer,private modalService:ModalService, private userService:UserService) { }


  getVideoUrl(code)
  {
    if (!code)
      return false;
    return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + code+"?rel=0");
  }

  ngOnInit() {
       this.userService.getNotifications(0).subscribe(
	       	notifications => {
		        this.notifications = notifications;
               for (const notification of this.notifications) {
                            notification.video=this.getVideoUrl(notification.video)
                        }
		    }
	    );
  }


  openNoty(noty) {
  	   if (noty.seen==0)
	       this.userService.seenNotification(noty.id).subscribe(
		       	result => {
			    }
		    );

	    noty.seen=1;
	    this.modalService.open('notification');
	    this.selected=noty;
  }


  loadMore() {
       this.userService.getNotifications(this.notifications.length).subscribe(
          notifications => {
            if (notifications.length!==0){
               for (const notification of notifications) {
                    notification.video=this.getVideoUrl(notification.video)
                }
               this.notifications = this.notifications.concat(notifications);
               }
               else{
                this.hide_more=1;
               }
        }
      );
  }

}
