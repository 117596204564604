<div class="full-chatbot" *ngIf="show">
<div class="chatbot-icon">
	<div class="chatbot-icon-container" [ngClass]="{'closed':!closed}" (click)="toggleChat()">
		<i class="fa fa-comments"></i>
	</div>
</div>
<div class="chatbot">
	<div class="messenger" [ngClass]="{'cutout':globals.cutout,'closed':closed,'keyboard':keyboard}">
		  <div class="menu">
		    <div class="button"  (click)="toggleChat()"><i class="fa fa-times"></i></div>
		  </div>
		<div class="chat">
		  <div class="chat-title">
		    <div i18n>Ask Flowbot</div>
		  </div>
		  <div class="messages">
		    <div class="messages-content">
		    	<div class="scrollbar" id='scrollbar'>
			    	<ng-container *ngFor="let message of messages | async">
			    		<div @slideRight *ngIf="message.type == 0 && message.sentBy === 'user'" class="message" [ngClass]="{ 'from': message.sentBy === 'bot',
						'message-personal':message.sentBy === 'user' }" [innerHTML]="message.content" ></div>

						<div @slideLeft *ngIf="message.type == 0 && message.sentBy === 'bot'" class="message" [ngClass]="{ 'from': message.sentBy === 'bot',
						'message-personal':message.sentBy === 'user' }" [innerHTML]="message.content" ></div>

					<div @slideLeft *ngIf="message.type == 1" class="card-container">
					  <div class="__container" *ngIf="message.data.imageUrl&&message.data.imageUrl.length > 0">
					      <img class="card-image" [src]="message.data.imageUrl">
					      <div class="title-card" *ngIf="message.data.title || message.data.subtitle">
					        <div class='title-card-container'>
					          <div class='title-card-container-text'>

					            <span *ngIf="message.data.title" [ngClass]="{'headline':message.data.subtitle,'single-headline':!message.data.subtitle}">{{message.data.title}}</span>
					            <span *ngIf="message.data.subtitle" [ngClass]="{'subhead':message.data.title,'single-subhead':!message.data.title}">{{message.data.subtitle}}</span>
					          </div>
					        </div>
					      </div>
					    </div>

					    <a *ngFor="let button of message.data.buttons" target="_blank" [href]="button.postback">
						    <button  class="card-button" type="button" aria-label="open">
						     	 <i class="fa fa-external-link btn-icon"></i>
						      	{{button.text}}
						    </button>
					    </a>
					   
					</div>

					<div @slideLeft *ngIf="((message.type == 2)||(message.type == 'options'))&&message.data.replies&&message.data.replies.length > 0" aria-hidden="false" class="replies-container">
						<button *ngFor="let button of message.data.replies" class="reply-button" type="button" (click)="replyButton($event,button,message)">
					    {{button}}
					  	</button>
					</div>

					<div @slideLeft *ngIf="(message.type == 'redirects')" aria-hidden="false" class="replies-container">
						<button *ngFor="let button of message.data.redirects" class="reply-button" type="button" (click)="redirectButton($event,button.redirect,message)">
					    {{button.text}}
					  	</button>
					</div>

					<div @slideLeft *ngIf="message.type == 'video'" class="card-container">
					      <small-video-player [preload]="'none'" [poster]="message.data.video_poster" [video]="message.data.video" ></small-video-player>
					</div>

					<div @slideLeft *ngIf="message.type == 'stars'" class="card-container">
						<div class="rating">
						   <span (click)="replyStars($event,5)" title="five stars">&#9734;</span>
						   <span  (click)="replyStars($event,4)" title="four stars">&#9734;</span>
						   <span   (click)="replyStars($event,3)" title="three stars">&#9734;</span>
						   <span  (click)="replyStars($event,2)" title="two stars">&#9734;</span>
						   <span  (click)="replyStars($event,1)" title="one star" >&#9734;</span>
						</div>
					</div>
					 

					</ng-container>
					<div class="loader" @fadeAnim *ngIf='loader'>
						<svg  version="1.1" id="loader" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		 viewBox="-49.5 -1.8 129 84.5" style="enable-background:new -49.5 -1.8 129 84.5;" xml:space="preserve">
						<path style="fill:#E9E9E9;" d="M42.4,73.3H-0.4c-18.1,0-33-14.8-33-33v-4.7c0-18.1,14.9-33,33-33h42.8c18.2,0,33,14.9,33,33v4.7
							C75.4,58.5,60.5,73.3,42.4,73.3z"/>
						<circle style="fill:#C8C8C8;" cx="1" cy="38" r="9"/>
						<circle style="fill:#C8C8C8;" cx="21" cy="38" r="9"/>
						<circle style="fill:#C8C8C8;" cx="41" cy="38" r="9"/>
						<circle style="fill:#E9E9E9;" cx="-25.2" cy="62.6" r="10.8"/>
						<circle style="fill:#E9E9E9;" cx="-41" cy="74.8" r="5"/>
					</svg>
					</div>
					

		    	</div>
		    </div>
		  </div>
		  <div class="message-box">
		  	<div class="message-inner-box message-text">
		    	<input i18n [(ngModel)]="formValue" (keyup.enter)="sendMessage()" type="text" class="message-input" placeholder="Chat with Flowbot" />
		    </div>
		    <div class="message-inner-box message-button" (mouseup)='preventLoseFocus($event)' (mousedown)='preventLoseFocus($event)' (click)="sendMessage($event)">
		    	<div class=''>
		    		<i class="fa fa-share"></i>
		    	</div>
		    </div>
		  </div>
		</div>
	  </div>
</div>
</div>
