import { Component, OnInit } from '@angular/core';
import { routeAnimation } from '../../_animations/index';
import { ActivatedRoute, Router } from '@angular/router';
import { PassDataService,MeditationService,UserService,ModalService } from '../../_services/index';

@Component({
  selector: 'app-meditate-stats',
  templateUrl: './meditate-stats.component.html',
  styleUrls: ['./meditate-stats.component.scss'],
   animations:[routeAnimation],
  host: { '[@fadeRoute]': '' }
})
export class MeditateStatsComponent implements OnInit {
	finished = false;
	minutes = 0;
	sessions:any = {};
	streak:any = {};
	congrat_text = "";
	error = "";
	journey = 0;
  constructor(private passDataService:PassDataService,private modalService:ModalService,private userService:UserService,private meditationService:MeditationService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
	this.journey=this.passDataService.get('journey');
  	this.route.params.subscribe(params => { 
         this.meditationService.getStats().subscribe(
	      result => {
	      	this.minutes = result.minutes;
	      	this.sessions = result.sessions;
	      	this.streak = result.streak; 
	      	this.finished = result.finished;
	      	 if (this.finished)
		       this.congrat_text="Congratulations, you finished."
		     else
		       this.congrat_text="Sorry you didn't finish today."
	      },
	        error => {
	            this.error = error ;
	          console.log("error",this.error)
	        })
    });

  }

  goBack(){
	if (!this.journey)
		  this.router.navigate(['meditate','list']);
	else
		this.router.navigate(['journey',this.journey]);
  }

}
