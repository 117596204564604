import { Injectable } from '@angular/core';
import { Globals } from '../globals';

import { UserService} from '../_services/index';

import { Observable ,  BehaviorSubject } from 'rxjs';

// Message class for displaying messages in the component
export class Message {
  constructor(public content: string, public sentBy: string, public type: number, public data:any) {}
}

@Injectable({
  providedIn: 'root'
})
export class ChatbotService {

  public readonly NORMAL_MODE = 0;
  public readonly FEEDBACK_MODE = 1;
  public readonly DIAGNOSTIC_TIPS_MODE = 2;
  public readonly FLOWBOT_SCHEDULED = 3;
  session_id: string;
  token: string;
  client: any;
  data:any = {};

  conversation = new BehaviorSubject<Message[]>([]);
  show = new BehaviorSubject<boolean>(false);
  mode = new BehaviorSubject<number>(0);
  loader = new BehaviorSubject<boolean>(false); // true is your initial value
  loader$ = this.loader.asObservable();

  constructor(private globals:Globals,private userService: UserService) {
      this.session_id = '_' + Math.random().toString(36).substr(2, 9);
  		this.token = globals.bot_key;
  		this.client = {};//new ApiAiClient({ accessToken: this.token });
  }

  // Sends and receives messages via DialogFlow
  converse(msg: string, init = false) {
    this.userService.flowbotSession().subscribe();
    if (this.mode.getValue() === this.FEEDBACK_MODE)
        return this.feedbackConverse(msg)
    if (this.mode.getValue() != this.NORMAL_MODE)
        return this.systemConverse(msg, init)
    if (!init){
    const userMessage = new Message(msg, 'user', 0, {});
    this.update(userMessage);
    }
    this.loader.next(true);
    this.setMode(this.NORMAL_MODE);
    this.userService.systemFlowbotAnswer(msg,this.getData(this.mode.getValue()),this.mode.getValue(),this.session_id)
        .subscribe(results => {
          this.loader.next(false);
          if (results.error){
            this.botLocalMessage(results.text,0);
            if (results.replies)
              this.botLocalMessage(results.text,2,{replies:results.replies});
            else{
              this.setData(this.mode.getValue(),{});
              this.setMode(this.NORMAL_MODE);
            }
            }else{
              var messages = this.prepareMessages(results);
              // console.log("MMMMMESAGES",messages);
              for(let message of messages){
                 this.update(new Message(message.data_text,'bot',message.data_item_type, message.data_item_return_data))
              }
        } },
        error => {

          });

    // return this.client.textRequest(msg)
    //            .then(res => {
    //               this.loader.next(false);
    //               if (res.result.fulfillment.messages){
    //                   res.result.fulfillment.messages.forEach((element) => {
    //                     // console.log('element',element)
    //                     this.update(new Message(element.speech,'bot',element.type, element));
    //                   });
    //                 }
                 
                      
    //            });
  }

  prepareMessages(response){
    let messages = [];
    let response_items = response.fulfillmentMessages;

    for(let fulfillmentItem of response_items){
      // console.log('!!!!!!!!',fulfillmentItem);

        for(let item_type in fulfillmentItem){
          let item = fulfillmentItem[item_type];
          let data_text = "";
          let data_item_type = 0;
          let data_item_return_data = {};
            switch (item_type) {
              case "text":
              // console.log(item,item[item_type],item[item_type][0]);
                data_text = item[item_type][0]; 
                data_item_type = 0
                data_item_return_data = {};
                break;
              case "quickReplies":
                data_item_type = 2
                data_item_return_data = {replies: item[item_type]};
                break;   
              case "card":
                data_item_type = 1
                data_item_return_data = item;
                break;               
              default:
                this.setData(this.mode.getValue(),{});
                this.setMode(this.NORMAL_MODE);
                return [];
              }
              messages.push(this.prepareMessageStructure(data_text,data_item_type,data_item_return_data))
            }
        }
        //payload prep
        if (typeof response.webhookPayload !=="undefined")
          for(let payload of response.webhookPayload.data){
            if (typeof payload.after !=="undefined")
              messages.splice(payload.after+1, 0, this.prepareMessageStructure(payload.text||"",payload.type,payload));
            else
              messages.push(this.prepareMessageStructure(payload.text||"",payload.type,payload));
          }
          // console.log("------",response_items);

      // console.log("????", messages);

      return messages;
  }

  prepareMessageStructure(text,type,item){
    return {data_text:text,data_item_type:type,data_item_return_data:item}
  }



  feedbackConverse(msg: string, init= false) {
    const userMessage = new Message(msg, 'user', 0, {});
    if (!init){
      this.update(userMessage);
    }
     this.userService.sendFeedbackAnswer(msg)
        .subscribe(results => {
          if (results.question){
            if (results.error)
            this.feedbackBotMessage({init_text:"Oh can you try again?",question:results.question})
          else
            this.feedbackBotMessage({question:results.question})
            }else{
              this.botLocalMessage('Thx for your feedback')
              this.setMode(this.NORMAL_MODE);
            }
        },
        error => {

          });
  }

    // Adds message to source
  feedbackBotMessage(feedback) {
    if (feedback.init_text)
      this.update(new Message(feedback.init_text,'bot', 0, {}));
    if (feedback.question.text)
      this.update(new Message(feedback.question.text,'bot', 0, {}));

    this.update(new Message("",'bot', feedback.question.type, feedback.question));
  }

    diagnosticTipsConverse(msg: string, init= false) {
    const userMessage = new Message(msg, 'user', 0, {});
    if (!init){
      this.update(userMessage);
    }
    if (!this.getData('diagnostic_tips').result_id){
        this.botLocalMessage('Ooops internal problem!',0);
        this.setMode(this.NORMAL_MODE);
        return false;
    }
     this.userService.answerDiagnosticTips(msg,this.getData('diagnostic_tips'))
        .subscribe(results => {
          if (results.error){
            this.botLocalMessage(results.text,0);
            if (results.replies)
              this.botLocalMessage(results.text,2,{replies:results.replies});
            else
              this.setMode(this.NORMAL_MODE);
            }else{
              this.botLocalMessage(results.text)
              this.setMode(this.NORMAL_MODE);
              if (results.help_video)
                this.converse("help.diagnostic", true)
            }
        },
        error => {

          });
  }

 systemConverse(msg: string, init= false) {
    const userMessage = new Message(msg, 'user', 0, {});
    if (!init){
      this.update(userMessage);
    }
    if (!this.getData(this.mode.getValue())){
        this.botLocalMessage('Ooops internal problem!',0);
        this.setMode(this.NORMAL_MODE);
        return false;
    }
     this.userService.systemFlowbotAnswer(msg,this.getData(this.mode.getValue()),this.mode.getValue(), this.session_id)
        .subscribe(results => {
          if (results.error){
            this.botLocalMessage(results.text,0);
            if (results.replies)
              this.botLocalMessage(results.text,2,{replies:results.replies});
            else{
              this.setData(this.mode.getValue(),{});
              this.setMode(this.NORMAL_MODE);
            }
            }else{
              for(let text of results.text){
                  this.botLocalMessage(text)
                 }
              if (results.replies)
                this.botLocalMessage(results.text,2,{replies:results.replies});

              if (results.callback_data.length>0){
                let data = this.getData(this.mode.getValue())
                for(let obj of results.callback_data){
                  data[obj.name]=obj.value;     
                 }
                 this.setData(this.mode.getValue(),data)
              }
              if (results.finished){
                 this.setData(this.mode.getValue(),{});
                 this.setMode(this.NORMAL_MODE);
              }
              if (results.bot_converse){
                // "help.diagnostic"
                this.converse(results.bot_converse, true)
              }
            }
        },
        error => {

          });
  }


  // Adds message to source
  update(msg: Message) {
    // console.log('message:',msg)
    this.conversation.next([msg]);
         setTimeout(() => { // scroll flowbot
                      let el = document.getElementById('scrollbar');
                      if (el.scrollHeight>300)
                        el.scrollBy({ 
                          top: el.scrollHeight, 
                          left: 0, 
                          behavior: 'smooth' 
                        });
                    // el.scrollTop = el.scrollHeight+200;
                  },200)
  }

 setShow(value: boolean) {
    this.show.next(value);
  }

  setMode(value: number) {
    this.mode.next(value);
  }

  // Adds message to source
  botLocalMessage(msg: string, type = 0, data = {}) {
    this.update(new Message(msg,'bot', type, data));
  }
  


  setData(name,value)
  {
    this.data[name]=value;
  }

  getData(name)
  {
    // console.log('DATA',this.data,name, this.data[name])
    if (this.data[name])
      return this.data[name]
  else
      return false;
  }

}