
import {catchError, map} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Globals } from '../globals';




import { ApiService } from './index';

@Injectable()
export class VideoService {
    constructor(
        private apiService: ApiService) {
    }

    getSeriesList(): Observable<any> {
       return this.apiService.get("videos/series").pipe(
       map((response: any) => response && response.results),
       catchError((error:any) => {
            return observableThrowError(error.error.results.error);
        }),)
    }

    getSeries(id): Observable<any> {
       return this.apiService.get("videos/series/"+id).pipe(
       map((response: any) => response && response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.results.error);
        }),)
    }


    getLastStatForSeries(id): Observable<any> {
       return this.apiService.get("videos/user_progress/last_by_series/"+id).pipe(
       map((response: any) => response && response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.results.error);
        }),)
    }

     saveStats(episode_id,percent): Observable<any> {
       return this.apiService.post("videos/user_progress/"+episode_id,{percent:percent}).pipe(
         map((response: any) => response && response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.results.error)
        }),)
    }

    saveViews(episode_id): Observable<any> {
       return this.apiService.post("videos/user_progress/views/"+episode_id,{}).pipe(
         map((response: any) => response && response.results.result),
       catchError((error:any) => {
            return observableThrowError(error.results.error)
        }),)
    }
    
    endQuiz(quiz_id,answers): Observable<any> {
       return this.apiService.post("videos/user_progress/quiz/"+quiz_id,{answers:answers}).pipe(
         map((response: any) => response && response.results),
       catchError((error:any) => {
            return observableThrowError(error.results.error)
        }),)
    }

    getOneMinuteCoachEpisodes(): Observable<any> {
        return this.apiService.get("videos/1mcoach").pipe(
        map((response: any) => response && response.results),
        catchError((error:any) => {
             return observableThrowError(error.error.results.error);
         }),)
     }
    
     oneMinuteCoachTrack(episode_id): Observable<any> {
        return this.apiService.post("videos/1mcoach/track/"+episode_id,{}).pipe(
          map((response: any) => response && response.results),
        catchError((error:any) => {
             return observableThrowError(error.results.error)
         }),)
     }

     oneMinuteCoachRate(episode_id,rate): Observable<any> {
        return this.apiService.post("videos/1mcoach/rate/"+episode_id,{rate:rate}).pipe(
          map((response: any) => response && response.results),
        catchError((error:any) => {
             return observableThrowError(error.results.error)
         }),)
     }

}






