<div class="meditations-player" (click)="playMeditationEvent()">
  <div class="meditations-player__main_container" >
      <div class="meditations-player__icon" [ngClass]="{'icon-padding':started}">
            <span *ngIf='!started' class="material-symbols-rounded">
              graphic_eq
            </span>
            <span [ngStyle]="{'font-size': '2.1rem'}" *ngIf='started&&!play' class="material-symbols-rounded">
              play_arrow
            </span>
            <span  *ngIf='started&&play' class="material-symbols-rounded">
              pause
            </span>
            <span [ngStyle]="{'font-size': '2.1rem'}" (click)="stopMeditation($event)" *ngIf='started' class="material-symbols-rounded">
              stop
            </span>
      </div>
      <div class="meditations-player__title_container">
        <div class="meditations-player__title">
          {{item.title}}
        </div>
        <div class="meditations-player__duration">
          {{formatTime(item.duration)}} min
        </div>
      </div>
      <div class="meditations-player__progressbar">
        <div [ngStyle]="{'width':current_progress+'%'}" class="meditations-player__progressbar-bar">

        </div>
        
      </div>
    </div>
    <div class="meditations-player__rates_container" [ngClass]="{'active':show_rate}" >
      <div class="meditations-player__rates_title">
        How do you feel about this session?
      </div>
      <div *ngIf="rate_sent" class="meditations-player__rates">
        <div  class="meditations-player__rates_title meditations-player__rates_title--green">
          Thank you for your rate.
        </div>
      </div>
      <div *ngIf="!rate_sent" class="meditations-player__rates">
        <span  (click)="sendRate($event, item.id, 0)" class="material-symbols-rounded">
          sentiment_dissatisfied
          </span>
        <span (click)="sendRate($event,item.id, 1)" class="material-symbols-rounded">
          sentiment_neutral
          </span>
        <span (click)="sendRate($event,item.id, 2)" class="material-symbols-rounded">
          sentiment_satisfied
          </span>
      </div>
    </div>
  </div>
  <audio-player (progress)="getCurrentProgress($event)" [noeq]='true' [url]="item.audio" [playEvent]="playMeditation.asObservable()"></audio-player>