import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs'
@Component({
  selector: 'audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements OnInit {
  public canvas: any = false;
  public ctx: any = false;
  public audioCtx: any = false;
  public bufferLength: any = false;
  public dataArray: any = false;
  public barWidth: any = false;
  public barHeight: any = false;
  public barSpacing: number = 20;
  public analyser: any = false;
  public x: number = 0;
  public play: boolean = false;
  public init: boolean = false;
  private animation: any = false;
  @ViewChild('audioOption') audioPlayerRef: ElementRef;
  private _playEvent: Subscription;
  @Input() playEvent: Observable<number>;
  @Input('noeq') noeq: boolean = false;
  @Input('url') url: string;
  @Output('progress') progressEvent = new EventEmitter<number>();

  addProgress(value: number) {
    this.progressEvent.emit(value);
  }

  constructor() { }


  ngOnDestroy() {
    this._playEvent.unsubscribe();
  }

  ngOnInit(): void {
    this._playEvent = this.playEvent.subscribe((play) => this.clickPlayPause(play));
    if (!this.noeq) {
      this.canvas = document.getElementById("canvas") as HTMLCanvasElement;
      // this.canvas.width = this.canvas.getBoundingClientRect().width;
      // this.canvas.height = this.canvas.getBoundingClientRect().height;
      // this.canvas.width = window.innerWidth;
      // this.canvas.height = window.innerHeight;
      this.ctx = this.canvas.getContext("2d");
      this.barWidth = this.canvas.width / 8;
      this.draw([300, 170, 100]);
    }
    // console.log(this.barWidth,this.canvas.width)
  }

  contextInit() {
    if (this.init)
      return true;




    this.audioCtx = new window.AudioContext();
    let audioSource = null;
    this.analyser = null;

    audioSource = this.audioCtx.createMediaElementSource(this.audioPlayerRef.nativeElement);
    this.analyser = this.audioCtx.createAnalyser();
    audioSource.connect(this.analyser);
    this.analyser.connect(this.audioCtx.destination);

    this.analyser.fftSize = 32;
    this.bufferLength = this.analyser.frequencyBinCount;
    this.dataArray = new Uint8Array(this.bufferLength);
    this.init = true;

  }

  clickPlayPause = (play: number) => {
      
    if (play==0) {
      this.play = false;
      this.audioPlayerRef.nativeElement.pause()
      this.audioPlayerRef.nativeElement.removeEventListener("timeupdate",()=>'')
      if (!this.noeq) {
        cancelAnimationFrame(this.animation)
      }
    }
    else if (play==1){
      this.play = true;
      this.audioPlayerRef.nativeElement.play()
      if (!this.noeq) {
        this.contextInit();
        this.animate();
      }
      this.audioPlayerRef.nativeElement.addEventListener("timeupdate", (currentTime) => {
        this.addProgress((currentTime.target.currentTime/currentTime.target.duration)*100);
      });
    }
    else{//stop
      this.play = false;
      this.audioPlayerRef.nativeElement.pause()
      this.audioPlayerRef.nativeElement.removeEventListener("timeupdate",()=>'')
      this.audioPlayerRef.nativeElement.currentTime=0;
    }

  }

  draw(draw_array: any) {
    // console.log(draw_array)
    if (draw_array.reduce((partialSum, a) => partialSum + a, 0) == 0)
      return;
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.beginPath();
    this.ctx.fillStyle = "#BE2675";
    this.x = 0;
    for (let i = 0; i < draw_array.length; i++) {
      this.barHeight = draw_array[i];
      this.ctx.roundRect(this.canvas.width / 2 - this.x - this.barWidth, this.canvas.height / 2 - this.barHeight, this.barWidth, this.barHeight, [1000, 1000, 0, 0]);
      this.ctx.roundRect(this.canvas.width / 2 - this.x - this.barWidth, this.canvas.height / 2 - 1, this.barWidth, this.barHeight, [0, 0, 1000, 1000]);
      this.x += this.barWidth + this.barSpacing;
    }

    this.x = this.barSpacing
    for (let i = 0; i < draw_array.length; i++) {
      this.barHeight = draw_array[i];
      this.ctx.roundRect(this.canvas.width / 2 + this.x, this.canvas.height / 2 - this.barHeight, this.barWidth, this.barHeight, [1000, 1000, 0, 0]);
      this.ctx.roundRect(this.canvas.width / 2 + this.x, this.canvas.height / 2, this.barWidth, this.barHeight, [0, 0, 1000, 1000]);
      this.x += this.barWidth + this.barSpacing;
    }
    this.ctx.fill();

  }

  animate = () => {
    this.analyser.getByteFrequencyData(this.dataArray);
    const arr = [1, 3, 5]

    let draw_array = []
    for (let i = 0; i < this.bufferLength; i++) {
      if (!arr.includes(i))
        continue;
      draw_array.push(this.dataArray[i]);
    }
    this.draw(draw_array);

    this.animation = requestAnimationFrame(this.animate);
  }





}
