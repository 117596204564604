import {environment as env} from "./environment.prod"

let environment = env;
environment.platform='test';
environment.production=false;
environment.apiUrl='http://localhost:8000/api/v1.0/';
// environment.apiUrl='https://resilience.guide/cms/api/v1.0/';
// environment.apiUrl='https://resilience.direct/cms/api/v1.0/';


export {environment};
