import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/index';
import {routeAnimation } from '../_animations/index';

@Component({
  selector: 'app-access-codes',
  templateUrl: './access-codes.component.html',
  styleUrls: ['./access-codes.component.scss'],
  animations:[routeAnimation],
  host: { '[@fadeRoute]': '' }
})
export class AccessCodesComponent implements OnInit {

	error = "If you have been provided with an Access Code please enter it below";
	model:any = {};
	list: any = [];

	constructor(private userService:UserService) { }

	ngOnInit() {
	}

    addAccessCode() {
      if (!this.model.access_code)
        {
          this.error= "Access Code empty";
          return true;
        }
      this.userService.addAccessCode(this.model.access_code).subscribe(
        result => {
           
            this.error= "Success! You have access to the following";
            this.model.access_code="";
            this.list = result.scope;
          },
          error => {
              this.error = error ;
          })
  }

}
